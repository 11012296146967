import React, { createContext, useContext, useReducer, useCallback } from 'react';
import { apiService } from '../services/api';

const StudentContext = createContext();

const studentReducer = (state, action) => {
  switch (action.type) {
    case 'SET_LOADING':
      return {
        ...state,
        isLoading: action.payload
      };
    case 'SET_ERROR':
      return {
        ...state,
        error: action.payload,
        isLoading: false
      };
    case 'ADD_STUDENT':
      return {
        ...state,
        items: [...state.items, action.payload],
        isLoading: false,
        error: null
      };
    case 'UPDATE_STUDENT':
      return {
        ...state,
        items: state.items.map(student =>
          student.id === action.payload.id ? action.payload : student
        ),
        isLoading: false,
        error: null
      };
    case 'DELETE_STUDENT':
      return {
        ...state,
        items: state.items.filter(student => student.id !== action.payload),
        isLoading: false,
        error: null
      };
    case 'SET_STUDENTS':
      return {
        ...state,
        items: action.payload,
        isLoading: false,
        error: null
      };
    default:
      return state;
  }
};

export const StudentProvider = ({ children }) => {
  const [state, dispatch] = useReducer(studentReducer, {
    items: [],
    isLoading: false,
    error: null,
  });

  const fetchStudents = useCallback(async () => {
    dispatch({ type: 'SET_LOADING', payload: true });
    try {
      const response = await apiService.getStudents();
      dispatch({ type: 'SET_STUDENTS', payload: response.data });
    } catch (error) {
      dispatch({ type: 'SET_ERROR', payload: error.message });
    }
  }, []);

  const addStudent = async (studentData) => {
    dispatch({ type: 'SET_LOADING', payload: true });
    try {
      const response = await apiService.createStudent(studentData);
      dispatch({ 
        type: 'ADD_STUDENT', 
        payload: {
          ...studentData,
          id: response.data.id,
          accountingCustomerId: response.data.accountingCustomerId 
        }
      });
      return response.data; 
    } catch (error) {
      dispatch({ 
        type: 'SET_ERROR', 
        payload: error.response?.data?.error || 'Failed to create student' 
      });
      throw error; 
    }
  };

  const updateStudent = (student) => {
    dispatch({ type: 'UPDATE_STUDENT', payload: student });
  };

  const deleteStudent = (studentId) => {
    dispatch({ type: 'DELETE_STUDENT', payload: studentId });
  };

  const setStudents = (students) => {
    dispatch({ type: 'SET_STUDENTS', payload: students });
  };

  const value = {
    students: state.items,
    isLoading: state.isLoading,
    error: state.error,
    fetchStudents,
    addStudent,
    updateStudent,
    deleteStudent,
    setStudents,
  };

  return (
    <StudentContext.Provider value={value}>
      {children}
    </StudentContext.Provider>
  );
};

export const useStudents = () => {
  const context = useContext(StudentContext);
  if (context === undefined) {
    throw new Error('useStudents must be used within a StudentProvider');
  }
  return context;
};

export default StudentProvider;