import NetInfo from '@react-native-community/netinfo';

export const checkNetworkAndApi = async () => {
  try {
    const netInfo = await NetInfo.fetch();
    
    if (!netInfo.isConnected) {
      throw new Error('No internet connection');
    }

    // Try to ping your API
    const response = await fetch(config.api.baseURL + '/health');
    if (!response.ok) {
      throw new Error('API is not responding');
    }

    return true;
  } catch (error) {
    console.log('Network check failed:', error);
    return false;
  }
};