// utils/referenceValidation.js

// Define valid source document types for each target document type
const VALID_REFERENCE_TYPES = {
  'RECEIPT': ['TAX_INVOICE'],
  'CREDIT_INVOICE': ['TAX_INVOICE', 'TAX_INVOICE_RECEIPT'],
  // Add other document types here if needed
};

export const validateReferenceDocument = (referenceDoc, targetDocType) => {
  if (!referenceDoc) return null;

  // Check if the reference document type is valid for the target document type
  const allowedTypes = VALID_REFERENCE_TYPES[targetDocType];
  if (!allowedTypes || !allowedTypes.includes(referenceDoc.document_type)) {
    return 'Invalid reference document type for this operation';
  }

  // Additional validation based on document type
  switch (targetDocType) {
    case 'CREDIT_INVOICE':
      // Validate that amount is not greater than original
      if (referenceDoc.remaining_amount <= 0) {
        return 'Cannot issue credit for fully credited document';
      }
      break;

    case 'RECEIPT':
      // Validate that document is not fully paid
      if (referenceDoc.status === 'PAID') {
        return 'Cannot issue receipt for fully paid document';
      }
      break;

    // Add other specific validations as needed
  }

  return null;
};

export const getReferenceDocumentDefaults = (referenceDoc, targetDocType) => {
  if (!referenceDoc) return null;

  const defaults = {
    entity: {
      id: referenceDoc.entity_id,
      accounting_id: referenceDoc.accounting_id,
      firstName: referenceDoc.firstName,
      lastName: referenceDoc.lastName,
      name: referenceDoc.supplier_name
    },
    description: `${targetDocType} for document #${referenceDoc.record_number}`,
    amount: referenceDoc.remaining_amount || referenceDoc.amount
  };

  // Add type-specific defaults
  switch (targetDocType) {
    case 'CREDIT_INVOICE':
      return {
        ...defaults,
        items: referenceDoc.items?.map(item => ({
          ...item,
          quantity: -item.quantity,
          lineTotal: -item.lineTotal
        })) || []
      };

    case 'RECEIPT':
      return {
        ...defaults,
        // Don't copy line items for receipts
        items: []
      };

    default:
      return defaults;
  }
};

export const getValidReferenceDocuments = (documents, targetDocType) => {
  if (!documents || !targetDocType) return [];
  
  return documents.filter(doc => {
    // Check if document type is valid for target
    const allowedTypes = VALID_REFERENCE_TYPES[targetDocType];
    if (!allowedTypes || !allowedTypes.includes(doc.document_type)) {
      return false;
    }

    // Additional filtering based on document type
    switch (targetDocType) {
      case 'CREDIT_INVOICE':
        return doc.status === 'ACTIVE' && doc.remaining_amount > 0;
      
      case 'RECEIPT':
        return doc.status === 'ACTIVE' && doc.status !== 'PAID';
      
      default:
        return doc.status === 'ACTIVE';
    }
  });
};