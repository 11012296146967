import React, { useState, useEffect, useMemo } from 'react';
import { View, Text, ScrollView } from 'react-native';
import { ReportContainer, useReportStyles } from '../../components/reports/ReportContainer';
import { usePayments } from '../../context/PaymentContext';
import { useStudents } from '../../context/StudentContext';
import { generateCSV } from '../../utils/helpers';
import * as Print from 'expo-print';
import * as Sharing from 'expo-sharing';

export default function IncomeReportScreen() {
    const styles = useReportStyles();
    const { records, fetchRecords, isLoading } = usePayments();
    const { students } = useStudents();
    const [selectedPeriod, setSelectedPeriod] = useState('month'); // month, quarter, year


    useEffect(() => {
        fetchRecords();
    }, [fetchRecords]);

    const reportData = useMemo(() => {
        const data = {
            totalIncome: 0,
            realizedIncome: 0,
            unrealizedIncome: 0,
            cancelledIncome: 0,
            byStudent: {},
            byMonth: {},
            byStatus: {
                PAID: 0,
                UNPAID: 0,
                CANCELLED: 0
            }
        };

        records.forEach(record => {
            // Skip receipts that are linked to invoices to avoid double counting
            if (record.type === 'RECEIPT' && record.linked_invoice_id) return;

            const amount = parseFloat(record.amount);
            const student = students.find(s => s.id === record.student_id);
            const month = new Date(record.issue_date).toLocaleString('default', { month: 'long' });

            // Total calculations
            if (record.status !== 'CANCELLED') {
                data.totalIncome += amount;
                if (record.status === 'PAID') {
                    data.realizedIncome += amount;
                } else {
                    data.unrealizedIncome += amount;
                }
            } else {
                data.cancelledIncome += amount;
            }

            // By student
            if (student) {
                data.byStudent[student.id] = data.byStudent[student.id] || {
                    name: `${student.firstName} ${student.lastName}`,
                    total: 0,
                    paid: 0,
                    unpaid: 0
                };
                data.byStudent[student.id].total += amount;
                if (record.status === 'PAID') {
                    data.byStudent[student.id].paid += amount;
                } else if (record.status === 'UNPAID') {
                    data.byStudent[student.id].unpaid += amount;
                }
            }

            // By month
            data.byMonth[month] = data.byMonth[month] || {
                total: 0,
                paid: 0,
                unpaid: 0
            };
            data.byMonth[month].total += amount;
            if (record.status === 'PAID') {
                data.byMonth[month].paid += amount;
            } else if (record.status === 'UNPAID') {
                data.byMonth[month].unpaid += amount;
            }

            // By status
            data.byStatus[record.status] += amount;
        });

        return data;
    }, [records, students]);

    const handleExportPDF = async () => {
        const formatCurrency = (amount) => `₪${parseFloat(amount).toFixed(2)}`;
        
        const htmlContent = `
          <html>
            <head>
              <style>
                body { font-family: Arial, sans-serif; }
                .header { text-align: center; margin-bottom: 20px; }
                .section { margin-bottom: 20px; }
                .section-title { 
                  font-size: 18px; 
                  font-weight: bold;
                  margin-bottom: 10px;
                  background-color: #f5f5f5;
                  padding: 5px;
                }
                table { 
                  width: 100%; 
                  border-collapse: collapse; 
                  margin-bottom: 20px;
                }
                th, td { 
                  border: 1px solid #ddd; 
                  padding: 8px; 
                  text-align: left;
                }
                th { background-color: #f5f5f5; }
                .total { font-weight: bold; }
              </style>
            </head>
            <body>
              <div class="header">
                <h1>Income Report</h1>
                <p>Generated on ${new Date().toLocaleDateString()}</p>
              </div>
      
              <div class="section">
                <div class="section-title">Summary</div>
                <table>
                  <tr>
                    <td>Total Income</td>
                    <td class="total">${formatCurrency(reportData.totalIncome)}</td>
                  </tr>
                  <tr>
                    <td>Realized Income</td>
                    <td>${formatCurrency(reportData.realizedIncome)}</td>
                  </tr>
                  <tr>
                    <td>Unrealized Income</td>
                    <td>${formatCurrency(reportData.unrealizedIncome)}</td>
                  </tr>
                  <tr>
                    <td>Cancelled</td>
                    <td>${formatCurrency(reportData.cancelledIncome)}</td>
                  </tr>
                </table>
              </div>
      
              <div class="section">
                <div class="section-title">Monthly Breakdown</div>
                <table>
                  <tr>
                    <th>Month</th>
                    <th>Total</th>
                    <th>Paid</th>
                    <th>Unpaid</th>
                  </tr>
                  ${Object.entries(reportData.byMonth)
                    .map(([month, data]) => `
                      <tr>
                        <td>${month}</td>
                        <td>${formatCurrency(data.total)}</td>
                        <td>${formatCurrency(data.paid)}</td>
                        <td>${formatCurrency(data.unpaid)}</td>
                      </tr>
                    `).join('')}
                </table>
              </div>
      
              <div class="section">
                <div class="section-title">By Student</div>
                <table>
                  <tr>
                    <th>Student</th>
                    <th>Total</th>
                    <th>Paid</th>
                    <th>Unpaid</th>
                  </tr>
                  ${Object.entries(reportData.byStudent)
                    .map(([_, data]) => `
                      <tr>
                        <td>${data.name}</td>
                        <td>${formatCurrency(data.total)}</td>
                        <td>${formatCurrency(data.paid)}</td>
                        <td>${formatCurrency(data.unpaid)}</td>
                      </tr>
                    `).join('')}
                </table>
              </div>
            </body>
          </html>
        `;
      
        try {
            const file = await Print.printToFileAsync({
              html: htmlContent,
              base64: false
            });
            
            await Sharing.shareAsync(file.uri, {
              UTI: '.pdf',
              mimeType: 'application/pdf'
            });
          
            return file.uri;
          } catch (error) {
            console.error('PDF generation error:', error);
            throw error;
          }
      };

    const handleExportCSV = () => {
        const headers = ['Category', 'Total', 'Paid', 'Unpaid'];
        const data = [
            {
                Category: 'Overall',
                Total: reportData.totalIncome,
                Paid: reportData.realizedIncome,
                Unpaid: reportData.unrealizedIncome
            },
            ...Object.entries(reportData.byStudent).map(([_, data]) => ({
                Category: data.name,
                Total: data.total,
                Paid: data.paid,
                Unpaid: data.unpaid
            })),
            ...Object.entries(reportData.byMonth).map(([month, data]) => ({
                Category: month,
                Total: data.total,
                Paid: data.paid,
                Unpaid: data.unpaid
            }))
        ];

        return generateCSV(headers, data);
    };

    return (
        <ReportContainer
            onExportCSV={handleExportCSV}
            onExportPDF={handleExportPDF}
            isLoading={isLoading}
        >
            <ScrollView style={styles.content}>
                {/* Summary Card */}
                <View style={styles.card}>
                    <Text style={styles.sectionTitle}>Summary</Text>
                    <View style={styles.row}>
                        <Text style={styles.label}>Total Income</Text>
                        <Text style={[styles.value, styles.highlight]}>
                            ₪{reportData.totalIncome.toFixed(2)}
                        </Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={styles.label}>Realized Income</Text>
                        <Text style={styles.value}>₪{reportData.realizedIncome.toFixed(2)}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={styles.label}>Unrealized Income</Text>
                        <Text style={styles.value}>₪{reportData.unrealizedIncome.toFixed(2)}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={styles.label}>Cancelled</Text>
                        <Text style={styles.value}>₪{reportData.cancelledIncome.toFixed(2)}</Text>
                    </View>
                </View>

                {/* Monthly Breakdown */}
                <View style={styles.card}>
                    <Text style={styles.sectionTitle}>Monthly Breakdown</Text>
                    {Object.entries(reportData.byMonth).map(([month, data]) => (
                        <View key={month} style={styles.row}>
                            <Text style={styles.label}>{month}</Text>
                            <View>
                                <Text style={styles.value}>₪{data.total.toFixed(2)}</Text>
                                <Text style={styles.smallText}>
                                    Paid: ₪{data.paid.toFixed(2)} | Unpaid: ₪{data.unpaid.toFixed(2)}
                                </Text>
                            </View>
                        </View>
                    ))}
                </View>

                {/* Student Breakdown */}
                <View style={styles.card}>
                    <Text style={styles.sectionTitle}>By Student</Text>
                    {Object.entries(reportData.byStudent).map(([_, data]) => (
                        <View key={data.name} style={styles.row}>
                            <Text style={styles.label}>{data.name}</Text>
                            <View>
                                <Text style={styles.value}>₪{data.total.toFixed(2)}</Text>
                                <Text style={styles.smallText}>
                                    Paid: ₪{data.paid.toFixed(2)} | Unpaid: ₪{data.unpaid.toFixed(2)}
                                </Text>
                            </View>
                        </View>
                    ))}
                </View>
            </ScrollView>
        </ReportContainer>
    );
}