// src/context/AuthContext.js
import React, { createContext, useContext, useReducer } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { apiService } from '../services/api';
import { reset } from '../navigation/navigationRef';

const AuthContext = createContext();

const authReducer = (state, action) => {
  switch (action.type) {
    case 'AUTH_START':
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case 'AUTH_SUCCESS':
      return {
        ...state,
        isLoading: false,
        user: action.payload.user,
        token: action.payload.token,
        error: null,
      };
    case 'AUTH_FAILURE':
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case 'LOGOUT':
      return {
        ...state,
        user: null,
        token: null,
        isLoading: false,
        error: null,
      };
    case 'CLEAR_ERROR':
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const AuthProvider = ({ children, navigation }) => {
  const [state, dispatch] = useReducer(authReducer, {
    user: null,
    token: null,
    isLoading: false,
    error: null,
  });

  const setAuthData = async (data) => {
    try {
      await AsyncStorage.setItem('authData', JSON.stringify(data));
      dispatch({ type: 'AUTH_SUCCESS', payload: data });
    } catch (error) {
      console.error('Error saving auth data:', error);
    }
  };

  const login = async (email, password) => {
    dispatch({ type: 'AUTH_START' });
    try {
      const response = await apiService.login({ email, password });
      await setAuthData(response.data);
      return true;
    } catch (error) {
      dispatch({ type: 'AUTH_FAILURE', payload: error.message });
      return false;
    }
  };

  const register = async (userData) => {
    dispatch({ type: 'AUTH_START' });
    try {
      const response = await apiService.register(userData);
      await setAuthData(response.data);
      return true;
    } catch (error) {
      dispatch({ type: 'AUTH_FAILURE', payload: error.message });
      return false;
    }
  };

  const logout = async () => {
    try {
      if (state.token) {
        await apiService.logout(state.token);
      }
      await AsyncStorage.removeItem('authData');
      dispatch({ type: 'LOGOUT' });
      
      // Use the navigation utility to reset to login screen
      reset('Login');
    } catch (error) {
      console.error('Error during logout:', error);
      await AsyncStorage.removeItem('authData');
      dispatch({ type: 'LOGOUT' });
      
      reset('Login');
    }
  };

  const clearError = () => {
    dispatch({ type: 'CLEAR_ERROR' });
  };

  // Check for stored auth data on app start
  React.useEffect(() => {
    const loadStoredAuth = async () => {
      try {
        const storedAuth = await AsyncStorage.getItem('authData');
        if (storedAuth) {
          const authData = JSON.parse(storedAuth);
          dispatch({ type: 'AUTH_SUCCESS', payload: authData });
        }
      } catch (error) {
        console.error('Error loading stored auth:', error);
      }
    };

    loadStoredAuth();
  }, []);

  const value = {
    user: state.user,
    token: state.token,
    isLoading: state.isLoading,
    error: state.error,
    login,
    register,
    logout,
    clearError,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};