import React, { createContext, useContext, useReducer, useCallback } from 'react';
import { apiService } from '../services/api';

const EventContext = createContext();

const validateEventData = (event) => {
  const errors = [];
  
  // Validate lesson type and duration match
  if (event.lessonType === 'single' && event.duration !== 40) {
    errors.push('Single lessons must be 40 minutes');
  }
  if (event.lessonType === 'double' && event.duration !== 80) {
    errors.push('Double lessons must be 80 minutes');
  }

  // Validate student count based on lesson type
  if (event.studentIds) {
    if (event.lessonType === 'single' && event.studentIds.length > 1) {
      errors.push('Single lessons can have at most one student');
    }
    if (event.lessonType === 'double' && event.studentIds.length > 2) {
      errors.push('Double lessons can have at most two students');
    }
  }

  return errors;
};

const eventReducer = (state, action) => {
  switch (action.type) {
    case 'SET_LOADING':
      return {
        ...state,
        isLoading: action.payload
      };
    case 'SET_ERROR':
      return {
        ...state,
        error: action.payload,
        isLoading: false
      };
    case 'ADD_EVENT':
      return {
        ...state,
        items: [...state.items, action.payload],
        isLoading: false,
        error: null
      };
    case 'UPDATE_EVENT':
      return {
        ...state,
        items: state.items.map(event =>
          event.id === action.payload.id ? action.payload : event
        ),
        isLoading: false,
        error: null
      };
    case 'DELETE_EVENT':
      return {
        ...state,
        items: state.items.filter(event => event.id !== action.payload),
        isLoading: false,
        error: null
      };
    case 'SET_EVENTS':
      return {
        ...state,
        items: action.payload,
        isLoading: false,
        error: null
      };
    default:
      return state;
  }
};

export const EventProvider = ({ children }) => {
  const [state, dispatch] = useReducer(eventReducer, {
    items: [],
    isLoading: false,
    error: null,
  });

  const fetchEvents = useCallback(async (startDate, endDate) => {
    dispatch({ type: 'SET_LOADING', payload: true });
    try {
      const response = await apiService.getEvents(startDate, endDate);
      dispatch({ type: 'SET_EVENTS', payload: response.data });
    } catch (error) {
      dispatch({ type: 'SET_ERROR', payload: error.message });
    }
  }, []);

  const addEvent = useCallback(async (event) => {
    dispatch({ type: 'SET_LOADING', payload: true });
    try {
      // Validate event data
      const validationErrors = validateEventData(event);
      if (validationErrors.length > 0) {
        throw new Error(validationErrors.join(', '));
      }
  
      const response = await apiService.createEvent(event);
      dispatch({ type: 'ADD_EVENT', payload: { ...event, id: response.data.id } });
    } catch (error) {
      dispatch({ type: 'SET_ERROR', payload: error.message });
    }
  }, []);

  const updateEvent = useCallback(async (event) => {
    dispatch({ type: 'SET_LOADING', payload: true });
    try {
      const validationErrors = validateEventData(event);
      if (validationErrors.length > 0) {
        throw new Error(validationErrors.join(', '));
      }
  
      await apiService.updateEvent(event.id, event);
      dispatch({ type: 'UPDATE_EVENT', payload: event });
    } catch (error) {
      dispatch({ type: 'SET_ERROR', payload: error.message });
    }
  }, []);

  const deleteEvent = useCallback(async (eventId) => {
    dispatch({ type: 'SET_LOADING', payload: true });
    try {
      await apiService.deleteEvent(eventId);
      dispatch({ type: 'DELETE_EVENT', payload: eventId });
    } catch (error) {
      dispatch({ type: 'SET_ERROR', payload: error.message });
    }
  }, []);

  const value = {
    events: state.items,
    isLoading: state.isLoading,
    error: state.error,
    fetchEvents,
    addEvent,
    updateEvent,
    deleteEvent,
  };

  return (
    <EventContext.Provider value={value}>
      {children}
    </EventContext.Provider>
  );
};

export const useEvents = () => {
  const context = useContext(EventContext);
  if (context === undefined) {
    throw new Error('useEvents must be used within an EventProvider');
  }
  return context;
};

export default EventProvider;