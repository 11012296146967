import React from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { Feather } from '@expo/vector-icons';
import { useThemedStyles } from '../../theme/hooks';

export const DocumentControls = ({
  onSearchPress,
  onFilterPress,
  onSortPress,
  onClearAll,
  sortConfig,
  activeFiltersCount,
  hasActiveFilters,
  getSortLabel,
}) => {
  const styles = useThemedStyles((colors) => ({
    controlsContainer: {
      paddingHorizontal: 16,
      flexDirection: 'row',
      alignItems: 'center',
      minHeight: 40,
      gap: 16,
    },
    leftControls: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    rightControls: {
      marginLeft: 'auto',
    },
    iconButton: {
      padding: 8,
      borderRadius: 8,
    },
    filterButton: {
      padding: 8,
      marginLeft: 8,
      position: 'relative',
      borderRadius: 8,
    },
    filterBadge: {
      position: 'absolute',
      top: -4,
      right: -4,
      backgroundColor: colors.primary,
      borderRadius: 12,
      minWidth: 16,
      height: 16,
      justifyContent: 'center',
      alignItems: 'center',
      borderWidth: 1.5,
      borderColor: colors.background,
    },
    filterBadgeText: {
      color: colors.white,
      fontSize: 10,
      fontWeight: 'bold',
    },
    sortButton: {
      flexDirection: 'row',
      alignItems: 'center',
      paddingHorizontal: 12,
      height: 32,
      backgroundColor: colors.background,
      borderRadius: 16,
      borderWidth: 1,
      borderColor: colors.border,
    },
    sortLabel: {
      fontSize: 14,
      color: colors.text,
      marginRight: 4,
    },
    clearButton: {
      flexDirection: 'row',
      alignItems: 'center',
      backgroundColor: `${colors.error}10`,
      paddingHorizontal: 8,
      paddingVertical: 4,
      borderRadius: 16,
    },
    clearButtonText: {
      color: colors.error,
      fontSize: 13,
      marginLeft: 4,
      fontWeight: '500',
    },
  }));

  return (
    <View style={styles.controlsContainer}>
      <View style={styles.leftControls}>
        <TouchableOpacity
          style={styles.iconButton}
          onPress={onSearchPress}
        >
          <Feather name="search" size={20} color={styles.sortLabel.color} />
        </TouchableOpacity>
        <TouchableOpacity
          style={styles.filterButton}
          onPress={onFilterPress}
        >
          <Feather name="filter" size={20} color={styles.sortLabel.color} />
          {activeFiltersCount > 0 && (
            <View style={styles.filterBadge}>
              <Text style={styles.filterBadgeText}>{activeFiltersCount}</Text>
            </View>
          )}
        </TouchableOpacity>
      </View>

      {hasActiveFilters && (
        <TouchableOpacity
          style={styles.clearButton}
          onPress={onClearAll}
        >
          <Feather name="x" size={14} color={styles.clearButtonText.color} />
          <Text style={styles.clearButtonText}>Clear</Text>
        </TouchableOpacity>
      )}

      <View style={styles.rightControls}>
        <TouchableOpacity
          style={styles.sortButton}
          onPress={onSortPress}
        >
          <Text style={styles.sortLabel}>{getSortLabel()}</Text>
          <Feather
            name={sortConfig.direction === 'asc' ? 'arrow-up' : 'arrow-down'}
            size={16}
            color={styles.sortLabel.color}
          />
        </TouchableOpacity>
      </View>
    </View>
  );
};