import React, { useRef, useEffect } from 'react';
import {
  View,
  Text,
  StyleSheet,
  Image,
  Dimensions,
  Animated,
  SafeAreaView,
  Pressable,
} from 'react-native';
import { useTheme } from '../../theme/ThemeContext';
import { colors } from '../../theme/colors';
import { useTranslation } from 'react-i18next';
import { Ionicons } from '@expo/vector-icons';

const { width } = Dimensions.get('window');

export default function WebLandingScreen({ navigation }) {
  // For responsive behavior
  const isMobile = width < 768;
  const isSmallMobile = width < 480;
  const { t, i18n } = useTranslation();
  const { isDark, toggleTheme } = useTheme();
  const themeColors = colors[isDark ? 'light' : 'dark'];
  
  // Set the language to Hebrew if not already set
  useEffect(() => {
    if (i18n.language !== 'he') {
      i18n.changeLanguage('he');
    }
  }, []);
  
  // Animation values
  const fadeIn = useRef(new Animated.Value(0)).current;
  
  useEffect(() => {
    // Simple fade in animation
    Animated.timing(fadeIn, {
      toValue: 1,
      duration: 800,
      useNativeDriver: true,
    }).start();
  }, []);

  // Add a mobile menu button for small screens
  const renderMobileMenuButton = () => {
    if (width > 600) return null;
    
    return (
      <Pressable 
        style={[styles.mobileMenuButton, { backgroundColor: themeColors.primary + '20' }]}
        onPress={() => console.log('Mobile menu clicked')}
      >
        <Ionicons name="menu-outline" size={24} color={themeColors.primary} />
      </Pressable>
    );
  };

  return (
    <SafeAreaView style={[styles.outerContainer, { backgroundColor: themeColors.background }]}>
      <View style={styles.container}>
      {/* Website Header/Navigation */}
      <View style={[styles.navbar, { backgroundColor: themeColors.card }]}>
        <View style={styles.navContent}>
          <View style={styles.navBrand}>
            <Image 
              source={require('../../../assets/icon.png')} 
              style={styles.navLogo} 
              resizeMode="contain"
            />
            <Text style={[styles.navTitle, { color: themeColors.text }]}>
              {t('app.name')}
            </Text>
          </View>
          
          {/* <View style={styles.navLinks}>
            <Pressable style={styles.navLink}>
              <Text style={[styles.navLinkText, { color: themeColors.primary }]}>{t('nav.features')}</Text>
            </Pressable>
            <Pressable style={styles.navLink}>
              <Text style={[styles.navLinkText, { color: themeColors.primary }]}>{t('nav.about')}</Text>
            </Pressable>
            <Pressable style={styles.navLink}>
              <Text style={[styles.navLinkText, { color: themeColors.primary }]}>{t('nav.contact')}</Text>
            </Pressable>
          </View> */}
          
          <View style={styles.navRightSection}>
            <Pressable 
              style={[styles.themeToggle, { backgroundColor: themeColors.primary + '20' }]}
              onPress={toggleTheme}
            >
              <Ionicons 
                name={isDark ? "moon-outline" : "sunny-outline"} 
                size={20} 
                color={themeColors.primary} 
              />
            </Pressable>
            {renderMobileMenuButton()}
          </View>
        </View>
      </View>
      
      {/* Hero Section */}
      <Animated.View 
        style={[
          styles.heroSection, 
          { opacity: fadeIn, backgroundColor: themeColors.background }
        ]}
      >
        <View style={styles.heroContent}>
          <View style={styles.heroTextContent}>
            <Text style={[styles.heroTitle, { color: themeColors.text }]}>
              {t('app.name')}
            </Text>
            <Text style={[styles.heroSubtitle, { color: themeColors.textSecondary }]}>
              {t('app.tagline')}
            </Text>
            <View style={styles.ctaContainer}>
              <Pressable style={[styles.primaryButton, { backgroundColor: themeColors.primary }]}>
                <Text style={styles.primaryButtonText}>{t('web.cta.learnMore')}</Text>
              </Pressable>
              <Pressable style={[styles.outlineButton, { borderColor: themeColors.primary }]}>
                <Text style={[styles.outlineButtonText, { color: themeColors.primary }]}>{t('web.cta.contact')}</Text>
              </Pressable>
            </View>
          </View>
          <View style={styles.heroImageContainer}>
            <Image 
              source={require('../../../assets/icon.png')} 
              style={styles.heroImage} 
              resizeMode="contain"
            />
          </View>
        </View>
      </Animated.View>
      
      {/* Features Section with a different background */}
      <View style={[styles.featuresSection, { backgroundColor: themeColors.card + '30' }]}>
        <Text style={[styles.sectionTitle, { color: themeColors.text }]}>
          {t('web.features.title')}
        </Text>
        
        <View style={styles.featuresGrid}>
          <FeatureCard 
            icon="calendar-outline" 
            title={t('features.schedule')}
            description={t('features.scheduleDesc')}
            color={themeColors.primary}
            themeColors={themeColors}
          />
          
          <FeatureCard 
            icon="people-outline" 
            title={t('features.students')}
            description={t('features.studentsDesc')}
            color={themeColors.success}
            themeColors={themeColors}
          />
          
          <FeatureCard 
            icon="stats-chart-outline" 
            title={t('features.reports')}
            description={t('features.reportsDesc')}
            color={themeColors.warning}
            themeColors={themeColors}
          />
        </View>
      </View>
      
      {/* Mobile App Download Section */}
      <View style={styles.downloadSection}>
        <View style={styles.downloadContent}>
          <View style={styles.downloadTextContent}>
            <Text style={[styles.downloadTitle, { color: themeColors.text }]}>
              {t('web.download.title')}
            </Text>
            <Text style={[styles.downloadDescription, { color: themeColors.textSecondary }]}>
              {t('auth.landing.webMessage')}
            </Text>
          </View>
          
          <View style={styles.storeButtonsContainer}>
            <View style={[styles.storeButton, { backgroundColor: themeColors.primary + '10', borderColor: themeColors.primary }]}>
              <Ionicons name="logo-apple" size={24} color={themeColors.primary} style={styles.storeIcon} />
              <View>
                <Text style={[styles.storeButtonSmallText, { color: themeColors.text }]}>Download on the</Text>
                <Text style={[styles.storeButtonText, { color: themeColors.text }]}>App Store</Text>
              </View>
            </View>
            
            <View style={[styles.storeButton, { backgroundColor: themeColors.primary + '10', borderColor: themeColors.primary }]}>
              <Ionicons name="logo-google-playstore" size={24} color={themeColors.primary} style={styles.storeIcon} />
              <View>
                <Text style={[styles.storeButtonSmallText, { color: themeColors.text }]}>GET IT ON</Text>
                <Text style={[styles.storeButtonText, { color: themeColors.text }]}>Google Play</Text>
              </View>
            </View>
          </View>
        </View>
      </View>
      
      {/* Footer */}
      <View style={[styles.footer, { backgroundColor: themeColors.card }]}>
        <View style={styles.footerContent}>
          <View style={styles.footerBrand}>
            <Image 
              source={require('../../../assets/icon.png')} 
              style={styles.footerLogo} 
              resizeMode="contain"
            />
            <Text style={[styles.footerTitle, { color: themeColors.text }]}>{t('app.name')}</Text>
          </View>
          
          {/* <View style={styles.footerLinks}>
            <View style={styles.footerColumn}>
              <Text style={[styles.footerColumnTitle, { color: themeColors.text }]}>{t('footer.company')}</Text>
              <Text style={[styles.footerLink, { color: themeColors.textSecondary }]}>{t('footer.about')}</Text>
              <Text style={[styles.footerLink, { color: themeColors.textSecondary }]}>{t('footer.careers')}</Text>
              <Text style={[styles.footerLink, { color: themeColors.textSecondary }]}>{t('footer.blog')}</Text>
            </View>
            
            <View style={styles.footerColumn}>
              <Text style={[styles.footerColumnTitle, { color: themeColors.text }]}>{t('footer.support')}</Text>
              <Text style={[styles.footerLink, { color: themeColors.textSecondary }]}>{t('footer.help')}</Text>
              <Text style={[styles.footerLink, { color: themeColors.textSecondary }]}>{t('footer.faq')}</Text>
              <Text style={[styles.footerLink, { color: themeColors.textSecondary }]}>{t('footer.contact')}</Text>
            </View>
            
            <View style={styles.footerColumn}>
              <Text style={[styles.footerColumnTitle, { color: themeColors.text }]}>{t('footer.legal')}</Text>
              <Text style={[styles.footerLink, { color: themeColors.textSecondary }]}>{t('footer.privacy')}</Text>
              <Text style={[styles.footerLink, { color: themeColors.textSecondary }]}>{t('footer.terms')}</Text>
            </View>
          </View> */}
        </View>
        
        <View style={styles.footerBottom}>
          <Text style={[styles.copyright, { color: themeColors.textSecondary }]}>
            © 2025 {t('app.name')}. {t('footer.copyright')}
          </Text>
          
          <View style={styles.socialLinks}>
            <Ionicons name="logo-twitter" size={20} color={themeColors.textSecondary} style={styles.socialIcon} />
            <Ionicons name="logo-facebook" size={20} color={themeColors.textSecondary} style={styles.socialIcon} />
            <Ionicons name="logo-instagram" size={20} color={themeColors.textSecondary} style={styles.socialIcon} />
          </View>
        </View>
      </View>
      </View>
    </SafeAreaView>
  );
}

const FeatureCard = ({ icon, title, description, color, themeColors }) => {
  return (
    <View style={[styles.featureCard, { backgroundColor: themeColors.card }]}>
      <View style={[styles.iconContainer, { backgroundColor: `${color}20` }]}>
        <Ionicons name={icon} size={30} color={color} />
      </View>
      <View style={styles.featureTextContainer}>
        <Text style={[styles.featureTitle, { color: themeColors.text }]}>{title}</Text>
        <Text style={[styles.featureDescription, { color: themeColors.textSecondary }]}>
          {description}
        </Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  outerContainer: {
    flex: 1,
    width: '100%',
    direction: 'rtl',
    height: '100%',
    overflow: 'auto',
  },
  container: {
    width: '100%',
    minHeight: '100%',
  },
  // Navbar styles
  navbar: {
    width: '100%',
    paddingVertical: 16,
    paddingHorizontal: 24,
    borderBottomWidth: 1,
    borderBottomColor: 'rgba(0,0,0,0.1)',
  },
  navContent: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxWidth: 1200,
    marginHorizontal: 'auto',
    width: '100%',
    paddingVertical: width < 480 ? 8 : 0,
  },
  navBrand: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  navLogo: {
    width: 40,
    height: 40,
    marginRight: 12,
  },
  navTitle: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  navLinks: {
    flexDirection: width > 600 ? 'row' : 'none',
    alignItems: 'center',
    display: width > 600 ? 'flex' : 'none',
  },
  navLink: {
    marginHorizontal: 16,
  },
  navLinkText: {
    fontSize: 16,
    fontWeight: '500',
  },
  navRightSection: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  themeToggle: {
    width: 36,
    height: 36,
    borderRadius: 18,
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 16,
  },
  mobileMenuButton: {
    width: 36,
    height: 36,
    borderRadius: 18,
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 8,
  },
  
  // Hero section styles
  heroSection: {
    paddingVertical: width < 480 ? 30 : 60,
    paddingHorizontal: width < 480 ? 16 : 24,
  },
  heroContent: {
    flexDirection: width > 768 ? 'row' : 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxWidth: 1200,
    marginHorizontal: 'auto',
    width: '100%',
  },
  heroTextContent: {
    flex: 1,
    alignItems: width > 768 ? 'flex-start' : 'center',
    marginBottom: width > 768 ? 0 : 40,
  },
  heroTitle: {
    fontSize: width < 480 ? 28 : 38,
    fontWeight: 'bold',
    marginBottom: 16,
    textAlign: width > 768 ? 'start' : 'center',
  },
  heroSubtitle: {
    fontSize: 18,
    lineHeight: 28,
    marginBottom: 32,
    textAlign: width > 768 ? 'start' : 'center',
    maxWidth: 500,
  },
  ctaContainer: {
    flexDirection: width < 480 ? 'column' : 'row',
    marginTop: 8,
    gap: 2,
    alignItems: width < 480 ? 'center' : 'flex-start',
    width: width < 480 ? '100%' : 'auto',
  },
  primaryButton: {
    paddingVertical: 12,
    paddingHorizontal: 24,
    borderRadius: 8,
    marginRight: width < 480 ? 0 : 16,
    marginBottom: width < 480 ? 10 : 0,
    width: width < 480 ? '80%' : 'auto',
    alignItems: 'center',
  },
  primaryButtonText: {
    color: '#FFFFFF',
    fontSize: 16,
    fontWeight: '600',
  },
  outlineButton: {
    paddingVertical: 12,
    paddingHorizontal: 24,
    borderRadius: 8,
    borderWidth: 1,
    width: width < 480 ? '80%' : 'auto',
    alignItems: 'center',
  },
  outlineButtonText: {
    fontSize: 16,
    fontWeight: '600',
  },
  heroImageContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  heroImage: {
    width: width < 480 ? 200 : 320,
    height: width < 480 ? 200 : 320,
  },
  
  // Features section styles
  featuresSection: {
    paddingVertical: width < 480 ? 30 : 60,
    paddingHorizontal: width < 480 ? 16 : 24,
  },
  sectionTitle: {
    fontSize: width < 480 ? 24 : 32,
    fontWeight: 'bold',
    marginBottom: width < 480 ? 24 : 40,
    textAlign: 'center',
  },
  featuresGrid: {
    flexDirection: width > 768 ? 'row' : 'column',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    maxWidth: 1200,
    marginHorizontal: 'auto',
    width: '100%',
  },
  featureCard: {
    width: width > 768 ? '30%' : '100%',
    marginBottom: 30,
    padding: 24,
    borderRadius: 8,
    alignItems: 'flex-start',
  },
  iconContainer: {
    width: 60,
    height: 60,
    borderRadius: 30,
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 16,
  },
  featureTextContainer: {
    width: '100%',
  },
  featureTitle: {
    fontSize: 20,
    fontWeight: '700',
    marginBottom: 12,
  },
  featureDescription: {
    fontSize: 16,
    lineHeight: 24,
  },
  
  // Download section styles
  downloadSection: {
    paddingVertical: width < 480 ? 40 : 60,
    paddingHorizontal: width < 480 ? 16 : 24,
    marginTop: 20,
    borderTopWidth: 1,
    borderTopColor: 'rgba(0,0,0,0.1)',
    backgroundColor: 'rgba(0,0,0,0.02)',
  },
  downloadContent: {
    flexDirection: width > 768 ? 'row' : 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxWidth: 1200,
    marginHorizontal: 'auto',
    width: '100%',
  },
  downloadTextContent: {
    flex: 1,
    marginBottom: width > 768 ? 0 : 40,
    alignItems: width > 768 ? 'flex-start' : 'center',
  },
  downloadTitle: {
    fontSize: width < 480 ? 22 : 28,
    fontWeight: 'bold',
    marginBottom: 16,
    textAlign: width > 768 ? 'start' : 'center',
  },
  downloadDescription: {
    fontSize: 16,
    lineHeight: 24,
    textAlign: width > 768 ? 'start' : 'center',
    maxWidth: 500,
  },
  storeButtonsContainer: {
    flexDirection: width > 500 ? 'row' : 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  storeButton: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 12,
    paddingHorizontal: 16,
    borderRadius: 8,
    borderWidth: 1,
    margin: 8,
    minWidth: 180,
  },
  storeIcon: {
    marginRight: 12,
  },
  storeButtonSmallText: {
    fontSize: 12,
    fontWeight: '400',
  },
  storeButtonText: {
    fontSize: 16,
    fontWeight: '600',
  },
  
  // Footer styles
  footer: {
    paddingTop: width < 480 ? 32 : 16,
    paddingBottom: 24,
    paddingHorizontal: width < 480 ? 16 : 24,
  },
  footerContent: {
    flexDirection: width > 768 ? 'row' : 'column',
    justifyContent: 'space-between',
    maxWidth: 1200,
    marginHorizontal: 'auto',
    width: '100%',
    // paddingBottom: 40,
    borderBottomWidth: 1,
    borderBottomColor: 'rgba(0,0,0,0.1)',
  },
  footerBrand: {
    marginBottom: width > 768 ? 0 : 30,
  },
  footerLogo: {
    width: 60,
    height: 60,
    marginBottom: 16,
  },
  footerTitle: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 8,
  },
  footerLinks: {
    flexDirection: width > 768 ? 'row' : 'column',
    justifyContent: 'space-around',
    width: width > 768 ? '60%' : '100%',
  },
  footerColumn: {
    marginBottom: 24,
    minWidth: 120,
  },
  footerColumnTitle: {
    fontSize: 16,
    fontWeight: '600',
    marginBottom: 16,
  },
  footerLink: {
    fontSize: 14,
    marginBottom: 8,
  },
  footerBottom: {
    flexDirection: width > 500 ? 'row' : 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 24,
    maxWidth: 1200,
    marginHorizontal: 'auto',
    width: '100%',
  },
  copyright: {
    fontSize: 14,
    marginBottom: width > 500 ? 0 : 16,
  },
  socialLinks: {
    flexDirection: 'row',
  },
  socialIcon: {
    marginLeft: 16,
  },
});