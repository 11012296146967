import React, { useState, useMemo } from 'react';
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  FlatList,
  Modal,
  TextInput,
  Alert,
} from 'react-native';
import { useStudents } from '../../context/StudentContext';
import { Feather } from '@expo/vector-icons';
import { useThemedStyles } from '../../theme/hooks';
import { useTranslation } from 'react-i18next';
import { Menu } from 'react-native-paper';

export default function StudentsScreen({ navigation }) {
  const { t } = useTranslation();
  const [isModalVisible, setModalVisible] = useState(false);
  const [editingStudent, setEditingStudent] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [visibleMenu, setVisibleMenu] = useState(''); 


  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    licenseType: '',
    notes: '',
  });

  const { students, addStudent, updateStudent, deleteStudent } = useStudents();

  // Filter students based on search query
  const filteredStudents = useMemo(() => {
    const query = searchQuery.toLowerCase().trim();
    if (!query) return students;

    return students.filter(student =>
      student.firstName.toLowerCase().includes(query) ||
      student.lastName.toLowerCase().includes(query)
    );
  }, [students, searchQuery]);

  const styles = useThemedStyles((colors) => ({
    container: {
      flex: 1,
      backgroundColor: colors.background,
    },
    list: {
      flexGrow: 1,
      padding: 16,
      paddingTop: 8,
    },
    studentCard: {
      flexDirection: 'row',
      backgroundColor: colors.card,
      borderRadius: 12,
      padding: 16,
      marginBottom: 12,
      elevation: 2,
      shadowColor: colors.shadowColor,
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.1,
      shadowRadius: 4,
    },
    studentInfo: {
      flex: 1,
    },
    studentName: {
      fontSize: 18,
      fontWeight: 'bold',
      marginBottom: 4,
      color: colors.text,
    },
    studentDetails: {
      fontSize: 14,
      color: colors.textSecondary,
      marginBottom: 2,
    },
    studentEmail: {
      fontSize: 14,
      color: colors.textSecondary,
    },
    deleteButton: {
      padding: 8,
    },
    fab: {
      position: 'absolute',
      right: 16,
      bottom: 16,
      backgroundColor: colors.primary,
      width: 56,
      height: 56,
      borderRadius: 28,
      justifyContent: 'center',
      alignItems: 'center',
      elevation: 4,
      shadowColor: colors.shadowColor,
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.25,
      shadowRadius: 4,
    },
    modalContainer: {
      flex: 1,
      justifyContent: 'center',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    modalContent: {
      backgroundColor: colors.card,
      margin: 16,
      borderRadius: 12,
      padding: 16,
      maxHeight: '90%',
    },
    modalTitle: {
      fontSize: 20,
      fontWeight: 'bold',
      marginBottom: 16,
      textAlign: 'center',
      color: colors.text,
    },
    input: {
      height: 50,
      borderWidth: 1,
      borderColor: colors.border,
      borderRadius: 8,
      paddingHorizontal: 16,
      marginBottom: 12,
      fontSize: 16,
      color: colors.text,
      backgroundColor: colors.background,
    },
    placeholder: {
      color: colors.textSecondary,
    },
    textArea: {
      height: 100,
      paddingTop: 12,
    },
    modalButtons: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginTop: 16,
    },
    button: {
      flex: 1,
      height: 50,
      borderRadius: 8,
      justifyContent: 'center',
      alignItems: 'center',
      marginHorizontal: 8,
    },
    cancelButton: {
      backgroundColor: colors.error,
    },
    saveButton: {
      backgroundColor: colors.primary,
    },
    buttonText: {
      color: colors.card,
      fontSize: 16,
      fontWeight: 'bold',
    },
    searchContainer: {
      position: 'relative',
      padding: 16,
      paddingBottom: 8,
      backgroundColor: colors.background,
    },
    searchInput: {
      height: 40,
      backgroundColor: colors.card,
      borderRadius: 20,
      paddingHorizontal: 16,
      paddingLeft: 40,
      fontSize: 16,
      color: colors.text,
      borderWidth: 1,
      borderColor: colors.border,
    },
    searchIcon: {
      position: 'absolute',
      width: 40,
      left: 25, 
      top: '50%',
      transform: [{                
        translateY: 5
      }],
      zIndex: 1,  
    },
    noResults: {
      textAlign: 'center',
      marginTop: 20,
      fontSize: 16,
      color: colors.textSecondary,
    },
    menuButton: {
      padding: 8,
      justifyContent: 'center',
    },
  }));

  const resetForm = () => {
    setFormData({
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      licenseType: '',
      notes: '',
    });
    setEditingStudent(null);
  };

  const handleSubmit = () => {
    if (!formData.firstName || !formData.lastName) {
      Alert.alert(t('common.error'), t('students.errors.nameRequired'));
      return;
    }

    const studentData = {
      id: editingStudent ? editingStudent.id : Date.now().toString(),
      ...formData,
      createdAt: editingStudent ? editingStudent.createdAt : new Date().toISOString(),
      updatedAt: new Date().toISOString(),
    };

    if (editingStudent) {
      updateStudent(studentData);
      Alert.alert(t('common.success'), t('students.success.updated'));
    } else {
      addStudent(studentData);
      Alert.alert(t('common.success'), t('students.success.added'));
    }

    setModalVisible(false);
    resetForm();
  };

  const handleDelete = (student) => {
    Alert.alert(
      t('students.delete.title'),
      t('students.delete.confirm', { name: `${student.firstName} ${student.lastName}` }),
      [
        {
          text: t('students.buttons.cancel'),
          style: 'cancel',
        },
        {
          text: t('students.buttons.delete'),
          style: 'destructive',
          onPress: () => {
            deleteStudent(student.id);
            Alert.alert(t('common.success'), t('students.success.deleted'));
          },
        },
      ]
    );
  };

  const handleEdit = (student) => {
    setEditingStudent(student);
    setFormData({
      firstName: student.firstName,
      lastName: student.lastName,
      phone: student.phone,
      email: student.email,
      licenseType: student.licenseType,
      notes: student.notes,
    });
    setModalVisible(true);
  };

  const renderStudent = ({ item }) => {
    return (
      <TouchableOpacity
        style={styles.studentCard}
        onPress={() => handleEdit(item)}
      >
        <View style={styles.studentInfo}>
          <Text style={styles.studentName}>
            {item.firstName} {item.lastName}
          </Text>
          <Text style={styles.studentDetails}>
            {item.phone} • {item.licenseType}
          </Text>
          <Text style={styles.studentEmail}>{item.email}</Text>
        </View>
        <Menu
          visible={visibleMenu === item.id}
          onDismiss={() => setVisibleMenu('')}
          anchor={
            <TouchableOpacity
              style={styles.menuButton}
              onPress={() => setVisibleMenu(item.id)}
            >
              <Feather name="more-vertical" size={20} color="#666" />
            </TouchableOpacity>
          }
          contentStyle={{ backgroundColor: 'white' }}
        >
          <Menu.Item 
            onPress={() => {
              setVisibleMenu('');
              navigation.navigate('StudentReport', { studentId: item.id });
            }}
            title="View Report"
            leadingIcon={props => <Feather name="file-text" size={20} color="#666" {...props} />}
          />
          <Menu.Item 
            onPress={() => {
              setVisibleMenu('');
              handleDelete(item);
            }}
            title="Delete"
            leadingIcon={props => <Feather name="trash-2" size={20} color="#FF3B30" {...props} />}
            titleStyle={{ color: '#FF3B30' }}
          />
        </Menu>
      </TouchableOpacity>
    );
  };

  return (
    <View style={styles.container}>
      <View style={styles.searchContainer}>
        <Feather
          name="search"
          size={20}
          color={styles.placeholder.color}
          style={styles.searchIcon}
        />
        <TextInput
          style={styles.searchInput}
          placeholder={t('students.search.search')}
          placeholderTextColor={styles.placeholder.color}
          value={searchQuery}
          onChangeText={setSearchQuery}
          autoCapitalize="none"
        />
      </View>

      <FlatList
        data={filteredStudents}
        renderItem={renderStudent}
        keyExtractor={item => item.id}
        contentContainerStyle={styles.list}
        ListEmptyComponent={() => (
          <Text style={styles.noResults}>
            {searchQuery
              ? t('students.search.noSearchResults')
              : t('students.search.noStudents')}
          </Text>
        )}
      />

      <TouchableOpacity
        style={styles.fab}
        onPress={() => {
          resetForm();
          setModalVisible(true);
        }}
      >
        <Feather name="plus" size={24} color="#fff" />
      </TouchableOpacity>

      <Modal
        visible={isModalVisible}
        animationType="slide"
        transparent={true}
        onRequestClose={() => {
          setModalVisible(false);
          resetForm();
        }}
      >
        <View style={styles.modalContainer}>
          <View style={styles.modalContent}>
            <Text style={styles.modalTitle}>
              {editingStudent ? t('students.editStudent') : t('students.addNew')}
            </Text>

            <TextInput
              style={styles.input}
              placeholder={t('students.fields.firstName')}
              placeholderTextColor={styles.placeholder.color}
              value={formData.firstName}
              onChangeText={(text) => setFormData({ ...formData, firstName: text })}
            />

            <TextInput
              style={styles.input}
              placeholder={t('students.fields.lastName')}
              placeholderTextColor={styles.placeholder.color}
              value={formData.lastName}
              onChangeText={(text) => setFormData({ ...formData, lastName: text })}
            />

            <TextInput
              style={styles.input}
              placeholder={t('students.fields.phone')}
              placeholderTextColor={styles.placeholder.color}
              value={formData.phone}
              onChangeText={(text) => setFormData({ ...formData, phone: text })}
              keyboardType="phone-pad"
            />

            <TextInput
              style={styles.input}
              placeholder={t('students.fields.email')}
              placeholderTextColor={styles.placeholder.color}
              value={formData.email}
              onChangeText={(text) => setFormData({ ...formData, email: text })}
              keyboardType="email-address"
              autoCapitalize="none"
            />

            <TextInput
              style={styles.input}
              placeholder={t('students.fields.licenseType')}
              placeholderTextColor={styles.placeholder.color}
              value={formData.licenseType}
              onChangeText={(text) => setFormData({ ...formData, licenseType: text })}
            />

            <TextInput
              style={[styles.input, styles.textArea]}
              placeholder={t('students.fields.notes')}
              placeholderTextColor={styles.placeholder.color}
              value={formData.notes}
              onChangeText={(text) => setFormData({ ...formData, notes: text })}
              multiline
              numberOfLines={4}
              textAlignVertical="top"
            />

            <View style={styles.modalButtons}>
              <TouchableOpacity
                style={[styles.button, styles.cancelButton]}
                onPress={() => {
                  setModalVisible(false);
                  resetForm();
                }}
              >
                <Text style={styles.buttonText}>{t('students.buttons.cancel')}</Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={[styles.button, styles.saveButton]}
                onPress={handleSubmit}
              >
                <Text style={styles.buttonText}>
                  {editingStudent ? t('students.buttons.update') : t('students.buttons.save')}
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>
    </View>
  );
}