import React from 'react';
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  Switch,
  Alert,
  ScrollView,
} from 'react-native';
import { Feather } from '@expo/vector-icons';
import { useTheme } from '../../theme/ThemeContext';
import { useAuth } from '../../context/AuthContext';
import { useThemedStyles } from '../../theme/hooks';
import { useLanguage } from '../../context/LanguageContext';
import { useTranslation } from 'react-i18next';
import * as Updates from 'expo-updates';

export default function SettingsScreen() {
  const { isDark, toggleTheme } = useTheme();
  const { user, logout } = useAuth();
  const { language, changeLanguage } = useLanguage();
  const { t } = useTranslation();

  const handleLanguageChange = async (newLanguage) => {
    if (newLanguage === language) return;
    
    Alert.alert(
      t('settings.language.changeTitle'),
      t('settings.language.changeMessage'),
      [
        {
          text: t('common.cancel'),
          style: 'cancel',
        },
        {
          text: t('common.confirm'),
          onPress: async () => {
            await changeLanguage(newLanguage);
            // The app needs to reload to apply RTL changes
            try {
              await Updates.reloadAsync();
            } catch (error) {
              // If reload fails, suggest manual restart
              Alert.alert(
                t('settings.language.restartTitle'),
                t('settings.language.restartMessage')
              );
            }
          },
        },
      ],
      { cancelable: true }
    );
  };
  
  const styles = useThemedStyles((colors) => ({
    container: {
      flex: 1,
      backgroundColor: colors.background,
    },
    section: {
      backgroundColor: colors.card,
      marginVertical: 8,
      borderRadius: 12,
      overflow: 'hidden',
    },
    sectionHeader: {
      paddingHorizontal: 16,
      paddingVertical: 12,
      backgroundColor: colors.card,
      borderBottomWidth: 1,
      borderBottomColor: colors.border,
    },
    sectionHeaderText: {
      fontSize: 14,
      fontWeight: '600',
      color: colors.textSecondary,
      textTransform: 'uppercase',
      letterSpacing: 1,
    },
    item: {
      flexDirection: 'row',
      alignItems: 'center',
      padding: 16,
      backgroundColor: colors.card,
      borderBottomWidth: 1,
      borderBottomColor: colors.border,
    },
    itemLast: {
      borderBottomWidth: 0,
    },
    itemLeft: {
      flexDirection: 'row',
      alignItems: 'center',
      flex: 1,
    },
    itemIcon: {
      width: 24,
      height: 24,
      marginRight: 12,
      justifyContent: 'center',
      alignItems: 'center',
    },
    itemText: {
      fontSize: 16,
      color: colors.text,
    },
    itemValue: {
      fontSize: 16,
      color: colors.textSecondary,
    },
    profileSection: {
      padding: 20,
      alignItems: 'center',
      backgroundColor: colors.card,
      marginBottom: 20,
    },
    profileImage: {
      width: 80,
      height: 80,
      borderRadius: 40,
      backgroundColor: colors.border,
      marginBottom: 12,
      justifyContent: 'center',
      alignItems: 'center',
    },
    profileName: {
      fontSize: 20,
      fontWeight: 'bold',
      color: colors.text,
      marginBottom: 4,
    },
    profileEmail: {
      fontSize: 16,
      color: colors.textSecondary,
    },
    selectedLanguage: {
      fontSize: 16,
      color: colors.primary,
    },
    languageOption: {
      flexDirection: 'row',
      alignItems: 'center',
      paddingVertical: 12,
      paddingHorizontal: 16,
      borderBottomWidth: 1,
      borderBottomColor: colors.border,
    },
    languageText: {
      flex: 1,
      fontSize: 16,
      color: colors.text,
    },
    languageNative: {
      fontSize: 14,
      color: colors.textSecondary,
      marginLeft: 8,
    },
    checkIcon: {
      marginLeft: 8,
    },
    logoutButton: {
      flexDirection: 'row',
      alignItems: 'center',
      padding: 16,
      backgroundColor: colors.card,
    },
    logoutText: {
      fontSize: 16,
      color: colors.error,
      marginLeft: 12,
    },
    modalOverlay: {
      flex: 1,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      justifyContent: 'flex-end',
    },
    modalContent: {
      backgroundColor: colors.card,
      borderTopLeftRadius: 12,
      borderTopRightRadius: 12,
      padding: 16,
    },
    modalTitle: {
      fontSize: 20,
      fontWeight: 'bold',
      color: colors.text,
      marginBottom: 16,
    },
  }));


  const languages = [
    { code: 'en', name: 'English', nativeName: 'English' },
    { code: 'he', name: 'Hebrew', nativeName: 'עברית' },
  ];

  const LanguageOption = ({ code, name, nativeName, isSelected, onSelect }) => (
    <TouchableOpacity
      style={styles.languageOption}
      onPress={() => onSelect(code)}
    >
      <Text style={styles.languageText}>{name}</Text>
      <Text style={styles.languageNative}>{nativeName}</Text>
      {isSelected && (
        <Feather 
          name="check" 
          size={20} 
          color={styles.selectedLanguage.color}
          style={styles.checkIcon}
        />
      )}
    </TouchableOpacity>
  );
  
  const SettingsItem = ({ icon, text, value, onPress, isLast, rightElement }) => (
    <TouchableOpacity
      style={[styles.item, isLast && styles.itemLast]}
      onPress={onPress}
      disabled={!onPress}
    >
      <View style={styles.itemLeft}>
        <View style={styles.itemIcon}>
          <Feather name={icon} size={22} color={styles.itemText.color} />
        </View>
        <Text style={styles.itemText}>{text}</Text>
      </View>
      {rightElement || (
        value && <Text style={styles.itemValue}>{value}</Text>
      )}
    </TouchableOpacity>
  );

  return (
    <ScrollView style={styles.container}>
      <View style={styles.profileSection}>
        <View style={styles.profileImage}>
          <Feather name="user" size={40} color={styles.itemValue.color} />
        </View>
        <Text style={styles.profileName}>{user?.name || 'User Name'}</Text>
        <Text style={styles.profileEmail}>{user?.email || 'user@example.com'}</Text>
      </View>

      <View style={styles.section}>
        <View style={styles.sectionHeader}>
          <Text style={styles.sectionHeaderText}>{t('settings.appearance')}</Text>
        </View>
        <SettingsItem 
          icon='moon'
          text={t('settings.darkMode')}
          onPress={toggleTheme}
          rightElement={<Switch
            style={{marginTop: -15, marginBottom: -15}}
            value={isDark}
            onValueChange={toggleTheme}
            trackColor={{ false: '#767577', true: '#81b0ff' }}
            thumbColor={isDark ? styles.selectedLanguage.color : '#f4f3f4'}
          />}
        />
      </View>

      <View style={styles.section}>
        <View style={styles.sectionHeader}>
          <Text style={styles.sectionHeaderText}>{t('settings.language.title')}</Text>
        </View>
        {languages.map((lang, index) => (
          <LanguageOption
            key={lang.code}
            {...lang}
            isSelected={language === lang.code}
            onSelect={handleLanguageChange}
          />
        ))}
      </View>

      <View style={styles.section}>
        <View style={styles.sectionHeader}>
          <Text style={styles.sectionHeaderText}>{t('settings.account.title')}</Text>
        </View>
        <SettingsItem
          icon="user"
          text={t('settings.account.editProfile')}
          onPress={() => {}}
        />
        <SettingsItem
          icon="lock"
          text={t('settings.account.changePassword')}
          onPress={() => {}}
        />
        <SettingsItem
          icon="bell"
          text={t('settings.account.notifications')}
          onPress={() => {}}
          isLast
        />
      </View>

      <View style={styles.section}>
        <View style={styles.sectionHeader}>
          <Text style={styles.sectionHeaderText}>{t('settings.app.title')}</Text>
        </View>
        <SettingsItem
          icon="info"
          text={t('settings.app.about')}
          onPress={() => {}}
        />
        <SettingsItem
          icon="help-circle"
          text={t('settings.app.help')}
          onPress={() => {}}
        />
        <SettingsItem
          icon="shield"
          text={t('settings.app.privacyPolicy')}
          onPress={() => {}}
          isLast
        />
      </View>

      <TouchableOpacity
        style={[styles.section, styles.logoutButton]}
        onPress={() => {
          Alert.alert(
            t('settings.logoutTitle'),
            t('settings.logoutMessage'),
            [
              {
                text: t('common.cancel'),
                style: 'cancel',
              },
              {
                text: t('common.logout'),
                style: 'destructive',
                onPress: logout,
              },
            ],
            { cancelable: true }
          );
        }}
      >
         <Feather name="log-out" size={22} color={styles.logoutText.color} />
        <Text style={styles.logoutText}>{t('settings.logout')}</Text>
      </TouchableOpacity>
    </ScrollView>
  );
}