import React, { useState, useRef, useEffect } from 'react';
import { 
  Modal, 
  View, 
  Text, 
  TextInput, 
  TouchableOpacity, 
  ScrollView,
  Animated,
  Alert
} from 'react-native';
import { Feather } from '@expo/vector-icons';
import { useTranslation } from 'react-i18next';
import { useVehicles } from '../../../context/VehicleContext';
import { styles } from './styles';

const EditEventModal = ({
  visible,
  event,
  title,
  duration,
  selectedStudents,
  selectedVehicle,
  searchQuery,
  onChangeTitle,
  onChangeDuration,
  onChangeSelectedStudents,
  onChangeSelectedVehicle,
  onChangeSearchQuery,
  onSave,
  onDelete,
  onClose,
  students
}) => {
  const { t } = useTranslation();
  const { vehicles } = useVehicles();
  const [isStudentSearchVisible, setIsStudentSearchVisible] = useState(false);
  const fadeAnim = useRef(new Animated.Value(0)).current;
  
  const maxStudents = duration === 80 ? 2 : 1;
  const isAtMaxStudents = selectedStudents.length >= maxStudents;

  console.log('selected VEHICLE !!!!: ', selectedVehicle);
  
  useEffect(() => {
    if (isStudentSearchVisible) {
      Animated.timing(fadeAnim, {
        toValue: 1,
        duration: 200,
        useNativeDriver: true,
      }).start();
    } else {
      fadeAnim.setValue(0);
    }
  }, [isStudentSearchVisible]);

  // Reset selected vehicle when modal closes
  useEffect(() => {
    if (event && visible) {
      // Set duration based on event's lessonType
      const newDuration = event.lessonType === 'single' ? 40 : 80;
      onChangeDuration(newDuration);
    }
    
  }, [visible, event]);

  const handleDurationChange = (newDuration) => {
    onChangeDuration(newDuration);
    const newType = newDuration === 40 ? 'single' : 'double';
    
    if (newDuration === 40 && selectedStudents.length > 1) {
      Alert.alert(
        t('calendar.alerts.studentLimit.title'),
        t('calendar.alerts.studentLimit.singleLesson'),
        [{ text: 'OK' }]
      );
      onChangeSelectedStudents(selectedStudents.slice(0, 1));
    }
  };


  const handleAddStudentPress = () => {
    if (isAtMaxStudents) {
      Alert.alert(
        t('calendar.alerts.studentLimit.title'),
        duration === 40 
          ? t('calendar.alerts.studentLimit.singleLesson')
          : t('calendar.alerts.studentLimit.doubleLesson'),
      );
      return;
    }
    setIsStudentSearchVisible(true);
  };

  const handleAddStudent = (student) => {
    if (selectedStudents.length < maxStudents) {
      onChangeSelectedStudents([...selectedStudents, student.id]);
      onChangeSearchQuery('');
      setIsStudentSearchVisible(false);
    }
  };

  const filteredStudents = students.filter(student => {
    if (!searchQuery) return false;
    const fullName = `${student.firstName} ${student.lastName}`.toLowerCase();
    return fullName.includes(searchQuery.toLowerCase());
  });

  const selectedStudentNames = selectedStudents.map(id => {
    const student = students.find(s => s.id === id);
    return student ? `${student.firstName} ${student.lastName}` : '';
  });

  if (!visible || !event) return null;

  const renderVehicleSection = () => (
    <View style={styles.vehicleSection}>
      <Text style={styles.sectionTitle}>{t('calendar.fields.vehicle')}</Text>
      <ScrollView 
        horizontal 
        showsHorizontalScrollIndicator={false}
        style={styles.vehicleList}
      >
        <TouchableOpacity
          style={[styles.vehicleItem, selectedVehicle === null && styles.selectedVehicle]}
          onPress={() => onChangeSelectedVehicle(null)}
        >
          <Text style={selectedVehicle === null ? styles.selectedVehicleText : styles.vehicleText}>
            {t('calendar.fields.noVehicle')}
          </Text>
        </TouchableOpacity>
        {vehicles.map(vehicle => (
          <TouchableOpacity
            key={vehicle.id}
            style={[styles.vehicleItem, selectedVehicle === vehicle.id && styles.selectedVehicle]}
            onPress={() => onChangeSelectedVehicle(vehicle.id)}
          >
            <Text style={selectedVehicle === vehicle.id ? styles.selectedVehicleText : styles.vehicleText}>
              {`${vehicle.make} ${vehicle.model}`}
            </Text>
            <Text style={selectedVehicle === vehicle.id ? styles.selectedVehiclePlate : styles.vehiclePlate}>
              {vehicle.licensePlate}
            </Text>
          </TouchableOpacity>
        ))}
      </ScrollView>
    </View>
  );

  if (!visible || !event) return null;

  const renderSearchSection = () => {
    if (!isStudentSearchVisible) {
      return (
        <TouchableOpacity
          style={[
            styles.assignStudentButton,
            isAtMaxStudents && styles.assignStudentButtonDisabled
          ]}
          onPress={handleAddStudentPress}
          disabled={isAtMaxStudents}
        >
          <Feather 
            name="user-plus" 
            size={20} 
            color={isAtMaxStudents ? "#999" : "#007AFF"} 
          />
          <Text style={[
            styles.assignStudentButtonText,
            isAtMaxStudents && styles.assignStudentButtonTextDisabled
          ]}>
            {maxStudents === 1 
              ? t('calendar.fields.addStudentSingle')
              : t('calendar.fields.addStudentDouble', { count: maxStudents - selectedStudents.length })}
          </Text>
        </TouchableOpacity>
      );
    }

    return (
      <View style={styles.searchContainer}>
        <View style={styles.searchInputContainer}>
          <TextInput
            style={styles.searchInput}
            placeholder={t('calendar.fields.searchStudents')}
            value={searchQuery}
            onChangeText={onChangeSearchQuery}
            autoFocus
          />
          <TouchableOpacity 
            style={styles.closeSearchButton}
            onPress={() => {
              setIsStudentSearchVisible(false);
              onChangeSearchQuery('');
            }}
          >
            <Feather name="x" size={20} color="#666" />
          </TouchableOpacity>
        </View>

        {searchQuery.length > 0 && (
          <View style={styles.dropdownContainer}>
            <ScrollView 
              style={styles.searchResults}
              keyboardShouldPersistTaps="handled"
              nestedScrollEnabled
            >
              {filteredStudents.length > 0 ? (
                filteredStudents
                  .filter(student => !selectedStudents.includes(student.id))
                  .map(student => (
                    <TouchableOpacity
                      key={student.id}
                      style={styles.searchResultItem}
                      onPress={() => handleAddStudent(student)}
                    >
                      <Text style={styles.searchResultText}>
                        {`${student.firstName} ${student.lastName}`}
                      </Text>
                    </TouchableOpacity>
                  ))
              ) : (
                <View style={styles.noResultsContainer}>
                  <Text style={styles.noResultsText}>
                    {t('calendar.search.noResults')}
                  </Text>
                </View>
              )}
            </ScrollView>
          </View>
        )}
      </View>
    );
  };


  return (
    <Modal
      visible={visible}
      transparent={true}
      animationType="slide"
      onRequestClose={onClose}
    >
      <View style={styles.modalContainer}>
        <View style={styles.modalContent}>
          <Text style={styles.modalTitle}>
            {event.id === 'new' ? t('calendar.addNew') : t('calendar.editLesson')}
          </Text>

          <TextInput
            style={styles.input}
            placeholder={t('calendar.fields.title')}
            value={title}
            onChangeText={onChangeTitle}
          />

          <View style={styles.durationContainer}>
            <TouchableOpacity
              style={[styles.durationButton, duration === 40 && styles.selectedDuration]}
              onPress={() => handleDurationChange(40)}
            >
              <Text style={duration === 40 ? styles.selectedDurationText : styles.durationText}>
                {t('calendar.fields.duration.single')}
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={[styles.durationButton, duration === 80 && styles.selectedDuration]}
              onPress={() => handleDurationChange(80)}
            >
              <Text style={duration === 80 ? styles.selectedDurationText : styles.durationText}>
                {t('calendar.fields.duration.double')}
              </Text>
            </TouchableOpacity>
          </View>

          {/* Vehicle Selection */}
          {renderVehicleSection()}

          {/* Selected Students Display */}
          {selectedStudents.length > 0 && (
            <View style={styles.selectedStudentsContainer}>
              {selectedStudentNames.map((name, index) => (
                <View key={selectedStudents[index]} style={styles.studentTag}>
                  <Text style={styles.studentTagText}>{name}</Text>
                  <TouchableOpacity onPress={() => onChangeSelectedStudents(
                    selectedStudents.filter((_, i) => i !== index)
                  )}>
                    <Feather name="x" size={16} color="#666" />
                  </TouchableOpacity>
                </View>
              ))}
            </View>
          )}

          {/* Student Search Section */}
          {renderSearchSection()}
        
          <View style={styles.buttonContainer}>
            <TouchableOpacity
              style={[styles.button, styles.cancelButton]}
              onPress={onClose}
            >
              <Text style={styles.buttonText}>{t('calendar.buttons.cancel')}</Text>
            </TouchableOpacity>
            
            {event.id !== 'new' && (
              <TouchableOpacity
                style={[styles.button, styles.deleteButton]}
                onPress={() => {
                  onDelete(event.id);
                  onClose();
                }}
              >
                <Text style={styles.buttonText}>{t('calendar.buttons.delete')}</Text>
              </TouchableOpacity>
            )}
            
            <TouchableOpacity
              style={[styles.button, styles.saveButton]}
              onPress={onSave}
            >
              <Text style={styles.buttonText}>
                {event.id === 'new' ? t('calendar.buttons.schedule') : t('calendar.buttons.update')}
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </Modal>
  );
};

export default React.memo(EditEventModal);