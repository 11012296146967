import React, { useState, useEffect } from 'react';
import { View, Text, TouchableOpacity, Modal, TextInput } from 'react-native';
import { Feather } from '@expo/vector-icons';
import { useTranslation } from 'react-i18next';
import { useThemedStyles } from '../../theme/hooks';
import { useAccounting } from '../../context/AccountingContext';
import { PAYMENT_METHODS, CREDIT_CARD_COMPANIES, DEAL_TYPES } from '../../constants';
import { Picker } from '@react-native-picker/picker';

export const PaymentFields = ({
  value,
  onChange,
  amount,
  disabled = false
}) => {
  const { t } = useTranslation();
  const { getCashierAccounts } = useAccounting();
  const [showMethodPicker, setShowMethodPicker] = useState(false);

  const styles = useThemedStyles((colors) => ({
    container: {
      marginBottom: 16,
    },
    amountSection: {
      backgroundColor: colors.card,
      borderRadius: 8,
      padding: 16,
      marginBottom: 16,
    },
    methodButton: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderWidth: 1,
      borderColor: colors.border,
      borderRadius: 8,
      padding: 12,
      backgroundColor: colors.background,
      marginBottom: 16,
    },
    methodRow: {
      flexDirection: 'row',
      alignItems: 'center',
      padding: 16,
      borderBottomWidth: 1,
      borderBottomColor: colors.border,
    },
    methodIcon: {
      marginRight: 12,
      color: colors.primary,
    },
    methodText: {
      flex: 1,
      fontSize: 16,
      color: colors.text,
    },
    input: {
      borderWidth: 1,
      borderColor: colors.border,
      borderRadius: 8,
      padding: 12,
      marginBottom: 12,
      fontSize: 16,
      color: colors.text,
      backgroundColor: colors.background,
    },
    pickerContainer: {
      borderWidth: 1,
      borderColor: colors.border,
      borderRadius: 8,
      marginBottom: 12,
      overflow: 'hidden',
      backgroundColor: colors.background,
    },
    picker: {
      color: colors.text,
    },
    fieldLabel: {
      fontSize: 14,
      color: colors.textSecondary,
      marginBottom: 4,
    },
    amountText: {
      fontSize: 24,
      fontWeight: 'bold',
      color: colors.text,
      textAlign: 'center',
      marginBottom: 8,
    },
    amountLabel: {
      fontSize: 14,
      color: colors.textSecondary,
      textAlign: 'center',
    },
    modalOverlay: {
      flex: 1,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      justifyContent: 'flex-end',
    },
    modalContent: {
      backgroundColor: colors.card,
      borderTopLeftRadius: 16,
      borderTopRightRadius: 16,
      maxHeight: '80%',
      paddingBottom: 34,
    },
    modalHeader: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: 16,
      borderBottomWidth: 1,
      borderBottomColor: colors.border,
    },
    modalTitle: {
      fontSize: 20,
      fontWeight: 'bold',
      color: colors.text,
    },
    closeButton: {
      padding: 8,
    },
    closeIcon: {
      color: colors.textSecondary,
    },
  }));

  // Initialize or update payment amount when total amount changes
  useEffect(() => {
    if (amount && (!value?.amount || value.amount !== amount)) {
      onChange(value ? { ...value, amount } : { amount });
    }
  }, [amount]);

  const findCashierAccount = (methodId) => {
    const cashierAccounts = getCashierAccounts();
    const method = PAYMENT_METHODS[methodId];

    if (!method || !method.accountName) {
      console.warn(`No account name mapping found for method: ${methodId}`);
      return null;
    }

    const cashierAccount = cashierAccounts.find(account =>
      account.accountName === method.accountName
    );

    if (!cashierAccount) {
      console.warn(`No cashier account found for account name: ${method.accountName}`);
    }

    return cashierAccount;
  };

  const renderMethodPicker = () => (
    <Modal
      visible={showMethodPicker}
      transparent
      animationType="fade"
      onRequestClose={() => setShowMethodPicker(false)}
    >
      <View style={styles.modalOverlay}>
        <View style={styles.modalContent}>
          <View style={styles.modalHeader}>
            <Text style={styles.modalTitle}>
              {t('documents.selectPaymentMethod')}
            </Text>
            <TouchableOpacity
              style={styles.closeButton}
              onPress={() => setShowMethodPicker(false)}
            >
              <Feather name="x" size={24} style={styles.closeIcon} />
            </TouchableOpacity>
          </View>

          {Object.values(PAYMENT_METHODS).map((method) => {
            const cashierAccount = findCashierAccount(method.id);
            if (!cashierAccount) {
              console.warn(`No cashier account found for method: ${method.id}`);
              return null;
            }

            return (
              <TouchableOpacity
                key={method.id}
                style={styles.methodRow}
                onPress={() => {
                  onChange({
                    id: method.id,
                    methodType: method.methodType,
                    amount,
                    details: {},
                    cashierAccount  // Automatically set the mapped cashier account
                  });
                  setShowMethodPicker(false);
                }}
              >
                <Feather
                  name={method.icon}
                  size={24}
                  style={styles.methodIcon}
                />
                <Text style={styles.methodText}>
                  {t(`documents.payment.methods.${method.id.toLowerCase()}`)}
                </Text>
                <Feather
                  name="chevron-right"
                  size={20}
                  style={styles.closeIcon}
                />
              </TouchableOpacity>
            );
          })}
        </View>
      </View>
    </Modal>
  );

  const renderPaymentAmount = () => (
    <View style={styles.amountSection}>
      <Text style={styles.amountLabel}>{t('documents.payment.amount')}</Text>
      <Text style={styles.amountText}>₪{amount?.toFixed(2) || '0.00'}</Text>
    </View>
  );

  const renderCreditCardFields = () => (
    <>
      <Text style={styles.fieldLabel}>{t('payment.creditCardCompany')}</Text>
      <View style={styles.pickerContainer}>
        <Picker
          selectedValue={value.details.creditCardCompanyCode}
          onValueChange={(itemValue) => onChange({
            ...value,
            details: { ...value.details, creditCardCompanyCode: itemValue }
          })}
          style={styles.picker}
        >
          {Object.values(CREDIT_CARD_COMPANIES).map(company => (
            <Picker.Item
              key={company.code}
              label={company.name}
              value={company.code}
            />
          ))}
        </Picker>
      </View>

      <Text style={styles.fieldLabel}>{t('payment.dealType')}</Text>
      <View style={styles.pickerContainer}>
        <Picker
          selectedValue={value.details.dealType}
          onValueChange={(itemValue) => onChange({
            ...value,
            details: { ...value.details, dealType: itemValue }
          })}
          style={styles.picker}
        >
          <Picker.Item
            label={t('payment.dealTypes.regular')}
            value={DEAL_TYPES.REGULAR}
          />
          <Picker.Item
            label={t('payment.dealTypes.installments')}
            value={DEAL_TYPES.INSTALLMENTS}
          />
        </Picker>
      </View>
    </>
  );

  const renderBankFields = () => (
    <>
      <Text style={styles.fieldLabel}>{t('documents.payment.bankNumber')}</Text>
      <TextInput
        style={styles.input}
        value={value.details.bankNumber}
        onChangeText={(text) => onChange({
          ...value,
          details: { ...value.details, bankNumber: text }
        })}
        keyboardType="numeric"
        maxLength={2}
      />

      <Text style={styles.fieldLabel}>{t('documents.payment.branchNumber')}</Text>
      <TextInput
        style={styles.input}
        value={value.details.branchNumber}
        onChangeText={(text) => onChange({
          ...value,
          details: { ...value.details, branchNumber: text }
        })}
        keyboardType="numeric"
        maxLength={3}
      />

      <Text style={styles.fieldLabel}>{t('documents.payment.accountNumber')}</Text>
      <TextInput
        style={styles.input}
        value={value.details.accountNumber}
        onChangeText={(text) => onChange({
          ...value,
          details: { ...value.details, accountNumber: text }
        })}
        keyboardType="numeric"
      />

      {value.id === 'CHECK' && (
        <>
          <Text style={styles.fieldLabel}>{t('documents.payment.checkNumber')}</Text>
          <TextInput
            style={styles.input}
            value={value.details.checkNumber}
            onChangeText={(text) => onChange({
              ...value,
              details: { ...value.details, checkNumber: text }
            })}
            keyboardType="numeric"
          />
        </>
      )}
    </>
  );

  const renderMethodFields = () => {
    if (!value?.id) return null;

    switch (value.id) {
      case 'CREDIT_CARD':
        return renderCreditCardFields();
      case 'CHECK':
      case 'BANK_TRANSFER':
        return renderBankFields();
      default:
        return null;
    }
  };

  return (
    <View style={styles.container}>
      {renderPaymentAmount()}

      <TouchableOpacity
        style={styles.methodButton}
        onPress={() => setShowMethodPicker(true)}
        disabled={disabled}
      >
        <Text style={styles.methodText}>
          {value?.id
            ? t(`documents.payment.methods.${value.id.toLowerCase()}`)
            : t('documents.selectPaymentMethod')}
        </Text>
        <Feather name="chevron-down" size={20} style={styles.closeIcon} />
      </TouchableOpacity>

      {renderMethodFields()}
      {renderMethodPicker()}
    </View>
  );
};