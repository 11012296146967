import { ACCOUNT_TYPES } from '../constants';

export const validateAccount = (account, type, documentType) => {
  if (!account) return null;

  switch (type) {
    case 'income':
      if (account.accountCodeDescription !== ACCOUNT_TYPES.INCOME) {
        return 'Selected account must be an income account';
      }
      break;

    case 'expense':
      if (account.accountCodeDescription !== ACCOUNT_TYPES.EXPENSE) {
        return 'Selected account must be an expense account';
      }
      break;

    case 'cashier':
      if (account.accountCodeDescription !== ACCOUNT_TYPES.CASHIER) {
        return 'Selected account must be a cashier account';
      }

      // Additional validation for payment methods
      if (documentType?.requiresPayment) {
        // Add specific validations based on payment method if needed
      }
      break;

    case 'vat':
      if (account.accountCodeDescription !== ACCOUNT_TYPES.VAT) {
        return 'Selected account must be a VAT account';
      }
      break;

    default:
      return null;
  }

  return null;
};

export const getAccountTypeByDocumentType = (documentType) => {
  switch (documentType?.id) {
    case 'TAX_INVOICE':
    case 'TAX_INVOICE_RECEIPT':
    case 'RECEIPT':
      return {
        main: 'income',
        vat: 'vat',
        payment: documentType.requiresPayment ? 'cashier' : null
      };

    case 'EXPENSE':
      return {
        main: 'expense',
        vat: 'vat',
        payment: documentType.requiresPayment ? 'cashier' : null
      };

    default:
      return {};
  }
};
