import React, { useState, useEffect } from 'react';
import { View, Text, TouchableOpacity, ScrollView, Alert } from 'react-native';
import { Feather } from '@expo/vector-icons';
import { useTranslation } from 'react-i18next';
import { useThemedStyles } from '../../theme/hooks';
import { useAccounting } from '../../context/AccountingContext';
import { useDocumentForm } from '../../context/DocumentFormContext';
import { DocumentFormField } from './DocumentFormField';
import { LineItemsForm } from './LineItemsForm';
import { calculateTotals } from '../../utils/documentUtils';
import { apiService } from '../../services/api';
import { DOCUMENT_TYPES } from '../../constants';
import { getAccountTypeByDocumentType } from '../../utils/accountValidation'

const standardizeEntity = (entityData, type = '') => {
  if (!entityData) return null;

  // Handle document reference data structure
  if (entityData.document_type) {
    return {
      id: entityData.entity_id,
      accounting_id: entityData.entity_id,
      firstName: entityData.firstName,
      lastName: entityData.lastName,
      name: entityData.supplier_name,
      type: entityData.entity_type,
      accountNumber: entityData.entity_id?.toString()
    };
  }

  // Handle regular entity data structure (from students/suppliers list)
  return {
    id: entityData.id || entityData.user_id,
    accounting_id: entityData.accounting_id || entityData.entity_id,
    firstName: entityData.firstName,
    lastName: entityData.lastName,
    name: entityData.supplier_name,
    type: type || entityData.entity_type,
    accountNumber: (entityData.accounting_id || entityData.entity_id)?.toString()
  };
};

const DocumentForm = ({
  documentType,
  onSubmit,
  onCancel,
  selectedDocument = null,
  referenceDocument = null
}) => {
  const { t } = useTranslation();
  const [referenceDocuments, setReferenceDocuments] = useState([]);
  const [isLoadingReferences, setIsLoadingReferences] = useState(false);
  const { fetchVatRate, vatRate, getIncomeAccounts, getVatAccountId } = useAccounting();
  const {
    entity,
    items,
    description,
    payment,
    selectedAccounts,
    referenceDocument: formReferenceDocument,
    updateEntity,
    updateItems,
    updatePayment,
    updateAccounts,
    updateReference,
    updateDescription,
    totals,
    resetForm,
    manualReferenceNumber,
    updateManualReference
  } = useDocumentForm();

  useEffect(() => {
    fetchVatRate();
  }, []);

  // Calculate totals based on items or reference document
  const currentTotals = React.useMemo(() => {
    const sourceDoc = referenceDocument;
    if (sourceDoc && ['RECEIPT', 'CREDIT_INVOICE'].includes(documentType?.id)) {
      return {
        totalBeforeDiscount: sourceDoc.amount_before_discount || sourceDoc.amount / (1 + (vatRate / 100)),
        totalDiscount: sourceDoc.discount || 0,
        totalAfterDiscount: sourceDoc.amount_after_discount || sourceDoc.amount / (1 + (vatRate / 100)),
        vatAmount: sourceDoc.vat_amount || (sourceDoc.amount - (sourceDoc.amount / (1 + (vatRate / 100)))),
        totalAmount: sourceDoc.amount || 0
      };
    }
    return calculateTotals(items, vatRate);
  }, [items, referenceDocument, documentType, vatRate]);

  // Set default income account
  useEffect(() => {
    if (['INCOME', 'TAX_INVOICE', 'TAX_INVOICE_RECEIPT', 'RECEIPT', 'CREDIT_INVOICE'].includes(documentType?.id)) {
      const incomeAccounts = getIncomeAccounts();
      if (incomeAccounts[0]) {
        updateAccounts({ income: incomeAccounts[0] })
      }
    }
  }, [documentType, getIncomeAccounts]);

  // Load reference documents when entity is selected
  useEffect(() => {
    const loadReferenceDocuments = async () => {
      if (!documentType?.requiresReference || !entity) return;

      setIsLoadingReferences(true);
      try {
        const response = await apiService.getValidReferenceDocuments(
          documentType.id,
          entity.accounting_id,
          entity.type
        );
        setReferenceDocuments(response.data);
      } catch (error) {
        console.error('Error loading reference documents:', error);
      } finally {
        setIsLoadingReferences(false);
      }
    };

    loadReferenceDocuments();
  }, [documentType, entity]);

  // Update the initial document data effect to handle totals and payment
  useEffect(() => {
    const sourceDoc = selectedDocument || referenceDocument;
    if (!sourceDoc) return;

    // Handle entity
    const standardizedEntity = standardizeEntity(sourceDoc);
    updateEntity(standardizedEntity);
    updateDescription(sourceDoc.description || '');
    updateReference(sourceDoc);
    updateAccounts({
      income: sourceDoc.income_account,
      expense: sourceDoc.expense_account
    });

    // Handle payment data for receipt types
    if (documentType.requiresPayment) {
      updatePayment({
        methodType: null,
        amount: sourceDoc.amount,
        details: {}
      });
    }

    // Handle items and totals based on document type
    if (documentType.id === 'RECEIPT') {
      const baseAmount = sourceDoc.amount / (1 + vatRate / 100);
      const vatAmount = sourceDoc.amount - baseAmount;

      // Create a single item representing the total
      updateItems([{
        description: sourceDoc.description || 'Original document charges',
        quantity: 1,
        unitPrice: baseAmount,
        lineDiscount: 0,
        lineTotal: baseAmount,
        vatRate,
        vatAmount
      }]);
    } else if (documentType.id === 'CREDIT_INVOICE') {
      // For credit invoice, copy items with negative quantities
      const baseAmount = sourceDoc.amount / (1 + vatRate / 100);
      const vatAmount = sourceDoc.amount - baseAmount;

      // Either copy existing items with negative quantities or create a single negative item
      if (sourceDoc.items?.length > 0) {
        updateItems(sourceDoc.items.map(item => ({
          ...item,
          quantity: item.quantity,
          lineTotal: item.lineTotal,
          vatRate: item.vatRate || vatRate,
          totalWithVat: sourceDoc.amount
        })));
      } else {
        updateItems([{
          description: sourceDoc.description || 'Credit for original document',
          quantity: 1,
          unitPrice: baseAmount,
          lineDiscount: 0,
          lineTotal: baseAmount,
          vatRate,
          vatAmount,
          totalWithVat: sourceDoc.amount
        }]);
      }
    } else {
      // For other types, copy items as is
      updateItems(
        sourceDoc.items?.length > 0 && documentType.canHaveLineItems
          ? sourceDoc.items.map(item => ({
            ...item,
            vatRate: item.vatRate || vatRate
          }))
          : []
      );
    }
  }, [selectedDocument, referenceDocument, documentType, vatRate]);

  // Form field handlers
  const handleEntityChange = (entity) => {
    if (!entity?.accounting_id) {
      return;
    }
    updateEntity(entity);
  };

  const handleReferenceDocumentChange = (doc) => {
    if (!doc) return;
    // Format entity data with what we have
    const entityData = {
      id: doc.entity_id,
      accounting_id: doc.entity_id,
      firstName: doc.firstName,
      lastName: doc.lastName,
      type: doc.entity_type,
      name: doc.entity_type === 'SUPPLIER' ? doc.supplier_name : `${doc.firstName} ${doc.lastName}`
    };

    // Update form state
    updateReference(doc);
    updateEntity(entityData);
    updateDescription(doc.description || '');

    // Set totals based on reference document amount
    if (documentType.id === 'RECEIPT') {
      const baseAmount = doc.amount / (1 + (vatRate / 100));

      // Create a single item representing the total
      updateItems([{
        description: doc.description || 'Original document charges',
        quantity: 1,
        unitPrice: baseAmount,
        lineDiscount: 0,
        lineTotal: baseAmount,
        vatRate: vatRate
      }]);
    } else if (documentType.id === 'CREDIT_INVOICE') {
      const baseAmount = doc.amount / (1 + (vatRate / 100));

      // Create a single negative item
      updateItems([{
        description: doc.description || 'Credit for original document',
        quantity: -1,
        unitPrice: baseAmount,
        lineDiscount: 0,
        lineTotal: -baseAmount,
        vatRate: vatRate
      }]);
    }
  };

  const handlePaymentChange = (payment) => {
    updatePayment({
      ...payment,
      amount: totals.totalAmount  // totals from context
    });
  };

  // Form validation
  const validateForm = () => {
    const errors = [];

    if (!entity) {
      errors.push(t('validation.entityRequired'));
    }

    if (documentType.canHaveLineItems &&
      !items.length &&
      !(documentType.id === 'RECEIPT' && referenceDocument)) {
      errors.push(t('validation.lineItemsRequired'));
    }

    if (documentType.requiresPayment) {
      if (!payment) {
        errors.push(t('validation.paymentRequired'));
      } else if (!payment.cashierAccount) {
        errors.push(t('validation.cashierRequired'));
      }
    }

    if (documentType.requiresReference && !formReferenceDocument) {
      errors.push(t('validation.referenceRequired'));
    }

    if (['TAX_INVOICE', 'TAX_INVOICE_RECEIPT'].includes(documentType.id) &&
      !selectedAccounts.income) {
      errors.push(t('validation.incomeAccountRequired'));
    }

    return errors;
  };

  const prepareSubmitData = () => {
    if (!entity?.accounting_id) {
      throw new Error('Entity ID is required');
    }

    const data = {
      // Document type info
      documentType: DOCUMENT_TYPES[documentType.id].documentTypeCode,
      customerAccountId: entity.accounting_id,
      entityType: documentType.requiresSupplier ? 'SUPPLIER' : 'STUDENT',
      entity,
      // Dates and metadata
      documentDate: new Date().toISOString().split('T')[0],
      businessBranchId: "001",

      // Amounts from calculated totals
      totalBeforeDiscount: totals.totalBeforeDiscount,
      documentDiscount: totals.totalDiscount,
      totalAfterDiscountBeforeVat: totals.totalAfterDiscount,
      vatAmount: totals.vatAmount,
      totalAmount: totals.totalAmount,

      // Description
      description: description || '',

      // Accounts
      vatAccountId: getVatAccountId(),
      incomeAccountId: selectedAccounts.income?.accountNumber,
      expenseAccountId: documentType.requiresOutgoingPayment ? selectedAccounts.expense?.accountNumber : undefined,
      // Line items
      items: items.map(item => ({
        description: item.description,
        quantity: item.quantity,
        unitPrice: item.unitPrice,
        lineDiscount: item.lineDiscount || 0,
        lineTotal: (item.quantity * item.unitPrice) - (item.lineDiscount || 0),
        vatRate: vatRate
      }))
    };

    if (documentType.requiresManualReference) {
      data.manualReferenceNumber = manualReferenceNumber
    }

    if (documentType.requiresPayment && payment) {
      data.payment = {
        methodType: payment.methodType,
        amount: totals.totalAmount,
        cashierAccountId: payment.cashierAccount?.accountNumber,
        date: new Date().toISOString().split('T')[0],
        reference: payment.reference,
        bankDetails: null
      };

      if (payment.methodType === '2') { // Check
        data.payment.bankDetails = {
          bankNumber: payment.details.bankNumber,
          bankBranch: payment.details.bankBranch,
          bankAccountNumber: payment.details.bankAccountNumber,
          checkNumber: payment.details.checkNumber
        };
      } else if (payment.methodType === '3') { // Credit card
        data.payment.bankDetails = {
          creditCardCompanyCode: payment.details.creditCardCompanyCode,
          cardName: payment.details.cardName,
          dealType: payment.details.dealType
        };
      }
    }

    // Add reference document if exists
    if (formReferenceDocument) {
      data.referenceDocumentId = formReferenceDocument.id;
      data.parentDocumentNumber = formReferenceDocument.record_number;
      data.referenceDocumentnumber = formReferenceDocument.record_number;
    }

    return data;
  };

  // Handle submit
  const handleSubmit = async () => {
    const errors = validateForm();
    if (errors.length) {
      Alert.alert(t('validation.error'), errors.join('\n'));
      return;
    }

    try {
      const submitData = prepareSubmitData();
      await onSubmit(submitData);
      resetForm()
    } catch (error) {
      console.error('Submit error:', error);
      Alert.alert(t('common.error'), error.message);
    }
  };

  // Get form fields configuration
  const getFormFields = () => {
    const fields = [];
    // Entity Selection
    if (!selectedDocument && !documentType.requiresReference) {
      fields.push({
        type: documentType.requiresSupplier ? 'supplier' : 'student',
        required: true,
        value: entity,
        onChange: handleEntityChange
      });
    }

    if (documentType.requiresManualReference) {
      fields.push({
        type: 'manualReference',
        required: true,
        value: manualReferenceNumber,
        onChange: updateManualReference,
        placeholder: t('documents.enterManualReference')
      });
    }

    if (documentType.requiresOutgoingPayment) {
      fields.push({
        type: 'expenseAccount',
        required: true,
        value: selectedAccounts.expense,
        onChange: (account) => updateAccounts({ expense: account })
      });
    }

    // Reference Document
    if (documentType.requiresReference || documentType.id === 'RECEIPT') {
      fields.push({
        type: 'reference',
        required: documentType.requiresReference,
        value: formReferenceDocument,
        onChange: handleReferenceDocumentChange,
        documents: referenceDocuments,
        isLoading: isLoadingReferences
      });
    }

    // Line Items
    if (documentType.canHaveLineItems &&
      !(documentType.id === 'RECEIPT' && formReferenceDocument)) {
      fields.push({
        type: 'lineItems',
        required: true,
        value: items,
        onChange: items => updateItems(items)
      });
    }

    // Payment
    if (documentType.requiresPayment && !documentType.requiresOutgoingPayment) {
      fields.push({
        type: 'payment',
        required: true,
        value: payment,
        amount: totals.totalAmount,
        onChange: handlePaymentChange
      });
    }

    // Description
    fields.push({
      type: 'description',
      required: false,
      value: description,
      onChange: description => updateDescription(description)
    });

    return fields;
  };

  const handleCancel = () => {
    resetForm();
    onCancel();
  };

  const styles = useThemedStyles((colors) => ({
    container: {
      padding: 16,
    },
    header: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 24,
    },
    title: {
      fontSize: 20,
      fontWeight: 'bold',
      color: colors.text,
    },
    closeButton: {
      padding: 8,
    },
    closeIcon: {
      color: colors.textSecondary,
    },
    actions: {
      flexDirection: 'row',
      justifyContent: 'flex-end',
      gap: 12,
      marginTop: 24,
    },
    button: {
      paddingVertical: 12,
      paddingHorizontal: 24,
      borderRadius: 8,
      minWidth: 100,
      alignItems: 'center',
    },
    submitButton: {
      backgroundColor: colors.primary,
    },
    cancelButton: {
      backgroundColor: colors.background,
      borderWidth: 1,
      borderColor: colors.border,
    },
    buttonText: {
      fontSize: 16,
      fontWeight: '500',
    },
    submitButtonText: {
      color: colors.card,
    },
    cancelButtonText: {
      color: colors.text,
    },
    totalsContainer: {
      backgroundColor: colors.background,
      borderRadius: 12,
      borderWidth: 1,
      borderColor: colors.border,
      marginTop: 24,
      overflow: 'hidden',
    },
    totalRow: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingVertical: 12,
      paddingHorizontal: 16,
      borderBottomWidth: 1,
      borderBottomColor: colors.border,
    },
    totalLabel: {
      fontSize: 14,
      color: colors.textSecondary,
    },
    totalAmount: {
      fontSize: 14,
      fontWeight: '500',
      color: colors.text,
    },
    totalRowFinal: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: 16,
      backgroundColor: colors.card,
    },
    totalLabelFinal: {
      fontSize: 16,
      fontWeight: '600',
      color: colors.text,
    },
    totalAmountFinal: {
      fontSize: 18,
      fontWeight: 'bold',
      color: colors.text,
    }
  }));

  return (
    <ScrollView style={styles.container}>
      {/* Form Header */}
      <View style={styles.header}>
        <Text style={styles.title}>
          {t(`documents.types.${documentType.id.toLowerCase()}`)}
        </Text>
        <TouchableOpacity style={styles.closeButton} onPress={handleCancel}>
          <Feather name="x" size={24} style={styles.closeIcon} />
        </TouchableOpacity>
      </View>

      {/* Form Fields */}
      {getFormFields().map((field) => (
        field.type === 'lineItems' ? (
          <LineItemsForm
            key="lineItems"
            items={field.value}
            onChange={field.onChange}
            disabled={field.disabled}
          />
        ) : (
          <DocumentFormField
            key={field.type}
            {...field}
            documentType={documentType}
          />
        )
      ))}

      {/* Totals display */}
      <View style={styles.totalsContainer}>
        <View style={styles.totalRow}>
          <Text style={styles.totalLabel}>{t('documents.totals.beforeDiscount')}</Text>
          <Text style={styles.totalAmount}>₪{currentTotals.totalBeforeDiscount.toFixed(2)}</Text>
        </View>
        <View style={styles.totalRow}>
          <Text style={styles.totalLabel}>{t('documents.totals.discount')}</Text>
          <Text style={styles.totalAmount}>₪{currentTotals.totalDiscount.toFixed(2)}</Text>
        </View>
        <View style={styles.totalRow}>
          <Text style={styles.totalLabel}>{t('documents.totals.afterDiscount')}</Text>
          <Text style={styles.totalAmount}>₪{currentTotals.totalAfterDiscount.toFixed(2)}</Text>
        </View>
        <View style={styles.totalRow}>
          <Text style={styles.totalLabel}>{t('documents.totals.vat')} ({vatRate}%)</Text>
          <Text style={styles.totalAmount}>₪{currentTotals.vatAmount.toFixed(2)}</Text>
        </View>
        <View style={styles.totalRowFinal}>
          <Text style={styles.totalLabelFinal}>{t('documents.totals.total')}</Text>
          <Text style={styles.totalAmountFinal}>₪{currentTotals.totalAmount.toFixed(2)}</Text>
        </View>
      </View>

      {/* Action buttons */}
      <View style={styles.actions}>
        <TouchableOpacity
          style={[styles.button, styles.cancelButton]}
          onPress={handleCancel}
        >
          <Text style={[styles.buttonText, styles.cancelButtonText]}>
            {t('common.cancel')}
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={[styles.button, styles.submitButton]}
          onPress={handleSubmit}
        >
          <Text style={[styles.buttonText, styles.submitButtonText]}>
            {t('common.create')}
          </Text>
        </TouchableOpacity>
      </View>
    </ScrollView>
  );
}
export { DocumentForm }