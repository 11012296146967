import React, { useState } from 'react';
import {
  View,
  Text,
  TextInput,
  TouchableOpacity,
  StyleSheet,
  ActivityIndicator,
  Alert,
  KeyboardAvoidingView,
  Platform,
  ScrollView,
  TouchableWithoutFeedback,
  Keyboard,
} from 'react-native';
import { useAuth } from '../../context/AuthContext';
import { useTranslation } from 'react-i18next';
import { useThemedStyles } from '../../theme/hooks';

export default function RegisterScreen({ navigation }) {
  const { t } = useTranslation();
  const { register, isLoading, error, clearError } = useAuth();
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    confirmPassword: '',
    firstName: '',
    lastName: '',
    phone: '',
    authorizedBusinessNumber: ''
  });

  React.useEffect(() => {
    // Clear any previous auth errors when component mounts
    clearError();
  }, []);

  const styles = useThemedStyles((colors) => ({
    container: {
      flex: 1,
      backgroundColor: colors.background,
    },
    scrollContent: {
      flexGrow: 1,
      padding: 20,
      justifyContent: 'center',
    },
    title: {
      fontSize: 24,
      fontWeight: 'bold',
      color: colors.text,
      marginBottom: 30,
      textAlign: 'center',
    },
    input: {
      height: 50,
      borderWidth: 1,
      borderColor: colors.border,
      borderRadius: 8,
      paddingHorizontal: 15,
      marginBottom: 15,
      fontSize: 16,
      backgroundColor: colors.card,
      color: colors.text,
    },
    errorText: {
      color: colors.error,
      marginBottom: 15,
      textAlign: 'center',
    },
    registerButton: {
      backgroundColor: colors.primary,
      height: 50,
      borderRadius: 8,
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: 15,
    },
    registerButtonText: {
      color: colors.card,
      fontSize: 16,
      fontWeight: 'bold',
    },
    loginButton: {
      alignItems: 'center',
    },
    loginButtonText: {
      color: colors.primary,
      fontSize: 14,
    },
  }));

  const validateForm = () => {
    if (!formData.email || !formData.password || !formData.firstName || !formData.lastName || !formData.authorizedBusinessNumber) {
      Alert.alert(t('register.error'), t('auth.register.errors.requiredFields'));
      return false;
    }

    if (formData.password !== formData.confirmPassword) {
      Alert.alert(t('auth.register.error'), t('auth.register.errors.passwordMismatch'));
      return false;
    }

    if (formData.password.length < 6) {
      Alert.alert(t('auth.register.error'), t('auth.register.errors.passwordLength'));
      return false;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      Alert.alert(t('auth.register.error'), t('auth.register.errors.invalidEmail'));
      return false;
    }

    return true;
  };

  const handleRegister = async () => {
    if (!validateForm()) return;

    const success = await register({
      email: formData.email.trim(),
      password: formData.password,
      firstName: formData.firstName.trim(),
      lastName: formData.lastName.trim(),
      phone: formData.phone.trim(),
      authorizedBusinessNumber: formData.authorizedBusinessNumber.trim(),
    });

    if (success) {
      Alert.alert(
        t('common.success'),
        t('auth.register.success'),
        [{ text: t('common.confirm'), onPress: () => navigation.replace('MainApp') }]
      );
    }
  };

  return (
    <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
      <KeyboardAvoidingView 
        style={styles.container} 
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
      >
        <ScrollView contentContainerStyle={styles.scrollContent}>
          <Text style={styles.title}>{t('auth.register.title')}</Text>

          <TextInput
            style={styles.input}
            placeholder={t('auth.register.fields.firstName')}
            value={formData.firstName}
            onChangeText={(text) => setFormData({ ...formData, firstName: text })}
            placeholderTextColor={styles.placeholder?.color}
          />

          <TextInput
            style={styles.input}
            placeholder={t('auth.register.fields.lastName')}
            value={formData.lastName}
            onChangeText={(text) => setFormData({ ...formData, lastName: text })}
            placeholderTextColor={styles.placeholder?.color}
          />

          <TextInput
            style={styles.input}
            placeholder={t('auth.register.fields.email')}
            value={formData.email}
            onChangeText={(text) => setFormData({ ...formData, email: text })}
            keyboardType="email-address"
            autoCapitalize="none"
            placeholderTextColor={styles.placeholder?.color}
          />

          <TextInput
            style={styles.input}
            placeholder={t('auth.register.fields.phone')}
            value={formData.phone}
            onChangeText={(text) => setFormData({ ...formData, phone: text })}
            keyboardType="phone-pad"
            placeholderTextColor={styles.placeholder?.color}
          />
          
          <TextInput
            style={styles.input}
            placeholder={t('auth.register.fields.authorizedBusinessNumber')}
            value={formData.authorizedBusinessNumber}
            onChangeText={(text) => setFormData({ ...formData, authorizedBusinessNumber: text })}
            keyboardType="phone-pad"
            placeholderTextColor={styles.placeholder?.color}
          />

          <TextInput
            style={styles.input}
            placeholder={t('auth.register.fields.password')}
            value={formData.password}
            onChangeText={(text) => setFormData({ ...formData, password: text })}
            secureTextEntry
            placeholderTextColor={styles.placeholder?.color}
          />

          <TextInput
            style={styles.input}
            placeholder={t('auth.register.fields.confirmPassword')}
            value={formData.confirmPassword}
            onChangeText={(text) => setFormData({ ...formData, confirmPassword: text })}
            secureTextEntry
            placeholderTextColor={styles.placeholder?.color}
          />

          {error && <Text style={styles.errorText}>{error}</Text>}

          <TouchableOpacity 
            style={styles.registerButton} 
            onPress={handleRegister}
            disabled={isLoading}
          >
            {isLoading ? (
              <ActivityIndicator color="#fff" />
            ) : (
              <Text style={styles.registerButtonText}>
                {t('auth.register.buttons.register')}
              </Text>
            )}
          </TouchableOpacity>

          <TouchableOpacity 
            style={styles.loginButton}
            onPress={() => navigation.navigate('Login')}
          >
            <Text style={styles.loginButtonText}>
              {t('auth.register.buttons.haveAccount')}
            </Text>
          </TouchableOpacity>
        </ScrollView>
      </KeyboardAvoidingView>
    </TouchableWithoutFeedback>
  );
}