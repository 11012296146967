// src/context/InstructorContext.js
import React, { createContext, useContext, useReducer, useCallback } from 'react';
import { apiService } from '../services/api';

const InstructorContext = createContext();

const instructorReducer = (state, action) => {
  switch (action.type) {
    case 'SET_LOADING':
      return {
        ...state,
        isLoading: action.payload
      };
    case 'SET_ERROR':
      return {
        ...state,
        error: action.payload,
        isLoading: false
      };
    case 'ADD_INSTRUCTOR':
      return {
        ...state,
        items: [...state.items, action.payload],
        isLoading: false,
        error: null
      };
    case 'UPDATE_INSTRUCTOR':
      return {
        ...state,
        items: state.items.map(instructor =>
          instructor.id === action.payload.id ? action.payload : instructor
        ),
        isLoading: false,
        error: null
      };
    case 'DELETE_INSTRUCTOR':
      return {
        ...state,
        items: state.items.filter(instructor => instructor.id !== action.payload),
        isLoading: false,
        error: null
      };
    case 'SET_INSTRUCTORS':
      return {
        ...state,
        items: action.payload,
        isLoading: false,
        error: null
      };
    default:
      return state;
  }
};

export const InstructorProvider = ({ children }) => {
  const [state, dispatch] = useReducer(instructorReducer, {
    items: [],
    isLoading: false,
    error: null,
  });

  const fetchInstructors = useCallback(async () => {
    dispatch({ type: 'SET_LOADING', payload: true });
    try {
      const response = await apiService.getInstructors();
      dispatch({ type: 'SET_INSTRUCTORS', payload: response.data });
    } catch (error) {
      dispatch({ type: 'SET_ERROR', payload: error.message });
    }
  }, []);

  const addInstructor = async (instructor) => {
    dispatch({ type: 'SET_LOADING', payload: true });
    try {
      const response = await apiService.addInstructor(instructor);
      dispatch({ type: 'ADD_INSTRUCTOR', payload: { ...instructor, id: response.data.id } });
    } catch (error) {
      dispatch({ type: 'SET_ERROR', payload: error.message });
    }
  };

  const updateInstructor = async (instructor) => {
    dispatch({ type: 'SET_LOADING', payload: true });
    try {
      await apiService.updateInstructor(instructor.id, instructor);
      dispatch({ type: 'UPDATE_INSTRUCTOR', payload: instructor });
    } catch (error) {
      dispatch({ type: 'SET_ERROR', payload: error.message });
    }
  };

  const deleteInstructor = async (instructorId) => {
    dispatch({ type: 'SET_LOADING', payload: true });
    try {
      await apiService.deleteInstructor(instructorId);
      dispatch({ type: 'DELETE_INSTRUCTOR', payload: instructorId });
    } catch (error) {
      dispatch({ type: 'SET_ERROR', payload: error.message });
    }
  };

  const value = {
    instructors: state.items,
    isLoading: state.isLoading,
    error: state.error,
    fetchInstructors,
    addInstructor,
    updateInstructor,
    deleteInstructor,
  };

  return (
    <InstructorContext.Provider value={value}>
      {children}
    </InstructorContext.Provider>
  );
};

export const useInstructors = () => {
  const context = useContext(InstructorContext);
  if (context === undefined) {
    throw new Error('useInstructors must be used within an InstructorProvider');
  }
  return context;
};

export default InstructorProvider;