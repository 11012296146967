import React, { useState, useMemo } from 'react';
import { View, Text, TouchableOpacity, Modal, FlatList, TextInput } from 'react-native';
import { Feather } from '@expo/vector-icons';
import { useTranslation } from 'react-i18next';
import { useThemedStyles } from '../../theme/hooks';
import { validateReferenceDocument } from '../../utils/referenceValidation';

export const ReferenceDocumentSelector = ({
    label,
    value,
    documents,
    documentType,
    onChange,
    disabled = false,
    required = false
}) => {
    const { t } = useTranslation();
    const [showPicker, setShowPicker] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [error, setError] = useState(null);

    const filteredDocuments = useMemo(() => {
        let filtered = documents || [];
        // Filter by status and document type
        filtered = filtered.filter(doc => {
            if (doc.status !== 'ACTIVE') return false;

            if (documentType?.id === 'RECEIPT') {
                return doc.document_type === 'TAX_INVOICE' && !doc.payment_date;
            } else if (documentType?.id === 'CREDIT_INVOICE') {
                return ['TAX_INVOICE', 'TAX_INVOICE_RECEIPT'].includes(doc.document_type) &&
                    doc.status !== 'CANCELLED';
            }
            return true;
        });

        if (searchQuery) {
            const query = searchQuery.toLowerCase();
            filtered = filtered.filter(doc =>
                doc.record_number?.toLowerCase().includes(query) ||
                doc.description?.toLowerCase().includes(query) ||
                getEntityName(doc).toLowerCase().includes(query)
            );
        }

        return filtered;
    }, [documents, documentType, searchQuery]);

    const getEntityName = (document) => {
        return document.entity_type === 'SUPPLIER'
            ? document.supplier_name
            : `${document.firstName} ${document.lastName}`;
    };

    const handleDocumentSelect = (document) => {
        const validationError = validateReferenceDocument(document, documentType.id);
        if (validationError) {
            setError(validationError);
            return;
        }

        const formattedDocument = {
            id: document.id,
            record_number: document.record_number,
            document_type: document.document_type,
            entity_type: document.entity_type,
            entity_id: document.entity_id,
            amount: document.amount,
            description: document.description,
            status: document.status,
            firstName: document.firstName,
            lastName: document.lastName,
            supplier_name: document.supplier_name,
            items: document.items || []
        };

        setError(null);
        onChange(formattedDocument);
        setShowPicker(false);
        setSearchQuery('');
    };

    const styles = useThemedStyles((colors) => ({
        container: {
            marginBottom: 16,
        },
        labelContainer: {
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: 8,
        },
        label: {
            fontSize: 14,
            color: colors.textSecondary,
        },
        requiredIndicator: {
            color: colors.error,
            marginLeft: 4,
        },
        pickerButton: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderWidth: 1,
            borderColor: error ? colors.error : colors.border,
            borderRadius: 8,
            padding: 12,
            backgroundColor: colors.background,
        },
        pickerButtonDisabled: {
            opacity: 0.6,
        },
        documentInfo: {
            flex: 1,
        },
        documentNumber: {
            fontSize: 16,
            color: colors.text,
            marginBottom: 4,
        },
        documentType: {
            fontSize: 14,
            color: colors.textSecondary,
        },
        modalOverlay: {
            flex: 1,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            justifyContent: 'flex-end',
        },
        modalContent: {
            backgroundColor: colors.card,
            borderTopLeftRadius: 16,
            borderTopRightRadius: 16,
            maxHeight: '80%',
        },
        modalHeader: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: 16,
            borderBottomWidth: 1,
            borderBottomColor: colors.border,
        },
        modalTitle: {
            fontSize: 20,
            fontWeight: 'bold',
            color: colors.text,
        },
        searchInput: {
            borderWidth: 1,
            borderColor: colors.border,
            borderRadius: 8,
            padding: 12,
            margin: 16,
            fontSize: 16,
            color: colors.text,
        },
        documentItem: {
            padding: 16,
            borderBottomWidth: 1,
            borderBottomColor: colors.border,
        },
        documentItemHeader: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: 4,
        },
        documentItemType: {
            fontSize: 14,
            color: colors.textSecondary,
        },
        documentItemAmount: {
            fontSize: 16,
            fontWeight: 'bold',
            color: colors.text,
        },
        documentItemEntity: {
            fontSize: 14,
            color: colors.textSecondary,
        },
        error: {
            color: colors.error,
            fontSize: 12,
            marginTop: 4,
            marginLeft: 4,
        },
        emptyList: {
            padding: 16,
            alignItems: 'center',
        },
        emptyListText: {
            fontSize: 16,
            color: colors.textSecondary,
        },
        textSecondary: {
            color: colors.textSecondary
        }
    }));

    const renderDocumentInfo = (document) => {
        if (!document) return null;

        const entityName = document.entity_type === 'SUPPLIER'
            ? document.supplier_name
            : `${document.firstName} ${document.lastName}`;

        return (
            <View style={styles.documentInfo}>
                <Text style={styles.documentNumber}>
                    {`${t(`documents.types.${document.document_type.toLowerCase()}`)} #${document.record_number}`}
                </Text>
                <Text style={styles.documentType}>
                    {getEntityName(document)} - ₪{document.amount.toFixed(2)}
                </Text>
            </View>
        );
    };

    return (
        <View style={styles.container}>
            <View style={styles.labelContainer}>
                <Text style={styles.label}>{label}</Text>
                {required && <Text style={styles.requiredIndicator}>*</Text>}
            </View>

            <TouchableOpacity
                style={[
                    styles.pickerButton,
                    disabled && styles.pickerButtonDisabled
                ]}
                onPress={() => !disabled && setShowPicker(true)}
                disabled={disabled}
            >
                {value ? (
                    renderDocumentInfo(value)
                ) : (
                    <Text style={styles.documentNumber}>
                        {t('documents.selectReference')}
                    </Text>
                )}
                {!disabled && <Feather name="chevron-down" size={20} color={styles.textSecondary.color} />}
            </TouchableOpacity>

            {error && <Text style={styles.error}>{error}</Text>}

            <Modal
                visible={showPicker}
                transparent
                animationType="fade"
                onRequestClose={() => {
                    setShowPicker(false);
                    setSearchQuery('');
                }}
            >
                <View style={styles.modalOverlay}>
                    <View style={styles.modalContent}>
                        <View style={styles.modalHeader}>
                            <Text style={styles.modalTitle}>{t('documents.selectReference')}</Text>
                            <TouchableOpacity
                                onPress={() => {
                                    setShowPicker(false);
                                    setSearchQuery('');
                                }}
                            >
                                <Feather name="x" size={24} color={styles.textSecondary.color} />
                            </TouchableOpacity>
                        </View>

                        <TextInput
                            style={styles.searchInput}
                            placeholder={t('documents.searchDocuments')}
                            value={searchQuery}
                            onChangeText={setSearchQuery}
                        />

                        <FlatList
                            data={filteredDocuments}
                            keyExtractor={item => item.record_number}
                            renderItem={({ item }) => {
                                const entityName = item.entity_type === 'SUPPLIER'
                                    ? item.supplier_name
                                    : `${item.firstName} ${item.lastName}`;

                                return (
                                    <TouchableOpacity
                                        style={styles.documentItem}
                                        onPress={() => handleDocumentSelect(item)}
                                    >
                                        <View style={styles.documentItemHeader}>
                                            <Text style={styles.documentNumber}>
                                                #{item.record_number}
                                            </Text>
                                            <Text style={styles.documentItemAmount}>
                                                ₪{item.amount.toFixed(2)}
                                            </Text>
                                        </View>
                                        <Text style={styles.documentItemType}>
                                            {t(`documents.types.${item.document_type.toLowerCase()}`)}
                                        </Text>
                                        <Text style={styles.documentItemEntity}>
                                            {entityName}
                                        </Text>
                                    </TouchableOpacity>
                                );
                            }}
                            ListEmptyComponent={() => (
                                <View style={styles.emptyList}>
                                    <Text style={styles.emptyListText}>
                                        {t('documents.noValidReferences')}
                                    </Text>
                                </View>
                            )}
                        />
                    </View>
                </View>
            </Modal>
        </View>
    );
};