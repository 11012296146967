import React, { useMemo, useEffect, useState } from 'react';
import {
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  useWindowDimensions,
  ActivityIndicator,
  Animated,
} from 'react-native';
import { CalendarUtils } from 'react-native-calendars';
import { Feather } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import { useThemedStyles } from '../../theme/hooks';
import { useStudents } from '../../context/StudentContext';
import { useEvents } from '../../context/EventContext';
import { useVehicles } from '../../context/VehicleContext';
import { checkNetworkAndApi } from '../../utils/networkCheck';

export default function DashboardScreen() {
  const navigation = useNavigation();
  const { width } = useWindowDimensions();
  const { t, i18n } = useTranslation();
  const [showWelcome, setShowWelcome] = useState(true);
  const welcomeHeight = useMemo(() => new Animated.Value(showWelcome ? 1 : 0), []);


  useEffect(() => {
    const loadData = async () => {
      const isNetworkOk = await checkNetworkAndApi();
      if (!isNetworkOk) {
        // Handle no network specifically
        return;
      }

      try {
        await Promise.all([
          fetchStudents(),
          fetchEvents(),
          fetchVehicles()
        ]);
      } catch (error) {
        console.log('Data loading failed:', error);
      }
    };

    loadData();
  }, [fetchStudents, fetchEvents, fetchVehicles]);

  const styles = useThemedStyles((colors) => ({
    // Base Container
    container: {
      flex: 1,
      backgroundColor: colors.background,
    },

    // Header Styles
    header: {
      overflow: 'hidden',
    },
    welcomeContent: {
      padding: 16,
      backgroundColor: colors.card,
      borderBottomWidth: 1,
      borderBottomColor: colors.border,
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
    },
    welcomeTextContainer: {
      flex: 1,
    },
    greeting: {
      fontSize: 20,
      fontWeight: '600',
      color: colors.text,
      marginBottom: 4,
    },
    date: {
      fontSize: 14,
      color: colors.textSecondary,
    },
    closeButton: {
      padding: 8,
      marginTop: -8,
      marginRight: -8,
    },

    // Stats Grid
    statsContainer: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      padding: 12,
      gap: 12,
    },
    widget: {
      backgroundColor: colors.card,
      borderRadius: 16,
      padding: 16,
      width: (width - 36) / 2, // Accounting for padding and gap
      elevation: 2,
      shadowColor: colors.shadowColor,
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.1,
      shadowRadius: 4,
    },
    iconContainer: {
      width: 40,
      height: 40,
      borderRadius: 12,
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: 12,
    },
    widgetValue: {
      fontSize: 20,
      fontWeight: '600',
      color: colors.text,
      marginBottom: 4,
    },
    widgetLabel: {
      fontSize: 13,
      color: colors.textSecondary,
    },

    // Period Stats
    periodStats: {
      backgroundColor: colors.card,
      marginHorizontal: 12,
      marginBottom: 12,
      borderRadius: 16,
      padding: 16,
      elevation: 2,
      shadowColor: colors.shadowColor,
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.1,
      shadowRadius: 4,
    },
    periodStatsContent: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    periodStat: {
      flex: 1,
      alignItems: 'center',
    },
    periodDivider: {
      width: 1,
      height: '80%',
      backgroundColor: colors.border,
      marginHorizontal: 16,
    },
    periodLabel: {
      fontSize: 13,
      color: colors.textSecondary,
      marginBottom: 4,
    },
    periodValue: {
      fontSize: 16,
      fontWeight: '600',
      color: colors.text,
    },

    // Section Container
    sectionContainer: {
      backgroundColor: colors.card,
      margin: 12,
      borderRadius: 16,
      padding: 16,
      elevation: 2,
      shadowColor: colors.shadowColor,
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.1,
      shadowRadius: 4,
    },
    sectionHeader: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 12,
    },
    sectionTitle: {
      fontSize: 16,
      fontWeight: '600',
      color: colors.text,
    },
    seeAllButton: {
      color: colors.primary,
      fontSize: 14,
      fontWeight: '500',
    },

    // Lesson Items
    lessonItem: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingVertical: 12,
      borderBottomWidth: 1,
      borderBottomColor: colors.border,
    },
    lessonItem_last: {
      borderBottomWidth: 0,
      paddingBottom: 0,
    },
    lessonInfo: {
      flex: 1,
    },
    lessonTitle: {
      fontSize: 15,
      fontWeight: '500',
      marginBottom: 4,
      color: colors.text,
    },
    lessonStudent: {
      fontSize: 13,
      color: colors.textSecondary,
    },
    lessonTimeInfo: {
      alignItems: 'flex-end',
    },
    lessonTime: {
      fontSize: 14,
      fontWeight: '500',
      color: colors.text,
    },
    lessonDuration: {
      fontSize: 12,
      color: colors.textSecondary,
    },
    noLessonsText: {
      textAlign: 'center',
      color: colors.textSecondary,
      fontStyle: 'italic',
      paddingVertical: 16,
    },

    // Loading & Error States
    loadingContainer: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      padding: 20,
    },
    errorContainer: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      padding: 20,
      backgroundColor: colors.background,
    },
    errorText: {
      color: colors.error,
      textAlign: 'center',
      marginBottom: 16,
    },
    retryButton: {
      backgroundColor: colors.primary,
      padding: 12,
      borderRadius: 8,
    },
    retryButtonText: {
      color: colors.white,
      fontWeight: '600',
    },

    textSecondary: {
      color: colors.textSecondary
    },
    primaryColor: {
      color: colors.primary
    }
  }));

  const toggleWelcome = () => {
    Animated.timing(welcomeHeight, {
      toValue: showWelcome ? 0 : 1,
      duration: 300,
      useNativeDriver: false,
    }).start();
    setShowWelcome(!showWelcome);
  };

  // Updated context usage with loading and error states
  const {
    students,
    isLoading: studentsLoading,
    error: studentsError,
    fetchStudents
  } = useStudents();

  const {
    events,
    isLoading: eventsLoading,
    error: eventsError,
    fetchEvents
  } = useEvents();

  const {
    vehicles,
    isLoading: vehiclesLoading,
    error: vehiclesError,
    fetchVehicles
  } = useVehicles();

  // Fetch data when component mounts
  useEffect(() => {
    fetchStudents();
    fetchEvents();
    fetchVehicles();
  }, [fetchStudents, fetchEvents, fetchVehicles]);


  // Calculate various metrics
  const metrics = useMemo(() => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    // Calculate start of week (Sunday)
    const startOfWeek = new Date(today);
    startOfWeek.setDate(today.getDate() - today.getDay());
    startOfWeek.setHours(0, 0, 0, 0);

    // Calculate end of week
    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 6);
    endOfWeek.setHours(23, 59, 59, 999);

    // Calculate start of month
    const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    startOfMonth.setHours(0, 0, 0, 0);

    // Calculate end of month
    const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    endOfMonth.setHours(23, 59, 59, 999);

    const isDateInRange = (eventStart, rangeStart, rangeEnd) => {
      const eventDate = eventStart.split(' ')[0];
      const startDate = CalendarUtils.getCalendarDateString(rangeStart);
      const endDate = CalendarUtils.getCalendarDateString(rangeEnd);
      return eventDate >= startDate && eventDate <= endDate;
    };

    return {
      activeStudents: students.length,

      lessonsToday: events.filter(event => {
        const eventDate = CalendarUtils.getCalendarDateString(new Date(event.start));
        const todayDate = CalendarUtils.getCalendarDateString(today);

        return eventDate === todayDate;
      }
      ).length,

      lessonsThisWeek: events.filter(event =>
        isDateInRange(event.start, startOfWeek, endOfWeek)
      ).length,

      lessonsThisMonth: events.filter(event =>
        isDateInRange(event.start, startOfMonth, endOfMonth)
      ).length,

      upcomingLessons: events
        .filter(event => {
          const eventDate = event.start.split(' ')[0];
          return eventDate >= CalendarUtils.getCalendarDateString(today);
        })
        .sort((a, b) => a.start.localeCompare(b.start))
        .slice(0, 5),

      totalHours: events.reduce((acc, event) =>
        acc + (event.duration / 60), 0
      ),

      doubleBookings: events.filter(event =>
        event.duration === 80
      ).length,
    };
  }, [students, events]);

  // Render loading state
  if (studentsLoading || eventsLoading) {
    return (
      <View style={styles.loadingContainer}>
        <ActivityIndicator size="large" color={styles.primaryColor} />
        <Text>{t('common.loading')}</Text>
      </View>
    );
  }

  // Render error state
  if (studentsError || eventsError) {
    const errorMessage = studentsError || eventsError;
    return (
      <View style={styles.errorContainer}>
        <Text style={styles.errorText}>
          {t(errorMessage)}
        </Text>
        <TouchableOpacity
          style={styles.retryButton}
          onPress={() => {
            fetchStudents();
            fetchEvents();
            fetchVehicles();
          }}
        >
          <Text style={styles.retryButtonText}>{t('common.retry')}</Text>
        </TouchableOpacity>
      </View>
    );
  }

  const StatWidget = ({ icon, label, value, color = '#007AFF' }) => (
    <View style={styles.widget}>
      <View style={[styles.iconContainer, { backgroundColor: color + '15' }]}>
        <Feather name={icon} size={20} color={color} />
      </View>
      <Text style={styles.widgetValue}>{value}</Text>
      <Text style={styles.widgetLabel}>{label}</Text>
    </View>
  );

  const UpcomingLessons = () => (
    <View style={styles.sectionContainer}>
      <View style={styles.sectionHeader}>
        <Text style={styles.sectionTitle}>{t('dashboard.upcoming.title')}</Text>
        <TouchableOpacity onPress={() => navigation.navigate('Calendar')}>
          <Text style={styles.seeAllButton}>{t('common.seeAll')}</Text>
        </TouchableOpacity>
      </View>
      {metrics.upcomingLessons.length > 0 ? (
        metrics.upcomingLessons.map((lesson, index) => {
          const student = students.find(s =>
            lesson.studentIds && lesson.studentIds.includes(s.id)
          );
          const isLast = index === metrics.upcomingLessons.length - 1;

          return (
            <View key={lesson.id} style={[styles.lessonItem, isLast && styles.lessonItem_last]}>
              <View style={styles.lessonInfo}>
                <Text style={styles.lessonTitle}>{lesson.title}</Text>
                {student && (
                  <Text style={styles.lessonStudent}>
                    {student.firstName} {student.lastName}
                  </Text>
                )}
              </View>
              <View style={styles.lessonTimeInfo}>
                <Text style={styles.lessonTime}>{lesson.time}</Text>
                <Text style={styles.lessonDuration}>
                  {t('dashboard.upcoming.duration', { duration: lesson.duration })}
                </Text>
              </View>
            </View>
          );
        })
      ) : (
        <Text style={styles.noLessonsText}>{t('common.noUpcomingLessons')}</Text>
      )}
    </View>
  );

  return (
    <ScrollView style={styles.container}>
      <Animated.View style={[
        styles.header,
        {
          maxHeight: welcomeHeight.interpolate({
            inputRange: [0, 1],
            outputRange: [0, 100]
          })
        }
      ]}>
        <View style={styles.welcomeContent}>
          <View style={styles.welcomeTextContainer}>
            <Text style={styles.greeting}>{t('common.welcome')}</Text>
            <Text style={styles.date}>
              {new Date().toLocaleDateString(i18n.language, {
                weekday: 'long',
                month: 'long',
                day: 'numeric',
              })}
            </Text>
          </View>
          <TouchableOpacity style={styles.closeButton} onPress={toggleWelcome}>
            <Feather name="x" size={20} color={styles.textSecondary} />
          </TouchableOpacity>
        </View>
      </Animated.View>

      <View style={styles.statsContainer}>
        <StatWidget
          icon="users"
          label={t('dashboard.stats.activeStudents')}
          value={metrics.activeStudents}
          color="#007AFF"
        />
        <StatWidget
          icon="calendar"
          label={t('dashboard.stats.lessonsToday')}
          value={metrics.lessonsToday}
          color="#FF9500"
        />
        <StatWidget
          icon="clock"
          label={t('dashboard.stats.totalHours')}
          value={metrics.totalHours.toFixed(1)}
          color="#34C759"
        />
        <StatWidget
          icon="trending-up"
          label={t('dashboard.stats.doubleLessons')}
          value={metrics.doubleBookings}
          color="#AF52DE"
        />
      </View>

      <View style={styles.periodStats}>
        <View style={styles.periodStatsContent}>
          <View style={styles.periodStat}>
            <Text style={styles.periodLabel}>{t('dashboard.periods.thisWeek')}</Text>
            <Text style={styles.periodValue}>
              {t('dashboard.periods.lessonsCount', { count: metrics.lessonsThisWeek })}
            </Text>
          </View>
          <View style={styles.periodDivider} />
          <View style={styles.periodStat}>
            <Text style={styles.periodLabel}>{t('dashboard.periods.thisMonth')}</Text>
            <Text style={styles.periodValue}>
              {t('dashboard.periods.lessonsCount', { count: metrics.lessonsThisMonth })}
            </Text>
          </View>
        </View>
      </View>

      <UpcomingLessons />
    </ScrollView>
  );
}