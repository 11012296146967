import React, { useEffect, useMemo } from 'react';
import { View, Text, ScrollView } from 'react-native';
import { usePayments } from '../../context/PaymentContext';
import { useSuppliers } from '../../context/SupplierContext';
import * as Print from 'expo-print';
import * as Sharing from 'expo-sharing';
import { generateCSV } from '../../utils/helpers';
import { ReportContainer, useReportStyles } from '../../components/reports/ReportContainer';

export default function ExpenseReportScreen() {
  const styles = useReportStyles();
  const { expenses, fetchExpenses, isLoading } = usePayments();
  const { suppliers, fetchSuppliers } = useSuppliers();

  useEffect(() => {
    fetchExpenses();
  }, [fetchExpenses]);

  useEffect(() => {
    fetchSuppliers();
  }, [fetchSuppliers]);

  const reportData = useMemo(() => {
    const data = {
      totalExpenses: 0,
      paidExpenses: 0,
      pendingExpenses: 0,
      cancelledExpenses: 0,
      bySupplier: {},
      byMonth: {},
      byCategory: {}
    };

    expenses.forEach(expense => {
      const amount = parseFloat(expense.amount);
      const supplier = suppliers.find(s => s.id === expense.supplier_id);
      const month = new Date(expense.expense_date).toLocaleString('default', { month: 'long' });

      // Total calculations
      if (expense.status !== 'CANCELLED') {
        data.totalExpenses += amount;
        if (expense.status === 'PAID') {
          data.paidExpenses += amount;
        } else {
          data.pendingExpenses += amount;
        }
      } else {
        data.cancelledExpenses += amount;
      }

      // By supplier
      if (supplier) {
        data.bySupplier[supplier.id] = data.bySupplier[supplier.id] || {
          name: supplier.name,
          category: supplier.category,
          total: 0,
          paid: 0,
          pending: 0
        };
        data.bySupplier[supplier.id].total += amount;
        if (expense.status === 'PAID') {
          data.bySupplier[supplier.id].paid += amount;
        } else if (expense.status === 'PENDING') {
          data.bySupplier[supplier.id].pending += amount;
        }

        // By category
        data.byCategory[supplier.category] = data.byCategory[supplier.category] || {
          total: 0,
          paid: 0,
          pending: 0
        };
        data.byCategory[supplier.category].total += amount;
        if (expense.status === 'PAID') {
          data.byCategory[supplier.category].paid += amount;
        } else if (expense.status === 'PENDING') {
          data.byCategory[supplier.category].pending += amount;
        }
      }

      // By month
      data.byMonth[month] = data.byMonth[month] || {
        total: 0,
        paid: 0,
        pending: 0
      };
      data.byMonth[month].total += amount;
      if (expense.status === 'PAID') {
        data.byMonth[month].paid += amount;
      } else if (expense.status === 'PENDING') {
        data.byMonth[month].pending += amount;
      }
    });

    return data;
  }, [expenses, suppliers]);

  const handleExportCSV = () => {
    const headers = ['Category', 'Total', 'Paid', 'Pending'];
    const data = [
      {
        Category: 'Overall',
        Total: reportData.totalExpenses,
        Paid: reportData.paidExpenses,
        Pending: reportData.pendingExpenses
      },
      ...Object.entries(reportData.bySupplier).map(([_, data]) => ({
        Category: data.name,
        Total: data.total,
        Paid: data.paid,
        Pending: data.pending
      })),
      ...Object.entries(reportData.byMonth).map(([month, data]) => ({
        Category: month,
        Total: data.total,
        Paid: data.paid,
        Pending: data.pending
      }))
    ];

    return generateCSV(headers, data);
  };

  const handleExportPDF = async () => {
    const formatCurrency = (amount) => `₪${parseFloat(amount).toFixed(2)}`;

    const htmlContent = `
      <html>
        <head>
          <style>
            body { font-family: Arial, sans-serif; }
            .header { text-align: center; margin-bottom: 20px; }
            .section { margin-bottom: 20px; }
            .section-title { 
              font-size: 18px; 
              font-weight: bold;
              margin-bottom: 10px;
              background-color: #f5f5f5;
              padding: 5px;
            }
            table { 
              width: 100%; 
              border-collapse: collapse; 
              margin-bottom: 20px;
            }
            th, td { 
              border: 1px solid #ddd; 
              padding: 8px; 
              text-align: left;
            }
            th { background-color: #f5f5f5; }
            .total { font-weight: bold; }
          </style>
        </head>
        <body>
          <div class="header">
            <h1>Expense Report</h1>
            <p>Generated on ${new Date().toLocaleDateString()}</p>
          </div>

          <div class="section">
            <div class="section-title">Summary</div>
            <table>
              <tr>
                <td>Total Expenses</td>
                <td class="total">${formatCurrency(reportData.totalExpenses)}</td>
              </tr>
              <tr>
                <td>Paid Expenses</td>
                <td>${formatCurrency(reportData.paidExpenses)}</td>
              </tr>
              <tr>
                <td>Pending Expenses</td>
                <td>${formatCurrency(reportData.pendingExpenses)}</td>
              </tr>
              <tr>
                <td>Cancelled</td>
                <td>${formatCurrency(reportData.cancelledExpenses)}</td>
              </tr>
            </table>
          </div>

          <div class="section">
            <div class="section-title">By Category</div>
            <table>
              <tr>
                <th>Category</th>
                <th>Total</th>
                <th>Paid</th>
                <th>Pending</th>
              </tr>
              ${Object.entries(reportData.byCategory)
        .map(([category, data]) => `
                  <tr>
                    <td>${category}</td>
                    <td>${formatCurrency(data.total)}</td>
                    <td>${formatCurrency(data.paid)}</td>
                    <td>${formatCurrency(data.pending)}</td>
                  </tr>
                `).join('')}
            </table>
          </div>

          <div class="section">
            <div class="section-title">By Supplier</div>
            <table>
              <tr>
                <th>Supplier</th>
                <th>Category</th>
                <th>Total</th>
                <th>Paid</th>
                <th>Pending</th>
              </tr>
              ${Object.entries(reportData.bySupplier)
        .map(([_, data]) => `
                  <tr>
                    <td>${data.name}</td>
                    <td>${data.category}</td>
                    <td>${formatCurrency(data.total)}</td>
                    <td>${formatCurrency(data.paid)}</td>
                    <td>${formatCurrency(data.pending)}</td>
                  </tr>
                `).join('')}
            </table>
          </div>

          <div class="section">
            <div class="section-title">Monthly Breakdown</div>
            <table>
              <tr>
                <th>Month</th>
                <th>Total</th>
                <th>Paid</th>
                <th>Pending</th>
              </tr>
              ${Object.entries(reportData.byMonth)
        .map(([month, data]) => `
                  <tr>
                    <td>${month}</td>
                    <td>${formatCurrency(data.total)}</td>
                    <td>${formatCurrency(data.paid)}</td>
                    <td>${formatCurrency(data.pending)}</td>
                  </tr>
                `).join('')}
            </table>
          </div>
        </body>
      </html>
    `;

    try {
      const file = await Print.printToFileAsync({
        html: htmlContent,
        base64: false
      });

      await Sharing.shareAsync(file.uri, {
        UTI: '.pdf',
        mimeType: 'application/pdf'
      });

      return file.uri;
    } catch (error) {
      console.error('PDF generation error:', error);
      throw error;
    }
  };

  return (
    <ReportContainer
      onExportCSV={handleExportCSV}
      onExportPDF={handleExportPDF}
      isLoading={isLoading}
    >
      <ScrollView style={styles.content}>
        {/* Summary Card */}
        <View style={styles.card}>
          <Text style={styles.sectionTitle}>Summary</Text>
          <View style={styles.row}>
            <Text style={styles.label}>Total Expenses</Text>
            <Text style={[styles.value, styles.highlight]}>
              ₪{reportData.totalExpenses.toFixed(2)}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.label}>Paid Expenses</Text>
            <Text style={styles.value}>₪{reportData.paidExpenses.toFixed(2)}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.label}>Pending Expenses</Text>
            <Text style={styles.value}>₪{reportData.pendingExpenses.toFixed(2)}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.label}>Cancelled</Text>
            <Text style={styles.value}>₪{reportData.cancelledExpenses.toFixed(2)}</Text>
          </View>
        </View>

        {/* Category Breakdown */}
        <View style={styles.card}>
          <Text style={styles.sectionTitle}>By Category</Text>
          {Object.entries(reportData.byCategory).map(([category, data]) => (
            <View key={category} style={styles.row}>
              <View style={styles.infoContainer}>
                <Text style={styles.mainText}>{category}</Text>
              </View>
              <View style={styles.amountsContainer}>
                <Text style={styles.totalAmount}>₪{data.total.toFixed(2)}</Text>
                <Text style={styles.breakdownText}>
                  Paid: ₪{data.paid.toFixed(2)}
                </Text>
                <Text style={styles.breakdownText}>
                  Pending: ₪{data.pending.toFixed(2)}
                </Text>
              </View>
            </View>
          ))}
        </View>

        {/* Supplier Breakdown */}
        <View style={styles.card}>
          <Text style={styles.sectionTitle}>By Supplier</Text>
          {Object.entries(reportData.bySupplier).map(([_, data]) => (
            <View key={data.name} style={styles.row}>
              <View style={styles.infoContainer}>
                <Text style={styles.mainText}>{data.name}</Text>
                <Text style={styles.subText}>{data.category}</Text>
              </View>
              <View style={styles.amountsContainer}>
                <Text style={styles.totalAmount}>₪{data.total.toFixed(2)}</Text>
                <Text style={styles.breakdownText}>
                  Paid: ₪{data.paid.toFixed(2)}
                </Text>
                <Text style={styles.breakdownText}>
                  Pending: ₪{data.pending.toFixed(2)}
                </Text>
              </View>
            </View>
          ))}
        </View>

        {/* Monthly Breakdown */}
        <View style={styles.card}>
          <Text style={styles.sectionTitle}>Monthly Breakdown</Text>
          {Object.entries(reportData.byMonth).map(([month, data]) => (
            <View key={month} style={styles.row}>
              <Text style={styles.label}>{month}</Text>
              <View>
                <Text style={styles.value}>₪{data.total.toFixed(2)}</Text>
                <Text style={styles.smallText}>
                  Paid: ₪{data.paid.toFixed(2)} | Pending: ₪{data.pending.toFixed(2)}
                </Text>
              </View>
            </View>
          ))}
        </View>
      </ScrollView>
    </ReportContainer>
  );
}