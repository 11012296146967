export const colors = {
    light: {
      primary: '#007AFF',
      background: '#F5F5F5',
      card: '#FFFFFF',
      text: '#000000',
      textSecondary: '#666666',
      border: '#EEEEEE',
      shadowColor: '#000000',
      error: '#FF3B30',
      success: '#34C759',
      warning: '#FF9500',
      purple: '#AF52DE',
    },
    dark: {
      primary: '#0A84FF',
      background: '#000000',
      card: '#1C1C1E',
      text: '#FFFFFF',
      textSecondary: '#EBEBF5',
      border: '#38383A',
      shadowColor: '#FFFFFF',
      error: '#FF453A',
      success: '#30D158',
      warning: '#FF9F0A',
      purple: '#BF5AF2',
    },
  };
  