import React, { useEffect, useMemo } from 'react';
import { View, ScrollView, StyleSheet } from 'react-native';
import { useStudents } from '../../context/StudentContext';
import { useEvents } from '../../context/EventContext';
import { Text, DataTable, Card } from 'react-native-paper';

const StudentReportScreen = ({ route }) => {
  console.log('Route: ', route);
  
  const { studentId } = route.params;
  const { students } = useStudents();
  const { events } = useEvents();

  const student = useMemo(() => 
    students.find(s => s.id === studentId),
    [students, studentId]
  );
  console.log('Student: ', student);
  

  const studentEvents = useMemo(() => {
    if (!events || !studentId) return [];
    
    return events
      .filter(event => {
        // Check if this event has the current student
        const eventStudents = event.studentIds || [];
        return eventStudents.includes(studentId);
      })
      .map((event, index) => ({
        date: new Date(event.start).toLocaleDateString(),
        lessonNumber: index + 1,
        lessonRate: '---', // This needs to come from accounting_records
        duration: event.duration,
        paymentDate: '---', // This needs to come from accounting_records
        receiptDate: '---', // This needs to come from accounting_records
        amountReceived: '---', // This needs to come from accounting_records
        balance: {
          credit: '---', // This needs to be calculated from accounting_records
          debit: '---' // This needs to be calculated from accounting_records
        }
      }))
      .sort((a, b) => new Date(a.date) - new Date(b.date));
  }, [events, studentId]);

  if (!student) {
    return (
      <View style={styles.container}>
        <Text>Student not found</Text>
      </View>
    );
  }

  // const notes = student.notes ? JSON.parse(student.notes) : {};

  return (
    <ScrollView style={styles.container}>
      <Card style={styles.section}>
        <Card.Title title="Personal Information" titleStyle={styles.cardTitle} />
        <Card.Content>
          <View style={styles.infoRow}>
            <Text style={styles.label}>Full Name:</Text>
            <Text style={styles.value}>{`${student.firstName} ${student.lastName}`}</Text>
          </View>
          
          <View style={styles.infoRow}>
            <Text style={styles.label}>Phone:</Text>
            <Text style={styles.value}>{student.phone}</Text>
          </View>
          
          <View style={styles.infoRow}>
            <Text style={styles.label}>Email:</Text>
            <Text style={styles.value}>{student.email}</Text>
          </View>
          
          <View style={styles.infoRow}>
            <Text style={styles.label}>Address:</Text>
            <Text style={styles.value}>{student.address || '---'}</Text>
          </View>
          
          <View style={styles.infoRow}>
            <Text style={styles.label}>ID Number:</Text>
            <Text style={styles.value}>{student.idNumber || '---'}</Text>
          </View>
          
          <View style={styles.infoRow}>
            <Text style={styles.label}>License Type:</Text>
            <Text style={styles.value}>{student.licenseType}</Text>
          </View>
          
          <View style={styles.infoRow}>
            <Text style={styles.label}>Notes:</Text>
            <Text style={styles.value}>{student.notes}</Text>
          </View>
        </Card.Content>
      </Card>

      <Card style={styles.section}>
        <Card.Title title="Lessons History" titleStyle={styles.cardTitle} />
        <Card.Content>
          <ScrollView horizontal>
            <DataTable style={styles.dataTable}>
              <DataTable.Header style={styles.tableHeader}>
                <DataTable.Title style={styles.tableCell}>Date</DataTable.Title>
                <DataTable.Title style={styles.tableCell} numeric>Lesson #</DataTable.Title>
                <DataTable.Title style={styles.tableCell} numeric>Rate ($)</DataTable.Title>
                <DataTable.Title style={styles.tableCell} numeric>Duration</DataTable.Title>
                <DataTable.Title style={styles.tableCell}>Payment Date</DataTable.Title>
                <DataTable.Title style={styles.tableCell}>Receipt Date</DataTable.Title>
                <DataTable.Title style={styles.tableCell} numeric>Received ($)</DataTable.Title>
                <DataTable.Title style={styles.tableCell}>Balance</DataTable.Title>
              </DataTable.Header>

              {studentEvents.map((lesson, index) => (
                <DataTable.Row key={index} style={styles.tableRow}>
                  <DataTable.Cell style={styles.tableCell}>{lesson.date}</DataTable.Cell>
                  <DataTable.Cell style={styles.tableCell} numeric>{lesson.lessonNumber}</DataTable.Cell>
                  <DataTable.Cell style={styles.tableCell} numeric>{lesson.lessonRate}</DataTable.Cell>
                  <DataTable.Cell style={styles.tableCell} numeric>{lesson.duration}</DataTable.Cell>
                  <DataTable.Cell style={styles.tableCell}>{lesson.paymentDate}</DataTable.Cell>
                  <DataTable.Cell style={styles.tableCell}>{lesson.receiptDate}</DataTable.Cell>
                  <DataTable.Cell style={styles.tableCell} numeric>{lesson.amountReceived}</DataTable.Cell>
                  <DataTable.Cell style={styles.tableCell}>
                    {lesson.balance.credit !== '---' ? `CR: ${lesson.balance.credit}` : ''}
                    {lesson.balance.debit !== '---' ? `DR: ${lesson.balance.debit}` : ''}
                  </DataTable.Cell>
                </DataTable.Row>
              ))}
            </DataTable>
          </ScrollView>
        </Card.Content>
      </Card>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
    backgroundColor: '#f5f5f5',
  },
  section: {
    marginBottom: 16,
    elevation: 2,
  },
  cardTitle: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  infoRow: {
    flexDirection: 'row',
    marginBottom: 12,
    paddingVertical: 8,
    borderBottomWidth: 1,
    borderBottomColor: '#eee',
  },
  label: {
    flex: 1,
    fontWeight: 'bold',
    color: '#666',
  },
  value: {
    flex: 2,
    color: '#333',
  },
  dataTable: {
    backgroundColor: 'white',
    borderRadius: 8,
    marginTop: 8,
  },
  tableHeader: {
    backgroundColor: '#f5f5f5',
    borderBottomWidth: 1,
    borderBottomColor: '#ddd',
  },
  tableRow: {
    borderBottomWidth: 1,
    borderBottomColor: '#eee',
  },
  tableCell: {
    paddingHorizontal: 16,
    paddingVertical: 8,
    marginHorizontal: 4,
    borderRightWidth: 1,
    borderRightColor: '#eee',
  }
});

export default StudentReportScreen;