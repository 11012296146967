import Constants from 'expo-constants';
import { Platform } from 'react-native';

const getApiUrl = () => {
  if (__DEV__) {
    // Development environment
    if (Platform.OS === 'android') {
      // Android emulator needs special localhost address
      return 'http://10.0.2.2:4001/api';
    } else if (Platform.OS === 'ios') {
      // iOS simulator can use localhost
      return 'http://localhost:4001/api';
    }
    // Add your local IP address for physical devices
    // return 'http://192.168.1.XXX:3000/api';
  }
  // Production environment
  return Constants.expoConfig?.extra?.apiUrl || 'https://your-production-api.com/api';
};

export const config = {
  api: {
    baseURL: getApiUrl(),
    timeout: parseInt(Constants.expoConfig?.extra?.apiTimeout || 10000, 10),
  }
};