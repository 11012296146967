import React, { useState } from 'react';
import { View, Text, TouchableOpacity, Modal, Alert } from 'react-native';
import { Feather } from '@expo/vector-icons';
import { useTranslation } from 'react-i18next';
import { useThemedStyles } from '../../theme/hooks';
import { DOCUMENT_FLOWS, getValidActions } from '../../constants/documentTypes';
import CancelModal from './CancelModal'

export const DocumentActionsMenu = ({
  document,
  onIssueReceipt,
  onIssueTaxInvoiceReceipt,
  onIssueCreditInvoice,
  onConvertToTaxInvoice,
  onCancel,
  onViewPdf
}) => {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(false);
  const [isCancelModalVisible, setIsCancelModalVisible] = useState(false);


  const styles = useThemedStyles((colors) => ({
    triggerButton: {
      padding: 8,
      marginLeft: 'auto',
    },
    triggerIcon: {
      color: colors.textSecondary,
    },
    modalOverlay: {
      flex: 1,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      justifyContent: 'flex-end',
    },
    menuContainer: {
      backgroundColor: colors.card,
      borderTopLeftRadius: 16,
      borderTopRightRadius: 16,
      paddingBottom: 34,
    },
    menuHeader: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: 16,
      borderBottomWidth: 1,
      borderBottomColor: colors.border,
    },
    menuTitle: {
      fontSize: 18,
      fontWeight: '600',
      color: colors.text,
    },
    closeButton: {
      padding: 8,
    },
    closeIcon: {
      color: colors.textSecondary,
    },
    menuItem: {
      flexDirection: 'row',
      alignItems: 'center',
      padding: 16,
      borderBottomWidth: 1,
      borderBottomColor: colors.border,
    },
    menuItemText: {
      marginLeft: 12,
      fontSize: 16,
      color: colors.text,
    },
    menuItemTextDestructive: {
      color: colors.error,
    },
    menuItemIcon: {
      color: colors.primary,
    },
    menuItemIconDestructive: {
      color: colors.error,
    },
    documentInfo: {
      padding: 16,
      borderBottomWidth: 1,
      borderBottomColor: colors.border,
    },
    documentNumber: {
      fontSize: 16,
      fontWeight: '600',
      color: colors.text,
      marginBottom: 4,
    },
    documentType: {
      fontSize: 14,
      color: colors.textSecondary,
    }
  }));

  const getActionConfig = (actionId, document) => {
    const configs = {
      convert_to_tax_invoice: {
        id: 'convert_to_tax_invoice',
        label: t('documents.actions.convertToTaxInvoice'),
        icon: 'file-text',
        onPress: () => {
          setIsVisible(false);
          onConvertToTaxInvoice(document);
        }
      },
      issue_receipt: {
        id: 'issue_receipt',
        label: t('documents.actions.issueReceipt'),
        icon: 'file-plus',
        onPress: () => {
          setIsVisible(false);
          onIssueReceipt(document);
        }
      },
      issue_tax_invoice_receipt: {
        id: 'issue_tax_invoice_receipt',
        label: t('documents.actions.issueTaxInvoiceReceipt'),
        icon: 'file-plus',
        onPress: () => {
          setIsVisible(false);
          onIssueTaxInvoiceReceipt(document);
        }
      },
      issue_credit: {
        id: 'issue_credit',
        label: t('documents.actions.issueCreditInvoice'),
        icon: 'file-minus',
        onPress: () => {
          setIsVisible(false);
          onIssueCreditInvoice(document);
        }
      },
      cancel: {
        id: 'cancel',
        label: t('documents.actions.cancel'),
        icon: 'x-circle',
        destructive: true,
        onPress: () => {
          setIsVisible(false);
          setIsCancelModalVisible(true)
        }
      },
      view_pdf: {
        id: 'view_pdf',
        label: t('documents.actions.viewPdf'),
        icon: 'file-text',
        onPress: () => {
          setIsVisible(false);
          onViewPdf(document);
        }
      }
    };

    return configs[actionId];
  };

  const availableActions = getValidActions(document.document_type, document.status)
    .map(actionId => getActionConfig(actionId, document))
    .filter(Boolean);

  if (availableActions.length === 0) {
    return null;
  }

  return (
    <>
      <TouchableOpacity
        style={styles.triggerButton}
        onPress={() => setIsVisible(true)}
      >
        <Feather
          name="more-vertical"
          size={20}
          style={styles.triggerIcon}
        />
      </TouchableOpacity>

      <Modal
        visible={isVisible}
        transparent
        animationType="fade"
        onRequestClose={() => setIsVisible(false)}
      >
        <TouchableOpacity
          style={styles.modalOverlay}
          activeOpacity={1}
          onPress={() => setIsVisible(false)}
        >
          <TouchableOpacity
            activeOpacity={1}
            onPress={e => e.stopPropagation()}
          >
            <View style={styles.menuContainer}>
              <View style={styles.menuHeader}>
                <Text style={styles.menuTitle}>
                  {t('documents.actions.title')}
                </Text>
                <TouchableOpacity
                  style={styles.closeButton}
                  onPress={() => setIsVisible(false)}
                >
                  <Feather
                    name="x"
                    size={24}
                    style={styles.closeIcon}
                  />
                </TouchableOpacity>
              </View>

              <View style={styles.documentInfo}>
                <Text style={styles.documentNumber}>
                  {document.record_number}
                </Text>
                <Text style={styles.documentType}>
                  {t(`documents.types.${document.document_type.toLowerCase()}`)}
                </Text>
              </View>

              {availableActions.map((action) => (
                <TouchableOpacity
                  key={action.id}
                  style={styles.menuItem}
                  onPress={action.onPress}
                >
                  <Feather
                    name={action.icon}
                    size={20}
                    style={[
                      styles.menuItemIcon,
                      action.destructive && styles.menuItemIconDestructive
                    ]}
                  />
                  <Text
                    style={[
                      styles.menuItemText,
                      action.destructive && styles.menuItemTextDestructive
                    ]}
                  >
                    {action.label}
                  </Text>
                </TouchableOpacity>
              ))}
            </View>
          </TouchableOpacity>
        </TouchableOpacity>
      </Modal>

      <CancelModal
        isVisible={isCancelModalVisible}
        onClose={() => setIsCancelModalVisible(false)}
        onCancel={onCancel}
        document={document}
        translations={{
          title: t('documents.cancel.title'),
          message: t('documents.cancel.message'),
          cancel: t('common.cancel'),
          confirm: t('documents.cancel.confirm'),
          reasonPlaceholder: t('documents.cancel.reasonPlaceholder')
        }}
      />
    </>
  );
};