import { useMemo } from 'react';
import { StyleSheet } from 'react-native';
import { colors } from './colors';
import { useTheme } from './ThemeContext';

export const useThemedStyles = (styleFactory) => {
  const { isDark } = useTheme();
  const currentColors = colors[isDark ? 'dark' : 'light'];
  
  return useMemo(() => {
    const styles = styleFactory(currentColors);
    return StyleSheet.create(styles);
  }, [isDark, currentColors, styleFactory]);
};