import React, { useEffect, useRef } from 'react';
import { Animated, TextInput, View, TouchableOpacity } from 'react-native';
import { Feather } from '@expo/vector-icons';
import { useThemedStyles } from '../../theme/hooks';

const CollapsibleSearchBar = ({
  visible,
  value,
  onChangeText,
  placeholder = "Search...",
  placeholderTextColor = "#A0A0A0",
  animationDuration = 200,
}) => {
  const slideAnim = useRef(new Animated.Value(visible ? 1 : 0)).current;
  
  const styles = useThemedStyles((colors) => ({
    searchContainer: {
      overflow: 'hidden',
      position: 'relative',
      backgroundColor: colors.background,
      borderBottomWidth: 1,
      borderBottomColor: colors.border,
      marginTop: 0,
      marginBottom: 0,
    },
    searchInputWrapper: {
      paddingHorizontal: 16,
      paddingVertical: 8,
      position: 'relative',
    },
    searchInput: {
      height: 40,
      backgroundColor: colors.card,
      borderRadius: 20,
      paddingHorizontal: 16,
      paddingLeft: 40,
      paddingRight: value ? 40 : 16,
      fontSize: 16,
      color: colors.text,
      borderWidth: 1,
      borderColor: colors.border,
    },
    searchIconContainer: {
      position: 'absolute',
      left: 28,
      height: 40, 
      top: 8, 
      justifyContent: 'center', 
      zIndex: 1,
    },
    clearButton: {
      position: 'absolute',
      right: 28,
      height: 40,
      top: 8,
      justifyContent: 'center',
      zIndex: 1,
    },
    clearButtonInner: {
      width: 30,
      height: 30,
      justifyContent: 'center',
      alignItems: 'center',
    },
  }));

  useEffect(() => {
    Animated.timing(slideAnim, {
      toValue: visible ? 1 : 0,
      duration: animationDuration,
      useNativeDriver: false,
    }).start();
  }, [visible, animationDuration]);

  const containerHeight = slideAnim.interpolate({
    inputRange: [0, 1],
    outputRange: [0, 56], // 40px input + 16px padding
  });

  const handleClear = () => {
    onChangeText('');
  };

  return (
    <Animated.View
      style={[
        styles.searchContainer,
        {
          height: containerHeight,
          opacity: slideAnim,
        }
      ]}
    >
      <View style={styles.searchInputWrapper}>
        <View style={styles.searchIconContainer}>
          <Feather
            name="search"
            size={20}
            color={placeholderTextColor}
          />
        </View>
        <TextInput
          style={styles.searchInput}
          placeholder={placeholder}
          value={value}
          onChangeText={onChangeText}
          placeholderTextColor={placeholderTextColor}
        />
        {value ? (
          <View style={styles.clearButton}>
            <TouchableOpacity 
              style={styles.clearButtonInner}
              onPress={handleClear}
            >
              <Feather
                name="x"
                size={20}
                color={placeholderTextColor}
              />
            </TouchableOpacity>
          </View>
        ) : null}
      </View>
    </Animated.View>
  );
};

export default CollapsibleSearchBar;