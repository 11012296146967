import React, { useState, useMemo, useEffect } from 'react';
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  FlatList,
  Modal,
  TextInput,
  Alert,
} from 'react-native';
import { useSuppliers } from '../../context/SupplierContext';
import { Feather } from '@expo/vector-icons';
import { useThemedStyles } from '../../theme/hooks';
import { useTranslation } from 'react-i18next';
import DropDownPicker from 'react-native-dropdown-picker';
import { colors } from '../../theme/colors';

export default function SuppliersScreen() {
  const { t } = useTranslation();
  const [isModalVisible, setModalVisible] = useState(false);
  const [editingSupplier, setEditingSupplier] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [dropDownOpen, setDropDownOpen] = useState(false);
  
  const [formData, setFormData] = useState({
    name: '',
    contactPerson: '',
    phone: '',
    email: '',
    address: '',
    category: '',
    paymentTerms: '',
    authorizedBusinessNumber: '',
    status: 'active',
    notes: '',
  });

  const { suppliers, addSupplier, updateSupplier, deleteSupplier, fetchSuppliers } = useSuppliers();

  useEffect(() => {
    fetchSuppliers();
  }, [fetchSuppliers]);

  const statusItems = [
    { label: t('suppliers.status.active'), value: 'active' },
    { label: t('suppliers.status.inactive'), value: 'inactive' },
  ];

  // Filter suppliers based on search query
  const filteredSuppliers = useMemo(() => {
    const query = searchQuery.toLowerCase().trim();
    if (!query) return suppliers;
    
    return suppliers.filter(supplier => 
      supplier.name.toLowerCase().includes(query) ||
      supplier.contactPerson.toLowerCase().includes(query)
    );
  }, [suppliers, searchQuery]);

  const styles = useThemedStyles((colors) => ({
    container: {
      flex: 1,
      backgroundColor: colors.background,
    },
    list: {
      padding: 16,
    },
    studentCard: {
      flexDirection: 'row',
      backgroundColor: colors.card,
      borderRadius: 12,
      padding: 16,
      marginBottom: 12,
      elevation: 2,
      shadowColor: colors.shadowColor,
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.1,
      shadowRadius: 4,
    },
    studentInfo: {
      flex: 1,
    },
    studentName: {
      fontSize: 18,
      fontWeight: 'bold',
      marginBottom: 4,
      color: colors.text,
    },
    studentDetails: {
      fontSize: 14,
      color: colors.textSecondary,
      marginBottom: 2,
    },
    studentEmail: {
      fontSize: 14,
      color: colors.textSecondary,
    },
    deleteButton: {
      padding: 8,
    },
    fab: {
      position: 'absolute',
      right: 16,
      bottom: 16,
      backgroundColor: colors.primary,
      width: 56,
      height: 56,
      borderRadius: 28,
      justifyContent: 'center',
      alignItems: 'center',
      elevation: 4,
      shadowColor: colors.shadowColor,
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.25,
      shadowRadius: 4,
    },
    modalContainer: {
      flex: 1,
      justifyContent: 'center',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    modalContent: {
      backgroundColor: colors.card,
      margin: 16,
      borderRadius: 12,
      padding: 16,
      maxHeight: '90%',
    },
    modalTitle: {
      fontSize: 20,
      fontWeight: 'bold',
      marginBottom: 16,
      textAlign: 'center',
      color: colors.text,
    },
    input: {
      height: 50,
      borderWidth: 1,
      borderColor: colors.border,
      borderRadius: 8,
      paddingHorizontal: 16,
      marginBottom: 12,
      fontSize: 16,
      color: colors.text,
      backgroundColor: colors.background,
    },
    placeholder: {
      color: colors.textSecondary,
    },
    textArea: {
      height: 100,
      paddingTop: 12,
    },
    modalButtons: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginTop: 16,
    },
    button: {
      flex: 1,
      height: 50,
      borderRadius: 8,
      justifyContent: 'center',
      alignItems: 'center',
      marginHorizontal: 8,
    },
    cancelButton: {
      backgroundColor: colors.error,
    },
    saveButton: {
      backgroundColor: colors.primary,
    },
    buttonText: {
      color: colors.card,
      fontSize: 16,
      fontWeight: 'bold',
    },
    searchContainer: {
      position: 'relative',
      padding: 16,
      paddingBottom: 8,
      backgroundColor: colors.background,
    },
    searchInput: {
      height: 40,
      backgroundColor: colors.card,
      borderRadius: 20,
      paddingHorizontal: 16,
      paddingLeft: 40,
      fontSize: 16,
      color: colors.text,
      borderWidth: 1,
      borderColor: colors.border,
    },
    searchIcon: {
      position: 'absolute',
      width: 40,
      left: 25, 
      top: '50%',
      transform: [{                
        translateY: 5
      }],
      zIndex: 1,  
    },
    noResults: {
      textAlign: 'center',
      marginTop: 20,
      fontSize: 16,
      color: colors.textSecondary,
    },
    list: {
      flexGrow: 1,
      padding: 16,
      paddingTop: 8,
    },
    statusBadge: {
      paddingHorizontal: 8,
      paddingVertical: 4,
      borderRadius: 12,
      marginTop: 4,
    },
    statusActive: {
      backgroundColor: colors.success,
    },
    statusInactive: {
      backgroundColor: colors.error,
    },
    statusOnLeave: {
      backgroundColor: colors.warning,
    },
    statusText: {
      color: colors.card,
      fontSize: 12,
      fontWeight: 'bold',
    },
    dropDown: {
      backgroundColor: colors.background,
      borderColor: colors.border,
      marginBottom: 12,
    },
    dropDownContainer: {
      backgroundColor: colors.card,
    },
    dateButton: {
      height: 50,
      borderWidth: 1,
      borderColor: colors.border,
      borderRadius: 8,
      paddingHorizontal: 16,
      marginBottom: 12,
      justifyContent: 'center',
      backgroundColor: colors.background,
    },
    dateButtonText: {
      fontSize: 16,
      color: colors.text,
    },
    statusBadge: {
      paddingHorizontal: 8,
      paddingVertical: 4,
      borderRadius: 12,
      marginTop: 4,
    },
    statusActive: {
      backgroundColor: colors.success,
    },
    statusInactive: {
      backgroundColor: colors.error,
    },
    supplierCard: {
      flexDirection: 'row',
      backgroundColor: colors.card,
      borderRadius: 12,
      padding: 16,
      marginBottom: 12,
      elevation: 2,
      shadowColor: colors.shadowColor,
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.1,
      shadowRadius: 4,
    },
    supplierName: {
      fontSize: 18,
      fontWeight: 'bold',
      color: colors.text,
      marginBottom: 4,
    },
    contactInfo: {
      fontSize: 14,
      color: colors.textSecondary,
      marginBottom: 2,
    },
    categoryText: {
      fontSize: 14,
      color: colors.primary,
      marginBottom: 2,
    },
  }));

  const resetForm = () => {
    setFormData({
      name: '',
      contactPerson: '',
      phone: '',
      email: '',
      address: '',
      category: '',
      paymentTerms: '',
      authorizedBusinessNumber: '',
      status: 'active',
      notes: '',
    });
    setEditingSupplier(null);
  };

  const handleSubmit = () => {
    if (!formData.name) {
      Alert.alert(t('common.error'), t('suppliers.errors.nameRequired'));
      return;
    }

    const supplierData = {
      id: editingSupplier ? editingSupplier.id : Date.now().toString(),
      ...formData,
      createdAt: editingSupplier ? editingSupplier.createdAt : new Date().toISOString(),
      updatedAt: new Date().toISOString(),
    };

    if (editingSupplier) {
      updateSupplier(supplierData);
      Alert.alert(t('common.success'), t('suppliers.success.updated'));
    } else {
      addSupplier(supplierData);
      Alert.alert(t('common.success'), t('suppliers.success.added'));
    }

    setModalVisible(false);
    resetForm();
  };

  const handleDelete = (supplier) => {
    Alert.alert(
      t('suppliers.delete.title'),
      t('suppliers.delete.confirm', { name: supplier.name }),
      [
        {
          text: t('suppliers.buttons.cancel'),
          style: 'cancel',
        },
        {
          text: t('suppliers.buttons.delete'),
          style: 'destructive',
          onPress: () => {
            deleteSupplier(supplier.id);
            Alert.alert(t('common.success'), t('suppliers.success.deleted'));
          },
        },
      ]
    );
  };

  const handleEdit = (supplier) => {
    setEditingSupplier(supplier);
    setFormData({
      name: supplier.name,
      contactPerson: supplier.contactPerson,
      phone: supplier.phone,
      email: supplier.email,
      address: supplier.address,
      category: supplier.category,
      paymentTerms: supplier.paymentTerms,
      authorizedBusinessNumber: supplier.authorizedBusinessNumber,
      status: supplier.status,
      notes: supplier.notes,
    });
    setModalVisible(true);
  };

  const renderSupplier = ({ item }) => (
    <TouchableOpacity
      style={styles.supplierCard}
      onPress={() => handleEdit(item)}
    >
      <View style={{ flex: 1 }}>
        <Text style={styles.supplierName}>{item.name}</Text>
        <Text style={styles.contactInfo}>
          {item.contactPerson} • {item.phone}
        </Text>
        <Text style={styles.contactInfo}>{item.email}</Text>
        <Text style={styles.categoryText}>{item.category}</Text>
        <View style={[
          styles.statusBadge, 
          item.status === 'active' ? styles.statusActive : styles.statusInactive
        ]}>
          <Text style={styles.statusText}>
            {t(`suppliers.status.${item.status}`)}
          </Text>
        </View>
      </View>
      <TouchableOpacity
        style={styles.deleteButton}
        onPress={() => handleDelete(item)}
      >
        <Feather name="trash-2" size={20} color="#FF3B30" />
      </TouchableOpacity>
    </TouchableOpacity>
  );

  return (
    <View style={styles.container}>
      <View style={styles.searchContainer}>
        <Feather 
          name="search" 
          size={20} 
          color={styles.placeholder.color} 
          style={styles.searchIcon}
        />
        <TextInput
          style={styles.searchInput}
          placeholder={t('suppliers.search.search')}
          placeholderTextColor={styles.placeholder.color}
          value={searchQuery}
          onChangeText={setSearchQuery}
          autoCapitalize="none"
        />
      </View>

      <FlatList
        data={filteredSuppliers}
        renderItem={renderSupplier}
        keyExtractor={item => item.id}
        contentContainerStyle={styles.list}
        ListEmptyComponent={() => (
          <Text style={styles.noResults}>
            {searchQuery 
              ? t('suppliers.search.noSearchResults') 
              : t('suppliers.search.noSuppliers')}
          </Text>
        )}
      />

      <TouchableOpacity
        style={styles.fab}
        onPress={() => {
          resetForm();
          setModalVisible(true);
        }}
      >
        <Feather name="plus" size={24} color="#fff" />
      </TouchableOpacity>

      <Modal
        visible={isModalVisible}
        animationType="slide"
        transparent={true}
        onRequestClose={() => {
          setModalVisible(false);
          resetForm();
        }}
      >
        <View style={styles.modalContainer}>
          <View style={styles.modalContent}>
            <Text style={styles.modalTitle}>
              {editingSupplier ? t('suppliers.editSupplier') : t('suppliers.addNew')}
            </Text>

            <TextInput
              style={styles.input}
              placeholder={t('suppliers.fields.name')}
              placeholderTextColor={styles.placeholder.color}
              value={formData.name}
              onChangeText={(text) => setFormData({ ...formData, name: text })}
            />

            <TextInput
              style={styles.input}
              placeholder={t('suppliers.fields.contactPerson')}
              placeholderTextColor={styles.placeholder.color}
              value={formData.contactPerson}
              onChangeText={(text) => setFormData({ ...formData, contactPerson: text })}
            />

            <TextInput
              style={styles.input}
              placeholder={t('suppliers.fields.phone')}
              placeholderTextColor={styles.placeholder.color}
              value={formData.phone}
              onChangeText={(text) => setFormData({ ...formData, phone: text })}
              keyboardType="phone-pad"
            />

            <TextInput
              style={styles.input}
              placeholder={t('suppliers.fields.email')}
              placeholderTextColor={styles.placeholder.color}
              value={formData.email}
              onChangeText={(text) => setFormData({ ...formData, email: text })}
              keyboardType="email-address"
              autoCapitalize="none"
            />

            <TextInput
              style={styles.input}
              placeholder={t('suppliers.fields.address')}
              placeholderTextColor={styles.placeholder.color}
              value={formData.address}
              onChangeText={(text) => setFormData({ ...formData, address: text })}
              multiline
              numberOfLines={2}
            />

            <TextInput
              style={styles.input}
              placeholder={t('suppliers.fields.category')}
              placeholderTextColor={styles.placeholder.color}
              value={formData.category}
              onChangeText={(text) => setFormData({ ...formData, category: text })}
            />

            <TextInput
              style={styles.input}
              placeholder={t('suppliers.fields.paymentTerms')}
              placeholderTextColor={styles.placeholder.color}
              value={formData.paymentTerms}
              onChangeText={(text) => setFormData({ ...formData, paymentTerms: text })}
            />

            <TextInput
              style={styles.input}
              placeholder={t('suppliers.fields.authorizedBusinessNumber')}
              placeholderTextColor={styles.placeholder.color}
              value={formData.authorizedBusinessNumber}
              onChangeText={(text) => setFormData({ ...formData, authorizedBusinessNumber: text })}
            />

            <DropDownPicker
              open={dropDownOpen}
              value={formData.status}
              items={statusItems}
              setOpen={setDropDownOpen}
              setValue={(callback) => {
                const value = callback(formData.status);
                setFormData({ ...formData, status: value });
              }}
              style={styles.dropDown}
              dropDownContainerStyle={styles.dropDownContainer}
              textStyle={{ color: colors.textPrimary }}
            />

            <TextInput
              style={[styles.input, styles.textArea]}
              placeholder={t('suppliers.fields.notes')}
              placeholderTextColor={styles.placeholder.color}
              value={formData.notes}
              onChangeText={(text) => setFormData({ ...formData, notes: text })}
              multiline
              numberOfLines={4}
              textAlignVertical="top"
            />

            <View style={styles.modalButtons}>
              <TouchableOpacity
                style={[styles.button, styles.cancelButton]}
                onPress={() => {
                  setModalVisible(false);
                  resetForm();
                }}
              >
                <Text style={styles.buttonText}>{t('suppliers.buttons.cancel')}</Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={[styles.button, styles.saveButton]}
                onPress={handleSubmit}
              >
                <Text style={styles.buttonText}>
                  {editingSupplier ? t('suppliers.buttons.update') : t('suppliers.buttons.save')}
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>
    </View>
  );
}