import React, { useState, useEffect } from 'react';
import {
    View,
    Text,
    Modal,
    TouchableOpacity,
    ScrollView,
    TouchableWithoutFeedback,
} from 'react-native';
import { Feather } from '@expo/vector-icons';
import { useThemedStyles } from '../../theme/hooks';

const FilterBottomSheet = ({
    visible,
    onClose,
    title,
    options,
    selectedValues,
    onSelect,
    onApply,
    isSortSheet = false
}) => {
    const [tempSelected, setTempSelected] = useState(selectedValues);
    const [tempSortDirection, setTempSortDirection] = useState('desc');

    const styles = useThemedStyles((colors) => ({
        modalOverlay: {
            flex: 1,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            justifyContent: 'flex-end',
        },
        contentContainer: {
            backgroundColor: colors.card,
            borderTopLeftRadius: 20,
            borderTopRightRadius: 20,
            maxHeight: '80%',
            width: '100%',
            overflow: 'hidden',
            elevation: 5,
            shadowColor: colors.shadowColor,
            shadowOffset: {
                width: 0,
                height: -2,
            },
            shadowOpacity: 0.25,
            shadowRadius: 3.84,
        },
        bottomSheetHeader: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingVertical: 16,
            paddingHorizontal: 20,
            borderBottomWidth: 1,
            borderBottomColor: colors.border,
            backgroundColor: colors.card,
        },
        bottomSheetTitle: {
            fontSize: 20,
            fontWeight: '600',
            color: colors.text,
        },
        scrollContent: {
            backgroundColor: colors.card,
        },
        bottomSheetOption: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingVertical: 16,
            paddingHorizontal: 20,
            backgroundColor: colors.card,
        },
        bottomSheetOptionContent: {
            flex: 1,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingRight: 16,
        },
        bottomSheetOptionText: {
            fontSize: 16,
            color: colors.text,
        },
        icon: {
            color: colors.text,
        },
        iconPrimary: {
            color: colors.primary,
        },
        bottomSheetFooter: {
            flexDirection: 'row',
            padding: 16,
            paddingBottom: 34, // Extra padding for bottom safe area
            backgroundColor: colors.card,
            borderTopWidth: 1,
            borderTopColor: colors.border,
        },
        bottomSheetButton: {
            flex: 1,
            height: 48,
            borderRadius: 8,
            alignItems: 'center',
            justifyContent: 'center',
            marginHorizontal: 6,
        },
        bottomSheetButtonPrimary: {
            backgroundColor: colors.primary,
        },
        bottomSheetButtonSecondary: {
            backgroundColor: colors.card,
            borderWidth: 1,
            borderColor: colors.border,
        },
        bottomSheetButtonTextPrimary: {
            color: colors.card,
            fontSize: 16,
            fontWeight: '600',
        },
        bottomSheetButtonTextSecondary: {
            color: colors.text,
            fontSize: 16,
        },
        optionSeparator: {
            height: 0.5,
            backgroundColor: colors.border,
            width: '100%',
        }
    }));

    useEffect(() => {
        setTempSelected(selectedValues);
    }, [visible, selectedValues]);

    const handleSelect = (value) => {
        if (isSortSheet) {
            setTempSelected([value]);
            setTempSortDirection(value === tempSelected[0] ?
                (tempSortDirection === 'desc' ? 'asc' : 'desc') : 'desc');
        } else {
            setTempSelected(prev =>
                prev.includes(value)
                    ? prev.filter(v => v !== value)
                    : [...prev, value]
            );
        }
    };

    return (
        <Modal
            visible={visible}
            transparent={true}
            animationType="slide"
            onRequestClose={onClose}
        >
            <TouchableWithoutFeedback onPress={onClose}>
                <View style={styles.modalOverlay}>
                    <TouchableWithoutFeedback>
                        <View style={styles.contentContainer}>
                            <View style={styles.bottomSheetHeader}>
                                <Text style={styles.bottomSheetTitle}>{title}</Text>
                                <TouchableOpacity onPress={onClose}>
                                    <Feather
                                        name="x"
                                        size={24}
                                        style={styles.icon}
                                    />
                                </TouchableOpacity>
                            </View>

                            <ScrollView style={styles.scrollContent}>
                                {options.map((option, index) => (
                                    <React.Fragment key={option.value || option.key}>
                                        <TouchableOpacity
                                            style={styles.bottomSheetOption}
                                            onPress={() => handleSelect(option.value || option.key)}
                                        >
                                            <View style={styles.bottomSheetOptionContent}>
                                                <Text style={styles.bottomSheetOptionText}>
                                                    {option.label}
                                                </Text>
                                                {isSortSheet && tempSelected[0] === (option.value || option.key) && (
                                                    <Feather
                                                        name={tempSortDirection === 'asc' ? 'arrow-up' : 'arrow-down'}
                                                        size={20}
                                                        style={styles.iconPrimary}
                                                    />
                                                )}
                                            </View>
                                            {!isSortSheet && tempSelected.includes(option.value) && (
                                                <Feather
                                                    name="check"
                                                    size={20}
                                                    style={styles.iconPrimary}
                                                />
                                            )}
                                        </TouchableOpacity>
                                        {index < options.length - 1 && <View style={styles.optionSeparator} />}
                                    </React.Fragment>
                                ))}
                            </ScrollView>

                            <View style={styles.bottomSheetFooter}>
                                <TouchableOpacity
                                    style={[styles.bottomSheetButton, styles.bottomSheetButtonSecondary]}
                                    onPress={onClose}
                                >
                                    <Text style={styles.bottomSheetButtonTextSecondary}>Cancel</Text>
                                </TouchableOpacity>
                                <TouchableOpacity
                                    style={[styles.bottomSheetButton, styles.bottomSheetButtonPrimary]}
                                    onPress={() => {
                                        if (isSortSheet) {
                                            onApply(tempSelected[0], tempSortDirection);
                                        } else {
                                            onApply(tempSelected);
                                        }
                                        onClose();
                                    }}
                                >
                                    <Text style={styles.bottomSheetButtonTextPrimary}>Apply</Text>
                                </TouchableOpacity>
                            </View>
                        </View>
                    </TouchableWithoutFeedback>
                </View>
            </TouchableWithoutFeedback>
        </Modal>
    );
};

export default FilterBottomSheet;