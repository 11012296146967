import React from 'react';
import { View, Text, TouchableOpacity, Alert, ScrollView } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import * as FileSystem from 'expo-file-system';
import * as Sharing from 'expo-sharing';
import { useThemedStyles } from '../../theme/hooks';

export const ReportContainer = ({ 
  children, 
  onExportCSV, 
  onExportPDF,
  isLoading 
}) => {
  const styles = useThemedStyles((colors) => ({
    container: {
        flex: 1,
        backgroundColor: colors.background,
      },
      header: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
        padding: 16,
        borderBottomWidth: 1,
        borderBottomColor: colors.border,
        backgroundColor: colors.card,
      },
      content: {
        padding: 16,
      },
      exportButton: {
        flexDirection: 'row',
        alignItems: 'center',
        padding: 8,
        borderRadius: 8,
        backgroundColor: colors.primary,
        marginLeft: 8,
      },
      exportButtonText: {
        color: colors.card,
        marginLeft: 4,
      },
      // Common card styles
      card: {
        backgroundColor: colors.card,
        borderRadius: 12,
        padding: 16,
        marginBottom: 16,
      },
      sectionTitle: {
        fontSize: 18,
        fontWeight: 'bold',
        color: colors.text,
        marginBottom: 12,
      },
      row: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 16,
        flexWrap: 'wrap',
      },
      // Common text styles
      mainText: {
        fontSize: 16,
        color: colors.text,
        marginBottom: 4,
      },
      subText: {
        fontSize: 14,
        color: colors.textSecondary,
        marginBottom: 4,
      },
      totalAmount: {
        fontSize: 16,
        fontWeight: 'bold',
        color: colors.text,
        marginBottom: 4,
      },
      breakdownText: {
        fontSize: 13,
        color: colors.textSecondary,
      },
      highlight: {
        color: colors.primary,
        fontWeight: 'bold',
      },
      // Layout containers
      infoContainer: {
        flex: 1,
        marginBottom: 8,
      },
      amountsContainer: {
        flex: 1,
        alignItems: 'flex-end',
      },
  }));

  const handleExport = async (type) => {
    try {
      if (type === 'csv') {
        const content = await onExportCSV();
        const path = `${FileSystem.documentDirectory}report.csv`;
        await FileSystem.writeAsStringAsync(path, content);
        await Sharing.shareAsync(path, {
          UTI: '.csv',
          mimeType: 'text/csv'
        });
      } else if (type === 'pdf') {
        await onExportPDF();
      }
    } catch (error) {
      console.error('Export error:', error);
      Alert.alert('Export Error', error.message);
    }
  };

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <TouchableOpacity 
          style={styles.exportButton}
          onPress={() => handleExport('csv')}
          disabled={isLoading}
        >
          <Ionicons name="download-outline" size={20} color="#fff" />
          <Text style={styles.exportButtonText}>CSV</Text>
        </TouchableOpacity>
        {onExportPDF && (
          <TouchableOpacity 
            style={styles.exportButton}
            onPress={() => handleExport('pdf')}
            disabled={isLoading}
          >
            <Ionicons name="document-outline" size={20} color="#fff" />
            <Text style={styles.exportButtonText}>PDF</Text>
          </TouchableOpacity>
        )}
      </View>
      <ScrollView style={styles.content}>
        {children}
      </ScrollView>
    </View>
  );
};

export const useReportStyles = () => {
    return useThemedStyles((colors) => ({
      card: {
        backgroundColor: colors.card,
        borderRadius: 12,
        padding: 16,
        marginBottom: 16,
      },
      sectionTitle: {
        fontSize: 18,
        fontWeight: 'bold',
        color: colors.text,
        marginBottom: 12,
      },
      row: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 16,
        flexWrap: 'wrap',
      },
      mainText: {
        fontSize: 16,
        color: colors.text,
        marginBottom: 4,
      },
      subText: {
        fontSize: 14,
        color: colors.textSecondary,
        marginBottom: 4,
      },
      totalAmount: {
        fontSize: 16,
        fontWeight: 'bold',
        color: colors.text,
        marginBottom: 4,
      },
      breakdownText: {
        fontSize: 13,
        color: colors.textSecondary,
      },
      highlight: {
        color: colors.primary,
        fontWeight: 'bold',
      },
      infoContainer: {
        flex: 1,
        marginBottom: 8,
      },
      amountsContainer: {
        flex: 1,
        alignItems: 'flex-end',
      },
    }));
  };