import React, { useMemo, useEffect, useState } from 'react';
import {
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  useWindowDimensions,
  ActivityIndicator,
  Animated,
} from 'react-native';
import _ from 'lodash';
import { Feather } from '@expo/vector-icons';
import { useTranslation } from 'react-i18next';
import { useThemedStyles } from '../../theme/hooks';
import { usePayments } from '../../context/PaymentContext';

const StatWidget = ({ icon, label, value, color = '#007AFF' }) => {
  const { width } = useWindowDimensions();
  const styles = useThemedStyles((colors) => ({
    widget: {
      backgroundColor: colors.card,
      borderRadius: 16,
      padding: 16,
      width: (width - 36) / 2, // Accounting for padding and gap
      elevation: 2,
      shadowColor: colors.shadowColor,
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.1,
      shadowRadius: 4,
    },
    iconContainer: {
      width: 40,
      height: 40,
      borderRadius: 12,
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: 12,
    },
    value: {
      fontSize: 20,
      fontWeight: '600',
      color: colors.text,
      marginBottom: 4,
    },
    label: {
      fontSize: 13,
      color: colors.textSecondary,
    },
  }));

  return (
    <View style={styles.widget}>
      <View style={[styles.iconContainer, { backgroundColor: color + '15' }]}>
        <Feather name={icon} size={20} color={color} />
      </View>
      <Text style={styles.value}>{value}</Text>
      <Text style={styles.label}>{label}</Text>
    </View>
  );
};

const TimeframeSection = ({ title, income, invoices, color }) => {
  const { t } = useTranslation();
  const styles = useThemedStyles((colors) => ({
    container: {
      backgroundColor: colors.card,
      margin: 12,
      marginTop: 0,
      borderRadius: 16,
      padding: 16,
      elevation: 2,
      shadowColor: colors.shadowColor,
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.1,
      shadowRadius: 4,
    },
    header: {
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: 16,
    },
    iconContainer: {
      width: 32,
      height: 32,
      borderRadius: 10,
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: 12,
    },
    title: {
      fontSize: 16,
      fontWeight: '600',
      color: colors.text,
    },
    statsContainer: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    stat: {
      flex: 1,
    },
    value: {
      fontSize: 20,
      fontWeight: '600',
      color: colors.text,
      marginBottom: 4,
    },
    label: {
      fontSize: 13,
      color: colors.textSecondary,
    },
  }));

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <View style={[styles.iconContainer, { backgroundColor: color + '15' }]}>
          <Feather name="calendar" size={16} color={color} />
        </View>
        <Text style={styles.title}>{title}</Text>
      </View>
      <View style={styles.statsContainer}>
        <View style={styles.stat}>
          <Text style={styles.value}>₪{income?.toLocaleString()}</Text>
          <Text style={styles.label}>{t('accounting.overview.income')}</Text>
        </View>
        <View style={styles.stat}>
          <Text style={styles.value}>{invoices}</Text>
          <Text style={styles.label}>{t('accounting.overview.invoices')}</Text>
        </View>
      </View>
    </View>
  );
};

const AccountingOverviewScreen = () => {
  const { t, i18n } = useTranslation();
  const { width } = useWindowDimensions();
  const { documents, isLoading, fetchDocuments } = usePayments();
  const [showWelcome, setShowWelcome] = useState(true);
  const welcomeHeight = useMemo(() => new Animated.Value(showWelcome ? 1 : 0), []);

  const styles = useThemedStyles((colors) => ({
    container: {
      flex: 1,
      backgroundColor: colors.background,
    },
    header: {
      overflow: 'hidden',
    },
    welcomeContent: {
      padding: 16,
      backgroundColor: colors.card,
      borderBottomWidth: 1,
      borderBottomColor: colors.border,
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
    },
    welcomeTextContainer: {
      flex: 1,
    },
    greeting: {
      fontSize: 20,
      fontWeight: '600',
      color: colors.text,
      marginBottom: 4,
    },
    date: {
      fontSize: 14,
      color: colors.textSecondary,
    },
    closeButton: {
      padding: 8,
      marginTop: -8,
      marginRight: -8,
    },
    statsContainer: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      padding: 12,
      gap: 12,
    },
    loadingContainer: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
    },
    textSecondary: {
      color: colors.textSecondary
    }
  }));

  useEffect(() => {
    fetchDocuments();
  }, [fetchDocuments]);

  const metrics = useMemo(() => {
    if (!documents?.length) return null;

    const now = new Date();
    const startOfWeek = new Date(now.setDate(now.getDate() - now.getDay()));
    const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);

    // Filter documents by timeframe
    const weeklyDocs = documents.filter(doc => new Date(doc.issue_date) >= startOfWeek);
    const monthlyDocs = documents.filter(doc => new Date(doc.issue_date) >= startOfMonth);

    // Get all paid documents from this month
    const monthlyPaidDocs = monthlyDocs.filter(doc => doc.status === 'PAID');

    // Calculate monthly expenses and direct income
    const monthlyExpenses = monthlyPaidDocs
      .filter(doc => doc.document_type === 'EXPENSE')
      .reduce((sum, doc) => sum + Number(doc.amount), 0);

    const monthlyDirectIncome = monthlyPaidDocs
      .filter(doc => doc.document_type === 'INCOME')
      .reduce((sum, doc) => sum + Number(doc.amount), 0);

    // Existing calculations...
    const weeklyIncome = weeklyDocs
      .filter(doc => doc.status === 'PAID')
      .reduce((sum, doc) => sum + Number(doc.amount), 0);
    const weeklyInvoiceCount = weeklyDocs
      .filter(doc => ['INVOICE', 'TAX_INVOICE'].includes(doc.document_type))
      .length;

    const monthlyIncome = monthlyPaidDocs
      .filter(doc => ['INVOICE', 'TAX_INVOICE', 'TAX_INVOICE_RECEIPT', 'RECEIPT'].includes(doc.document_type))
      .reduce((sum, doc) => sum + Number(doc.amount), 0);
    const monthlyInvoiceCount = monthlyDocs
      .filter(doc => ['INVOICE', 'TAX_INVOICE'].includes(doc.document_type))
      .length;

    const openDocuments = documents.filter(doc =>
      ['INVOICE', 'TAX_INVOICE'].includes(doc.document_type) &&
      doc.status === 'ACTIVE'
    );

    const unpaidStudents = _.uniqBy(
      openDocuments.filter(doc => doc.entity_type === 'STUDENT'),
      'entity_id'
    );

    return {
      weeklyIncome,
      weeklyInvoiceCount,
      monthlyIncome,
      monthlyInvoiceCount,
      monthlyExpenses,
      monthlyDirectIncome,
      openDocumentsCount: openDocuments.length,
      openAmount: openDocuments.reduce((sum, doc) => sum + Number(doc.amount), 0),
      unpaidStudentsCount: unpaidStudents.length,
    };
  }, [documents]);

  const toggleWelcome = () => {
    Animated.timing(welcomeHeight, {
      toValue: showWelcome ? 0 : 1,
      duration: 300,
      useNativeDriver: false,
    }).start();
    setShowWelcome(!showWelcome);
  };

  if (isLoading) {
    return (
      <View style={[styles.container, styles.loadingContainer]}>
        <ActivityIndicator size="large" />
        <Text>{t('common.loading')}</Text>
      </View>
    );
  }

  return (
    <ScrollView style={styles.container}>
      <Animated.View style={[
        styles.header,
        {
          maxHeight: welcomeHeight.interpolate({
            inputRange: [0, 1],
            outputRange: [0, 100]
          })
        }
      ]}>
        <View style={styles.welcomeContent}>
          <View style={styles.welcomeTextContainer}>
            <Text style={styles.greeting}>{t('accounting.overview.title')}</Text>
            <Text style={styles.date}>
              {new Date().toLocaleDateString(i18n.language, {
                weekday: 'long',
                month: 'long',
                day: 'numeric',
              })}
            </Text>
          </View>
          <TouchableOpacity style={styles.closeButton} onPress={toggleWelcome}>
            <Feather name="x" size={20} color={styles.textSecondary.color} />
          </TouchableOpacity>
        </View>
      </Animated.View>

      <View style={styles.statsContainer}>
        <StatWidget
          icon="file-text"
          label={t('accounting.overview.openInvoices')}
          value={metrics?.openDocumentsCount || 0}
          color="#FF3B30"
        />
        <StatWidget
          icon="dollar-sign"
          label={t('accounting.overview.outstandingAmount')}
          value={`₪${metrics?.openAmount?.toLocaleString() || 0}`}
          color="#34C759"
        />
        <StatWidget
          icon="users"
          label={t('accounting.overview.unpaidStudents')}
          value={metrics?.unpaidStudentsCount || 0}
          color="#007AFF"
        />
        <StatWidget
          icon="trending-down"
          label={t('accounting.overview.monthlyExpenses')}
          value={`₪${metrics?.monthlyExpenses?.toLocaleString() || 0}`}
          color="#5856D6"  // Different color for expenses
        />
        <StatWidget
          icon="trending-up"
          label={t('accounting.overview.monthlyDirectIncome')}
          value={`₪${metrics?.monthlyDirectIncome?.toLocaleString() || 0}`}
          color="#FF9500"  // Different color for direct income
        />
      </View>

      <TimeframeSection
        title={t('accounting.overview.thisMonth')}
        income={metrics?.monthlyIncome || 0}
        invoices={metrics?.monthlyInvoiceCount || 0}
        color="#FF9500"
      />

      <TimeframeSection
        title={t('accounting.overview.thisWeek')}
        income={metrics?.weeklyIncome || 0}
        invoices={metrics?.weeklyInvoiceCount || 0}
        color="#5856D6"
      />
    </ScrollView>
  );
};

export default AccountingOverviewScreen;