import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { View } from 'react-native';
import {
    ExpandableCalendar,
    TimelineList,
    CalendarProvider,
    CalendarUtils
} from 'react-native-calendars';
import groupBy from 'lodash/groupBy';
import { useTranslation } from 'react-i18next';
import { useEvents } from '../../../context/EventContext';
import { useStudents } from '../../../context/StudentContext';
import EditEventModal from './EditEventModal';
import { styles } from './styles';
import { configureLocale } from './locale';

const INITIAL_TIME = { hour: 9, minutes: 0 };

// Updated to handle MySQL datetime format
const formatDateTimeForSQL = (date, time) => {
    return `${date} ${time}`;
};

const today = new Date();

const getDate = (offset = 0) => {
    const date = new Date(today);
    date.setDate(date.getDate() + offset);
    return CalendarUtils.getCalendarDateString(date);
};

const parseDateTime = (dateTimeStr) => {
    const date = new Date(dateTimeStr);
    return {
        date: date.toISOString().split('T')[0],
        time: date.toTimeString().split(' ')[0],
        hour: date.getHours(),
        minutes: date.getMinutes()
    };
};

const useEventGrouping = (events) => {
    return useMemo(() => {
        if (!events?.length) return {};

        // First normalize events with proper Date objects
        const normalizedEvents = events.map(event => ({
            ...event,
            start: new Date(event.start),
            end: new Date(event.end)
        }));

        // Group by date without modifying the event structure
        const grouped = groupBy(normalizedEvents, event =>
            event.start.toISOString().split('T')[0]
        );

        // TimelineList seems to expect the full event object including the Date objects
        return grouped;
    }, [events]);
};

const TimelineCalendar = () => {
    const { t, i18n } = useTranslation();
    const {
        events,
        eventsLoading,
        eventsError,
        fetchEvents,
        addEvent,
        updateEvent,
        deleteEvent
    } = useEvents();
    const { students } = useStudents();

    const [isLocaleInitialized, setIsLocaleInitialized] = useState(false);
    const [currentDate, setCurrentDate] = useState(getDate());
    const [isEditModalVisible, setEditModalVisible] = useState(false);
    const [editingEvent, setEditingEvent] = useState(null);
    const [selectedStudents, setSelectedStudents] = useState([]);
    const [selectedVehicle, setSelectedVehicle] = useState(null);
    const [title, setTitle] = useState('');
    const [duration, setDuration] = useState(40);
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        configureLocale(t, i18n.language);
        setIsLocaleInitialized(true);
    }, []);

    useEffect(() => {
        if (isLocaleInitialized) {
            configureLocale(t, i18n.language);
        }
    }, [i18n.language, t, isLocaleInitialized]);

    const eventsByDate = useEventGrouping(events);

    const markedDates = useMemo(() =>
        Object.keys(eventsByDate).reduce((acc, date) => ({
            ...acc,
            [date]: { marked: true }
        }), {}),
        [eventsByDate]
    );

    const handleDateChanged = useCallback((date) => {
        setCurrentDate(date);
    }, []);

    const createNewEvent = useCallback((timeString, timeObject) => {
        const { date, hour, minutes } = timeObject;
        const startTime = `${hour.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:00`;
        const endHour = hour + 1;
        const endTime = `${endHour.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:00`;

        const newEvent = {
            id: 'new',
            start: formatDateTimeForSQL(date, startTime),
            end: formatDateTimeForSQL(date, endTime),
            title: '',
            summary: '',
            color: '#e6add8',
            studentIds: [],
            duration: 40,
            lessonType: 'single',
            vehicleId: null
        };

        setEditingEvent(newEvent);
        setTitle('');
        setSelectedStudents([]);
        setDuration(40);
        setSelectedVehicle(null);
        setEditModalVisible(true);
    }, []);

    const handleEventPress = useCallback((event) => {
        if (!event) return;

        const fullEvent = events.find(e => e.id === event.id);
        if (!fullEvent) return;


        const eventToEdit = {
            ...fullEvent,
            studentIds: fullEvent.studentIds || [],
            duration: fullEvent.duration || null,
            vehicleId: fullEvent.vehicleId
        };


        setEditingEvent(eventToEdit);
        setTitle(eventToEdit.title);
        setSelectedStudents(eventToEdit.studentIds);
        setDuration(eventToEdit.duration);
        setSelectedVehicle(eventToEdit.vehicleId);
        setEditModalVisible(true);
    }, [events]);


    const timelineProps = useMemo(() => ({
        format24h: true,
        onBackgroundLongPress: createNewEvent,
        onEventPress: handleEventPress,
        unavailableHours: [{ start: 0, end: 6 }, { start: 22, end: 24 }],
        overlapEventsSpacing: 8,
        rightEdgeSpacing: 24,
    }), [createNewEvent, handleEventPress]);

    const calculateEndTime = useCallback((startTime, duration) => {
        const [hours, minutes] = startTime.split(':');
        const totalMinutes = parseInt(hours) * 60 + parseInt(minutes) + parseInt(duration);
        const endHours = Math.floor(totalMinutes / 60);
        const endMinutes = totalMinutes % 60;
        return `${endHours.toString().padStart(2, '0')}:${endMinutes.toString().padStart(2, '0')}:00`;
    }, []);

    const handleSaveEvent = useCallback(() => {
        if (!editingEvent || !title.trim()) {
            alert(t('calendar.errors.titleRequired'));
            return;
        }

        const { date, time } = parseDateTime(editingEvent.start);
        const endTime = calculateEndTime(time, duration);

        const studentNames = students
            .filter(s => selectedStudents.includes(s.id))
            .map(s => `${s.firstName} ${s.lastName}`)
            .join(', ');

        const summaryText = studentNames
            ? t('calendar.summary.withStudents', { duration, students: studentNames })
            : t('calendar.summary.noStudents', { duration });

        const eventData = {
            id: editingEvent.id === 'new' ? Date.now().toString() : editingEvent.id,
            start: formatDateTimeForSQL(date, time),
            end: formatDateTimeForSQL(date, endTime),
            title,
            summary: summaryText,
            color: '#e6add8',
            studentIds: selectedStudents,
            duration: parseInt(duration),
            lessonType: duration === 40 ? 'single' : 'double',
            vehicleId: selectedVehicle
        };

        console.log('TimelineCalendar - Saving event:', eventData);

        if (editingEvent.id === 'new') {
            console.log('Adding new event');
            addEvent(eventData);
            alert(t('calendar.success.scheduled'));
        } else {
            console.log('Updating existing event');
            updateEvent(eventData);
            alert(t('calendar.success.updated'));
        }

        resetModalState();
    }, [editingEvent, title, duration, selectedStudents, selectedVehicle, students, t, addEvent, updateEvent, calculateEndTime]);


    const resetModalState = useCallback(() => {
        setEditModalVisible(false);
        setEditingEvent(null);
        setTitle('');
        setSelectedStudents([]);
        setDuration(40);
        setSelectedVehicle(null);
        setSearchQuery('');
    }, []);


    if (!isLocaleInitialized) {
        return null;
    }

    return (
        <View style={styles.container}>
            <CalendarProvider
                date={currentDate}
                onDateChanged={handleDateChanged}
                showTodayButton
                disabledOpacity={0.6}
            >
                <ExpandableCalendar
                    firstDay={1}
                    leftArrowImageSource={require('../../../assets/previous.png')}
                    rightArrowImageSource={require('../../../assets/next.png')}
                    markedDates={markedDates}
                    locale={i18n.language}
                />
                <TimelineList
                    events={eventsByDate}
                    timelineProps={timelineProps}
                    showNowIndicator
                    scrollToNow
                    scrollToFirst
                    initialTime={INITIAL_TIME}
                />
            </CalendarProvider>

            <EditEventModal
                visible={isEditModalVisible}
                event={editingEvent}
                title={title}
                duration={duration}
                selectedStudents={selectedStudents}
                selectedVehicle={selectedVehicle}
                searchQuery={searchQuery}
                onChangeTitle={setTitle}
                onChangeDuration={setDuration}
                onChangeSelectedStudents={setSelectedStudents}
                onChangeSelectedVehicle={setSelectedVehicle}
                onChangeSearchQuery={setSearchQuery}
                onSave={handleSaveEvent}
                onDelete={deleteEvent}
                onClose={resetModalState}
                students={students}
            />
        </View>
    );
};

export default TimelineCalendar;