import React, { createContext, useContext, useReducer, useCallback } from 'react';
import { apiService } from '../services/api';

const AccountingContext = createContext();

const ACTIONS = {
  SET_ACCOUNTS: 'SET_ACCOUNTS',
  SET_VAT_RATE: 'SET_VAT_RATE',
  SET_LOADING: 'SET_LOADING',
  SET_ERROR: 'SET_ERROR'
};

const accountingReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.SET_ACCOUNTS:
      return {
        ...state,
        accounts: action.payload,
        isLoading: false
      };
    case ACTIONS.SET_VAT_RATE:
      return {
        ...state,
        vatRate: action.payload
      };
    default:
      return state;
  }
};

export const AccountingProvider = ({ children }) => {
  const [state, dispatch] = useReducer(accountingReducer, {
    accounts: [],
    vatRate: 0,
    isLoading: false,
    error: null
  });

  const fetchAccounts = useCallback(async () => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const response = await apiService.getAccounts();
      dispatch({ type: ACTIONS.SET_ACCOUNTS, payload: response.data });
    } catch (error) {
      dispatch({ type: ACTIONS.SET_ERROR, payload: error.message });
    }
  }, []);

  const fetchVatRate = useCallback(async () => {
    try {
      const response = await apiService.getCurrentVatRate();
      dispatch({ type: ACTIONS.SET_VAT_RATE, payload: response });
    } catch (error) {
      dispatch({ type: ACTIONS.SET_ERROR, payload: error.message });
    }
  }, []);

  const getAccountsByType = useCallback((accountCodeDesc) => {
    return state.accounts.filter(account => 
        account.accountCodeDescription === accountCodeDesc
    );
  }, [state.accounts]);

  const value = {
    accounts: state.accounts,
    vatRate: state.vatRate,
    isLoading: state.isLoading,
    error: state.error,
    fetchAccounts,
    fetchVatRate,
    getAccountsByType,
    getVatAccountId: () => '60000001', // Fixed VAT account
    getIncomeAccounts: () => getAccountsByType('הכנסות'),
    getExpenseAccounts: () => getAccountsByType('הוצאות'),
    getCashierAccounts: () => getAccountsByType('קופות')
  };

  return (
    <AccountingContext.Provider value={value}>
      {children}
    </AccountingContext.Provider>
  );
};

export const useAccounting = () => {
  const context = useContext(AccountingContext);
  if (context === undefined) {
    throw new Error('useAccounting must be used within an AccountingProvider');
  }
  return context;
};