export const DOCUMENT_TYPES = {
    INVOICE: {
        id: 'INVOICE',
        code: 300,
        label: 'Invoice',
        icon: 'file',
        quickAction: false,
        requiresStudent: true,
        requiresPayment: false,
        requiresReference: false,
        canHaveLineItems: true,
        documentTypeCode: 300,
        requiredAccounts: {
            vat: false,
            income: false,
            cashier: false
        }
    },
    TAX_INVOICE: {
        id: 'TAX_INVOICE',
        code: 305,
        label: 'Tax Invoice',
        icon: 'file-plus',
        quickAction: true,
        requiresStudent: true,
        requiresPayment: false,
        requiresReference: false,
        canHaveLineItems: true,
        documentTypeCode: 305,
        requiredAccounts: {
            vat: true,
            income: true,
            cashier: false
        }
    },
    TAX_INVOICE_RECEIPT: {
        id: 'TAX_INVOICE_RECEIPT',
        code: 320,
        label: 'Tax Invoice Receipt',
        icon: 'layers',
        quickAction: true,
        requiresStudent: true,
        requiresPayment: true,
        requiresReference: false,
        canHaveLineItems: true,
        documentTypeCode: 320,
        requiredAccounts: {
            vat: true,
            income: true,
            cashier: true
        }
    },
    RECEIPT: {
        id: 'RECEIPT',
        code: 400,
        label: 'Receipt',
        icon: 'tag',
        quickAction: true,
        requiresStudent: true,
        requiresPayment: true,
        requiresReference: false,
        canHaveLineItems: true,
        documentTypeCode: 400,
        requiredAccounts: {
            vat: true,
            income: true,
            cashier: true
        }
    },
    CREDIT_INVOICE: {
        id: 'CREDIT_INVOICE',
        code: 330,
        label: 'Credit Invoice',
        icon: 'refresh-ccw',
        quickAction: false,
        requiresStudent: true,
        requiresPayment: false,
        requiresReference: true,
        canHaveLineItems: true,
        documentTypeCode: 330,
        requiredAccounts: {
            vat: true,
            income: true,
            cashier: false
        }
    },
    EXPENSE: {
        id: 'EXPENSE',
        code: null,
        label: 'Expense',
        icon: 'trending-down',
        quickAction: false,
        requiresSupplier: true,
        requiresPayment: false,
        requiresOutgoingPayment: true,
        requiresReference: false,
        requiresManualReference: true,
        canHaveLineItems: true,
        documentTypeCode: null,
        requiredAccounts: {
            vat: true,
            expense: true,
            cashier: true
        }
    },
    INCOME: {
        id: 'INCOME',
        code: null,
        label: 'Direct Income',
        icon: 'trending-up',
        quickAction: false,
        requiresStudent: true,
        requiresPayment: true,
        requiresReference: false,
        requiresManualReference: true,
        canHaveLineItems: true,
        documentTypeCode: null,
        isDirect: true,
        requiredAccounts: {
            income: true,
            vat: true,
            cashier: true
        }
    }
};

export const DOCUMENT_STATUS = {
    ACTIVE: 'ACTIVE',
    PAID: 'PAID',
    CANCELLED: 'CANCELLED'
};

export const DOCUMENT_FLOWS = {
    INVOICE: {
        ACTIVE: {
            allowedTransitions: ['TAX_INVOICE'],
            actions: ['convert_to_tax_invoice', 'cancel']
        },
        CANCELLED: {
            allowedTransitions: [],
            actions: ['view_pdf']
        }
    },
    TAX_INVOICE: {
        ACTIVE: {
            allowedTransitions: ['RECEIPT', 'TAX_INVOICE_RECEIPT', 'CREDIT_INVOICE'],
            actions: ['issue_receipt', 'issue_credit', 'view_pdf', 'cancel']
        },
        PAID: {
            allowedTransitions: ['CREDIT_INVOICE'],
            actions: ['issue_credit', 'view_pdf', 'cancel']
        },
        CANCELLED: {
            allowedTransitions: [],
            actions: ['view_pdf']
        }
    },
    TAX_INVOICE_RECEIPT: {
        PAID: {
            allowedTransitions: ['CREDIT_INVOICE'],
            actions: ['issue_credit', 'view_pdf', 'cancel']
        },
        CANCELLED: {
            allowedTransitions: [],
            actions: ['view_pdf']
        }
    },
    RECEIPT: {
        PAID: {
            allowedTransitions: ['CREDIT_INVOICE'],
            actions: ['issue_credit', 'view_pdf', 'cancel']
        },
        CANCELLED: {
            allowedTransitions: [],
            actions: ['view_pdf']
        }
    },
    CREDIT_INVOICE: {
        ACTIVE: {
            allowedTransitions: [],
            actions: ['view_pdf', 'cancel']
        },
        CANCELLED: {
            allowedTransitions: [],
            actions: ['view_pdf']
        }
    },
    EXPENSE: {
        PAID: {
            allowedTransitions: [],
            actions: ['view_pdf', 'cancel']
        },
        CANCELLED: {
            allowedTransitions: [],
            actions: ['view_pdf']
        }
    },
    INCOME: {
        PAID: {
            allowedTransitions: [],
            actions: ['view_pdf', 'cancel']
        },
        CANCELLED: {
            allowedTransitions: [],
            actions: ['view_pdf']
        }
    }
};

// Helper functions
export const getValidTransitions = (documentType, status) => {
    const flow = DOCUMENT_FLOWS[documentType]?.[status];
    return flow?.allowedTransitions || [];
};

export const getValidActions = (documentType, status) => {
    const flow = DOCUMENT_FLOWS[documentType]?.[status];
    return flow?.actions || [];
};

export const canTransitionTo = (fromType, fromStatus, toType) => {
    const validTransitions = getValidTransitions(fromType, fromStatus);
    return validTransitions.includes(toType);
};

export const canPerformAction = (documentType, status, action) => {
    const validActions = getValidActions(documentType, status);
    return validActions.includes(action);
};

export const getRequiredAccounts = (documentType) => {
    return DOCUMENT_TYPES[documentType]?.requiredAccounts || {};
};

export const getDocumentTypeCode = (documentType) => {
    return DOCUMENT_TYPES[documentType]?.documentTypeCode;
};
