export const calculateTotals = (items = [], vatRate = 18) => {
  if (!Array.isArray(items)) return {
    totalBeforeDiscount: 0,
    totalDiscount: 0,
    totalAfterDiscount: 0,
    vatAmount: 0,
    totalAmount: 0
  };

  const totalBeforeDiscount = items.reduce((sum, item) => 
    sum + ((item.quantity || 0) * (item.unitPrice || 0)), 0);
  
  const totalDiscount = items.reduce((sum, item) => 
    sum + (item.lineDiscount || 0), 0);
  
  const totalAfterDiscount = totalBeforeDiscount - totalDiscount;
  
  // Ensure precision in VAT calculation
  const vatAmount = Number((totalAfterDiscount * vatRate / 100).toFixed(2));
  const totalAmount = Number((totalAfterDiscount + vatAmount).toFixed(2));

  return {
    totalBeforeDiscount: Number(totalBeforeDiscount.toFixed(2)),
    totalDiscount: Number(totalDiscount.toFixed(2)),
    totalAfterDiscount: Number(totalAfterDiscount.toFixed(2)),
    vatAmount,
    totalAmount
  };
};