import React, { useEffect, useMemo } from 'react';
import { View, Text } from 'react-native';
import { ReportContainer, useReportStyles } from '../../components/reports/ReportContainer';
import { usePayments } from '../../context/PaymentContext';
import * as Print from 'expo-print';
import * as Sharing from 'expo-sharing';
import { generateCSV } from '../../utils/helpers';

export default function BalanceReportScreen() {
  const styles = useReportStyles();
  const { records, expenses, fetchRecords, fetchExpenses, isLoading } = usePayments();

  useEffect(() => {
    fetchRecords();
    fetchExpenses();
  }, [fetchRecords, fetchExpenses]);

  const reportData = useMemo(() => {
    const data = {
      overview: {
        totalIncome: 0,
        totalExpenses: 0,
        netBalance: 0,
        realizedIncome: 0,
        paidExpenses: 0,
        realizedBalance: 0,
        pendingIncome: 0,
        pendingExpenses: 0,
        pendingBalance: 0
      },
      byMonth: {}
    };

    // Process income
    records.forEach(record => {
      // Skip receipts that are linked to invoices to avoid double counting
      if (record.type === 'RECEIPT' && record.linked_invoice_id) return;
      if (record.status === 'CANCELLED') return;

      const amount = parseFloat(record.amount);
      const month = new Date(record.issue_date).toLocaleString('default', { month: 'long' });

      // Update overview
      data.overview.totalIncome += amount;
      if (record.status === 'PAID') {
        data.overview.realizedIncome += amount;
      } else {
        data.overview.pendingIncome += amount;
      }

      // Update monthly data
      data.byMonth[month] = data.byMonth[month] || {
        income: { total: 0, realized: 0, pending: 0 },
        expenses: { total: 0, paid: 0, pending: 0 },
        balance: { total: 0, realized: 0, pending: 0 }
      };

      data.byMonth[month].income.total += amount;
      if (record.status === 'PAID') {
        data.byMonth[month].income.realized += amount;
      } else {
        data.byMonth[month].income.pending += amount;
      }
    });

    // Process expenses
    expenses.forEach(expense => {
      if (expense.status === 'CANCELLED') return;

      const amount = parseFloat(expense.amount);
      const month = new Date(expense.expense_date).toLocaleString('default', { month: 'long' });

      // Update overview
      data.overview.totalExpenses += amount;
      if (expense.status === 'PAID') {
        data.overview.paidExpenses += amount;
      } else {
        data.overview.pendingExpenses += amount;
      }

      // Update monthly data
      data.byMonth[month] = data.byMonth[month] || {
        income: { total: 0, realized: 0, pending: 0 },
        expenses: { total: 0, paid: 0, pending: 0 },
        balance: { total: 0, realized: 0, pending: 0 }
      };

      data.byMonth[month].expenses.total += amount;
      if (expense.status === 'PAID') {
        data.byMonth[month].expenses.paid += amount;
      } else {
        data.byMonth[month].expenses.pending += amount;
      }
    });

    // Calculate balances
    data.overview.netBalance = data.overview.totalIncome - data.overview.totalExpenses;
    data.overview.realizedBalance = data.overview.realizedIncome - data.overview.paidExpenses;
    data.overview.pendingBalance = data.overview.pendingIncome - data.overview.pendingExpenses;

    // Calculate monthly balances
    Object.values(data.byMonth).forEach(month => {
      month.balance.total = month.income.total - month.expenses.total;
      month.balance.realized = month.income.realized - month.expenses.paid;
      month.balance.pending = month.income.pending - month.expenses.pending;
    });

    return data;
  }, [records, expenses]);

  const handleExportCSV = () => {
    const headers = ['Month', 'Income', 'Expenses', 'Balance', 'Status'];
    const data = [
      {
        Month: 'Total',
        Income: reportData.overview.totalIncome,
        Expenses: reportData.overview.totalExpenses,
        Balance: reportData.overview.netBalance,
        Status: 'Overall'
      },
      {
        Month: 'Total',
        Income: reportData.overview.realizedIncome,
        Expenses: reportData.overview.paidExpenses,
        Balance: reportData.overview.realizedBalance,
        Status: 'Realized'
      },
      {
        Month: 'Total',
        Income: reportData.overview.pendingIncome,
        Expenses: reportData.overview.pendingExpenses,
        Balance: reportData.overview.pendingBalance,
        Status: 'Pending'
      },
      ...Object.entries(reportData.byMonth).map(([month, data]) => ({
        Month: month,
        Income: data.income.total,
        Expenses: data.expenses.total,
        Balance: data.balance.total,
        Status: 'Monthly Total'
      }))
    ];

    return generateCSV(headers, data);
  };

  const handleExportPDF = async () => {
    const formatCurrency = (amount) => `₪${parseFloat(amount).toFixed(2)}`;
    
    const htmlContent = `
      <html>
        <head>
          <style>
            body { font-family: Arial, sans-serif; }
            .header { text-align: center; margin-bottom: 20px; }
            .section { margin-bottom: 20px; }
            .section-title { 
              font-size: 18px; 
              font-weight: bold;
              margin-bottom: 10px;
              background-color: #f5f5f5;
              padding: 5px;
            }
            table { 
              width: 100%; 
              border-collapse: collapse; 
              margin-bottom: 20px;
            }
            th, td { 
              border: 1px solid #ddd; 
              padding: 8px; 
              text-align: left;
            }
            th { background-color: #f5f5f5; }
            .total { font-weight: bold; }
            .positive { color: green; }
            .negative { color: red; }
          </style>
        </head>
        <body>
          <div class="header">
            <h1>Balance Report</h1>
            <p>Generated on ${new Date().toLocaleDateString()}</p>
          </div>

          <div class="section">
            <div class="section-title">Overview</div>
            <table>
              <tr>
                <th></th>
                <th>Income</th>
                <th>Expenses</th>
                <th>Balance</th>
              </tr>
              <tr>
                <td>Total</td>
                <td>${formatCurrency(reportData.overview.totalIncome)}</td>
                <td>${formatCurrency(reportData.overview.totalExpenses)}</td>
                <td class="${reportData.overview.netBalance >= 0 ? 'positive' : 'negative'}">${formatCurrency(reportData.overview.netBalance)}</td>
              </tr>
              <tr>
                <td>Realized</td>
                <td>${formatCurrency(reportData.overview.realizedIncome)}</td>
                <td>${formatCurrency(reportData.overview.paidExpenses)}</td>
                <td class="${reportData.overview.realizedBalance >= 0 ? 'positive' : 'negative'}">${formatCurrency(reportData.overview.realizedBalance)}</td>
              </tr>
              <tr>
                <td>Pending</td>
                <td>${formatCurrency(reportData.overview.pendingIncome)}</td>
                <td>${formatCurrency(reportData.overview.pendingExpenses)}</td>
                <td class="${reportData.overview.pendingBalance >= 0 ? 'positive' : 'negative'}">${formatCurrency(reportData.overview.pendingBalance)}</td>
              </tr>
            </table>
          </div>

          <div class="section">
            <div class="section-title">Monthly Breakdown</div>
            <table>
              <tr>
                <th>Month</th>
                <th>Income</th>
                <th>Expenses</th>
                <th>Balance</th>
              </tr>
              ${Object.entries(reportData.byMonth)
                .map(([month, data]) => `
                  <tr>
                    <td>${month}</td>
                    <td>${formatCurrency(data.income.total)}</td>
                    <td>${formatCurrency(data.expenses.total)}</td>
                    <td class="${data.balance.total >= 0 ? 'positive' : 'negative'}">${formatCurrency(data.balance.total)}</td>
                  </tr>
                `).join('')}
            </table>
          </div>
        </body>
      </html>
    `;

    try {
      const file = await Print.printToFileAsync({
        html: htmlContent,
        base64: false
      });
      
      await Sharing.shareAsync(file.uri, {
        UTI: '.pdf',
        mimeType: 'application/pdf'
      });

      return file.uri;
    } catch (error) {
      console.error('PDF generation error:', error);
      throw error;
    }
  };

  return (
    <ReportContainer
      onExportCSV={handleExportCSV}
      onExportPDF={handleExportPDF}
      isLoading={isLoading}
    >
      {/* Overview Card */}
      <View style={styles.card}>
        <Text style={styles.sectionTitle}>Overview</Text>
        <View style={styles.row}>
          <Text style={styles.mainText}>Total Income</Text>
          <Text style={styles.totalAmount}>₪{reportData.overview.totalIncome.toFixed(2)}</Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.mainText}>Total Expenses</Text>
          <Text style={styles.totalAmount}>₪{reportData.overview.totalExpenses.toFixed(2)}</Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.mainText}>Net Balance</Text>
          <Text style={[styles.totalAmount, { color: reportData.overview.netBalance >= 0 ? 'green' : 'red' }]}>
            ₪{reportData.overview.netBalance.toFixed(2)}
          </Text>
        </View>
      </View>

      {/* Realized vs Pending Card */}
      <View style={styles.card}>
        <Text style={styles.sectionTitle}>Realized vs Pending</Text>
        <View style={styles.row}>
          <View style={styles.infoContainer}>
            <Text style={styles.mainText}>Realized</Text>
            <Text style={styles.breakdownText}>Income: ₪{reportData.overview.realizedIncome.toFixed(2)}</Text>
            <Text style={styles.breakdownText}>Expenses: ₪{reportData.overview.paidExpenses.toFixed(2)}</Text>
          </View>
          <View style={styles.amountsContainer}>
            <Text style={[styles.totalAmount, { color: reportData.overview.realizedBalance >= 0 ? 'green' : 'red' }]}>
              ₪{reportData.overview.realizedBalance.toFixed(2)}
            </Text>
          </View>
        </View>
        <View style={styles.row}>
          <View style={styles.infoContainer}>
            <Text style={styles.mainText}>Pending</Text>
            <Text style={styles.breakdownText}>Income: ₪{reportData.overview.pendingIncome.toFixed(2)}</Text>
            <Text style={styles.breakdownText}>Expenses: ₪{reportData.overview.pendingExpenses.toFixed(2)}</Text>
          </View>
          <View style={styles.amountsContainer}>
            <Text style={[styles.totalAmount, { color: reportData.overview.pendingBalance >= 0 ? 'green' : 'red' }]}>
              ₪{reportData.overview.pendingBalance.toFixed(2)}
            </Text>
          </View>
        </View>
      </View>

      {/* Monthly Breakdown */}
      <View style={styles.card}>
        <Text style={styles.sectionTitle}>Monthly Breakdown</Text>
        {Object.entries(reportData.byMonth).map(([month, data]) => (
          <View key={month} style={styles.row}>
            <View style={styles.infoContainer}>
              <Text style={styles.mainText}>{month}</Text>
              <Text style={styles.breakdownText}>Income: ₪{data.income.total.toFixed(2)}</Text>
              <Text style={styles.breakdownText}>Expenses: ₪{data.expenses.total.toFixed(2)}</Text>
            </View>
            <View style={styles.amountsContainer}>
              <Text style={[styles.totalAmount, { color: data.balance.total >= 0 ? 'green' : 'red' }]}>
                ₪{data.balance.total.toFixed(2)}
              </Text>
              <Text style={styles.breakdownText}>
                Realized: ₪{data.balance.realized.toFixed(2)}
              </Text>
              <Text style={styles.breakdownText}>
                Pending: ₪{data.balance.pending.toFixed(2)}
              </Text>
            </View>
          </View>
        ))}
      </View>
    </ReportContainer>
  );
}