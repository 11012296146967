import React, { createContext, useContext, useReducer } from 'react';
import { calculateTotals } from '../utils/documentUtils';
import { DEFAULT_BRANCH_ID } from '../constants';
import { vatRate } from './AccountingContext';

const DocumentFormContext = createContext();

const ACTIONS = {
    SET_INITIAL_DATA: 'SET_INITIAL_DATA',
    UPDATE_ENTITY: 'UPDATE_ENTITY',
    UPDATE_ITEMS: 'UPDATE_ITEMS',
    UPDATE_PAYMENT: 'UPDATE_PAYMENT',
    UPDATE_ACCOUNTS: 'UPDATE_ACCOUNTS',
    UPDATE_REFERENCE: 'UPDATE_REFERENCE',
    UPDATE_DESCRIPTION: 'UPDATE_DESCRIPTION',
    UPDATE_VALUE_DATE: 'UPDATE_VALUE_DATE',
    RESET_FORM: 'RESET_FORM',
    UPDATE_MANUAL_REFERENCE: 'UPDATE_MANUAL_REFERENCE',
};

const initialState = {
    entity: null,
    items: [],
    description: '',
    manualReferenceNumber: '',
    payment: null,
    selectedAccounts: {
        income: null,
        expense: null,
        cashier: null
    },
    referenceDocument: null,
    valueDate: new Date().toISOString().split('T')[0],
    businessBranchId: DEFAULT_BRANCH_ID, // Default branch
    totals: calculateTotals([], vatRate) // Initialize with empty items
};

const documentFormReducer = (state, action) => {
    switch (action.type) {
        case ACTIONS.SET_INITIAL_DATA:
            return {
                ...state,
                ...action.payload,
                totals: calculateTotals(action.payload.items || [], action.payload.vatRate)
            };

        case ACTIONS.UPDATE_ENTITY:
            return {
                ...state,
                entity: action.payload
            };

        case ACTIONS.UPDATE_ITEMS:
            const totals = calculateTotals(action.payload, state.vatRate)
            return {
                ...state,
                items: action.payload,
                totals
            };

        case ACTIONS.UPDATE_PAYMENT:
            return {
                ...state,
                payment: action.payload
            };

        case ACTIONS.UPDATE_ACCOUNTS:
            return {
                ...state,
                selectedAccounts: {
                    ...state.selectedAccounts,
                    ...action.payload
                }
            };

        case ACTIONS.UPDATE_REFERENCE:
            return {
                ...state,
                referenceDocument: action.payload
            };

        case ACTIONS.UPDATE_DESCRIPTION:
            return {
                ...state,
                description: action.payload
            };

        case ACTIONS.UPDATE_VALUE_DATE:
            return {
                ...state,
                valueDate: action.payload
            };

        case ACTIONS.RESET_FORM:
            return initialState;

        case ACTIONS.UPDATE_MANUAL_REFERENCE:
            return {
                ...state,
                manualReferenceNumber: action.payload
            };

        default:
            return state;
    }
};

export const DocumentFormProvider = ({ children }) => {
    const [state, dispatch] = useReducer(documentFormReducer, initialState);

    const value = {
        ...state,
        setInitialData: (data) => dispatch({ type: ACTIONS.SET_INITIAL_DATA, payload: data }),
        updateEntity: (entity) => dispatch({ type: ACTIONS.UPDATE_ENTITY, payload: entity }),
        updateItems: (items) => dispatch({ type: ACTIONS.UPDATE_ITEMS, payload: items }),
        updatePayment: (payment) => dispatch({ type: ACTIONS.UPDATE_PAYMENT, payload: payment }),
        updateAccounts: (accounts) => dispatch({ type: ACTIONS.UPDATE_ACCOUNTS, payload: accounts }),
        updateReference: (doc) => dispatch({ type: ACTIONS.UPDATE_REFERENCE, payload: doc }),
        updateDescription: (desc) => dispatch({ type: ACTIONS.UPDATE_DESCRIPTION, payload: desc }),
        updateValueDate: (date) => dispatch({ type: ACTIONS.UPDATE_VALUE_DATE, payload: date }),
        resetForm: () => dispatch({ type: ACTIONS.RESET_FORM }),
        updateManualReference: (number) => dispatch({ type: ACTIONS.UPDATE_MANUAL_REFERENCE, payload: number }),
    };

    return (
        <DocumentFormContext.Provider value={value}>
            {children}
        </DocumentFormContext.Provider>
    );
};

export const useDocumentForm = () => {
    const context = useContext(DocumentFormContext);
    if (context === undefined) {
        throw new Error('useDocumentForm must be used within a DocumentFormProvider');
    }
    return context;
};