import { LocaleConfig } from 'react-native-calendars';

export const configureLocale = (t, language) => {
  LocaleConfig.locales[language] = {
    monthNames: Array.from({ length: 12 }, (_, i) => 
      t(`calendar.monthNames.${i + 1}`)
    ),
    monthNamesShort: Array.from({ length: 12 }, (_, i) => 
      t(`calendar.monthNamesShort.${i + 1}`)
    ),
    dayNames: Array.from({ length: 7 }, (_, i) => 
      t(`calendar.dayNames.${i + 1}`)
    ),
    dayNamesShort: Array.from({ length: 7 }, (_, i) => 
      t(`calendar.dayNamesShort.${i + 1}`)
    ),
    today: t('calendar.today')
  };
  
  LocaleConfig.defaultLocale = language;
};