// src/screens/main/ReportExpenseScreen.js
import React, { useState, useMemo, useEffect } from 'react';
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  FlatList,
  Modal,
  TextInput,
  Alert,
  ActivityIndicator,
} from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { Feather } from '@expo/vector-icons';
import { usePayments } from '../../context/PaymentContext';
import { useSuppliers } from '../../context/SupplierContext';
import { useThemedStyles } from '../../theme/hooks';
import { useTranslation } from 'react-i18next';

export default function ReportExpenseScreen() {
  const { t } = useTranslation();
  const { expenses, isLoading, createExpense, fetchExpenses } = usePayments();
  const { suppliers, fetchSuppliers } = useSuppliers();
  const [isExpenseModalVisible, setExpenseModalVisible] = useState(false);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [amount, setAmount] = useState('');
  const [description, setDescription] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('');
  const [showSupplierPicker, setShowSupplierPicker] = useState(false);
  const [supplierSearch, setSupplierSearch] = useState('');
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    fetchExpenses();
    fetchSuppliers();
  }, [fetchExpenses, fetchSuppliers]);

  // Filter records based on search query
  const filteredRecords = useMemo(() => {
    const query = searchQuery.toLowerCase().trim();    
    if (!query) return expenses;
    
    return expenses.filter(expense => {
      const supplier = suppliers.find(s => s.id === expense.supplier_id);

      return supplier.name.toLowerCase().includes(query) ||
      expense.description.toLowerCase().includes(query) ||
      expense.status.toLowerCase().includes(query)
    }
    );
  }, [expenses, searchQuery]);

  // Filter tax invoices only (modify sortedInvoices)
  const sortedRecords = useMemo(() => {
    return [...filteredRecords]
      .sort((a, b) => new Date(b.expense_date) - new Date(a.expense_date));
  }, [filteredRecords]);

  const styles = useThemedStyles((colors) => ({
    container: {
      flex: 1,
      backgroundColor: colors.background,
    },
    createButton: {
      flexDirection: 'row',
      alignItems: 'center',
      backgroundColor: colors.primary,
      padding: 15,
      margin: 16,
      borderRadius: 8,
      justifyContent: 'center',
    },
    createButtonText: {
      color: colors.card,
      fontSize: 16,
      fontWeight: 'bold',
      marginLeft: 8,
    },
    expenseItem: {
      backgroundColor: colors.card,
      borderRadius: 12,
      padding: 16,
      marginTop: 12,
      marginHorizontal: 16,
      elevation: 2,
      shadowColor: colors.shadowColor,
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.1,
      shadowRadius: 4,
    },
    expenseHeader: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 8,
    },
    expenseNumber: {
      fontSize: 16,
      fontWeight: 'bold',
      color: colors.text,
    },
    expenseStatus: {
      fontSize: 14,
      fontWeight: '600',
      paddingHorizontal: 8,
      paddingVertical: 4,
      borderRadius: 4,
    },
    expenseDetails: {
      marginBottom: 12,
    },
    supplierName: {
      fontSize: 15,
      color: colors.textSecondary,
      marginBottom: 4,
    },
    amount: {
      fontSize: 18,
      fontWeight: 'bold',
      color: colors.text,
      marginBottom: 4,
    },
    description: {
      fontSize: 14,
      color: colors.textSecondary,
      marginBottom: 4,
    },
    paymentMethod: {
      fontSize: 14,
      color: colors.textSecondary,
    },
    date: {
      fontSize: 14,
      color: colors.textSecondary,
      marginBottom: 4,
    },
    modalOverlay: {
      flex: 1,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      justifyContent: 'center',
      padding: 16,
    },
    modalContent: {
      backgroundColor: colors.card,
      borderRadius: 12,
      padding: 20,
    },
    modalTitle: {
      fontSize: 20,
      fontWeight: 'bold',
      marginBottom: 16,
      color: colors.text,
    },
    supplierSelector: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderWidth: 1,
      borderColor: colors.border,
      borderRadius: 8,
      padding: 12,
      marginBottom: 16,
      backgroundColor: colors.background,
    },
    input: {
      borderWidth: 1,
      borderColor: colors.border,
      borderRadius: 8,
      padding: 12,
      marginBottom: 16,
      color: colors.text,
      backgroundColor: colors.background,
    },
    textArea: {
      height: 100,
      textAlignVertical: 'top',
    },
    buttonContainer: {
      flexDirection: 'row',
      gap: 12,
    },
    button: {
      flex: 1,
      padding: 12,
      borderRadius: 8,
      alignItems: 'center',
    },
    cancelButton: {
      backgroundColor: colors.background,
    },
    submitButton: {
      backgroundColor: colors.primary,
    },
    buttonText: {
      fontSize: 16,
      fontWeight: '600',
    },
    cancelButtonText: {
      color: colors.text,
    },
    submitButtonText: {
      color: colors.card,
    },
    searchInput: {
      borderWidth: 1,
      borderColor: colors.border,
      borderRadius: 8,
      padding: 12,
      marginBottom: 16,
      color: colors.text,
      backgroundColor: colors.background,
    },
    supplierList: {
      maxHeight: 300,
    },
    supplierItem: {
      padding: 12,
      borderBottomWidth: 1,
      borderBottomColor: colors.border,
    },
    supplierItemText: {
      fontSize: 16,
      color: colors.text,
    },
    supplierItemSubText: {
      fontSize: 14,
      color: colors.textSecondary,
      marginTop: 4,
    },
    statusPending: {
      backgroundColor: `${colors.warning}20`,
      color: colors.warning,
    },
    statusPaid: {
      backgroundColor: `${colors.success}20`,
      color: colors.success,
    },
    fab: {
      position: 'absolute',
      right: 16,
      bottom: 16,
      backgroundColor: colors.primary,
      width: 56,
      height: 56,
      borderRadius: 28,
      justifyContent: 'center',
      alignItems: 'center',
      elevation: 4,
      shadowColor: colors.shadowColor,
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.25,
      shadowRadius: 4,
    },
    searchContainer: {
      position: 'relative',
      paddingHorizontal: 16,
      paddingTop: 16,
      backgroundColor: colors.background,
    },
    searchInput: {
      height: 40,
      backgroundColor: colors.card,
      borderRadius: 20,
      paddingHorizontal: 16,
      paddingLeft: 40,
      fontSize: 16,
      color: colors.text,
      borderWidth: 1,
      borderColor: colors.border,
    },
    searchIcon: {
      position: 'absolute',
      width: 40,
      left: 25, 
      top: '50%',
      transform: [{                
        translateY: 5
      }],
      zIndex: 1,    
    },
    placeholder: {
      color: colors.textSecondary,
    },
    noResults: {
      textAlign: 'center',
      marginTop: 20,
      fontSize: 16,
      color: colors.textSecondary,
    },
  }));

  const filteredSuppliers = useMemo(() => {
    return suppliers.filter(supplier =>
      supplier.name.toLowerCase().includes(supplierSearch.toLowerCase()) ||
      supplier.contactPerson.toLowerCase().includes(supplierSearch.toLowerCase())
    );
  }, [suppliers, supplierSearch]);

  const handleCreateExpense = async () => {
    if (!selectedSupplier) {
      Alert.alert(t('errors.validation'), t('expenses.errors.selectSupplier'));
      return;
    }

    const amountValue = parseFloat(amount);
    if (isNaN(amountValue) || amountValue <= 0) {
      Alert.alert(t('errors.validation'), t('expenses.errors.validAmount'));
      return;
    }

    try {
      await createExpense({
        supplier_id: selectedSupplier.id,
        amount: amountValue,
        description,
        payment_method: paymentMethod,
      });

      Alert.alert(t('success'), t('expenses.success.expenseCreated'));
      setExpenseModalVisible(false);
      resetForm();
    } catch (error) {
      Alert.alert(t('errors.title'), error.message);
    }
  };

  const resetForm = () => {
    setSelectedSupplier(null);
    setAmount('');
    setDescription('');
    setPaymentMethod('');
  };

  const renderExpenseItem = ({ item: expense }) => {
    const supplier = suppliers.find(s => s.id === expense.supplier_id);

    return (
      <View style={styles.expenseItem}>
        <View style={styles.expenseHeader}>
          <Text style={styles.expenseNumber}>{expense.record_number}</Text>
          <Text
            style={[
              styles.expenseStatus,
              expense.status === 'PAID' ? styles.statusPaid : styles.statusPending,
            ]}
          >
            {t(`expenses.status.${expense.status.toLowerCase()}`)}
          </Text>
        </View>

        <View style={styles.expenseDetails}>
          <Text style={styles.supplierName}>
            {supplier ? supplier.name : t('expenses.unknownSupplier')}
          </Text>
          <Text style={styles.amount}>₪{expense.amount.toFixed(2)}</Text>
          <Text style={styles.date}>
            {new Date(expense.expense_date).toLocaleDateString()}
          </Text>
          {expense.description && (
            <Text style={styles.description}>{expense.description}</Text>
          )}
          {expense.payment_method && (
            <Text style={styles.paymentMethod}>{expense.payment_method}</Text>
          )}
        </View>
      </View>
    );
  };

  return (
    <View style={styles.container}>
      <View style={styles.searchContainer}>
        <Feather
          name="search"
          size={20}
          color={styles.placeholder.color}
          style={styles.searchIcon}
        />
        <TextInput
          style={styles.searchInput}
          placeholder={t('expenses.search.placeholder')}
          placeholderTextColor={styles.placeholder.color}
          value={searchQuery}
          onChangeText={setSearchQuery}
          autoCapitalize="none"
        />
      </View>
      {/* <TouchableOpacity
        style={styles.createButton}
        onPress={() => setExpenseModalVisible(true)}
      >
        <Ionicons name="add" size={24} color="#fff" />
        <Text style={styles.createButtonText}>{t('expenses.addExpense')}</Text>
      </TouchableOpacity> */}

      {isLoading ? (
        <ActivityIndicator size="large" color="#007AFF" />
      ) : (
        <FlatList
          data={sortedRecords}
          renderItem={renderExpenseItem}
          keyExtractor={item => item.id.toString()}
          ListEmptyComponent={() => (
            <Text style={styles.noResults}>
              {searchQuery 
                ? t('expenses.search.noSearchResults') 
                : t('expenses.search.noStudents')}
            </Text>
          )}
        />
      )}

      <TouchableOpacity
        style={styles.fab}
        onPress={() => {
          // resetForm();
          setExpenseModalVisible(true);
        }}
      >
        <Feather name="plus" size={24} color="#fff" />
      </TouchableOpacity>

      {/* Create Expense Modal */}
      <Modal
        visible={isExpenseModalVisible}
        animationType="fade"
        transparent={true}
        onRequestClose={() => {
          setExpenseModalVisible(false);
          resetForm();
        }}
      >
        <View style={styles.modalOverlay}>
          <View style={styles.modalContent}>
            <Text style={styles.modalTitle}>{t('expenses.newExpense')}</Text>

            <TouchableOpacity
              style={styles.supplierSelector}
              onPress={() => setShowSupplierPicker(true)}
            >
              <Text style={{ color: selectedSupplier ? '#000' : '#666' }}>
                {selectedSupplier
                  ? selectedSupplier.name
                  : t('expenses.selectSupplier')}
              </Text>
              <Ionicons name="chevron-down" size={20} color="#666" />
            </TouchableOpacity>

            <TextInput
              style={styles.input}
              placeholder={t('expenses.amount')}
              value={amount}
              onChangeText={setAmount}
              keyboardType="decimal-pad"
              placeholderTextColor="#666"
            />

            <TextInput
              style={[styles.input, styles.textArea]}
              placeholder={t('expenses.description')}
              value={description}
              onChangeText={setDescription}
              multiline
              numberOfLines={4}
              placeholderTextColor="#666"
            />

            <TextInput
              style={styles.input}
              placeholder={t('expenses.paymentMethod')}
              value={paymentMethod}
              onChangeText={setPaymentMethod}
              placeholderTextColor="#666"
            />

            <View style={styles.buttonContainer}>
              <TouchableOpacity
                style={[styles.button, styles.cancelButton]}
                onPress={() => {
                  setExpenseModalVisible(false);
                  resetForm();
                }}
              >
                <Text style={[styles.buttonText, styles.cancelButtonText]}>
                  {t('common.cancel')}
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={[styles.button, styles.submitButton]}
                onPress={handleCreateExpense}
              >
                <Text style={[styles.buttonText, styles.submitButtonText]}>
                  {t('common.create')}
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>

      {/* Supplier Picker Modal */}
      <Modal
        visible={showSupplierPicker}
        animationType="fade"
        transparent={true}
        onRequestClose={() => setShowSupplierPicker(false)}
      >
        <View style={styles.modalOverlay}>
          <View style={styles.modalContent}>
            <Text style={styles.modalTitle}>{t('expenses.selectSupplier')}</Text>

            <TextInput
              style={styles.searchInput}
              placeholder={t('expenses.searchSuppliers')}
              value={supplierSearch}
              onChangeText={setSupplierSearch}
              placeholderTextColor="#666"
            />

            <FlatList
              data={filteredSuppliers}
              style={styles.supplierList}
              keyExtractor={item => item.id.toString()}
              renderItem={({ item }) => (
                <TouchableOpacity
                  style={styles.supplierItem}
                  onPress={() => {
                    setSelectedSupplier(item);
                    setShowSupplierPicker(false);
                    setSupplierSearch('');
                  }}
                >
                  <Text style={styles.supplierItemText}>{item.name}</Text>
                  <Text style={styles.supplierItemSubText}>
                    {item.contactPerson} • {item.category}
                  </Text>
                </TouchableOpacity>
              )}
            />

            <TouchableOpacity
              style={[styles.button, styles.cancelButton]}
              onPress={() => {
                setShowSupplierPicker(false);
                setSupplierSearch('');
              }}
            >
              <Text style={[styles.buttonText, styles.cancelButtonText]}>
                {t('common.cancel')}
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </Modal>
    </View>
  );
}