import React from 'react';
import { View, Text } from 'react-native';
import { useTranslation } from 'react-i18next';
import { useThemedStyles } from '../../theme/hooks';
import { DocumentActionsMenu } from './DocumentActionsMenu';

export const DocumentCard = ({ 
  document, 
  onIssueReceipt,
  onIssueTaxInvoiceReceipt,
  onIssueCreditInvoice,
  onCancel,
  onViewPdf
}) => {
  const { t } = useTranslation();

  const styles = useThemedStyles((colors) => ({
    documentCard: {
      backgroundColor: colors.card,
      borderRadius: 12,
      padding: 16,
      marginBottom: 12,
      elevation: 2,
      shadowColor: colors.shadowColor,
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.1,
      shadowRadius: 4,
    },
    cardHeader: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      marginBottom: 12,
    },
    headerLeft: {
      flex: 1,
    },
    headerRight: {
      flexDirection: 'row',
      alignItems: 'center',
      gap: 12,
    },
    documentNumber: {
      fontSize: 16,
      fontWeight: 'bold',
      color: colors.text,
      marginBottom: 4,
    },
    documentType: {
      fontSize: 12,
      color: colors.textSecondary,
    },
    statusBadge: {
      paddingHorizontal: 8,
      paddingVertical: 4,
      borderRadius: 12,
      fontSize: 12,
      fontWeight: '600',
    },
    cardContent: {
      gap: 12,
    },
    cardMainInfo: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 4,
    },
    entityName: {
      flex: 1,
      fontSize: 15,
      color: colors.textSecondary,
      marginRight: 12,
    },
    amount: {
      fontSize: 18,
      fontWeight: '600',
      color: colors.text,
    },
    cardFooter: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    description: {
      flex: 1,
      fontSize: 14,
      color: colors.textSecondary,
      marginRight: 12,
    },
    date: {
      fontSize: 13,
      color: colors.textSecondary,
    },
    statusActive: {
      backgroundColor: `${colors.primary}20`,
      color: colors.primary,
    },
    statusPaid: {
      backgroundColor: `${colors.success}20`,
      color: colors.success,
    },
    statusCancelled: {
      backgroundColor: `${colors.error}20`,
      color: colors.error,
    },
  }));

  const getStatusStyle = (status) => {
    const colors = {
      ACTIVE: styles.statusActive,
      PAID: styles.statusPaid,
      CANCELLED: styles.statusCancelled,
    };
    return colors[status] || {};
  };
 
  return (
    <View style={styles.documentCard}>
      {/* Header section with document number, type, and status */}
      <View style={styles.cardHeader}>
        <View style={styles.headerLeft}>
          <Text style={styles.documentNumber}>{document.record_number}</Text>
          <Text style={styles.documentType}>
            {t(`documents.types.${document.document_type.toLowerCase()}`)}
          </Text>
        </View>
        <View style={styles.headerRight}>
          <Text style={[styles.statusBadge, getStatusStyle(document.status)]}>
            {t(`documents.status.${document.status.toLowerCase()}`)}
          </Text>
          <DocumentActionsMenu
            document={document}
            onIssueReceipt={onIssueReceipt}
            onIssueTaxInvoiceReceipt={onIssueTaxInvoiceReceipt}
            onIssueCreditInvoice={onIssueCreditInvoice}
            onCancel={onCancel}
            onViewPdf={onViewPdf}
          />
        </View>
      </View>

      {/* Content section */}
      <View style={styles.cardContent}>
        <View style={styles.cardMainInfo}>
          <Text style={styles.entityName}>
            {document.entity_type === 'STUDENT' ? 
              `${document.firstName} ${document.lastName}` : 
              document.supplier_name}
          </Text>
          <Text style={styles.amount}>₪{document.amount.toFixed(2)}</Text>
        </View>
        
        <View style={styles.cardFooter}>
          {document.description && (
            <Text style={styles.description} numberOfLines={1}>
              {document.description}
            </Text>
          )}
          <Text style={styles.date}>
            {new Date(document.issue_date).toLocaleDateString()}
          </Text>
        </View>
      </View>
    </View>
  );
};