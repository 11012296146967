import React, { useState, useMemo, useEffect } from 'react';
import {
  View,
  Text,
  FlatList,
  Modal,
  Alert,
  ActivityIndicator,
  TouchableWithoutFeedback,
} from 'react-native';
import { Feather } from '@expo/vector-icons';
import { usePayments } from '../../context/PaymentContext';
import { useThemedStyles } from '../../theme/hooks';
import { useTranslation } from 'react-i18next';
import { DocumentTypeFAB, DocumentForm, DocumentCard, FilterDrawer, DocumentControls, PdfViewerModal } from '../../components/Documents';
import CollapsibleSearchBar from '../../components/common/CollapsibleSearchBar';
import FilterBottomSheet from '../../components/common/FilterBottomSheet';
import { useAccounting } from '../../context/AccountingContext';
import { useSuppliers } from '../../context/SupplierContext';
import { DOCUMENT_TYPES } from '../../constants';
import _ from 'lodash';

// Sort options
const sortOptions = [
  { label: 'Date', key: 'issue_date' },
  { label: 'Amount', key: 'amount' },
  { label: 'Document #', key: 'record_number' },
];

export default function PaymentsScreen() {
  const { t } = useTranslation();
  const { documents, isLoading, createDocument, cancelDocument, fetchDocuments, getDocumentPdfUrl } = usePayments();
  const { fetchAccounts } = useAccounting();
  const { fetchSuppliers } = useSuppliers();
  const [searchVisible, setSearchVisible] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedDocumentType, setSelectedDocumentType] = useState(null);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [showDocumentForm, setShowDocumentForm] = useState(false);
  const [pdfViewerVisible, setPdfViewerVisible] = useState(false);
  const [currentPdfUrl, setCurrentPdfUrl] = useState(null);
  const [statusFilters, setStatusFilters] = useState([]);
  const [entityFilters, setEntityFilters] = useState([]);
  const [typeFilters, setTypeFilters] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: 'issue_date', direction: 'desc' });
  const [activeSheet, setActiveSheet] = useState(null);
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);
  const [selectedEntities, setSelectedEntities] = useState([]);
  const [filterResetKey, setFilterResetKey] = useState(0);

  // Available filters based on docu
  useEffect(() => {
    fetchDocuments();
  }, [fetchDocuments]);

  useEffect(() => {
    fetchAccounts();
  }, [fetchAccounts]);

  useEffect(() => {
    fetchSuppliers();
  }, [fetchSuppliers]);

  const getSortLabel = () => {
    const option = sortOptions.find(opt => opt.key === sortConfig.key);
    return option ? option.label : 'Date';
  };

  const handleClearAll = () => {
    setStatusFilters([]);
    setEntityFilters([]);
    setTypeFilters([]);
    setSortConfig({ key: 'issue_date', direction: 'desc' });
    setSearchQuery('');
    setSearchVisible(false)
    setFilterResetKey(prev => prev + 1);
  };

  const filteredAndSortedDocuments = useMemo(() => {
    let result = [...documents];

    if (searchQuery) {
      const query = searchQuery.toLowerCase();
      result = result.filter(doc =>
        doc.record_number.toLowerCase().includes(query) ||
        doc.firstName?.toLowerCase().includes(query) ||
        doc.lastName?.toLowerCase().includes(query) ||
        doc.supplier_name?.toLowerCase().includes(query) ||
        doc.description?.toLowerCase().includes(query)
      );
    }

    if (statusFilters.length > 0) {
      result = result.filter(doc => statusFilters.includes(doc.status));
    }
    if (entityFilters.length > 0) {
      result = result.filter(doc => entityFilters.includes(doc.entity_type));
    }
    if (typeFilters.length > 0) {
      result = result.filter(doc =>
        typeFilters.some(type => doc.document_type.includes(type))
      );
    }
    if (selectedEntities.length > 0) {
      result = result.filter(doc => {
        const fullName = doc.firstName && doc.lastName ?
          `${doc.firstName} ${doc.lastName}` : doc.supplier_name;
        return selectedEntities.includes(fullName);
      });
    }

    return _.orderBy(result, [sortConfig.key], [sortConfig.direction]);
  }, [documents, searchQuery, statusFilters, entityFilters, typeFilters, selectedEntities, sortConfig]);

  const styles = useThemedStyles((colors) => ({
    // Layout styles
    container: {
      flex: 1,
      backgroundColor: colors.background,
    },
    header: {
      backgroundColor: colors.white,
      borderBottomWidth: 1,
      borderBottomColor: colors.border,
      paddingTop: 8,
      marginBottom: 0,
    },
    list: {
      padding: 16,
    },

    // Loading styles
    loading: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
    },
    loadingIndicator: {
      color: colors.primary,
    },

    // Empty state styles
    emptyState: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      paddingTop: 48,
    },
    emptyStateIcon: {
      color: colors.textSecondary,
    },
    emptyStateText: {
      fontSize: 16,
      color: colors.textSecondary,
      textAlign: 'center',
      marginTop: 12,
    },

    // Modal styles
    modalOverlay: {
      flex: 1,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      justifyContent: 'flex-end',
    },
    modalContent: {
      backgroundColor: colors.card,
      borderTopLeftRadius: 16,
      borderTopRightRadius: 16,
      paddingBottom: 34,
    },
  }));

  const handleCreateDocument = async (formData) => {
    try {
      const documentData = {
        ...formData,
        type: selectedDocumentType.id,
      };

      await createDocument(documentData);
      Alert.alert('Success', t('documents.success.created'));
      setShowDocumentForm(false);
      setSelectedDocumentType(null);
    } catch (error) {
      Alert.alert('Error', error.message);
    }
  };

  const handleCancelDocument = async (document, reason) => {
    console.log("Handle Cancel: ", document)
    Alert.alert(
      t('documents.cancel.title'),
      t('documents.cancel.message', { number: document.record_number }),
      [
        { text: t('common.cancel'), style: 'cancel' },
        {
          text: t('documents.cancel.confirm'),
          style: 'destructive',
          onPress: async () => {
            try {
              await cancelDocument(document.id, reason);
              Alert.alert('Success', t('documents.success.cancelled'));
            } catch (error) {
              Alert.alert('Error', error.message);
            }
          },
        },
      ]
    );
  };

  const handleViewPdf = async (document) => {
    try {
      setPdfViewerVisible(true);
      const pdfUrl = await getDocumentPdfUrl(document);
      if (pdfUrl) {
        setCurrentPdfUrl(pdfUrl);
      } else {
        Alert.alert('Error', 'Could not retrieve PDF URL');
      }
    } catch (error) {
      Alert.alert('Error', error.message || 'Failed to fetch PDF');
    }
  };

  const hasActiveFilters = statusFilters.length > 0 ||
    entityFilters.length > 0 ||
    typeFilters.length > 0 ||
    searchQuery !== '' ||
    (sortConfig.key !== 'issue_date' || sortConfig.direction !== 'desc');

  const getActiveFiltersCount = () => {
    return statusFilters.length +
      entityFilters.length +
      typeFilters.length +
      selectedEntities.length;
  };

  return (
    <View style={styles.container}>
      <TouchableWithoutFeedback onPress={() => {
        if (searchVisible && !searchQuery) {
          setSearchVisible(false);
        }
      }}>
        <View style={styles.header}>
          <DocumentControls
            onSearchPress={() => setSearchVisible(!searchVisible)}
            onFilterPress={() => setIsFilterDrawerOpen(true)}
            onSortPress={() => setActiveSheet('sort')}
            onClearAll={handleClearAll}
            sortConfig={sortConfig}
            activeFiltersCount={getActiveFiltersCount()}
            hasActiveFilters={hasActiveFilters}
            getSortLabel={getSortLabel}
          />

          <CollapsibleSearchBar
            visible={searchVisible}
            value={searchQuery}
            onChangeText={setSearchQuery}
            placeholder="Search documents..."
          />
        </View>
      </TouchableWithoutFeedback>

      {/* FilterDrawer */}
      <FilterDrawer
        key={filterResetKey}
        visible={isFilterDrawerOpen}
        onClose={() => setIsFilterDrawerOpen(false)}
        filters={{
          status: statusFilters,
          entity: entityFilters,
          type: typeFilters,
          selectedEntities,
        }}
        onApply={(newFilters) => {
          setStatusFilters(newFilters.status);
          setEntityFilters(newFilters.entity);
          setTypeFilters(newFilters.type);
          setSelectedEntities(newFilters.selectedEntities);
        }}
        documents={documents}
      />

      {/* Documents List */}
      {isLoading ? (
        <ActivityIndicator style={styles.loading} size="large" color={styles.loadingIndicator.color} />
      ) : (
        <FlatList
          data={filteredAndSortedDocuments}
          renderItem={({ item: document }) => (
            <DocumentCard
              document={document}
              onIssueReceipt={() => {
                setSelectedDocumentType(DOCUMENT_TYPES.RECEIPT);
                setSelectedDocument(document);
                setShowDocumentForm(true);
              }}
              onIssueTaxInvoiceReceipt={() => {
                setSelectedDocumentType(DOCUMENT_TYPES.TAX_INVOICE_RECEIPT);
                setSelectedDocument(document);
                setShowDocumentForm(true);
              }}
              onIssueCreditInvoice={() => {
                setSelectedDocumentType(DOCUMENT_TYPES.CREDIT_INVOICE);
                setSelectedDocument(document);
                setShowDocumentForm(true);
              }}
              onCancel={handleCancelDocument}
              onViewPdf={handleViewPdf}
            />
          )}
          keyExtractor={item => item.id.toString()}
          contentContainerStyle={styles.list}
          ListEmptyComponent={() => (
            <View style={styles.emptyState}>
              <Feather name="file-text" size={48} style={styles.emptyStateIcon} />
              <Text style={styles.emptyStateText}>
                {searchQuery
                  ? t('documents.search.noResults')
                  : t('documents.search.noDocuments')}
              </Text>
            </View>
          )}
        />
      )}

      <FilterBottomSheet
        visible={activeSheet === 'sort'}
        onClose={() => setActiveSheet(null)}
        title="Sort by"
        options={sortOptions}
        selectedValues={[sortConfig.key]}
        onApply={(key, direction) => setSortConfig({ key, direction })}
        isSortSheet={true}
      />

      {/* Document Type FAB */}
      <DocumentTypeFAB
        onSelectType={(type) => {
          setSelectedDocumentType(type);
          setShowDocumentForm(true);
        }}
      />

      {/* Document Form Modal */}
      <Modal
        visible={showDocumentForm}
        animationType="slide"
        transparent={true}
        onRequestClose={() => {
          setShowDocumentForm(false);
          setSelectedDocumentType(null);
          setSelectedDocument(null);
        }}
      >
        <View style={styles.modalOverlay}>
          <View style={styles.modalContent}>
            <DocumentForm
              documentType={selectedDocumentType}
              onSubmit={handleCreateDocument}
              selectedDocument={selectedDocument}
              onCancel={() => {
                setShowDocumentForm(false);
                setSelectedDocumentType(null);
                setSelectedDocument(null);
              }}
            />
          </View>
        </View>
      </Modal>

      <PdfViewerModal
        isVisible={pdfViewerVisible}
        pdfUrl={currentPdfUrl}
        onClose={() => {
          setPdfViewerVisible(false);
          setCurrentPdfUrl(null);
        }}
      />
    </View>
  );
}