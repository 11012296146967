import React from 'react';
import { Platform } from 'react-native';
import NativeLandingScreen from './NativeLandingScreen';
import WebLandingScreen from './WebLandingScreen';

export default function LandingScreen(props) {
  // Return the appropriate component based on platform
  return Platform.OS === 'web' 
    ? <WebLandingScreen {...props} /> 
    : <NativeLandingScreen {...props} />;
}