import axios from 'axios';
import { config } from '../config/env';
import { Platform } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';

console.log('API Config:', config); // Debug configuration

const api = axios.create({
    baseURL: config.api.baseURL,
    timeout: config.api.timeout,
    // Add headers if needed
    headers: {
        'Content-Type': 'application/json',
    },
});

api.interceptors.request.use(
    async (config) => {
        // Get token from storage if available
        const token = await AsyncStorage.getItem('authToken');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        console.log(`🚀 Making ${config.method?.toUpperCase()} request to:`, config.baseURL + config.url);
        return config;
    },
    (error) => {
        console.log('❌ Request Error:', error);
        return Promise.reject(error);
    }
);

// Add response interceptor
api.interceptors.response.use(
    (response) => {
        console.log('✅ Response received');
        return response;
    },
    (error) => {
        console.log('❌ Response Error:', {
            message: error.message,
            url: error.config?.url,
            method: error.config?.method,
            baseURL: error.config?.baseURL,
        });

        if (error.message === 'Network Error') {
            console.log('Debugging info:', {
                platform: Platform.OS,
                baseURL: config.api.baseURL,
                apiConfig: config.api,
            });
        }

        return Promise.reject(error);
    }
);

export const apiService = {
    // Auth
    register: async (userData) => {
        try {
            const response = await api.post('/auth/register', {
                email: userData.email,
                password: userData.password,
                firstName: userData.firstName,
                lastName: userData.lastName,
                phone: userData.phone,
                authorizedBusinessNumber: userData.authorizedBusinessNumber
            });

            // Store the token if registration is successful
            if (response.data.token) {
                await AsyncStorage.setItem('authToken', response.data.token);
            }

            return response;
        } catch (error) {
            console.log('Registration error:', error);
            throw error.response?.data?.error || 'Registration failed';
        }
    },

    login: async (credentials) => {
        try {
            const response = await api.post('/auth/login', {
                email: credentials.email,
                password: credentials.password
            });

            // Store the token if login is successful
            if (response.data.token) {
                await AsyncStorage.setItem('authToken', response.data.token);
            }

            return response;
        } catch (error) {
            console.log('Login error:', error);
            throw error.response?.data?.error || 'Login failed';
        }
    },

    logout: async () => {
        try {
            await api.post('/auth/logout');
            await AsyncStorage.removeItem('authToken');
        } catch (error) {
            console.log('Logout error:', error);
            // Still remove the token even if the API call fails
            await AsyncStorage.removeItem('authToken');
            throw error.response?.data?.error || 'Logout failed';
        }
    },

    // Method to verify token and get current user
    getCurrentUser: async () => {
        try {
            const response = await api.get('/auth/me');
            return response;
        } catch (error) {
            console.log('Get current user error:', error);
            throw error.response?.data?.error || 'Failed to get user data';
        }
    },

    // Students
    getStudents: async () => {
        try {
            const response = await api.get('/students');
            return response;
        } catch (error) {
            console.log('getStudents error:', error);
            throw error;
        }
    },
    //   getStudents: () => api.get('/students'),
    createStudent: (studentData) => api.post('/students', studentData),
    updateStudent: (id, studentData) => api.put(`/students/${id}`, studentData),
    deleteStudent: (id) => api.delete(`/students/${id}`),

    // Events
    getEvents: async (startDate, endDate) => {
        try {
            const params = new URLSearchParams();
            if (startDate) params.append('start', startDate);
            if (endDate) params.append('end', endDate);

            const response = await api.get(`/events${params.toString() ? `?${params.toString()}` : ''}`);
            return response;
        } catch (error) {
            console.log('getEvents error:', error);
            throw error;
        }
    },
    createEvent: (eventData) => api.post('/events', eventData),
    updateEvent: (id, eventData) => api.put(`/events/${id}`, eventData),
    deleteEvent: (id) => api.delete(`/events/${id}`),

    // Vehicles
    getVehicles: () => api.get('/vehicles'),
    createVehicle: (vehicleData) => api.post('/vehicles', vehicleData),
    updateVehicle: (id, vehicleData) => api.put(`/vehicles/${id}`, vehicleData),
    deleteVehicle: (id) => api.delete(`/vehicles/${id}`),

    // Instructors
    getInstructors: async () => {
        try {
            const response = await api.get('/instructors');
            return response;
        } catch (error) {
            console.log('getInstructors error:', error);
            throw error;
        }
    },
    addInstructor: (instructorData) => api.post('/instructors', instructorData),
    updateInstructor: (id, instructorData) => api.put(`/instructors/${id}`, instructorData),
    deleteInstructor: (id) => api.delete(`/instructors/${id}`),

    // Suppliers
    getSuppliers: async () => {
        try {
            const response = await api.get('/suppliers');
            return response;
        } catch (error) {
            console.log('getSuppliers error:', error);
            throw error;
        }
    },
    addSupplier: (supplierData) => api.post('/suppliers', supplierData),
    updateSupplier: (id, supplierData) => api.put(`/suppliers/${id}`, supplierData),
    deleteSupplier: (id) => api.delete(`/suppliers/${id}`),

    // Accounting Records
    getAccountingDocuments: async () => {
        try {
            const response = await api.get('/accounting');
            return response;
        } catch (error) {
            console.log('getAccountingDocuments error:', error);
            throw error;
        }
    },

    createAccountingDocument: async (documentData) => {
        try {
            let endpoint = '/accounting';

            // Handle direct transactions first
            if (documentData.type === 'INCOME') {
                endpoint = '/accounting/income';
                return await api.post(endpoint, {
                    referenceDocumentNumber: documentData.manualReferenceNumber || "MANUAL",
                    authorizedBusinessNumber: documentData.authorizedBusinessNumber,
                    description: documentData.description,
                    customerAccountId: documentData.customerAccountId,
                    incomeAccountId: documentData.incomeAccountId,
                    vatAccountId: documentData.vatAccountId,
                    amount: documentData.totalAmount,
                    details: documentData.description,
                    valueDate: documentData.documentDate,
                    businessBranchId: "001",
                    payments: documentData.payment ? [documentData.payment] : []
                });
            }

            if (documentData.type === 'EXPENSE') {
                endpoint = '/accounting/expenses';
                return await api.post(endpoint, {
                    referenceDocumentNumber: documentData.manualReferenceNumber || "MANUAL",
                    authorizedBusinessNumber: documentData.authorizedBusinessNumber,
                    description: documentData.description,
                    supplierAccountId: documentData.customerAccountId,
                    expenseAccountId: documentData.expenseAccountId,
                    vatAccountId: documentData.vatAccountId,
                    amount: documentData.totalAmount,
                    details: documentData.description,
                    valueDate: documentData.documentDate,
                    businessBranchId: "001",
                    payments: documentData.payment ? [documentData.payment] : []
                });
            }

            // Format data for standard documents
            const formattedData = {
                documentType: documentData.documentType,
                customerAccountId: documentData.customerAccountId,
                entityType: documentData.entityType,
                documentDate: documentData.documentDate,
                businessBranchId: documentData.businessBranchId,
                totalBeforeDiscount: documentData.totalBeforeDiscount,
                documentDiscount: documentData.documentDiscount,
                totalAfterDiscountBeforeVat: documentData.totalAfterDiscountBeforeVat,
                vatAmount: documentData.vatAmount,
                totalAmount: documentData.totalAmount,
                description: documentData.description,
                vatAccountId: documentData.vatAccountId,
                incomeAccountId: documentData.incomeAccountId,
                items: documentData.items,
                referenceDocumentId: documentData.referenceDocumentId
            };

            // Add payment if exists
            if (documentData.payment) {
                formattedData.payments = [{
                    ...documentData.payment,
                    date: documentData.payment.date || new Date().toISOString().split('T')[0],
                    amount: documentData.totalAmount
                }];
            }

            // Add reference document fields if needed
            if (documentData.type === 'CREDIT_INVOICE') {
                formattedData.originalInvoiceNumber = documentData.parentDocumentNumber;
                formattedData.parentDocumentNumber = documentData.parentDocumentNumber;
                formattedData.contraAccountId = documentData.contraAccountId || documentData.incomeAccountId;
            }

            if (documentData.type === 'RECEIPT') {
                formattedData.originalInvoiceNumber = documentData.parentDocumentNumber;
            }

            // Make the request
            console.log('Sending to API:', JSON.stringify(formattedData, null, 2));
            const response = await api.post(endpoint, formattedData);
            return response;

        } catch (error) {
            console.log('createAccountingDocument error:', error);
            throw error;
        }
    },

    cancelAccountingDocument: async (documentId, reason) => {
        try {
            const response = await api.put(`/accounting/${documentId}/cancel`, { reason });
            return response;
        } catch (error) {
            console.log('cancelAccountingDocument error:', error);
            throw error;
        }
    },

    getAccountingDocumentsByEntity: async (entityType, entityId) => {
        try {
            const response = await api.get(`/accounting/entity/${entityType}/${entityId}`);
            return response;
        } catch (error) {
            console.log('getAccountingDocumentsByEntity error:', error);
            throw error;
        }
    },

    createTaxInvoice: async (data) => {
        try {
            return await apiService.createAccountingDocument({
                ...data,
                type: 'TAX_INVOICE'
            });
        } catch (error) {
            console.log('createTaxInvoice error:', error);
            throw error;
        }
    },

    createTaxInvoiceReceipt: async (data) => {
        try {
            return await apiService.createAccountingDocument({
                ...data,
                type: 'TAX_INVOICE_RECEIPT'
            });
        } catch (error) {
            console.log('createTaxInvoiceReceipt error:', error);
            throw error;
        }
    },

    createReceipt: async (data) => {
        try {
            return await apiService.createAccountingDocument({
                ...data,
                type: 'RECEIPT'
            });
        } catch (error) {
            console.log('createReceipt error:', error);
            throw error;
        }
    },

    createCreditInvoice: async (data) => {
        try {
            return await apiService.createAccountingDocument({
                ...data,
                type: 'CREDIT_INVOICE'
            });
        } catch (error) {
            console.log('createCreditInvoice error:', error);
            throw error;
        }
    },

    createExpense: async (data) => {
        try {
            return await apiService.createAccountingDocument({
                ...data,
                type: 'EXPENSE'
            });
        } catch (error) {
            console.log('createExpense error:', error);
            throw error;
        }
    },

    getAccounts: async () => {
        try {
            const response = await api.get('/accounting/accounts');
            return response;
        } catch (error) {
            console.log('getAccounts error:', error);
            throw error;
        }
    },

    getCurrentVatRate: async () => {
        try {
            const response = await api.get('/accounting/vat-rate');
            return response.data.vatRate;
        } catch (error) {
            console.log('getCurrentVatRate error:', error);
            throw error;
        }
    },

    getBusinessBranches: async () => {
        try {
            const response = await api.get('/accounting/configuration/branches');
            return response.data;
        } catch (error) {
            console.log('getBusinessBranches error:', error);
            throw error;
        }
    },

    getValidReferenceDocuments: async (documentType, entityId = null, entityType = null) => {
        try {
            const params = new URLSearchParams();
            if (documentType) params.append('documentType', documentType);
            if (entityId) params.append('entityId', entityId);
            if (entityType) params.append('entityType', entityType);

            const response = await api.get(`/accounting/references?${params.toString()}`);
            return response;
        } catch (error) {
            console.log('getValidReferenceDocuments error:', error);
            throw error;
        }
    },

    getDocumentPdfUrl: async (documentNumber) => {
        try {
            const response = await api.get(`/accounting/documents/${documentNumber}/pdf`);
            return response;
        } catch (error) {
            console.log('getDocumentPdfUrl error:', error);
            throw error.response?.data?.error || 'Failed to get PDF URL';
        }
    },
};