import React, { useEffect } from 'react';
import { Modal, View, ActivityIndicator, Platform, Alert } from 'react-native';
import { useThemedStyles } from '../../theme/hooks';
import * as FileSystem from 'expo-file-system';
import * as Sharing from 'expo-sharing';
import * as IntentLauncher from 'expo-intent-launcher';

export const PdfViewerModal = ({ isVisible, pdfUrl, onClose }) => {
  const styles = useThemedStyles((colors) => ({
    modalContainer: {
      flex: 1,
      backgroundColor: colors.background + 'CC',
      justifyContent: 'center',
      alignItems: 'center'
    },
    loadingText: {
      color: colors.text,
      marginTop: 16
    }
  }));

  useEffect(() => {
    if (isVisible && pdfUrl) {
      handlePdf();
    }
  }, [isVisible, pdfUrl]);

  const handlePdf = async () => {
    try {
      // Generate a local filename
      const filename = pdfUrl.split('/').pop() || 'document.pdf';
      const localUri = `${FileSystem.documentDirectory}${filename}`;

      // Download the file
      const { uri } = await FileSystem.downloadAsync(pdfUrl, localUri);

      if (Platform.OS === 'android') {
        // On Android, open with the system PDF viewer
        const contentUri = await FileSystem.getContentUriAsync(uri);
        await IntentLauncher.startActivityAsync('android.intent.action.VIEW', {
          data: contentUri,
          flags: 1,
          type: 'application/pdf',
        });
      } else {
        // On iOS, use the sharing dialog which includes preview
        await Sharing.shareAsync(uri, {
          UTI: 'com.adobe.pdf',
          mimeType: 'application/pdf',
        });
      }

      // Close the modal after opening the PDF
      onClose();
    } catch (error) {
      console.error('PDF handling error:', error);
      Alert.alert(
        'Error',
        'Failed to open PDF. Please try again later.'
      );
      onClose();
    }
  };

  return (
    <Modal
      visible={isVisible}
      transparent={true}
      onRequestClose={onClose}
    >
      <View style={styles.modalContainer}>
        <ActivityIndicator size="large" />
      </View>
    </Modal>
  );
};