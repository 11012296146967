import React, { useState } from 'react';
import {
  View,
  Modal,
  TextInput,
  TouchableOpacity,
  Text
} from 'react-native';
import { useThemedStyles } from '../../theme/hooks';

const makeStyles = (colors) => ({
  styles: {
    modalOverlay: {
      flex: 1,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      justifyContent: 'center',
      alignItems: 'center',
    },
    modalContent: {
      backgroundColor: colors.card,
      borderRadius: 10,
      padding: 20,
      width: '80%',
      maxWidth: 400,
      shadowColor: colors.shadowColor,
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.25,
      shadowRadius: 3.84,
      elevation: 5,
    },
    title: {
      fontSize: 18,
      fontWeight: 'bold',
      marginBottom: 10,
      color: colors.text,
    },
    message: {
      marginBottom: 15,
      color: colors.text,
    },
    input: {
      borderWidth: 1,
      borderColor: colors.border,
      borderRadius: 5,
      padding: 10,
      marginBottom: 15,
      color: colors.text,
      backgroundColor: colors.card,
    },
    buttonContainer: {
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
    button: {
      paddingVertical: 8,
      paddingHorizontal: 15,
      borderRadius: 5,
      marginLeft: 10,
    },
    cancelButton: {
      backgroundColor: colors.border,
    },
    confirmButton: {
      backgroundColor: colors.error,
    },
    buttonText: {
      fontSize: 16,
    },
    cancelButtonText: {
      color: colors.text,
    },
    confirmText: {
      color: '#FFFFFF',
    },
  },
  colors
});

const CancelModal = ({ 
  isVisible, 
  onClose, 
  onCancel, 
  document, 
  translations 
}) => {
  const [reason, setReason] = useState('');
  const { styles, colors } = useThemedStyles(makeStyles);

  const handleCancel = () => {
    onCancel(document, reason);
    onClose();
    setReason('');
  };

  return (
    <Modal
      visible={isVisible}
      transparent
      animationType="fade"
      onRequestClose={onClose}
    >
      <View style={styles.modalOverlay}>
        <View style={styles.modalContent}>
          <Text style={styles.title}>
            {translations.title}
          </Text>
          
          <Text style={styles.message}>
            {translations.message.replace('{number}', document?.record_number)}
          </Text>

          <TextInput
            style={styles.input}
            placeholder={translations.reasonPlaceholder}
            placeholderTextColor={colors.textSecondary}
            value={reason}
            onChangeText={setReason}
            multiline
            numberOfLines={3}
          />

          <View style={styles.buttonContainer}>
            <TouchableOpacity 
              style={[styles.button, styles.cancelButton]} 
              onPress={onClose}
            >
              <Text style={[styles.buttonText, styles.cancelButtonText]}>
                {translations.cancel}
              </Text>
            </TouchableOpacity>

            <TouchableOpacity 
              style={[styles.button, styles.confirmButton]}
              onPress={handleCancel}
            >
              <Text style={[styles.buttonText, styles.confirmText]}>
                {translations.confirm}
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </Modal>
  );
};

export default CancelModal;