import React, { createContext, useContext, useReducer, useCallback } from 'react';
import { apiService } from '../services/api';
import { DOCUMENT_TYPES } from '../constants/documentTypes';

const PaymentContext = createContext();

// Constants matching database ENUMs
const DOCUMENT_STATUS = {
    DRAFT: 'DRAFT',
    ACTIVE: 'ACTIVE',
    PAID: 'PAID',
    CANCELLED: 'CANCELLED'
};

const ENTITY_TYPES = {
    STUDENT: 'STUDENT',
    SUPPLIER: 'SUPPLIER'
};

const ACTIONS = {
    SET_DOCUMENTS: 'SET_DOCUMENTS',
    CREATE_DOCUMENT: 'CREATE_DOCUMENT',
    UPDATE_DOCUMENT: 'UPDATE_DOCUMENT',
    SET_LOADING: 'SET_LOADING',
    SET_ERROR: 'SET_ERROR',
};

const validateDocumentData = (documentData) => {
    const errors = [];
    const documentConfig = DOCUMENT_TYPES[documentData.type];

    // Basic validation
    if (!documentConfig) {
        errors.push(`Invalid document type: ${documentData.type}`);
        return errors;
    }

    // Entity type validation
    if (!documentData.entityType || !Object.values(ENTITY_TYPES).includes(documentData.entityType)) {
        errors.push(`Invalid entity type: ${documentData.entityType}. Must be either STUDENT or SUPPLIER`);
    }

    // Entity-Document type relationship validation
    if (documentData.entityType === ENTITY_TYPES.SUPPLIER && documentData.type !== 'EXPENSE') {
        errors.push('Supplier entities can only be used with EXPENSE documents');
    }
    if (documentData.entityType === ENTITY_TYPES.STUDENT && documentData.type === 'EXPENSE') {
        errors.push('Student entities cannot be used with EXPENSE documents');
    }

    // Changed from entityId to customerAccountId
    if (!documentData.customerAccountId) {
        errors.push('Entity ID is required');
    }

    // Required accounts validation based on document type
    const { requiredAccounts } = documentConfig;
    if (requiredAccounts) {
        if (requiredAccounts.income && !documentData.incomeAccountId) {
            errors.push('Income account is required');
        }
        if (requiredAccounts.expense && !documentData.expenseAccountId) {
            errors.push('Expense account is required');
        }
        if (requiredAccounts.vat && !documentData.vatAccountId) {
            errors.push('VAT account is required');
        }
    }

    // Payment validation
    if (documentConfig.requiresPayment) {
        if (!documentData.payment) {
            errors.push('Payment information is required');
        } else if (!documentData.payment.cashierAccountId) {
            errors.push('Cashier account is required');
        }
    }

    // Reference document validation
    if (documentConfig.requiresReference && !documentData.referenceDocumentId) {
        errors.push('Reference document is required');
    }

    if (errors.length > 0) {
        throw new Error(errors.join(', '));
    }
};

const formatPaymentForDB = (payment, documentAmount) => {
    if (!payment) return null;

    const basePayment = {
        payment_method: payment.methodType,
        amount: documentAmount,
        payment_date: payment.date || new Date().toISOString().split('T')[0],
        payment_reference: payment.reference || null
    };

    // Structure bank_details JSON based on payment method
    switch (payment.methodType) {
        case '2': // Check/Bank transfer
            basePayment.bank_details = JSON.stringify({
                bankNumber: payment.bankNumber,
                bankBranch: payment.bankBranch,
                bankAccountNumber: payment.bankAccountNumber,
                checkNumber: payment.checkNumber
            });
            break;
        case '3': // Credit card
            basePayment.bank_details = JSON.stringify({
                creditCardCompanyCode: payment.creditCardCompanyCode,
                cardName: payment.cardName,
                dealType: payment.dealType
            });
            break;
        default:
            basePayment.bank_details = null;
    }

    return basePayment;
};

const formatNewDocumentForState = (apiResponse, documentData) => {
    const stateDocument = {
        id: apiResponse.data.id,
        record_number: apiResponse.data.documentNumber,
        document_type: documentData.type,
        entity_type: documentData.entityType,
        entity_id: documentData.customerAccountId,
        amount: documentData.totalAmount,
        description: documentData.description,
        status: documentData.payment ? DOCUMENT_STATUS.PAID : DOCUMENT_STATUS.ACTIVE,
        issue_date: documentData.documentDate || new Date().toISOString().split('T')[0],
        payment_method: documentData.payment?.methodType,
        reference_document_id: documentData.referenceDocumentId,

        // Additional fields from entity
        firstName: documentData.entity?.firstName,
        lastName: documentData.entity?.lastName,
        supplier_name: documentData.entity?.name,

        // Add totals information for display
        totalBeforeDiscount: documentData.totalBeforeDiscount,
        documentDiscount: documentData.documentDiscount,
        totalAfterDiscountBeforeVat: documentData.totalAfterDiscountBeforeVat,
        vatAmount: documentData.vatAmount,
        totalAmount: documentData.totalAmount,

        items: documentData.items
    };

    // Add payment details if present
    if (documentData.payment) {
        stateDocument.payment_date = documentData.payment.date;
        stateDocument.payment_details = formatPaymentForDB(documentData.payment, documentData.totalAmount);  // Changed from amount
    }

    return {
        ...stateDocument,
        document_number: apiResponse.data.documentNumber,
        transactionId: apiResponse.data.transactionId,
        ...(apiResponse.data || {})
    };
};

const paymentReducer = (state, action) => {
    switch (action.type) {
        case ACTIONS.SET_DOCUMENTS:
            return {
                ...state,
                documents: action.payload,
                isLoading: false,
                error: null
            };
        case ACTIONS.CREATE_DOCUMENT:
            return {
                ...state,
                documents: [action.payload, ...state.documents],
                isLoading: false,
                error: null
            };
        case ACTIONS.UPDATE_DOCUMENT:
            return {
                ...state,
                documents: state.documents.map(doc =>
                    doc.id === action.payload.id ? { ...doc, ...action.payload } : doc
                ),
                isLoading: false,
                error: null
            };
        case ACTIONS.SET_LOADING:
            return {
                ...state,
                isLoading: action.payload
            };
        case ACTIONS.SET_ERROR:
            return {
                ...state,
                error: action.payload,
                isLoading: false
            };
        default:
            return state;
    }
};

export const PaymentProvider = ({ children }) => {
    const [state, dispatch] = useReducer(paymentReducer, {
        documents: [],
        isLoading: false,
        error: null,
    });

    const fetchDocuments = useCallback(async () => {
        dispatch({ type: ACTIONS.SET_LOADING, payload: true });
        try {
            const response = await apiService.getAccountingDocuments();
            const documents = response.data.map(doc => ({
                ...doc,
                amount: parseFloat(doc.amount)
            }))
                .sort((a, b) => new Date(b.issue_date) - new Date(a.issue_date));
            dispatch({ type: ACTIONS.SET_DOCUMENTS, payload: documents });
        } catch (error) {
            dispatch({ type: ACTIONS.SET_ERROR, payload: error.message });
        }
    }, []);

    const createDocument = async (documentData) => {
        dispatch({ type: ACTIONS.SET_LOADING, payload: true });
        try {
            validateDocumentData(documentData);
            const response = await apiService.createAccountingDocument(documentData);
            
            // Format the new document for state
            const newDocument = formatNewDocumentForState(response, documentData);
            // Update state
            dispatch({
                type: ACTIONS.CREATE_DOCUMENT,
                payload: newDocument
            });

            // Update reference document status if applicable
            if (documentData.referenceDocumentId) {
                const referenceDoc = state.documents.find(
                    doc => doc.id === documentData.referenceDocumentId
                );
                if (referenceDoc) {
                    dispatch({
                        type: ACTIONS.UPDATE_DOCUMENT,
                        payload: {
                            ...referenceDoc,
                            status: DOCUMENT_STATUS.PAID
                        }
                    });
                }
            }

            return newDocument;
        } catch (error) {
            console.error('Document creation error:', error);
            dispatch({ type: ACTIONS.SET_ERROR, payload: error.message });
            throw error;
        } finally {
            dispatch({ type: ACTIONS.SET_LOADING, payload: false });
        }
    };

    const cancelDocument = async (documentId, reason) => {
        dispatch({ type: ACTIONS.SET_LOADING, payload: true });
        try {
            await apiService.cancelAccountingDocument(documentId, reason);
            dispatch({
                type: ACTIONS.UPDATE_DOCUMENT,
                payload: {
                    id: documentId,
                    status: DOCUMENT_STATUS.CANCELLED
                }
            });
        } catch (error) {
            dispatch({ type: ACTIONS.SET_ERROR, payload: error.message });
            throw error;
        }
    };

    const getDocumentsByEntity = async (entityType, entityId) => {
        dispatch({ type: ACTIONS.SET_LOADING, payload: true });
        try {
            const response = await apiService.getAccountingDocumentsByEntity(entityType, entityId);
            return response.data;
        } catch (error) {
            dispatch({ type: ACTIONS.SET_ERROR, payload: error.message });
            throw error;
        }
    };

    const getDocumentPdfUrl = async (document) => {
        try {
            const response = await apiService.getDocumentPdfUrl(
                document.record_number
            );
            return response.data?.url;
        } catch (error) {
            dispatch({ type: ACTIONS.SET_ERROR, payload: error.message });
            throw error;
        } 
    };

    const value = {
        documents: state.documents,
        isLoading: state.isLoading,
        error: state.error,
        fetchDocuments,
        createDocument,
        cancelDocument,
        getDocumentsByEntity,
        getDocumentPdfUrl
    };

    return (
        <PaymentContext.Provider value={value}>
            {children}
        </PaymentContext.Provider>
    );
};

export const usePayments = () => {
    const context = useContext(PaymentContext);
    if (context === undefined) {
        throw new Error('usePayments must be used within a PaymentProvider');
    }
    return context;
};