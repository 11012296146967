import React, { useState, useMemo } from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  ScrollView,
  TextInput,
  TouchableWithoutFeedback,
} from 'react-native';
import { Feather } from '@expo/vector-icons';
import { useThemedStyles } from '../../theme/hooks';

const filterOptions = {
  status: [
    { label: 'Active', value: 'ACTIVE' },
    { label: 'Paid', value: 'PAID' },
    { label: 'Cancelled', value: 'CANCELLED' },
  ],
  entity: [
    { label: 'Students', value: 'STUDENT' },
    { label: 'Suppliers', value: 'SUPPLIER' },
  ],
  type: [
    { label: 'Tax Invoice', value: 'TAX_INVOICE' },
    { label: 'Tax Invoice Receipt', value: 'TAX_INVOICE_RECEIPT' },
    { label: 'Receipt', value: 'RECEIPT' },
    { label: 'Credit Note', value: 'CREDIT' },
  ],
};

const FilterSection = ({ title, options, selectedValues, onSelect, isOpen, onToggle, styles }) => (
  <View>
    <TouchableOpacity 
      style={styles.sectionHeader} 
      onPress={onToggle}
    >
      <Text style={styles.sectionTitle}>{title}</Text>
      <Feather 
        name={isOpen ? "chevron-up" : "chevron-down"} 
        size={20} 
        color="#000" 
      />
    </TouchableOpacity>
    {isOpen && (
      <View style={styles.sectionContent}>
        {options.map(option => (
          <TouchableOpacity
            key={option.value}
            style={styles.filterOption}
            onPress={() => onSelect(option.value)}
          >
            <Text style={styles.filterOptionText}>{option.label}</Text>
            {selectedValues?.includes(option.value) && (
              <Feather name="check" size={20} color="#2196F3" />
            )}
          </TouchableOpacity>
        ))}
      </View>
    )}
  </View>
);

const EntitySearch = ({ documents, selectedEntities, onSelect, styles }) => {
  const [searchText, setSearchText] = useState('');
  const [isFocused, setIsFocused] = useState(false);

  const entities = useMemo(() => {
    const uniqueEntities = new Set();
    documents.forEach(doc => {
      if (doc.firstName && doc.lastName) {
        uniqueEntities.add(`${doc.firstName} ${doc.lastName}`);
      }
      if (doc.supplier_name) {
        uniqueEntities.add(doc.supplier_name);
      }
    });
    return Array.from(uniqueEntities);
  }, [documents]);

  const filteredEntities = useMemo(() => {
    if (!searchText && !isFocused) return [];
    if (!searchText) return entities.slice(0, 5);
    return entities
      .filter(entity => entity.toLowerCase().includes(searchText.toLowerCase()))
      .slice(0, 10);
  }, [entities, searchText, isFocused]);

  return (
    <View style={styles.filterSection}>
      <Text style={styles.sectionTitle}>Search by Name</Text>
      <TextInput
        style={styles.searchInput}
        value={searchText}
        onChangeText={setSearchText}
        placeholder="Search entities..."
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      />
      {(isFocused || searchText) && filteredEntities.length > 0 && (
        <View style={styles.dropdown}>
          {filteredEntities.map(entity => (
            <TouchableOpacity
              key={entity}
              style={styles.dropdownItem}
              onPress={() => {
                onSelect(entity);
                setSearchText('');
                setIsFocused(false);
              }}
            >
              <Text style={styles.dropdownText}>{entity}</Text>
              {selectedEntities?.includes(entity) && (
                <Feather name="check" size={20} color="#2196F3" />
              )}
            </TouchableOpacity>
          ))}
        </View>
      )}
    </View>
  );
};

export const FilterDrawer = ({ visible, onClose, filters, onApply, documents }) => {
  const [localFilters, setLocalFilters] = useState({
    status: filters.status,
    entity: filters.entity,
    type: filters.type,
    selectedEntities: filters.selectedEntities
  });
  
  const [openSections, setOpenSections] = useState({
    status: true,
    entity: true,
    type: true
  });

  const styles = useThemedStyles((colors) => ({
    // Drawer styles
    drawerOverlay: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      zIndex: 1000,
    },
    drawer: {
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      width: '80%',
      maxWidth: 360,
      backgroundColor: colors.card,
      zIndex: 1001,
      elevation: 5,
    },
    drawerHeader: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: 20,
      borderBottomWidth: 1,
      borderBottomColor: colors.border,
    },
    drawerTitle: {
      fontSize: 20,
      fontWeight: '600',
      color: colors.text,
    },
    drawerContent: {
      flex: 1,
      backgroundColor: colors.card,
      paddingBottom: 100,
    },
    drawerFooter: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      flexDirection: 'row',
      padding: 16,
      backgroundColor: colors.card,
      borderTopWidth: 1,
      borderTopColor: colors.border,
      paddingBottom: 34,
    },
    // Filter Section Styles
    filterSection: {
      padding: 16,
      borderBottomWidth: 1,
      borderBottomColor: colors.border,
    },
    sectionHeader: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: 16,
      borderBottomWidth: 1,
      borderBottomColor: colors.border,
    },
    sectionTitle: {
      fontSize: 16,
      fontWeight: '600',
      color: colors.text,
      marginBottom: 12,
    },
    sectionContent: {
      paddingHorizontal: 16,
    },
    filterOption: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingVertical: 12,
    },
    filterOptionText: {
      fontSize: 16,
      color: colors.text,
    },
    // Search Input styles
    searchInput: {
      height: 40,
      backgroundColor: colors.card,
      borderRadius: 20,
      paddingHorizontal: 16,
      fontSize: 16,
      color: colors.text,
      borderWidth: 1,
      borderColor: colors.border,
    },
    // Dropdown styles
    dropdown: {
      maxHeight: 200,
      backgroundColor: colors.card,
      borderRadius: 8,
      marginTop: 8,
      elevation: 4,
      shadowColor: colors.shadowColor,
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.25,
      shadowRadius: 4,
    },
    dropdownItem: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: 12,
      borderBottomWidth: 1,
      borderBottomColor: colors.border,
    },
    dropdownText: {
      fontSize: 16,
      color: colors.text,
    },
    // Button styles
    bottomSheetButton: {
      flex: 1,
      height: 48,
      borderRadius: 8,
      alignItems: 'center',
      justifyContent: 'center',
      marginHorizontal: 6,
    },
    bottomSheetButtonPrimary: {
      backgroundColor: colors.primary,
    },
    bottomSheetButtonSecondary: {
      backgroundColor: colors.card,
      borderWidth: 1,
      borderColor: colors.border,
    },
    bottomSheetButtonTextPrimary: {
      color: colors.white,
      fontSize: 16,
      fontWeight: '600',
    },
    bottomSheetButtonTextSecondary: {
      color: colors.text,
      fontSize: 16,
    },
  }));

  const toggleSection = (section) => {
    setOpenSections(prev => ({
      ...prev,
      [section]: !prev[section]
    }));
  };
  
  const handleSelect = (section, value) => {
    setLocalFilters(prev => ({
      ...prev,
      [section]: prev[section].includes(value)
        ? prev[section].filter(v => v !== value)
        : [...prev[section], value]
    }));
  };

  return (
    <>
      {visible && (
        <TouchableWithoutFeedback onPress={onClose}>
          <View style={styles.drawerOverlay} />
        </TouchableWithoutFeedback>
      )}
      <View style={[styles.drawer, !visible && { display: 'none' }]}>
        <View style={styles.drawerHeader}>
          <Text style={styles.drawerTitle}>Filters</Text>
          <TouchableOpacity onPress={onClose}>
            <Feather name="x" size={24} color="#000" />
          </TouchableOpacity>
        </View>

        <ScrollView style={styles.drawerContent}>
          <FilterSection
            title="Status"
            options={filterOptions.status}
            selectedValues={localFilters.status}
            onSelect={(value) => handleSelect('status', value)}
            isOpen={openSections.status}
            onToggle={() => toggleSection('status')}
            styles={styles}
          />
          <FilterSection
            title="Entity"
            options={filterOptions.entity}
            selectedValues={localFilters.entity}
            onSelect={(value) => handleSelect('entity', value)}
            isOpen={openSections.entity}
            onToggle={() => toggleSection('entity')}
            styles={styles}
          />
          <FilterSection
            title="Document Type"
            options={filterOptions.type}
            selectedValues={localFilters.type}
            onSelect={(value) => handleSelect('type', value)}
            isOpen={openSections.type}
            onToggle={() => toggleSection('type')}
            styles={styles}
          />
          <EntitySearch
            documents={documents}
            selectedEntities={localFilters.selectedEntities}
            onSelect={(entity) => handleSelect('selectedEntities', entity)}
            styles={styles}
          />
        </ScrollView>

        <View style={styles.drawerFooter}>
          <TouchableOpacity
            style={[styles.bottomSheetButton, styles.bottomSheetButtonSecondary]}
            onPress={onClose}
          >
            <Text style={styles.bottomSheetButtonTextSecondary}>Cancel</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={[styles.bottomSheetButton, styles.bottomSheetButtonPrimary]}
            onPress={() => {
              onApply(localFilters);
              onClose();
            }}
          >
            <Text style={styles.bottomSheetButtonTextPrimary}>Apply</Text>
          </TouchableOpacity>
        </View>
      </View>
    </>
  );
};
