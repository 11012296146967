import React, { useState } from 'react';
import { View, Text, TouchableOpacity, Modal } from 'react-native';
import { Feather } from '@expo/vector-icons';
import { useTranslation } from 'react-i18next';
import { useThemedStyles } from '../../theme/hooks';
import { DOCUMENT_TYPES } from '../../constants/documentTypes';

export const DocumentTypeFAB = ({ onSelectType }) => {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);
  const [showFullModal, setShowFullModal] = useState(false);

  const styles = useThemedStyles((colors) => ({
    container: {
      position: 'absolute',
      right: 16,
      bottom: 16,
      alignItems: 'flex-end',
      zIndex: 1000,
    },
    menu: {
      position: 'absolute',
      bottom: 72,
      right: 0,
      backgroundColor: colors.card,
      borderRadius: 12,
      minWidth: 200,
      paddingVertical: 8,
      elevation: 4,
      shadowColor: colors.shadowColor,
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.25,
      shadowRadius: 4,
    },
    menuItem: {
      flexDirection: 'row',
      alignItems: 'center',
      paddingVertical: 12,
      paddingHorizontal: 16,
    },
    menuItemText: {
      color: colors.text,
      marginLeft: 12,
      fontSize: 16,
    },
    menuItemIcon: {
      color: colors.primary,
    },
    separator: {
      height: 1,
      backgroundColor: colors.border,
      marginVertical: 8,
    },
    fab: {
      backgroundColor: colors.primary,
      width: 56,
      height: 56,
      borderRadius: 28,
      justifyContent: 'center',
      alignItems: 'center',
      elevation: 4,
      shadowColor: colors.shadowColor,
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.25,
      shadowRadius: 4,
    },
    fabIcon: {
      color: colors.card,
    },
    modalOverlay: {
      flex: 1,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      justifyContent: 'flex-end',
    },
    modalContent: {
      backgroundColor: colors.card,
      borderTopLeftRadius: 16,
      borderTopRightRadius: 16,
      paddingBottom: 34,
    },
    modalHeader: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: 16,
      borderBottomWidth: 1,
      borderBottomColor: colors.border,
    },
    modalTitle: {
      fontSize: 20,
      fontWeight: 'bold',
      color: colors.text,
    },
    closeButton: {
      padding: 8,
    },
    closeIcon: {
      color: colors.textSecondary,
    },
    sectionTitle: {
      fontSize: 16,
      fontWeight: '600',
      color: colors.textSecondary,
      padding: 16,
      paddingBottom: 8,
    }
  }));

  const handleQuickAction = (documentType) => {
    setIsExpanded(false);
    onSelectType(documentType);
  };

  const renderDocumentTypeItem = (documentType) => (
    <TouchableOpacity
      key={documentType.id}
      style={styles.menuItem}
      onPress={() => {
        setShowFullModal(false);
        onSelectType(documentType);
      }}
    >
      <Feather 
        name={documentType.icon} 
        size={20} 
        style={styles.menuItemIcon}
      />
      <Text style={styles.menuItemText}>
        {t(`documents.types.${documentType.id.toLowerCase()}`)}
      </Text>
    </TouchableOpacity>
  );

  return (
    <View style={styles.container}>
      {isExpanded && (
        <View style={styles.menu}>
          {Object.values(DOCUMENT_TYPES).filter((doc) => doc.quickAction).map((documentType) => renderDocumentTypeItem(documentType))}
          <View style={styles.separator} />
          <TouchableOpacity
            style={styles.menuItem}
            onPress={() => {
              setIsExpanded(false);
              setShowFullModal(true);
            }}
          >
            <Feather 
              name="more-horizontal" 
              size={20} 
              style={styles.menuItemIcon}
            />
            <Text style={styles.menuItemText}>
              {t('common.more')}
            </Text>
          </TouchableOpacity>
        </View>
      )}

      <TouchableOpacity
        style={styles.fab}
        onPress={() => setIsExpanded(!isExpanded)}
      >
        <Feather
          name={isExpanded ? 'x' : 'plus'}
          size={24}
          style={styles.fabIcon}
        />
      </TouchableOpacity>

      <Modal
        visible={showFullModal}
        transparent
        animationType="fade"
        onRequestClose={() => setShowFullModal(false)}
      >
        <View style={styles.modalOverlay}>
          <View style={styles.modalContent}>
            <View style={styles.modalHeader}>
              <Text style={styles.modalTitle}>
                {t('documents.select.type')}
              </Text>
              <TouchableOpacity 
                style={styles.closeButton}
                onPress={() => setShowFullModal(false)}
              >
                <Feather 
                  name="x" 
                  size={24} 
                  style={styles.closeIcon}
                />
              </TouchableOpacity>
            </View>

            <Text style={styles.sectionTitle}>{t('documents.sections.income')}</Text>
            {[
              DOCUMENT_TYPES.INVOICE,
              DOCUMENT_TYPES.TAX_INVOICE,
              DOCUMENT_TYPES.TAX_INVOICE_RECEIPT,
              DOCUMENT_TYPES.RECEIPT,
              DOCUMENT_TYPES.INCOME,
            ].map(renderDocumentTypeItem)}

            <Text style={styles.sectionTitle}>{t('documents.sections.expense')}</Text>
            {[
              DOCUMENT_TYPES.CREDIT_INVOICE,
              DOCUMENT_TYPES.EXPENSE,
            ].map(renderDocumentTypeItem)}
          </View>
        </View>
      </Modal>
    </View>
  );
};