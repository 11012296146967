import React, { useRef, useEffect } from 'react';
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  Image,
  Dimensions,
  Animated,
  SafeAreaView,
} from 'react-native';
// RTL is already handled in i18n.js
import { useTheme } from '../../theme/ThemeContext';
import { colors } from '../../theme/colors';
import { useTranslation } from 'react-i18next';
// Using existing he translations from i18n
import { StatusBar } from 'expo-status-bar';
import { Ionicons } from '@expo/vector-icons';

const { width } = Dimensions.get('window');

export default function NativeLandingScreen({ navigation }) {
  const { t, i18n } = useTranslation();
  const { isDark } = useTheme();
  const themeColors = colors[isDark ? 'dark' : 'light'];
  
  // Set the language to Hebrew if not already set
  useEffect(() => {
    if (i18n.language !== 'he') {
      i18n.changeLanguage('he');
    }
  }, []);
  
  // Animation values
  const fadeIn = useRef(new Animated.Value(0)).current;
  const slideUp = useRef(new Animated.Value(50)).current;
  const scaleTitle = useRef(new Animated.Value(0.8)).current;
  
  useEffect(() => {
    // Sequence of animations
    Animated.sequence([
      // First fade in the title with scale
      Animated.timing(fadeIn, {
        toValue: 1,
        duration: 800,
        useNativeDriver: true,
      }),
      // Then animate the buttons sliding up
      Animated.timing(slideUp, {
        toValue: 0,
        duration: 600,
        useNativeDriver: true,
      }),
    ]).start();
    
    // Animate title scale separately
    Animated.timing(scaleTitle, {
      toValue: 1,
      duration: 1000,
      useNativeDriver: true,
    }).start();
  }, []);

  return (
    <SafeAreaView style={[styles.container, { backgroundColor: themeColors.background }]}>
      <StatusBar style={isDark ? 'light' : 'dark'} />
      
      <View style={styles.header}>
        {/* Logo and title section with animation */}
        <Animated.View 
          style={[
            styles.titleContainer, 
            { 
              opacity: fadeIn,
              transform: [{ scale: scaleTitle }]
            }
          ]}
        >
          <Image 
            source={require('../../../assets/icon.png')} 
            style={styles.logo} 
            resizeMode="contain"
          />
          <Text style={[styles.title, { color: themeColors.text }]}>
            {t('app.name')}
          </Text>
          <Text style={[styles.subtitle, { color: themeColors.textSecondary }]}>
            {t('app.tagline')}
          </Text>
        </Animated.View>
      </View>
      
      <View style={styles.featureSection}>
        <View style={styles.featureRow}>
          <FeatureItem 
            icon="calendar-outline" 
            title={t('features.schedule')}
            description={t('features.scheduleDesc')}
            color={themeColors.primary}
            themeColors={themeColors}
          />
          
          <FeatureItem 
            icon="people-outline" 
            title={t('features.students')}
            description={t('features.studentsDesc')}
            color={themeColors.success}
            themeColors={themeColors}
          />
          
          <FeatureItem 
            icon="stats-chart-outline" 
            title={t('features.reports')}
            description={t('features.reportsDesc')}
            color={themeColors.warning}
            themeColors={themeColors}
          />
        </View>
      </View>
      
      {/* Action buttons */}
      <Animated.View 
        style={[
          styles.buttonContainer, 
          { 
            opacity: fadeIn,
            transform: [{ translateY: slideUp }]
          }
        ]}
      >
        <TouchableOpacity
          style={[styles.button, { backgroundColor: themeColors.primary }]}
          onPress={() => navigation.navigate('Login')}
        >
          <Text style={styles.buttonText}>{t('auth.landing.login')}</Text>
        </TouchableOpacity>
        
        <TouchableOpacity
          style={[styles.secondaryButton, { borderColor: themeColors.primary }]}
          onPress={() => navigation.navigate('Register')}
        >
          <Text style={[styles.secondaryButtonText, { color: themeColors.primary }]}>
            {t('auth.landing.register')}
          </Text>
        </TouchableOpacity>
      </Animated.View>
    </SafeAreaView>
  );
}

const FeatureItem = ({ icon, title, description, color, themeColors }) => {
  return (
    <View style={styles.featureItem}>
      <View style={[styles.iconContainer, { backgroundColor: `${color}20` }]}>
        <Ionicons name={icon} size={24} color={color} />
      </View>
      <View style={styles.featureTextContainer}>
        <Text style={[styles.featureTitle, { color: themeColors.text, textAlign: 'center' }]}>{title}</Text>
        <Text style={[styles.featureDescription, { color: themeColors.textSecondary, textAlign: 'center' }]}>
          {description}
        </Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    direction: 'rtl',
  },
  header: {
    flex: 2,
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 20,
  },
  titleContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  logo: {
    width: 100,
    height: 100,
    marginBottom: 10,
  },
  title: {
    fontSize: 28,
    fontWeight: 'bold',
    marginBottom: 6,
    textAlign: 'center',
  },
  subtitle: {
    fontSize: 14,
    textAlign: 'center',
    paddingHorizontal: 20,
    marginBottom: 10,
  },
  featureSection: {
    flex: 3,
    padding: 15,
  },
  featureRow: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    alignItems: 'flex-start',
  },
  featureItem: {
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: 16,
    width: '100%',
    paddingHorizontal: 10,
  },
  iconContainer: {
    width: 50,
    height: 50,
    borderRadius: 25,
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 8,
  },
  featureTextContainer: {
    width: '100%',
    alignItems: 'center',
  },
  featureTitle: {
    fontSize: 16,
    fontWeight: '700',
    marginBottom: 4,
  },
  featureDescription: {
    fontSize: 13,
    lineHeight: 18,
  },
  buttonContainer: {
    padding: 16,
    paddingBottom: 24,
  },
  button: {
    height: 48,
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 8,
  },
  buttonText: {
    color: '#FFFFFF',
    fontSize: 15,
    fontWeight: '600',
  },
  secondaryButton: {
    height: 48,
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
  },
  secondaryButtonText: {
    fontSize: 15,
    fontWeight: '600',
  },
});