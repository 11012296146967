import 'react-native-gesture-handler';
import React from 'react';
import {
  View,
  Text,
  TouchableOpacity
} from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { Ionicons } from '@expo/vector-icons';
import { StatusBar } from 'expo-status-bar';
import { useTranslation } from 'react-i18next';
import './src/i18n/i18n';
import { navigationRef } from './src/navigation/navigationRef';

// Providers
import { PaperProvider } from 'react-native-paper';
import { LanguageProvider } from './src/context/LanguageContext';
import { StudentProvider } from './src/context/StudentContext';
import { InstructorProvider } from './src/context/InstructorContext';
import { SupplierProvider } from './src/context/SupplierContext';
import { VehicleProvider } from './src/context/VehicleContext';
import { EventProvider } from './src/context/EventContext';
import { PaymentProvider } from './src/context/PaymentContext';
import { AccountingProvider } from './src/context/AccountingContext';
import { DocumentFormProvider } from './src/context/DocumentFormContext';
import { AuthProvider } from './src/context/AuthContext';
import { ThemeProvider, useTheme } from './src/theme/ThemeContext';
import { colors } from './src/theme/colors';

// Screens
import LandingScreen from './src/screens/landing/LandingScreen';
import LoginScreen from './src/screens/auth/LoginScreen';
import RegisterScreen from './src/screens/auth/RegisterScreen';
import DashboardScreen from './src/screens/main/DashboardScreen';
import StudentsScreen from './src/screens/main/StudentsScreen';
import InstructorsScreen from './src/screens/main/InstructorsScreen';
import SuppliersScreen from './src/screens/main/SuppliersScreen';
import PaymentsScreen from './src/screens/main/PaymentsScreen';
import ReportExpenseScreen from './src/screens/main/ReportExpenseScreen';
import IncomeReportScreen from './src/screens/reports/IncomeReportScreen';
import BalanceReportScreen from './src/screens/reports/BalanceReportScreen';
import ExpenseReportScreen from './src/screens/reports/ExpenseReportScreen';
import StudentReportScreen from './src/screens/reports/StudentReportScreen';
import VehiclesScreen from './src/screens/main/VehiclesScreen';
import SettingsScreen from './src/screens/main/SettingsScreen';
import TimelineCalendar from './src/screens/main/TimelineCalendar';
import AccountingOverviewScreen from './src/screens/main/AccountingOverviewScreen';

const Stack = createNativeStackNavigator();
const Tab = createBottomTabNavigator();

const MenuItem = ({ icon, title, onPress, color }) => {
  const { isDark } = useTheme();
  const themeColors = colors[isDark ? 'dark' : 'light'];

  return (
    <TouchableOpacity
      onPress={onPress}
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: themeColors.card,
        borderRadius: 12,
        marginBottom: 12,
        padding: 16,
        elevation: 2,
        shadowColor: "#000",
        shadowOffset: {
          width: 0,
          height: 2,
        },
        shadowOpacity: 0.1,
        shadowRadius: 3.84,
      }}
    >
      {/* Icon Container */}
      <View
        style={{
          backgroundColor: `${color}20`, // 20 is hex for 12% opacity
          padding: 10,
          borderRadius: 10,
          marginRight: 16,
        }}
      >
        <Ionicons
          name={icon}
          size={24}
          color={color}
        />
      </View>

      {/* Title */}
      <View style={{ flex: 1 }}>
        <Text
          style={{
            fontSize: 16,
            fontWeight: '500',
            color: themeColors.text,
          }}
        >
          {title}
        </Text>
      </View>

      {/* Chevron */}
      <Ionicons
        name="chevron-forward"
        size={20}
        color={themeColors.textSecondary}
      />
    </TouchableOpacity>
  );
};

const DirectoryStack = createNativeStackNavigator();
function DirectoryNavigator() {
  const { t } = useTranslation();

  return (
    <DirectoryStack.Navigator>
      <DirectoryStack.Screen
        name="DirectoryMenu"
        component={DirectoryMenuScreen}
        options={{
          headerTitle: t('navigation.directory'),
        }}
      />

      <DirectoryStack.Screen
        name="Students"
        component={StudentsScreen}
        options={{
          headerTitle: t('navigation.students'),
        }}
      />
      <DirectoryStack.Screen
        name="Instructors"
        component={InstructorsScreen}
        options={{ headerTitle: t('navigation.instructors') }}
      />
      <DirectoryStack.Screen
        name="Vehicles"
        component={VehiclesScreen}
        options={{ headerTitle: t('navigation.vehicles') }}
      />
      <DirectoryStack.Screen
        name="Suppliers"
        component={SuppliersScreen}
        options={{ headerTitle: t('navigation.suppliers') }}
      />
    </DirectoryStack.Navigator>
  );
}

const DirectoryMenuScreen = ({ navigation }) => {
  const { isDark } = useTheme();
  const themeColors = colors[isDark ? 'dark' : 'light'];
  const { t } = useTranslation();

  return (
    <View style={{
      flex: 1,
      padding: 20,
      backgroundColor: themeColors.background
    }}>
      <MenuItem
        icon="people-outline"
        title={t('navigation.students')}
        onPress={() => navigation.navigate('Students')}
        color="#4CAF50"  // Green for students
      />

      <MenuItem
        icon="school-outline"
        title={t('navigation.instructors')}
        onPress={() => navigation.navigate('Instructors')}
        color="#2196F3"  // Blue for instructors
      />

      <MenuItem
        icon="car-outline"
        title={t('navigation.vehicles')}
        onPress={() => navigation.navigate('Vehicles')}
        color="#FF9800"  // Orange for vehicles
      />

      <MenuItem
        icon="business-outline"
        title={t('navigation.suppliers')}
        onPress={() => navigation.navigate('Suppliers')}
        color="#9C27B0"  // Purple for suppliers
      />
    </View>
  );
};

const AccountingStack = createNativeStackNavigator();
function AccountingNavigator() {
  const { t } = useTranslation();

  return (
    <AccountingStack.Navigator>
      <AccountingStack.Screen
        name="AccountingMenu"
        component={AccountingMenuScreen}
        options={{
          headerTitle: t('navigation.accounting')
        }}
      />
      <AccountingStack.Screen
        name="Overview"
        component={AccountingOverviewScreen}
        options={{ headerTitle: t('navigation.overview') }}
      />
      <AccountingStack.Screen
        name="Documents"
        component={PaymentsScreen}
        options={{ headerTitle: t('navigation.documents') }}
      />
      <AccountingStack.Screen
        name="AccountingReports"
        component={ReportsMenuScreen}
        options={{ headerTitle: t('navigation.reports') }}
      />
      <AccountingStack.Screen
        name="GenerateInvoice"
        component={PaymentsScreen}
        options={{ headerTitle: t('navigation.generateInvoice') }}
      />
      <AccountingStack.Screen
        name="GenerateReceipt"
        component={PaymentsScreen}
        options={{ headerTitle: t('navigation.generateReceipt') }}
      />
      <AccountingStack.Screen
        name="ReportExpense"
        component={ReportExpenseScreen}
        options={{ headerTitle: t('navigation.reportExpense') }}
      />
      {/* Add Report screens */}
      <AccountingStack.Screen
        name="IncomeReport"
        component={IncomeReportScreen}
        options={{ headerTitle: t('navigation.reportNames.income') }}
      />
      <AccountingStack.Screen
        name="ExpenseReport"
        component={ExpenseReportScreen}
        options={{ headerTitle: t('navigation.reportNames.expense') }}
      />
      <AccountingStack.Screen
        name="BalanceReport"
        component={BalanceReportScreen}
        options={{ headerTitle: t('navigation.reportNames.balance') }}
      />
      <AccountingStack.Screen
        name="VehicleReport"
        component={PaymentsScreen}
        options={{ headerTitle: t('navigation.reportNames.vehicle') }}
      />
      <AccountingStack.Screen
        name="StudentTestsReport"
        component={PaymentsScreen}
        options={{ headerTitle: t('navigation.reportNames.studentTests') }}
      />
    </AccountingStack.Navigator>
  );
}

const ReportsMenuScreen = ({ navigation }) => {
  const { isDark } = useTheme();
  const themeColors = colors[isDark ? 'dark' : 'light'];
  const { t } = useTranslation();

  return (
    <View style={{
      flex: 1,
      padding: 20,
      backgroundColor: themeColors.background
    }}>
      <MenuItem
        icon="cash-outline"
        title={t('navigation.reportNames.income')}
        onPress={() => navigation.navigate('IncomeReport')}
        color="#4CAF50"  // Green
      />

      <MenuItem
        icon="trending-down-outline"
        title={t('navigation.reportNames.expense')}
        onPress={() => navigation.navigate('ExpenseReport')}
        color="#F44336"  // Red
      />

      <MenuItem
        icon="bar-chart-outline"
        title={t('navigation.reportNames.balance')}
        onPress={() => navigation.navigate('BalanceReport')}
        color="#2196F3"  // Blue
      />

      <MenuItem
        icon="car-outline"
        title={t('navigation.reportNames.vehicle')}
        onPress={() => navigation.navigate('VehicleReport')}
        color="#FF9800"  // Orange
      />

      <MenuItem
        icon="school-outline"
        title={t('navigation.reportNames.studentTests')}
        onPress={() => navigation.navigate('StudentTestsReport')}
        color="#9C27B0"  // Purple
      />
    </View>
  );
};

const AccountingMenuScreen = ({ navigation }) => {
  const { isDark } = useTheme();
  const themeColors = colors[isDark ? 'dark' : 'light'];
  const { t } = useTranslation();

  return (
    <View style={{
      flex: 1,
      padding: 20,
      backgroundColor: themeColors.background
    }}>
      <MenuItem
        icon="bar-chart-outline"
        title={t('navigation.overview')}
        onPress={() => navigation.navigate('Overview')}
        color="#4CAF50"  // Green
      />

      <MenuItem
        icon="documents-outline"
        title={t('navigation.documents')}
        onPress={() => navigation.navigate('Documents')}
        color="#2196F3"  // Blue
      />

      <MenuItem
        icon="stats-chart-outline"
        title={t('navigation.reports')}
        onPress={() => navigation.navigate('AccountingReports')}
        color="#FF9800"  // Orange
      />
    </View>
  );
};

function MainTabs() {
  const { isDark } = useTheme();
  const themeColors = colors[isDark ? 'dark' : 'light'];
  const { t } = useTranslation();

  return (
    <Tab.Navigator
      screenOptions={({ route }) => ({
        unmountOnBlur: false,
        tabBarIcon: ({ focused, color, size }) => {
          let iconName;
          switch (route.name) {
            case 'Dashboard':
              iconName = focused ? 'home' : 'home-outline';
              break;
            case 'Calendar':
              iconName = focused ? 'calendar' : 'calendar-outline';
              break;
            case 'Directory':
              iconName = focused ? 'folder' : 'folder-outline';
              break;
            case 'Accounting':
              iconName = focused ? 'calculator' : 'calculator-outline';
              break;
            case 'Settings':
              iconName = focused ? 'settings' : 'settings-outline';
              break;
            default:
              iconName = 'ellipse';
          }
          return <Ionicons name={iconName} size={size} color={color} />;
        },
        tabBarActiveTintColor: themeColors.primary,
        tabBarInactiveTintColor: themeColors.textSecondary,
        tabBarStyle: {
          backgroundColor: themeColors.card,
          borderTopColor: themeColors.border,
        },
      })}
    >
      <Tab.Screen
        name="Dashboard"
        component={DashboardScreen}
        options={{
          title: t('navigation.dashboard')
        }}
      />
      <Tab.Screen
        name="Calendar"
        component={TimelineCalendar}
        options={{
          title: t('navigation.calendar')
        }}
      />
      <Tab.Screen
        name="Directory"
        component={DirectoryNavigator}
        options={{
          headerShown: false,
          title: t('navigation.directory')
        }}
      />
      <Tab.Screen
        name="Accounting"
        component={AccountingNavigator}
        options={{
          headerShown: false,
          title: t('navigation.accounting')
        }}
      />
      <Tab.Screen
        name="Settings"
        component={SettingsScreen}
        options={{
          title: t('navigation.settings')
        }}
      />
    </Tab.Navigator>
  );
}

function AppContent() {
  const { isDark } = useTheme();
  const themeColors = colors[isDark ? 'dark' : 'light'];
  const { t } = useTranslation();

  const navigationTheme = {
    dark: isDark,
    colors: {
      primary: themeColors.primary,
      background: themeColors.background,
      card: themeColors.card,
      text: themeColors.text,
      border: themeColors.border,
      notification: themeColors.primary,
    },
  };

  return (
    <>
      <StatusBar style={isDark ? 'light' : 'dark'} />
      <NavigationContainer ref={navigationRef} theme={navigationTheme}>
        <Stack.Navigator initialRouteName="Landing">
          <Stack.Screen
            name="Landing"
            component={LandingScreen}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="Login"
            component={LoginScreen}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="Register"
            component={RegisterScreen}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="MainApp"
            component={MainTabs}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="Settings"
            component={SettingsScreen}
            options={({ navigation }) => ({
              title: t('navigation.settings'),
              headerLeft: () => (
                <TouchableOpacity
                  onPress={() => navigation.goBack()}
                  style={{ marginLeft: 16, width: 40, height: 40, justifyContent: 'center' }}
                >
                  <Ionicons
                    name="arrow-back"
                    size={24}
                    color={themeColors.text}
                  />
                </TouchableOpacity>
              ),
            })}
          />
          <Stack.Screen
            name="StudentReport"
            component={StudentReportScreen}
            options={{ headerTitle: t('navigation.reportNames.student') }}
          />
        </Stack.Navigator>
      </NavigationContainer>
    </>
  );
}

export default function App() {
  return (
    <AuthProvider>
      <ThemeProvider>
        <LanguageProvider>
          <PaymentProvider>
            <AccountingProvider>
              <DocumentFormProvider>
                <SupplierProvider>
                  <StudentProvider>
                    <InstructorProvider>
                      <VehicleProvider>
                        <EventProvider>
                          <PaperProvider>
                            <SafeAreaProvider>
                              <AppContent />
                            </SafeAreaProvider>
                          </PaperProvider>
                        </EventProvider>
                      </VehicleProvider>
                    </InstructorProvider>
                  </StudentProvider>
                </SupplierProvider>
              </DocumentFormProvider>
            </AccountingProvider>
          </PaymentProvider>
        </LanguageProvider>
      </ThemeProvider>
    </AuthProvider>
  );
}