export const en = {
    app: {
        name: 'iDrive',
        tagline: 'Your complete driving school management solution'
    },
    features: {
        schedule: "Smart Scheduling",
        scheduleDesc: "Effortlessly manage lessons and appointments",
        students: "Student Management",
        studentsDesc: "Keep track of student progress and data",
        reports: "Detailed Reports",
        reportsDesc: "Financial insights and business analytics"
    },
    navigation: {
        dashboard: "Dashboard",
        calendar: "Calendar",
        directory: "Directory",
        accounting: "Accounting",
        settings: "Settings",
        students: "Students",
        instructors: "Instructors",
        vehicles: "Vehicles",
        suppliers: "Suppliers",
        payments: "Payments",
        reports: "Reports",
        generateInvoice: "Generate Invoice",
        generateReceipt: "Generate Receipt",
        reportExpense: "Report Expense",
        overview: "Overview",
        documents: "Documents",
        reportNames: {
            income: "Income Report",
            expense: "Expense Report",
            balance: "Balance Report",
            vehicle: "Vehicle Report",
            studentTests: "Student Tests Report",
            student: "Student Report",
        }
    },
    common: {
        welcome: 'Welcome back!',
        seeAll: 'See All',
        noUpcomingLessons: 'No upcoming lessons',
        cancel: 'Cancel',
        confirm: 'Confirm',
        logout: 'Logout',
        success: 'Success',
        warning: 'Warning',
        error: 'Error',
        loading: 'Loading...',
        retry: 'Try Again',
        networkError: 'Network Error. Please check your connection.',
        somethingWentWrong: 'Something went wrong. Please try again.',
        create: 'Create',
        update: 'Update',
        save: 'Save',
        delete: 'Delete',
        edit: 'Edit',
        validationError: "Validation Error",
        more: "More",
        search: "Search"
    },
    auth: {
        landing: {
            login: "Login",
            register: "Register",
            webMessage: "Full app functionality coming soon to web"
        },
        login: {
            title: "Login",
            error: "Login Error",
            fields: {
                email: "Email",
                password: "Password"
            },
            buttons: {
                login: "Login",
                register: "Don't have an account? Register",
                forgotPassword: "Forgot Password?"
            },
            errors: {
                invalidCredentials: "Invalid email or password",
                requiredFields: "Please enter both email and password",
                networkError: "Unable to connect to server. Please check your connection."
            },
            success: "Logged in successfully"
        },
        register: {
            title: "Register",
            fields: {
                firstName: "First Name",
                lastName: "Last Name",
                email: "Email",
                phone: "Phone",
                password: "Password",
                confirmPassword: "Confirm Password",
                authorizedBusinessNumber: "Authorized Business Number"
            },
            buttons: {
                register: "Register",
                haveAccount: "Already have an account? Login"
            },
            error: "Registration Error",
            errors: {
                requiredFields: "Please fill in all required fields",
                passwordMismatch: "Passwords do not match",
                passwordLength: "Password must be at least 6 characters long",
                invalidEmail: "Please enter a valid email address",
                emailExists: "This email is already registered"
            },
            success: "Registration successful"
        },
        logout: {
            confirm: "Are you sure you want to logout?",
            buttons: {
                confirm: "Yes, Logout",
                cancel: "Cancel"
            },
            success: "Logged out successfully"
        }
    },
    dashboard: {
        stats: {
            activeStudents: 'Active Students',
            lessonsToday: 'Lessons Today',
            totalHours: 'Total Hours',
            doubleLessons: 'Double Lessons',
        },
        periods: {
            thisWeek: 'This Week',
            thisMonth: 'This Month',
            lessonsCount: '{{count}} lessons',
        },
        upcoming: {
            title: 'Upcoming Lessons',
            duration: '{{duration}} min',
        },
    },
    calendar: {
        title: 'Calendar',
        addNew: 'Schedule New Lesson',
        editLesson: 'Edit Lesson',
        available: 'Available',
        summary: {
            withStudents: 'Duration: {{duration}}min\nStudents: {{students}}',
            noStudents: 'Duration: {{duration}}min',
        },
        delete: {
            title: 'Delete Lesson',
            confirm: 'Are you sure you want to delete this lesson?'
        },
        fields: {
            title: 'Enter lesson title',
            vehicle: "Vehicle",
            noVehicle: "No Vehicle",
            duration: {
                single: 'Single (40 min)',
                double: 'Double (80 min)'
            },
            students: 'Students (up to {{count}})',
            addStudent: 'Add Student',
            searchStudents: 'Search students...',
            addStudentSingle: 'Add Student (Max 1)',
            addStudentDouble: 'Add Student ({{count}} remaining)',
        },
        buttons: {
            cancel: 'Cancel',
            delete: 'Delete',
            schedule: 'Schedule',
            update: 'Update',
            edit: 'Edit Lesson'
        },
        errors: {
            titleRequired: 'Please enter an event title'
        },
        success: {
            scheduled: 'Lesson scheduled successfully!',
            updated: 'Lesson updated successfully!'
        },
        viewTypes: {
            month: 'Month',
            week: 'Week',
            day: 'Day'
        },
        monthNames: {
            1: 'January',
            2: 'February',
            3: 'March',
            4: 'April',
            5: 'May',
            6: 'June',
            7: 'July',
            8: 'August',
            9: 'September',
            10: 'October',
            11: 'November',
            12: 'December'
        },
        monthNamesShort: {
            1: 'Jan',
            2: 'Feb',
            3: 'Mar',
            4: 'Apr',
            5: 'May',
            6: 'Jun',
            7: 'Jul',
            8: 'Aug',
            9: 'Sep',
            10: 'Oct',
            11: 'Nov',
            12: 'Dec'
        },
        dayNames: {
            1: 'Sunday',
            2: 'Monday',
            3: 'Tuesday',
            4: 'Wednesday',
            5: 'Thursday',
            6: 'Friday',
            7: 'Saturday'
        },
        dayNamesShort: {
            1: 'Sun',
            2: 'Mon',
            3: 'Tue',
            4: 'Wed',
            5: 'Thu',
            6: 'Fri',
            7: 'Sat'
        },
        today: 'Today',
        alerts: {
            studentLimit: {
                title: 'Student Limit Reached',
                singleLesson: "Single lessons can have up to 1 student",
                doubleLesson: "Double lessons can have up to 2 students",
            }
        },
        search: {
            noResults: 'No students found'
        },
    },
    settings: {
        appearance: 'Appearance',
        darkMode: 'Dark Mode',
        logout: 'Logout',
        logoutTitle: 'Logout',
        logoutMessage: 'Are you sure you want to logout?',
        language: {
            title: 'Language',
            changeTitle: 'Change Language',
            changeMessage: 'Are you sure you want to change the language? The app will reload to apply changes.',
            restartTitle: 'Restart Required',
            restartMessage: 'Please restart the app to apply the language changes.',
        },
        account: {
            title: 'Account',
            editProfile: 'Edit Profile',
            changePassword: 'Change Password',
            notifications: 'Notifications',
        },
        app: {
            title: 'App',
            about: 'About',
            help: 'Help',
            privacyPolicy: 'Privacy Policy'
        },
    },
    accounting: {
        overview: {
            title: "Accounting Overview",
            openInvoices: "Open Invoices",
            outstandingAmount: "Outstanding Amount",
            unpaidStudents: "Unpaid Students",
            thisMonth: "This Month",
            thisWeek: "This Week",
            income: "Income",
            invoices: "Invoices",
            monthlyExpenses: "Monthly Expenses",
            monthlyDirectIncome: "Monthly Direct Income"
        }
    },
    documents: {
        sections: {
            income: "Income",
            expense: "Expense"
        },
        types: {
            invoice: "Invoice",
            tax_invoice: "Tax Invoice",
            tax_invoice_receipt: "Tax Invoice Receipt",
            receipt: "Receipt",
            credit_invoice: "Credit Invoice",
            expense: "Expense",
            income: "Income",
        },
        status: {
            active: "Active",
            paid: "Paid",
            cancelled: "Cancelled"
        },
        actions: {
            title: "Document Actions",
            issueReceipt: "Issue Receipt",
            issueTaxInvoiceReceipt: "Issue Tax Invoice Receipt",
            issueCreditInvoice: "Issue Credit Invoice",
            cancel: "Cancel Document",
            viewHistory: "View History",
            viewRelated: "View Related Documents",
            viewPdf: "View PDF"
        },
        create: {
            tax_invoice: "Create Tax Invoice",
            tax_invoice_receipt: "Create Tax Invoice Receipt",
            receipt: "Create Receipt",
            credit_invoice: "Create Credit Invoice",
            expense: "Create Expense"
        },
        student: "Student",
        supplier: "Supplier",
        selectEntity: "Select...",
        selectStudent: "Select Student",
        selectSupplier: "Select Supplier",
        searchStudents: "Search Students",
        searchSuppliers: "Search Suppliers",
        amount: "Amount",
        description: "Description",
        descriptionPlaceholder: "Enter description...",
        enterAmount: "Enter amount",
        enterDescription: "Enter description",
        selectPaymentMethod: "Select Payment Method",
        selectReference: "Select Reference Document",
        noDescription: "No description",
        search: {
            placeholder: "Search documents...",
            noResults: "No documents found matching your search",
            noDocuments: "No documents added yet",
        },
        success: {
            created: "Document created successfully",
            cancelled: "Document cancelled successfully",
        },
        errors: {
            selectEntity: "Please select {{type}}",
            validAmount: "Please enter a valid amount",
            requiredField: "This field is required",
            alreadyPaid: "Document is already paid",
            alreadyCancelled: "Document is already cancelled",
            "cancellation": "Failed to cancel document",
        },
        confirmation: {
            cancel: "Are you sure you want to cancel document {{number}}?",
        },
        accounts: {
            income: "Income Account",
            expense: "Expense Account",
            cashier: "Cashier Account",
            vat: "VAT Account",
            selectIncome: "Select Income Account",
            selectExpense: "Select Expense Account",
            selectCashier: "Select Cashier Account",
            selectVat: "Select VAT Account",
            search: "Search Accounts"
        },
        referenceDocument: "Reference Document",
        selectReference: "Select Reference Document",
        searchDocuments: "Search Documents",
        noValidReferences: "No valid reference documents found",
        documentNumber: "Document #{{number}}",
        manualReferenceNumber: "Manual Invoice Number",
        enterManualReference: "Enter manual invoice number",
        validation: {
            manualReferenceRequired: "Manual invoice number is required",
            invalidAccount: "Invalid account selected",
            invalidReference: "Invalid reference document",
            accountRequired: "Account selection is required",
            referenceRequired: "Reference document is required",
            paymentRequired: "Payment details are required",
            amountMismatch: "Payment amount must match document total",
            invalidPaymentMethod: "Invalid payment method",
            invalidBankDetails: "Invalid bank details",
            invalidCardDetails: "Invalid card details"
        },
        totals: {
            beforeDiscount: "Total Before Discount",
            discount: "Discount",
            afterDiscount: "Total After Discount",
            vat: "VAT",
            total: "Total Amount"
        },
        select: {
            student: "Select {{entityType}}",
            supplier: "Select {{entityType}}",
            type: "Select Document Type",
        },
        lineItems: {
            title: "Title",
            description: "Description",
            descriptionPlaceholder: "Enter Description Here",
            quantity: "Quantity",
            unitPrice: "Unit Price",
            discount: "Discount",
            subtotal: "Subtotal",
            vat: "VAT",
            total: "Total",
            addItem: "Add Line Item",
            itemNumber: "Item {{number}}",
        },
        cancel: {
            title: "Cancel Document",
            message: "Are you sure you want to cancel document #{{number}}?",
            confirm: "Cancel Document",
            reasonTitle: "Cancellation Reason",
            reasonMessage: "Please provide a reason for cancellation",
            reasonPlaceholder: "Enter the reason for cancellation"
        },
        payment: {
            amount: "Amount",
            methods: {
                cash: "Cash",
                check: "Check",
                credit_card: "Credit Card",
                bank_transfer: "Bank Transfer",
                bit: "Bit",
                paybox: "Paybox",
            },
            bankNumber: "Bank Number",
            branchNumber: "Branch Number",
            accountNumber: "Account Number",
            checkNumber: "Check Number",
            creditCardCompany: "Card Company",
            dealType: "Deal Type",
            dealTypes: {
                regular: "Regular",
                installments: "Installments",
            }
        },
        
    },
    students: {
        title: 'Students',
        addNew: 'Add New Student',
        editStudent: 'Edit Student',
        search: {
            search: "Search students...",
            noSearchResults: "No students found matching your search",
            noStudents: "No students added yet",
        },
        delete: {
            title: 'Delete Student',
            confirm: 'Are you sure you want to delete {{name}}?'
        },
        fields: {
            firstName: 'First Name',
            lastName: 'Last Name',
            phone: 'Phone Number',
            email: 'Email',
            licenseType: 'License Type',
            notes: 'Notes'
        },
        buttons: {
            cancel: 'Cancel',
            save: 'Save',
            update: 'Update',
            delete: 'Delete'
        },
        errors: {
            nameRequired: 'First and last name are required'
        },
        success: {
            added: 'Student added successfully',
            updated: 'Student updated successfully',
            deleted: 'Student deleted successfully'
        }
    },
    vehicles: {
        search: "Search vehicles...",
        noSearchResults: "No vehicles found matching your search",
        noVehicles: "No vehicles added yet",
        addNew: "Add New Vehicle",
        editVehicle: "Edit Vehicle",
        sections: {
            basic: "Basic Information",
            insurance: "Insurance",
            maintenance: "Maintenance",
            notes: "Notes"
        },
        fields: {
            licensePlate: "License Plate",
            make: "Make",
            model: "Model",
            year: "Year",
            transmission: "Transmission",
            insuranceProvider: "Insurance Provider",
            policyNumber: "Policy Number",
            insuranceExpiry: "Insurance Expiry Date",
            odometer: "Odometer Reading",
            lastService: "Last Service Date",
            nextService: "Next Service Due",
            notes: "Notes",
            licenseTypes: "License Types",
            features: "Features",
            status: "Status",
            registrationExpiry: "Registration Expiry Date"
        },
        status: {
            active: "Active",
            maintenance: "In Maintenance",
            outOfService: "Out of Service"
        },
        transmission: {
            automatic: "Automatic",
            manual: "Manual"
        },
        features: {
            dualControls: "Dual Controls",
            parkingSensors: "Parking Sensors",
            camera: "Reverse Camera",
            abs: "ABS",
            tractionControl: "Traction Control",
            dashcam: "Dashcam"
        },
        success: {
            added: "Vehicle added successfully",
            updated: "Vehicle updated successfully",
            deleted: "Vehicle deleted successfully"
        },
        errors: {
            requiredFields: "Please fill in all required fields",
            invalidYear: "Please enter a valid year",
            yearTooLow: "Year must be 1900 or later",
            yearTooHigh: "Year cannot be later than next year",
            invalidOdometer: "Please enter a valid odometer reading",
            odometerTooLow: "Odometer reading cannot be negative"
        },
        delete: {
            title: "Delete Vehicle",
            confirm: "Are you sure you want to delete vehicle {{plate}}?"
        },
        warnings: {
            insuranceExpiring: "⚠️ Insurance expiring soon",
            registrationExpiring: "⚠️ Registration expiring soon"
        },
        buttons: {
            delete: "Delete",
            cancel: "Cancel",
        },
    },
    instructors: {
        title: "Instructors",
        addNew: "Add New Instructor",
        editInstructor: "Edit Instructor",
        search: {
            search: "Search instructors...",
            noSearchResults: "No instructors found matching your search",
            noInstructors: "No instructors added yet"
        },
        fields: {
            firstName: "First Name",
            lastName: "Last Name",
            phone: "Phone",
            email: "Email",
            licenseTypes: "License Types",
            startDate: "Start Date",
            status: "Status",
            hourlyRate: "Hourly Rate",
            notes: "Notes"
        },
        status: {
            active: "Active",
            inactive: "Inactive",
            onLeave: "On Leave"
        },
        buttons: {
            save: "Save",
            update: "Update",
            cancel: "Cancel",
            delete: "Delete"
        },
        success: {
            added: "Instructor added successfully",
            updated: "Instructor updated successfully",
            deleted: "Instructor deleted successfully"
        },
        errors: {
            nameRequired: "First and last name are required",
            emailRequired: "Email is required",
            invalidEmail: "Invalid email format"
        },
        delete: {
            title: "Delete Instructor",
            confirm: "Are you sure you want to delete {{name}}?"
        }
    },
    suppliers: {
        title: "Suppliers",
        addNew: "Add New Supplier",
        editSupplier: "Edit Supplier",
        search: {
            search: "Search suppliers...",
            noSearchResults: "No suppliers found matching your search",
            noSuppliers: "No suppliers added yet"
        },
        fields: {
            name: "Company Name",
            contactPerson: "Contact Person",
            phone: "Phone",
            email: "Email",
            address: "Address",
            category: "Category",
            paymentTerms: "Payment Terms",
            authorizedBusinessNumber: "Tax ID",
            status: "Status",
            notes: "Notes"
        },
        status: {
            active: "Active",
            inactive: "Inactive"
        },
        buttons: {
            save: "Save",
            update: "Update",
            cancel: "Cancel",
            delete: "Delete"
        },
        success: {
            added: "Supplier added successfully",
            updated: "Supplier updated successfully",
            deleted: "Supplier deleted successfully"
        },
        errors: {
            nameRequired: "Company name is required",
            emailRequired: "Email is required",
            invalidEmail: "Invalid email format"
        },
        delete: {
            title: "Delete Supplier",
            confirm: "Are you sure you want to delete {{name}}?"
        }
    },
};