import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { I18nManager } from 'react-native';
import * as Localization from 'expo-localization';
import moment from 'moment';
import 'moment/locale/he';
import { en } from './translations/en';
import { he } from './translations/he';

// Initialize moment locale
moment.locale(Localization.locale.split('-')[0]);

i18n
 .use(initReactI18next)
 .init({
   compatibilityJSON: 'v3',
   resources: {
     en: {
       translation: en,
     },
     he: {
       translation: he,
     },
   },
   lng: Localization.locale.split('-')[0],
   fallbackLng: 'en',
   interpolation: {
     escapeValue: false,
   },
 });

// Update moment locale when language changes  
i18n.on('languageChanged', (lng) => {
 moment.locale(lng);
});

// Handle RTL for Hebrew
I18nManager.allowRTL(true);
I18nManager.forceRTL(i18n.language === 'he');

export default i18n;