export const he = {
    app: {
        name: 'iDrive',
        tagline: 'הפתרון המושלם לניהול בית ספר לנהיגה'
    },
    features: {
        schedule: "תזמון חכם",
        scheduleDesc: "ניהול קל של שיעורים ופגישות",
        students: "ניהול תלמידים",
        studentsDesc: "מעקב אחר התקדמות ונתוני התלמידים",
        reports: "דוחות מפורטים",
        reportsDesc: "תובנות פיננסיות וניתוח עסקי"
    },
    navigation: {
        dashboard: "לוח בקרה",
        calendar: "יומן",
        directory: "ספרייה",
        accounting: "הנהלת חשבונות",
        settings: "הגדרות",
        students: "תלמידים",
        instructors: "מדריכים",
        vehicles: "כלי רכב",
        suppliers: "ספקים",
        payments: "תשלומים",
        reports: "דוחות",
        generateInvoice: "הפקת חשבונית",
        generateReceipt: "הפקת קבלה",
        reportExpense: "דיווח הוצאה",
        overview: "סקירה כללית",
        documents: "מסמכים",
        reportNames: {
            income: "דוח הכנסות",
            expense: "דוח הוצאות",
            balance: "דוח מאזן",
            vehicle: "דוח רכב",
            studentTests: "דוח מבחני תלמידים",
            student: "דוח תלמיד"
        }
    },
    common: {
        welcome: 'ברוך שובך!',
        seeAll: 'ראה הכל',
        noUpcomingLessons: 'אין שיעורים קרובים',
        cancel: 'ביטול',
        confirm: 'אישור',
        logout: 'התנתק',
        success: 'הצלחה',
        warning: 'אזהרה',
        error: 'שגיאה',
        loading: 'טוען...',
        retry: 'נסה שוב',
        networkError: 'שגיאת רשת. אנא בדוק את החיבור שלך.',
        somethingWentWrong: 'משהו השתבש. אנא נסה שוב.',
        create: 'צור',
        update: 'עדכן',
        save: 'שמור',
        delete: 'מחק',
        edit: 'ערוך',
        validationError: "שגיאת אימות",
        more: "עוד",
        search: "חיפוש"
    },
    auth: {
        landing: {
            login: "התחברות",
            register: "הרשמה",
            webMessage: "פונקציונליות מלאה באתר בקרוב"
        },
        login: {
            title: "התחברות",
            error: "שגיאת התחברות",
            fields: {
                email: "דוא\"ל",
                password: "סיסמה"
            },
            buttons: {
                login: "התחבר",
                register: "אין לך חשבון? הירשם",
                forgotPassword: "שכחת סיסמה?"
            },
            errors: {
                invalidCredentials: "דוא\"ל או סיסמה לא תקינים",
                requiredFields: "אנא הזן דוא\"ל וסיסמה",
                networkError: "לא ניתן להתחבר לשרת. אנא בדוק את החיבור שלך."
            },
            success: "התחברת בהצלחה"
        },
        register: {
            title: "הרשמה",
            fields: {
                firstName: "שם פרטי",
                lastName: "שם משפחה",
                email: "דוא\"ל",
                phone: "טלפון",
                password: "סיסמה",
                confirmPassword: "אימות סיסמה",
                authorizedBusinessNumber: "מספר עוסק מורשה"
            },
            buttons: {
                register: "הירשם",
                haveAccount: "כבר יש לך חשבון? התחבר"
            },
            error: "שגיאת הרשמה",
            errors: {
                requiredFields: "אנא מלא את כל השדות הנדרשים",
                passwordMismatch: "הסיסמאות אינן תואמות",
                passwordLength: "הסיסמה חייבת להכיל לפחות 6 תווים",
                invalidEmail: "אנא הזן כתובת דוא\"ל תקינה",
                emailExists: "כתובת הדוא\"ל כבר רשומה במערכת"
            },
            success: "ההרשמה הושלמה בהצלחה"
        },
        logout: {
            confirm: "האם אתה בטוח שברצונך להתנתק?",
            buttons: {
                confirm: "כן, התנתק",
                cancel: "ביטול"
            },
            success: "התנתקת בהצלחה"
        }
    },
    dashboard: {
        stats: {
            activeStudents: 'תלמידים פעילים',
            lessonsToday: 'שיעורים היום',
            totalHours: 'סה״כ שעות',
            doubleLessons: 'שיעורים כפולים',
        },
        periods: {
            thisWeek: 'השבוע',
            thisMonth: 'החודש',
            lessonsCount: '{{count}} שיעורים',
        },
        upcoming: {
            title: 'שיעורים קרובים',
            duration: '{{duration}} דקות',
        },
    },
    calendar: {
        title: 'יומן',
        addNew: 'קביעת שיעור חדש',
        editLesson: 'עריכת שיעור',
        available: 'פנוי',
        summary: {
            withStudents: 'משך: {{duration}} דקות\nתלמידים: {{students}}',
            noStudents: 'משך: {{duration}} דקות',
        },
        delete: {
            title: 'מחיקת שיעור',
            confirm: 'האם אתה בטוח שברצונך למחוק שיעור זה?'
        },
        fields: {
            title: 'הזן כותרת לשיעור',
            vehicle: "רכב",
            noVehicle: "ללא רכב",
            duration: {
                single: 'רגיל (40 דקות)',
                double: 'כפול (80 דקות)'
            },
            students: 'תלמידים (עד {{count}})',
            addStudent: 'הוסף תלמיד',
            searchStudents: 'חיפוש תלמידים...',
            addStudentSingle: 'הוסף תלמיד (מקסימום 1)',
            addStudentDouble: 'הוסף תלמיד (נותרו {{count}})',
        },
        buttons: {
            cancel: 'ביטול',
            delete: 'מחק',
            schedule: 'קבע שיעור',
            update: 'עדכן',
            edit: 'ערוך שיעור'
        },
        errors: {
            titleRequired: 'אנא הזן כותרת לאירוע'
        },
        success: {
            scheduled: 'השיעור נקבע בהצלחה!',
            updated: 'השיעור עודכן בהצלחה!'
        },
        viewTypes: {
            month: 'חודש',
            week: 'שבוע',
            day: 'יום'
        },
        monthNames: {
            1: 'ינואר',
            2: 'פברואר',
            3: 'מרץ',
            4: 'אפריל',
            5: 'מאי',
            6: 'יוני',
            7: 'יולי',
            8: 'אוגוסט',
            9: 'ספטמבר',
            10: 'אוקטובר',
            11: 'נובמבר',
            12: 'דצמבר'
        },
        monthNamesShort: {
            1: 'ינו',
            2: 'פבר',
            3: 'מרץ',
            4: 'אפר',
            5: 'מאי',
            6: 'יונ',
            7: 'יול',
            8: 'אוג',
            9: 'ספט',
            10: 'אוק',
            11: 'נוב',
            12: 'דצמ'
        },
        dayNames: {
            1: 'ראשון',
            2: 'שני',
            3: 'שלישי',
            4: 'רביעי',
            5: 'חמישי',
            6: 'שישי',
            7: 'שבת'
        },
        dayNamesShort: {
            1: 'א',
            2: 'ב',
            3: 'ג',
            4: 'ד',
            5: 'ה',
            6: 'ו',
            7: 'ש'
        },
        today: 'היום',
        alerts: {
            studentLimit: {
                title: 'הגעת למגבלת התלמידים',
                singleLesson: "בשיעור רגיל ניתן לשבץ תלמיד אחד בלבד",
                doubleLesson: "בשיעור כפול ניתן לשבץ עד שני תלמידים",
            }
        },
        search: {
            noResults: 'לא נמצאו תלמידים'
        },
    },
    settings: {
        appearance: 'מראה',
        darkMode: 'מצב כהה',
        logout: 'התנתק',
        logoutTitle: 'התנתקות',
        logoutMessage: 'האם אתה בטוח שברצונך להתנתק?',
        language: {
            title: 'שפה',
            changeTitle: 'שינוי שפה',
            changeMessage: 'האם אתה בטוח שברצונך לשנות את השפה? האפליקציה תטען מחדש כדי להחיל את השינויים.',
            restartTitle: 'נדרשת הפעלה מחדש',
            restartMessage: 'אנא הפעל מחדש את האפליקציה כדי להחיל את שינויי השפה.',
        },
        account: {
            title: 'חשבון',
            editProfile: 'ערוך פרופיל',
            changePassword: 'שנה סיסמה',
            notifications: 'התראות',
        },
        app: {
            title: 'אפליקציה',
            about: 'אודות',
            help: 'עזרה',
            privacyPolicy: 'מדיניות פרטיות'
        },
    },
    accounting: {
        overview: {
            title: "סקירת הנהלת חשבונות",
            openInvoices: "חשבוניות פתוחות",
            outstandingAmount: "סכום לתשלום",
            unpaidStudents: "תלמידים שטרם שילמו",
            thisMonth: "החודש",
            thisWeek: "השבוע",
            income: "הכנסות",
            invoices: "חשבוניות",
            monthlyExpenses: "הוצאות חודשיות",
            monthlyDirectIncome: "הכנסה ישירה חודשית"
        }
    },
    documents: {
        sections: {
            income: "הכנסות",
            expense: "הוצאות"
        },
        types: {
            invoice: "חשבונית",
            tax_invoice: "חשבונית מס",
            tax_invoice_receipt: "חשבונית מס/קבלה",
            receipt: "קבלה",
            credit_invoice: "חשבונית זיכוי",
            expense: "הוצאה",
            income: "הכנסה",
        },
        status: {
            active: "פעיל",
            paid: "שולם",
            cancelled: "מבוטל"
        },
        actions: {
            title: "פעולות מסמך",
            issueReceipt: "הפק קבלה",
            issueTaxInvoiceReceipt: "הפק חשבונית מס/קבלה",
            issueCreditInvoice: "הפק חשבונית זיכוי",
            cancel: "בטל מסמך",
            viewHistory: "צפה בהיסטוריה",
            viewRelated: "צפה במסמכים קשורים",
            viewPdf: "צפה ב-PDF"
        },
        create: {
            tax_invoice: "צור חשבונית מס",
            tax_invoice_receipt: "צור חשבונית מס/קבלה",
            receipt: "צור קבלה",
            credit_invoice: "צור חשבונית זיכוי",
            expense: "צור הוצאה"
        },
        student: "תלמיד",
        supplier: "ספק",
        selectEntity: "בחר...",
        selectStudent: "בחר תלמיד",
        selectSupplier: "בחר ספק",
        searchStudents: "חפש תלמידים",
        searchSuppliers: "חפש ספקים",
        amount: "סכום",
        description: "תיאור",
        descriptionPlaceholder: "הזן תיאור...",
        enterAmount: "הזן סכום",
        enterDescription: "הזן תיאור",
        selectPaymentMethod: "בחר אמצעי תשלום",
        selectReference: "בחר מסמך ייחוס",
        noDescription: "ללא תיאור",
        search: {
            placeholder: "חפש מסמכים...",
            noResults: "לא נמצאו מסמכים התואמים את החיפוש",
            noDocuments: "טרם נוספו מסמכים",
        },
        success: {
            created: "המסמך נוצר בהצלחה",
            cancelled: "המסמך בוטל בהצלחה",
        },
        errors: {
            selectEntity: "אנא בחר {{type}}",
            validAmount: "אנא הזן סכום תקין",
            requiredField: "שדה זה הוא חובה",
            alreadyPaid: "המסמך כבר שולם",
            alreadyCancelled: "המסמך כבר בוטל",
            "cancellation": "ביטול המסמך נכשל",
        },
        confirmation: {
            cancel: "האם אתה בטוח שברצונך לבטל מסמך {{number}}?",
        },
        accounts: {
            income: "חשבון הכנסות",
            expense: "חשבון הוצאות",
            cashier: "קופה",
            vat: "חשבון מע\"מ",
            selectIncome: "בחר חשבון הכנסות",
            selectExpense: "בחר חשבון הוצאות",
            selectCashier: "בחר קופה",
            selectVat: "בחר חשבון מע\"מ",
            search: "חפש חשבונות"
        },
        referenceDocument: "מסמך ייחוס",
        selectReference: "בחר מסמך ייחוס",
        searchDocuments: "חפש מסמכים",
        noValidReferences: "לא נמצאו מסמכי ייחוס תקינים",
        documentNumber: "מסמך מספר {{number}}",
        manualReferenceNumber: "מספר חשבונית ידני",
        enterManualReference: "הזן מספר חשבונית ידני",
        validation: {
            manualReferenceRequired: "מספר חשבונית ידני הוא חובה",
            invalidAccount: "נבחר חשבון לא תקין",
            invalidReference: "מסמך ייחוס לא תקין",
            accountRequired: "נדרש לבחור חשבון",
            referenceRequired: "נדרש מסמך ייחוס",
            paymentRequired: "נדרשים פרטי תשלום",
            amountMismatch: "סכום התשלום חייב להתאים לסך המסמך",
            invalidPaymentMethod: "אמצעי תשלום לא תקין",
            invalidBankDetails: "פרטי בנק לא תקינים",
            invalidCardDetails: "פרטי כרטיס לא תקינים"
        },
        totals: {
            beforeDiscount: "סה״כ לפני הנחה",
            discount: "הנחה",
            afterDiscount: "סה״כ אחרי הנחה",
            vat: "מע״מ",
            total: "סה״כ לתשלום"
        },
        select: {
            student: "בחר {{entityType}}",
            supplier: "בחר {{entityType}}",
            type: "בחר סוג מסמך",
        },
        lineItems: {
            title: "כותרת",
            description: "תיאור",
            descriptionPlaceholder: "הזן תיאור כאן",
            quantity: "כמות",
            unitPrice: "מחיר ליחידה",
            discount: "הנחה",
            subtotal: "סיכום ביניים",
            vat: "מע״מ",
            total: "סה״כ",
            addItem: "הוסף שורה",
            itemNumber: "פריט {{number}}",
        },
        cancel: {
            title: "ביטול מסמך",
            message: "האם אתה בטוח שברצונך לבטל מסמך מספר {{number}}?",
            confirm: "בטל מסמך",
            reasonTitle: "סיבת ביטול",
            reasonMessage: "אנא ספק סיבה לביטול",
            reasonPlaceholder: "הזן את סיבת הביטול"
        },
    },
    payment: {
        amount: "סכום",
        methods: {
            cash: "מזומן",
            check: "צ׳ק",
            credit_card: "כרטיס אשראי",
            bank_transfer: "העברה בנקאית",
            bit: "ביט",
            paybox: "פייבוקס",
        },
        bankNumber: "מספר בנק",
        branchNumber: "מספר סניף",
        accountNumber: "מספר חשבון",
        checkNumber: "מספר צ׳ק",
        creditCardCompany: "חברת אשראי",
        dealType: "סוג עסקה",
        dealTypes: {
            regular: "רגילה",
            installments: "תשלומים",
        }
    },
    students: {
        title: 'תלמידים',
        addNew: 'הוסף תלמיד חדש',
        editStudent: 'ערוך תלמיד',
        search: {
            search: "חפש תלמידים...",
            noSearchResults: "לא נמצאו תלמידים התואמים את החיפוש",
            noStudents: "טרם נוספו תלמידים",
        },
        delete: {
            title: 'מחק תלמיד',
            confirm: 'האם אתה בטוח שברצונך למחוק את {{name}}?'
        },
        fields: {
            firstName: 'שם פרטי',
            lastName: 'שם משפחה',
            phone: 'מספר טלפון',
            email: 'דוא״ל',
            licenseType: 'סוג רישיון',
            notes: 'הערות'
        },
        buttons: {
            cancel: 'ביטול',
            save: 'שמור',
            update: 'עדכן',
            delete: 'מחק'
        },
        errors: {
            nameRequired: 'שם פרטי ושם משפחה הם שדות חובה'
        },
        success: {
            added: 'התלמיד נוסף בהצלחה',
            updated: 'התלמיד עודכן בהצלחה',
            deleted: 'התלמיד נמחק בהצלחה'
        }
    },
    vehicles: {
        search: "חפש כלי רכב...",
        noSearchResults: "לא נמצאו כלי רכב התואמים את החיפוש",
        noVehicles: "טרם נוספו כלי רכב",
        addNew: "הוסף רכב חדש",
        editVehicle: "ערוך רכב",
        sections: {
            basic: "מידע בסיסי",
            insurance: "ביטוח",
            maintenance: "תחזוקה",
            notes: "הערות"
        },
        fields: {
            licensePlate: "מספר רישוי",
            make: "יצרן",
            model: "דגם",
            year: "שנה",
            transmission: "תיבת הילוכים",
            insuranceProvider: "חברת ביטוח",
            policyNumber: "מספר פוליסה",
            insuranceExpiry: "תאריך תפוגת ביטוח",
            odometer: "מד מרחק",
            lastService: "תאריך טיפול אחרון",
            nextService: "תאריך טיפול הבא",
            notes: "הערות",
            licenseTypes: "סוגי רישיון",
            features: "מאפיינים",
            status: "סטטוס",
            registrationExpiry: "תאריך תפוגת רישיון רכב"
        },
        status: {
            active: "פעיל",
            maintenance: "בטיפול",
            outOfService: "מושבת"
        },
        transmission: {
            automatic: "אוטומטי",
            manual: "ידני"
        },
        features: {
            dualControls: "פדלים כפולים",
            parkingSensors: "חיישני חניה",
            camera: "מצלמת רוורס",
            abs: "ABS",
            tractionControl: "בקרת יציבות",
            dashcam: "מצלמת דרך"
        },
        success: {
            added: "הרכב נוסף בהצלחה",
            updated: "הרכב עודכן בהצלחה",
            deleted: "הרכב נמחק בהצלחה"
        },
        errors: {
            requiredFields: "אנא מלא את כל שדות החובה",
            invalidYear: "אנא הזן שנה תקינה",
            yearTooLow: "השנה חייבת להיות 1900 או מאוחר יותר",
            yearTooHigh: "השנה לא יכולה להיות מאוחרת משנה הבאה",
            invalidOdometer: "אנא הזן קריאת מד מרחק תקינה",
            odometerTooLow: "קריאת מד מרחק לא יכולה להיות שלילית"
        },
        delete: {
            title: "מחק רכב",
            confirm: "האם אתה בטוח שברצונך למחוק רכב {{plate}}?"
        },
        warnings: {
            insuranceExpiring: "⚠️ הביטוח עומד לפוג בקרוב",
            registrationExpiring: "⚠️ רישיון הרכב עומד לפוג בקרוב"
        },
        buttons: {
            delete: "מחק",
            cancel: "ביטול",
        },
    },
    instructors: {
        title: "מדריכים",
        addNew: "הוסף מדריך חדש",
        editInstructor: "ערוך מדריך",
        search: {
            search: "חפש מדריכים...",
            noSearchResults: "לא נמצאו מדריכים התואמים את החיפוש",
            noInstructors: "טרם נוספו מדריכים"
        },
        fields: {
            firstName: "שם פרטי",
            lastName: "שם משפחה",
            phone: "טלפון",
            email: "דוא״ל",
            licenseTypes: "סוגי רישיון",
            startDate: "תאריך תחילת עבודה",
            status: "סטטוס",
            hourlyRate: "תעריף לשעה",
            notes: "הערות"
        },
        status: {
            active: "פעיל",
            inactive: "לא פעיל",
            onLeave: "בחופשה"
        },
        buttons: {
            save: "שמור",
            update: "עדכן",
            cancel: "ביטול",
            delete: "מחק"
        },
        success: {
            added: "המדריך נוסף בהצלחה",
            updated: "המדריך עודכן בהצלחה",
            deleted: "המדריך נמחק בהצלחה"
        },
        errors: {
            nameRequired: "שם פרטי ושם משפחה הם שדות חובה",
            emailRequired: "דוא״ל הוא שדה חובה",
            invalidEmail: "פורמט דוא״ל לא תקין"
        },
        delete: {
            title: "מחק מדריך",
            confirm: "האם אתה בטוח שברצונך למחוק את {{name}}?"
        }
    },
    suppliers: {
        title: "ספקים",
        addNew: "הוסף ספק חדש",
        editSupplier: "ערוך ספק",
        search: {
            search: "חפש ספקים...",
            noSearchResults: "לא נמצאו ספקים התואמים את החיפוש",
            noSuppliers: "טרם נוספו ספקים"
        },
        fields: {
            name: "שם חברה",
            contactPerson: "איש קשר",
            phone: "טלפון",
            email: "דוא״ל",
            address: "כתובת",
            category: "קטגוריה",
            paymentTerms: "תנאי תשלום",
            authorizedBusinessNumber: "מספר עוסק",
            status: "סטטוס",
            notes: "הערות"
        },
        status: {
            active: "פעיל",
            inactive: "לא פעיל"
        },
        buttons: {
            save: "שמור",
            update: "עדכן",
            cancel: "ביטול",
            delete: "מחק"
        },
        success: {
            added: "הספק נוסף בהצלחה",
            updated: "הספק עודכן בהצלחה",
            deleted: "הספק נמחק בהצלחה"
        },
        errors: {
            nameRequired: "שם חברה הוא שדה חובה",
            emailRequired: "דוא״ל הוא שדה חובה",
            invalidEmail: "פורמט דוא״ל לא תקין"
        },
        delete: {
            title: "מחק ספק",
            confirm: "האם אתה בטוח שברצונך למחוק את {{name}}?"
        },
    },
    web: {
        comingSoon: "אפליקציה לנייד זמינה עכשיו",
        downloadApp: "הורד את האפליקציה",
        contactUs: "יש לך שאלות? צור קשר בכתובת",
        cta: {
            learnMore: "קרא עוד",
            contact: "צור קשר",
        },
        features: {
            title: "השירותים שלנו"
        },
        download: {
            title: "בקרוב בחנויות האפליקציות"
        }
    },
    footer: {
        copyright: "כל הזכויות שמורות"
    }
}
