import React, { useState, useMemo, useEffect } from 'react';
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  FlatList,
  Modal,
  TextInput,
  Alert,
} from 'react-native';
import { useInstructors } from '../../context/InstructorContext';
import { Feather } from '@expo/vector-icons';
import { useThemedStyles } from '../../theme/hooks';
import { useTranslation } from 'react-i18next';
import DateTimePicker from '@react-native-community/datetimepicker';
import DropDownPicker from 'react-native-dropdown-picker';
import { colors } from '../../theme/colors';

export default function InstructorsScreen() {
  const { t } = useTranslation();
  const [isModalVisible, setModalVisible] = useState(false);
  const [editingInstructor, setEditingInstructor] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [dropDownOpen, setDropDownOpen] = useState(false);

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    licenseTypes: [],
    startDate: new Date(),
    status: 'active',
    hourlyRate: '',
    notes: '',
  });

  const { instructors, addInstructor, updateInstructor, deleteInstructor, fetchInstructors } = useInstructors();

  useEffect(() => {
    fetchInstructors();
  }, [fetchInstructors]);

  const statusItems = [
    { label: t('instructors.status.active'), value: 'active' },
    { label: t('instructors.status.inactive'), value: 'inactive' },
    { label: t('instructors.status.onLeave'), value: 'onLeave' },
  ];

  // Filter instructors based on search query
  const filteredInstructors = useMemo(() => {
    const query = searchQuery.toLowerCase().trim();
    if (!query) return instructors;
    
    return instructors.filter(instructor => 
      instructor.firstName.toLowerCase().includes(query) ||
      instructor.lastName.toLowerCase().includes(query)
    );
  }, [instructors, searchQuery]);

  const styles = useThemedStyles((colors) => ({
    container: {
      flex: 1,
      backgroundColor: colors.background,
    },
    list: {
      padding: 16,
    },
    studentCard: {
      flexDirection: 'row',
      backgroundColor: colors.card,
      borderRadius: 12,
      padding: 16,
      marginBottom: 12,
      elevation: 2,
      shadowColor: colors.shadowColor,
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.1,
      shadowRadius: 4,
    },
    studentInfo: {
      flex: 1,
    },
    studentName: {
      fontSize: 18,
      fontWeight: 'bold',
      marginBottom: 4,
      color: colors.text,
    },
    studentDetails: {
      fontSize: 14,
      color: colors.textSecondary,
      marginBottom: 2,
    },
    studentEmail: {
      fontSize: 14,
      color: colors.textSecondary,
    },
    deleteButton: {
      padding: 8,
    },
    fab: {
      position: 'absolute',
      right: 16,
      bottom: 16,
      backgroundColor: colors.primary,
      width: 56,
      height: 56,
      borderRadius: 28,
      justifyContent: 'center',
      alignItems: 'center',
      elevation: 4,
      shadowColor: colors.shadowColor,
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.25,
      shadowRadius: 4,
    },
    modalContainer: {
      flex: 1,
      justifyContent: 'center',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    modalContent: {
      backgroundColor: colors.card,
      margin: 16,
      borderRadius: 12,
      padding: 16,
      maxHeight: '90%',
    },
    modalTitle: {
      fontSize: 20,
      fontWeight: 'bold',
      marginBottom: 16,
      textAlign: 'center',
      color: colors.text,
    },
    input: {
      height: 50,
      borderWidth: 1,
      borderColor: colors.border,
      borderRadius: 8,
      paddingHorizontal: 16,
      marginBottom: 12,
      fontSize: 16,
      color: colors.text,
      backgroundColor: colors.background,
    },
    placeholder: {
      color: colors.textSecondary,
    },
    textArea: {
      height: 100,
      paddingTop: 12,
    },
    modalButtons: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginTop: 16,
    },
    button: {
      flex: 1,
      height: 50,
      borderRadius: 8,
      justifyContent: 'center',
      alignItems: 'center',
      marginHorizontal: 8,
    },
    cancelButton: {
      backgroundColor: colors.error,
    },
    saveButton: {
      backgroundColor: colors.primary,
    },
    buttonText: {
      color: colors.card,
      fontSize: 16,
      fontWeight: 'bold',
    },
    searchContainer: {
      position: 'relative',
      padding: 16,
      paddingBottom: 8,
      backgroundColor: colors.background,
    },
    searchInput: {
      height: 40,
      backgroundColor: colors.card,
      borderRadius: 20,
      paddingHorizontal: 16,
      paddingLeft: 40,
      fontSize: 16,
      color: colors.text,
      borderWidth: 1,
      borderColor: colors.border,
    },
    searchIcon: {
      position: 'absolute',
      width: 40,
      left: 25, 
      top: '50%',
      transform: [{                
        translateY: 5
      }],
      zIndex: 1,  
    },
    noResults: {
      textAlign: 'center',
      marginTop: 20,
      fontSize: 16,
      color: colors.textSecondary,
    },
    list: {
      flexGrow: 1,
      padding: 16,
      paddingTop: 8,
    },
    statusBadge: {
      paddingHorizontal: 8,
      paddingVertical: 4,
      borderRadius: 12,
      marginTop: 4,
    },
    statusActive: {
      backgroundColor: colors.success,
    },
    statusInactive: {
      backgroundColor: colors.error,
    },
    statusOnLeave: {
      backgroundColor: colors.warning,
    },
    statusText: {
      color: colors.card,
      fontSize: 12,
      fontWeight: 'bold',
    },
    dropDown: {
      backgroundColor: colors.background,
      borderColor: colors.border,
      marginBottom: 12,
    },
    dropDownContainer: {
      backgroundColor: colors.card,
    },
    dateButton: {
      height: 50,
      borderWidth: 1,
      borderColor: colors.border,
      borderRadius: 8,
      paddingHorizontal: 16,
      marginBottom: 12,
      justifyContent: 'center',
      backgroundColor: colors.background,
    },
    dateButtonText: {
      fontSize: 16,
      color: colors.text,
    },
  }));

  const resetForm = () => {
    setFormData({
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      licenseTypes: [],
      startDate: new Date(),
      status: 'active',
      hourlyRate: '',
      notes: '',
    });
    setEditingInstructor(null);
  };

  const handleSubmit = () => {
    if (!formData.firstName || !formData.lastName) {
      Alert.alert(t('common.error'), t('instructors.errors.nameRequired'));
      return;
    }

    const instructorData = {
      id: editingInstructor ? editingInstructor.id : Date.now().toString(),
      ...formData,
      licenseTypes: JSON.stringify(formData.licenseTypes),
      startDate: formData.startDate.toISOString().split('T')[0],
      hourlyRate: parseFloat(formData.hourlyRate),
      createdAt: editingInstructor ? editingInstructor.createdAt : new Date().toISOString(),
      updatedAt: new Date().toISOString(),
    };

    if (editingInstructor) {
      updateInstructor(instructorData);
      Alert.alert(t('common.success'), t('instructors.success.updated'));
    } else {
      addInstructor(instructorData);
      Alert.alert(t('common.success'), t('instructors.success.added'));
    }

    setModalVisible(false);
    resetForm();
  };

  const handleDelete = (instructor) => {
    Alert.alert(
      t('instructors.delete.title'),
      t('instructors.delete.confirm', { name: `${instructor.firstName} ${instructor.lastName}` }),
      [
        {
          text: t('instructors.buttons.cancel'),
          style: 'cancel',
        },
        {
          text: t('instructors.buttons.delete'),
          style: 'destructive',
          onPress: () => {
            deleteInstructor(instructor.id);
            Alert.alert(t('common.success'), t('instructors.success.deleted'));
          },
        },
      ]
    );
  };

  const handleEdit = (instructor) => {
    setEditingInstructor(instructor);
    setFormData({
      firstName: instructor.firstName,
      lastName: instructor.lastName,
      phone: instructor.phone,
      email: instructor.email,
      licenseTypes: typeof instructor.licenseTypes === 'string' 
        ? JSON.parse(instructor.licenseTypes) 
        : instructor.licenseTypes,
      startDate: new Date(instructor.startDate),
      status: instructor.status,
      hourlyRate: instructor.hourlyRate.toString(),
      notes: instructor.notes,
    });
    setModalVisible(true);
  };

  const getStatusStyle = (status) => {
    switch (status) {
      case 'active':
        return styles.statusActive;
      case 'inactive':
        return styles.statusInactive;
      case 'onLeave':
        return styles.statusOnLeave;
      default:
        return styles.statusActive;
    }
  };

  const renderInstructor = ({ item }) => (
    <TouchableOpacity
      style={styles.studentCard}
      onPress={() => handleEdit(item)}
    >
      <View style={styles.studentInfo}>
        <Text style={styles.studentName}>
          {item.firstName} {item.lastName}
        </Text>
        <Text style={styles.studentDetails}>
          {item.phone} • ₪{item.hourlyRate}/hr
        </Text>
        <Text style={styles.studentEmail}>{item.email}</Text>
        <View style={[styles.statusBadge, getStatusStyle(item.status)]}>
          <Text style={styles.statusText}>
            {t(`instructors.status.${item.status}`)}
          </Text>
        </View>
      </View>
      <TouchableOpacity
        style={styles.deleteButton}
        onPress={() => handleDelete(item)}
      >
        <Feather name="trash-2" size={20} color="#FF3B30" />
      </TouchableOpacity>
    </TouchableOpacity>
  );

  return (
    <View style={styles.container}>
      <View style={styles.searchContainer}>
        <Feather 
          name="search" 
          size={20} 
          color={styles.placeholder.color} 
          style={styles.searchIcon}
        />
        <TextInput
          style={styles.searchInput}
          placeholder={t('instructors.search.search')}
          placeholderTextColor={styles.placeholder.color}
          value={searchQuery}
          onChangeText={setSearchQuery}
          autoCapitalize="none"
        />
      </View>

      <FlatList
        data={filteredInstructors}
        renderItem={renderInstructor}
        keyExtractor={item => item.id}
        contentContainerStyle={styles.list}
        ListEmptyComponent={() => (
          <Text style={styles.noResults}>
            {searchQuery 
              ? t('instructors.search.noSearchResults') 
              : t('instructors.search.noInstructors')}
          </Text>
        )}
      />

      <TouchableOpacity
        style={styles.fab}
        onPress={() => {
          resetForm();
          setModalVisible(true);
        }}
      >
        <Feather name="plus" size={24} color="#fff" />
      </TouchableOpacity>

      <Modal
        visible={isModalVisible}
        animationType="slide"
        transparent={true}
        onRequestClose={() => {
          setModalVisible(false);
          resetForm();
        }}
      >
        <View style={styles.modalContainer}>
          <View style={styles.modalContent}>
            <Text style={styles.modalTitle}>
              {editingInstructor ? t('instructors.editInstructor') : t('instructors.addNew')}
            </Text>

            <TextInput
              style={styles.input}
              placeholder={t('instructors.fields.firstName')}
              placeholderTextColor={styles.placeholder.color}
              value={formData.firstName}
              onChangeText={(text) => setFormData({ ...formData, firstName: text })}
            />

            <TextInput
              style={styles.input}
              placeholder={t('instructors.fields.lastName')}
              placeholderTextColor={styles.placeholder.color}
              value={formData.lastName}
              onChangeText={(text) => setFormData({ ...formData, lastName: text })}
            />

            <TextInput
              style={styles.input}
              placeholder={t('instructors.fields.phone')}
              placeholderTextColor={styles.placeholder.color}
              value={formData.phone}
              onChangeText={(text) => setFormData({ ...formData, phone: text })}
              keyboardType="phone-pad"
            />

            <TextInput
              style={styles.input}
              placeholder={t('instructors.fields.email')}
              placeholderTextColor={styles.placeholder.color}
              value={formData.email}
              onChangeText={(text) => setFormData({ ...formData, email: text })}
              keyboardType="email-address"
              autoCapitalize="none"
            />

            <TouchableOpacity
              style={styles.dateButton}
              onPress={() => setShowDatePicker(true)}
            >
              <Text style={styles.dateButtonText}>
                {t('instructors.fields.startDate')}: {formData.startDate.toLocaleDateString()}
              </Text>
            </TouchableOpacity>

            {showDatePicker && (
              <DateTimePicker
                value={formData.startDate}
                mode="date"
                display="default"
                onChange={(event, selectedDate) => {
                  setShowDatePicker(false);
                  if (selectedDate) {
                    setFormData({ ...formData, startDate: selectedDate });
                  }
                }}
              />
            )}

            <DropDownPicker
              open={dropDownOpen}
              value={formData.status}
              items={statusItems}
              setOpen={setDropDownOpen}
              setValue={(callback) => {
                const value = callback(formData.status);
                setFormData({ ...formData, status: value });
              }}
              style={styles.dropDown}
              dropDownContainerStyle={styles.dropDownContainer}
              textStyle={{ color: colors.textPrimary }}
            />

            <TextInput
              style={styles.input}
              placeholder={t('instructors.fields.hourlyRate')}
              placeholderTextColor={styles.placeholder.color}
              value={formData.hourlyRate}
              onChangeText={(text) => setFormData({ ...formData, hourlyRate: text })}
              keyboardType="numeric"
            />

            <TextInput
              style={[styles.input, styles.textArea]}
              placeholder={t('instructors.fields.notes')}
              placeholderTextColor={styles.placeholder.color}
              value={formData.notes}
              onChangeText={(text) => setFormData({ ...formData, notes: text })}
              multiline
              numberOfLines={4}
              textAlignVertical="top"
            />

            <View style={styles.modalButtons}>
              <TouchableOpacity
                style={[styles.button, styles.cancelButton]}
                onPress={() => {
                  setModalVisible(false);
                  resetForm();
                }}
              >
                <Text style={styles.buttonText}>{t('instructors.buttons.cancel')}</Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={[styles.button, styles.saveButton]}
                onPress={handleSubmit}
              >
                <Text style={styles.buttonText}>
                  {editingInstructor ? t('instructors.buttons.update') : t('instructors.buttons.save')}
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>
    </View>
  );
}