import React, { createContext, useContext, useReducer, useCallback } from 'react';
import { apiService } from '../services/api';


const VehicleContext = createContext();

const vehicleReducer = (state, action) => {
  switch (action.type) {
    case 'SET_LOADING':
      return {
        ...state,
        isLoading: action.payload
      };
    case 'SET_ERROR':
      return {
        ...state,
        error: action.payload,
        isLoading: false
      };
    case 'ADD_VEHICLE':
      return {
        ...state,
        items: [...state.items, action.payload],
      };
    case 'UPDATE_VEHICLE':
      return {
        ...state,
        items: state.items.map(vehicle =>
          vehicle.id === action.payload.id ? action.payload : vehicle
        ),
      };
    case 'DELETE_VEHICLE':
      return {
        ...state,
        items: state.items.filter(vehicle => vehicle.id !== action.payload),
      };
    case 'SET_VEHICLES':
      return {
        ...state,
        items: action.payload,
      };
    default:
      return state;
  }
};

export const VehicleProvider = ({ children }) => {
  const [state, dispatch] = useReducer(vehicleReducer, {
    items: [],
    isLoading: false,
    error: null,
  });

  const fetchVehicles = useCallback(async () => {
    dispatch({ type: 'SET_LOADING', payload: true });
    try {
      const response = await apiService.getVehicles();
      dispatch({ type: 'SET_VEHICLES', payload: response.data });
    } catch (error) {
      dispatch({ type: 'SET_ERROR', payload: error.message });
    }
  }, []);

  const addVehicle = async (vehicle) => {
    dispatch({ type: 'SET_LOADING', payload: true });
    try {
      const response = await apiService.createVehicle(vehicle);
      dispatch({ 
        type: 'ADD_VEHICLE', 
        payload: {
          ...vehicle,
          id: response.data.id
        }
      });
      return response.data; 
    } catch (error) {
      dispatch({ 
        type: 'SET_ERROR', 
        payload: error.response?.data?.error || 'Failed to create vehicle' 
      });
      throw error; 
    }
  };

  const updateVehicle = async (vehicle) => {
    dispatch({ type: 'SET_LOADING', payload: true });
    try {
      const response = await apiService.updateVehicle(vehicle.id, vehicle);
      dispatch({ 
        type: 'UPDATE_VEHICLE', 
        payload: {
          ...vehicle,
          id: response.data.id
        }
      });
      return response.data; 
    } catch (error) {
      dispatch({ 
        type: 'SET_ERROR', 
        payload: error.response?.data?.error || 'Failed to update vehicle' 
      });
      throw error; 
    }
  };

  const deleteVehicle = async (vehicleId) => {
    dispatch({ type: 'SET_LOADING', payload: true });
    try {
      const response = await apiService.deleteVehicle(vehicleId);
      dispatch({ 
        type: 'DELETE_VEHICLE', 
        payload: vehicleId
      });
      return response.data; 
    } catch (error) {
      dispatch({ 
        type: 'SET_ERROR', 
        payload: error.response?.data?.error || 'Failed to delete vehicle' 
      });
      throw error; 
    }
  };

  const setVehicles = (vehicles) => {
    dispatch({ type: 'SET_VEHICLES', payload: vehicles });
  };

  const value = {
    vehicles: state.items,
    isLoading: state.isLoading,
    error: state.error,
    fetchVehicles,
    addVehicle,
    updateVehicle,
    deleteVehicle,
    setVehicles,
  };

  return (
    <VehicleContext.Provider value={value}>
      {children}
    </VehicleContext.Provider>
  );
};

export const useVehicles = () => {
  const context = useContext(VehicleContext);
  if (context === undefined) {
    throw new Error('useVehicles must be used within a VehicleProvider');
  }
  return context;
};

export default VehicleProvider;