import React from 'react';
import { View, Text, TextInput, TouchableOpacity, ScrollView } from 'react-native';
import { Feather } from '@expo/vector-icons';
import { useTranslation } from 'react-i18next';
import { useThemedStyles } from '../../theme/hooks';
import { useAccounting } from '../../context/AccountingContext';

const LineItemsForm = ({ items, onChange, disabled = false }) => {
    const { t } = useTranslation();
    const { vatRate } = useAccounting();

    const addItem = () => {
        onChange([
            ...items,
            {
                description: '',
                quantity: 0,
                unitPrice: 0,
                lineDiscount: 0,
                lineTotal: 0,
                vatAmount: 0,
                totalWithVat: 0,
                vatRate
            }
        ]);
    };

    const removeItem = (index) => {
        const newItems = [...items];
        newItems.splice(index, 1);
        onChange(newItems);
    };

    const updateItem = (index, field, value) => {
        const newItems = [...items];
        const item = { ...newItems[index] };

        // Update the field
        item[field] = value;

        // Recalculate all totals when quantity, unitPrice, or discount changes
        if (field === 'quantity' || field === 'unitPrice' || field === 'lineDiscount') {
            // Ensure we're working with positive numbers
            const quantity = Math.abs(parseFloat(item.quantity)) || 0;
            const unitPrice = Math.abs(parseFloat(item.unitPrice)) || 0;
            const lineDiscount = Math.abs(parseFloat(item.lineDiscount)) || 0;

            // Calculate base amount
            const baseAmount = quantity * unitPrice;

            // Ensure discount doesn't exceed the base amount
            const effectiveDiscount = Math.min(lineDiscount, baseAmount);

            item.lineTotal = baseAmount - effectiveDiscount;
            item.vatAmount = (item.lineTotal * (vatRate / 100));
            item.totalWithVat = item.lineTotal + item.vatAmount;

            // Update the displayed values
            item.quantity = quantity;
            item.unitPrice = unitPrice;
            item.lineDiscount = effectiveDiscount;
        }

        newItems[index] = item;
        onChange(newItems);
    };

    const styles = useThemedStyles((colors) => ({
        container: {
            marginBottom: 24,
        },
        header: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: 16,
            paddingHorizontal: 4,
        },
        headerText: {
            fontSize: 18,
            fontWeight: '600',
            color: colors.text,
        },
        itemContainer: {
            backgroundColor: colors.card,
            borderRadius: 16,
            marginBottom: 16,
            overflow: 'hidden',
            borderWidth: 1,
            borderColor: colors.border,
        },
        itemHeader: {
            backgroundColor: colors.background,
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingVertical: 12,
            paddingHorizontal: 16,
            borderBottomWidth: 1,
            borderBottomColor: colors.border,
        },
        itemNumber: {
            fontSize: 14,
            fontWeight: '600',
            color: colors.textSecondary,
        },
        itemContent: {
            padding: 16,
        },
        row: {
            flexDirection: 'row',
            alignItems: 'flex-start',
            marginBottom: 16,
            gap: 12,
        },
        column: {
            flex: 1,
        },
        input: {
            borderWidth: 1,
            borderColor: colors.border,
            borderRadius: 8,
            padding: 12,
            fontSize: 14,
            color: colors.text,
            backgroundColor: colors.background,
        },
        descriptionInput: {
            height: 80,
            textAlignVertical: 'top',
            marginBottom: 16,
        },
        numberInput: {
            textAlign: 'right',
        },
        addButton: {
            flexDirection: 'row',
            alignItems: 'center',
            padding: 14,
            backgroundColor: colors.primary,
            borderRadius: 12,
            justifyContent: 'center',
            marginTop: 8,
        },
        addButtonDisabled: {
            opacity: 0.5,
        },
        addButtonText: {
            color: colors.card,
            marginLeft: 8,
            fontSize: 15,
            fontWeight: '500',
        },
        removeButton: {
            padding: 8,
        },
        removeIcon: {
            color: colors.error,
        },
        label: {
            fontSize: 13,
            color: colors.textSecondary,
            marginBottom: 6,
            fontWeight: '500',
        },
        totalsContainer: {
            backgroundColor: colors.background,
            borderTopWidth: 1,
            borderTopColor: colors.border,
            marginTop: 16,
            paddingVertical: 12,
            paddingHorizontal: 16,
            borderRadius: 8,
        },
        totalRow: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingVertical: 4,
        },
        totalLabel: {
            fontSize: 14,
            color: colors.textSecondary,
        },
        totalAmount: {
            fontSize: 14,
            fontWeight: '500',
            color: colors.text,
        },
        totalRowFinal: {
            marginTop: 8,
            paddingTop: 8,
            borderTopWidth: 1,
            borderTopColor: colors.border,
        },
        totalAmountFinal: {
            fontSize: 16,
            fontWeight: 'bold',
            color: colors.text,
        },
    }));

    return (
        <View style={styles.container}>
            <View style={styles.header}>
                <Text style={styles.headerText}>{t('documents.lineItems.title')}</Text>
            </View>

            <ScrollView>
                {items.map((item, index) => (
                    <View key={index} style={styles.itemContainer}>
                        <View style={styles.itemHeader}>
                            <Text style={styles.itemNumber}>
                                {t('documents.lineItems.itemNumber', { number: index + 1 })}
                            </Text>
                            {!disabled && (
                                <TouchableOpacity
                                    style={styles.removeButton}
                                    onPress={() => removeItem(index)}
                                >
                                    <Feather name="trash-2" size={18} style={styles.removeIcon} />
                                </TouchableOpacity>
                            )}
                        </View>

                        <View style={styles.itemContent}>
                            <View style={styles.column}>
                                <Text style={styles.label}>{t('documents.lineItems.description')}</Text>
                                <TextInput
                                    style={[styles.input, styles.descriptionInput]}
                                    value={item.description}
                                    onChangeText={(text) => updateItem(index, 'description', text)}
                                    placeholder={t('documents.lineItems.descriptionPlaceholder')}
                                    multiline
                                    numberOfLines={3}
                                    editable={!disabled}
                                />
                            </View>

                            <View style={styles.row}>
                                <View style={styles.column}>
                                    <Text style={styles.label}>{t('documents.lineItems.quantity')}</Text>
                                    <TextInput
                                        style={[styles.input, styles.numberInput]}
                                        value={item.quantity.toString()}
                                        onChangeText={(text) => updateItem(index, 'quantity', parseFloat(text) || 0)}
                                        keyboardType="decimal-pad"
                                        editable={!disabled}
                                    />
                                </View>

                                <View style={styles.column}>
                                    <Text style={styles.label}>{t('documents.lineItems.unitPrice')}</Text>
                                    <TextInput
                                        style={[styles.input, styles.numberInput]}
                                        value={item.unitPrice.toString()}
                                        onChangeText={(text) => updateItem(index, 'unitPrice', parseFloat(text) || 0)}
                                        keyboardType="decimal-pad"
                                        editable={!disabled}
                                    />
                                </View>

                                <View style={styles.column}>
                                    <Text style={styles.label}>{t('documents.lineItems.discount')}</Text>
                                    <TextInput
                                        style={[styles.input, styles.numberInput]}
                                        value={item.lineDiscount.toString()}
                                        onChangeText={(text) => updateItem(index, 'lineDiscount', parseFloat(text) || 0)}
                                        keyboardType="decimal-pad"
                                        editable={!disabled}
                                    />
                                </View>
                            </View>

                            <View style={styles.totalsContainer}>
                                <View style={styles.totalRow}>
                                    <Text style={styles.totalLabel}>{t('documents.lineItems.subtotal')}</Text>
                                    <Text style={styles.totalAmount}>₪{item.lineTotal.toFixed(2)}</Text>
                                </View>
                                <View style={styles.totalRow}>
                                    <Text style={styles.totalLabel}>{t('documents.lineItems.vat')} ({vatRate}%)</Text>
                                    <Text style={styles.totalAmount}>₪{item.vatAmount.toFixed(2)}</Text>
                                </View>
                                <View style={[styles.totalRow, styles.totalRowFinal]}>
                                    <Text style={styles.totalLabel}>{t('documents.lineItems.total')}</Text>
                                    <Text style={styles.totalAmountFinal}>₪{item.totalWithVat.toFixed(2)}</Text>
                                </View>
                            </View>
                        </View>
                    </View>
                ))}
            </ScrollView>

            {!disabled && (
                <TouchableOpacity
                    style={[styles.addButton, disabled && styles.addButtonDisabled]}
                    onPress={addItem}
                    disabled={disabled}
                >
                    <Feather name="plus" size={16} color="#fff" />
                    <Text style={styles.addButtonText}>{t('documents.lineItems.addItem')}</Text>
                </TouchableOpacity>
            )}
        </View>
    );
};

export { LineItemsForm };