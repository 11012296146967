// src/context/SupplierContext.js
import React, { createContext, useContext, useReducer, useCallback } from 'react';
import { apiService } from '../services/api';

const SupplierContext = createContext();

const supplierReducer = (state, action) => {
  switch (action.type) {
    case 'SET_LOADING':
      return {
        ...state,
        isLoading: action.payload
      };
    case 'SET_ERROR':
      return {
        ...state,
        error: action.payload,
        isLoading: false
      };
    case 'ADD_SUPPLIER':
      return {
        ...state,
        items: [...state.items, action.payload],
        isLoading: false,
        error: null
      };
    case 'UPDATE_SUPPLIER':
      return {
        ...state,
        items: state.items.map(supplier =>
          supplier.id === action.payload.id ? action.payload : supplier
        ),
        isLoading: false,
        error: null
      };
    case 'DELETE_SUPPLIER':
      return {
        ...state,
        items: state.items.filter(supplier => supplier.id !== action.payload),
        isLoading: false,
        error: null
      };
    case 'SET_SUPPLIERS':
      return {
        ...state,
        items: action.payload,
        isLoading: false,
        error: null
      };
    default:
      return state;
  }
};

export const SupplierProvider = ({ children }) => {
  const [state, dispatch] = useReducer(supplierReducer, {
    items: [],
    isLoading: false,
    error: null,
  });

  const fetchSuppliers = useCallback(async () => {
    dispatch({ type: 'SET_LOADING', payload: true });
    try {
      const response = await apiService.getSuppliers();
      dispatch({ type: 'SET_SUPPLIERS', payload: response.data });
    } catch (error) {
      dispatch({ type: 'SET_ERROR', payload: error.message });
    }
  }, []);

  const addSupplier = async (supplier) => {
    dispatch({ type: 'SET_LOADING', payload: true });
    try {
      const response = await apiService.addSupplier(supplier);
      dispatch({ type: 'ADD_SUPPLIER', payload: { ...supplier, id: response.data.id } });
    } catch (error) {
      dispatch({ type: 'SET_ERROR', payload: error.message });
    }
  };

  const updateSupplier = async (supplier) => {
    dispatch({ type: 'SET_LOADING', payload: true });
    try {
      await apiService.updateSupplier(supplier.id, supplier);
      dispatch({ type: 'UPDATE_SUPPLIER', payload: supplier });
    } catch (error) {
      dispatch({ type: 'SET_ERROR', payload: error.message });
    }
  };

  const deleteSupplier = async (supplierId) => {
    dispatch({ type: 'SET_LOADING', payload: true });
    try {
      await apiService.deleteSupplier(supplierId);
      dispatch({ type: 'DELETE_SUPPLIER', payload: supplierId });
    } catch (error) {
      dispatch({ type: 'SET_ERROR', payload: error.message });
    }
  };

  const value = {
    suppliers: state.items,
    isLoading: state.isLoading,
    error: state.error,
    fetchSuppliers,
    addSupplier,
    updateSupplier,
    deleteSupplier,
  };

  return (
    <SupplierContext.Provider value={value}>
      {children}
    </SupplierContext.Provider>
  );
};

export const useSuppliers = () => {
  const context = useContext(SupplierContext);
  if (context === undefined) {
    throw new Error('useSuppliers must be used within a SupplierProvider');
  }
  return context;
};

export default SupplierProvider;