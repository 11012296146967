import React from 'react';
import { View, TextInput, TouchableOpacity, Text } from 'react-native';
import { useTranslation } from 'react-i18next';
import { useThemedStyles } from '../../theme/hooks';
import { useStudents } from '../../context/StudentContext';
import { useSuppliers } from '../../context/SupplierContext';
import { useAccounting } from '../../context/AccountingContext';
import { usePayments } from '../../context/PaymentContext';
import { AccountSelector } from './AccountSelector';
import { PaymentFields } from './PaymentFields';
import DateTimePicker from '@react-native-community/datetimepicker';
import { validateAccount, getAccountTypeByDocumentType } from '../../utils/accountValidation';
import { ReferenceDocumentSelector } from './ReferenceDocumentSelector';
import { validateReferenceDocument } from '../../utils/referenceValidation';

const DocumentFormField = ({
  type,
  required,
  value,
  onChange,
  documentType,
  disabled = false,
  amount = 0
}) => {
  const { t } = useTranslation();
  const { students } = useStudents();
  const { suppliers } = useSuppliers();
  const {
    accounts,
    getIncomeAccounts,
    getExpenseAccounts,
    getCashierAccounts
  } = useAccounting();
  const { documents, isLoading, createDocument, cancelDocument, fetchDocuments } = usePayments();


  const styles = useThemedStyles((colors) => ({
    fieldContainer: {
      marginBottom: 16,
    },
    labelContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: 8,
    },
    label: {
      fontSize: 14,
      color: colors.textSecondary,
    },
    requiredIndicator: { 
      color: colors.error,
      marginLeft: 4,
      fontSize: 14,
    },
    input: {
      borderWidth: 1,
      borderColor: colors.border,
      borderRadius: 8,
      padding: 12,
      fontSize: 16,
      color: colors.text,
      backgroundColor: colors.background,
    },
    textArea: {
      height: 100,
      textAlignVertical: 'top',
    },
    entityButton: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderWidth: 1,
      borderColor: colors.border,
      borderRadius: 8,
      padding: 12,
      backgroundColor: colors.background,
    },
    entityButtonDisabled: {
      opacity: 0.6,
    },
    entityLabel: {
      fontSize: 16,
      color: colors.text,
    },
    entityLabelDisabled: {
      color: colors.textSecondary,
    },
    datePickerButton: {
      borderWidth: 1,
      borderColor: colors.border,
      borderRadius: 8,
      padding: 12,
      backgroundColor: colors.background,
    }
}));

  const getAccountsByType = (type) => {
    switch (type) {
      case 'income':
        return getIncomeAccounts();
      case 'expense':
        return getExpenseAccounts();
      case 'cashier':
        return getCashierAccounts();
      case 'vat':
        return accounts.filter(acc => acc.accountCodeDescription === ACCOUNT_TYPES.VAT);
      default:
        return accounts;
    }
  };

  const renderAccountSelector = (accountType) => {
    const accountConfig = getAccountTypeByDocumentType(documentType);
    const isRequired = accountConfig[accountType] !== null;

    return (
      <AccountSelector
        label={t(`documents.accounts.${accountType}`)}
        value={value}
        accounts={getAccountsByType(accountType)}
        onChange={onChange}
        accountType={accountType}
        disabled={disabled}
        placeholder={t(`documents.accounts.select${accountType.charAt(0).toUpperCase() + accountType.slice(1)}`)}
        required={isRequired}
        validateAccount={(account) => validateAccount(account, accountType, documentType)}
      />
    );
  };

  const renderEntitySelector = (entityType, entities) => {
    const formattedEntities = entities.map(entity => {
      return {
        ...entity,
        // Ensure accounting_id is available
        accounting_id: entity.accounting_id?.toString(),
        // Format display name
        accountName: entityType === 'student'
          ? `${entity.firstName} ${entity.lastName}`
          : entity.name
      };
    });

    return (
      <AccountSelector
        label={t(`documents.select.${entityType}`, { entityType })}
        value={value}
        accounts={formattedEntities}
        onChange={onChange}
        disabled={disabled}
        placeholder={t(`documents.select.${entityType}`, { entityType })}
      />
    );
  };

  switch (type) {
    case 'incomeAccount':
      return renderAccountSelector('income');

    case 'expenseAccount':
      return renderAccountSelector('expense');

    case 'cashierAccount':
      return renderAccountSelector('cashier');

    case 'vatAccount':
      return renderAccountSelector('vat');

    case 'reference':
      const handleReferenceChange = (document) => {
        const validationError = validateReferenceDocument(document, documentType.id);
        if (!validationError) {
          onChange(document);
        }

        return validationError;
      };

      return (
        <ReferenceDocumentSelector
          label={t('documents.referenceDocument')}
          value={value}
          documents={documents}
          documentType={documentType}
          onChange={handleReferenceChange}
          disabled={disabled}
          required={documentType?.requiresReference}
        />
      );

    case 'student':
      return renderEntitySelector('student', students);

    case 'supplier':
      return renderEntitySelector('supplier', suppliers);

    case 'description':
      return (
        <View style={styles.fieldContainer}>
          <TextInput
            style={[styles.input, styles.textArea]}
            placeholder={t('documents.enterDescription')}
            multiline
            numberOfLines={4}
            value={value}
            onChangeText={onChange}
          />
        </View>
      );

    case 'payment':
      return (
        <View style={styles.fieldContainer}>
          <PaymentFields
            value={value}
            onChange={onChange}
            amount={amount}
          />
        </View>
      );

    case 'valueDate':
      return (
        <View style={styles.fieldContainer}>
          <TouchableOpacity
            style={styles.datePickerButton}
            onPress={() => onChange(new Date())}
          >
            <Text style={styles.entityLabel}>
              {value ? new Date(value).toLocaleDateString() : t('documents.selectDate')}
            </Text>
          </TouchableOpacity>
          {Platform.OS === 'android' && value && (
            <DateTimePicker
              value={new Date(value)}
              mode="date"
              display="default"
              onChange={(event, selectedDate) => {
                if (selectedDate) {
                  onChange(selectedDate.toISOString().split('T')[0]);
                }
              }}
            />
          )}
        </View>
      );

    case 'incomeAccount':
      return (
        <AccountSelector
          label={t('documents.selectIncomeAccount')}
          accounts={getIncomeAccounts()}
          value={value}
          onChange={onChange}
          placeholder={t('documents.selectIncomeAccount')}
        />
      );

    case 'expenseAccount':
      return (
        <AccountSelector
          label={t('documents.selectExpenseAccount')}
          accounts={getExpenseAccounts()}
          value={value}
          onChange={onChange}
          placeholder={t('documents.selectExpenseAccount')}
        />
      );

      case 'manualReference':
        return (
          <View style={styles.fieldContainer}>
            <View style={styles.labelContainer}>
              <Text style={styles.label}>{t('documents.manualReferenceNumber')}</Text>
              {required && <Text style={styles.requiredIndicator}>*</Text>}
            </View>
            <TextInput
              style={styles.input}
              placeholder={t('documents.enterManualReference')}
              value={value}
              onChangeText={onChange}
            />
          </View>
        );

    default:
      return null;
  }
};

export { DocumentFormField };