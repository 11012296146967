export * from './documentTypes';

// You can add these other constants as you create them
export const STATUS = {
  ACTIVE: 'ACTIVE',
  PAID: 'PAID',
  CANCELLED: 'CANCELLED',
  DRAFT: 'DRAFT'
};

export const ENTITY_TYPES = {
  STUDENT: 'STUDENT',
  SUPPLIER: 'SUPPLIER'
};

export const ACCOUNT_TYPES = {
  INCOME: 'הכנסות',
  EXPENSE: 'הוצאות',
  CASHIER: 'קופות',
  VAT: 'מוסדות'
};

export const DEFAULT_ACCOUNTS = {
  VAT: '60000001'  // Default VAT account number
};

export const PAYMENT_METHODS = {
  CASH: {
    id: 'CASH',
    methodType: '1',
    label: 'Cash',
    icon: 'dollar-sign',
    accountName: 'קופה - מזומן'
  },
  CHECK: {
    id: 'CHECK',
    methodType: '2',
    label: 'Check',
    icon: 'check-square',
    accountName: 'קופה - צ׳ק',
    fields: ['bankNumber', 'branchNumber', 'accountNumber', 'checkNumber'],
  },
  CREDIT_CARD: {
    id: 'CREDIT_CARD',
    methodType: '3',
    label: 'Credit Card',
    icon: 'credit-card',
    accountName: 'קופה - חשבון בנק',
    fields: ['creditCardCompanyCode', 'cardName', 'dealType']
  },
  BANK_TRANSFER: {
    id: 'BANK_TRANSFER',
    methodType: '4',
    label: 'Bank Transfer',
    icon: 'repeat',
    accountName: 'קופה - חשבון בנק',
    fields: ['bankNumber', 'branchNumber', 'accountNumber']
  },
  BIT: {
    id: 'BIT',
    methodType: '5',
    icon: 'smartphone',
    accountName: 'קופה - ביט'
  },
  PAYBOX: {
    id: 'PAYBOX',
    methodType: '6',
    icon: 'smartphone',
    accountName: 'קופה - פייבוקס'
  }
};

export const CREDIT_CARD_COMPANIES = {
  VISA: {
    code: '2',
    name: 'Visa'
  },
  MASTERCARD: {
    code: '3',
    name: 'MasterCard'
  },
  AMEX: {
    code: '4',
    name: 'American Express'
  }
};

export const DEAL_TYPES = {
  REGULAR: '1',
  INSTALLMENTS: '2'
};

// Business branch configuration
export const DEFAULT_BRANCH_ID = '001';