import React, { useState, useMemo } from 'react';
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  FlatList,
  Modal,
  TextInput,
  Alert,
  ScrollView,
} from 'react-native';
import { useVehicles } from '../../context/VehicleContext';
import { Feather } from '@expo/vector-icons';
import { useThemedStyles } from '../../theme/hooks';
import { useTranslation } from 'react-i18next';
import DateTimePicker from '@react-native-community/datetimepicker';

export default function VehiclesScreen() {
  const { t } = useTranslation();
  const [isModalVisible, setModalVisible] = useState(false);
  const [editingVehicle, setEditingVehicle] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [currentDateField, setCurrentDateField] = useState(null);

  const initialFormState = {
    licensePlate: '',
    make: '',
    model: '',
    year: null,
    transmission: 'automatic', // ENUM('automatic', 'manual')
    licenseTypes: [], // JSON array
    insurance_provider: '',
    insurance_policy_number: '',
    insurance_expiry_date: null,
    last_service_date: null,
    next_service_due: null,
    odometer_reading: null,
    status: 'active', // ENUM('active', 'maintenance', 'outOfService')
    notes: '',
    features: [], // JSON array
    registration_expiry: null
  };

  const [formData, setFormData] = useState(initialFormState);

  // Helper function to format number values for display
  const formatNumberValue = (value) => {
    return value === null || value === undefined ? '' : String(value);
  };

  // Helper function to parse numeric input
  const handleNumberInput = (text, field, objectPath = null) => {
    const numericValue = text.trim() === '' ? null : Number(text);

    if (objectPath) {
      // Handle nested objects (like maintenance.odometerReading)
      setFormData(prev => ({
        ...prev,
        [objectPath]: {
          ...prev[objectPath],
          [field]: numericValue
        }
      }));
    } else {
      // Handle top-level fields
      setFormData(prev => ({
        ...prev,
        [field]: numericValue
      }));
    }
  };

  const isValidNumber = (value, options = {}) => {
    const { min, max, allowNull = true } = options;

    if (value === null) {
      return allowNull;
    }

    const num = Number(value);
    if (isNaN(num)) {
      return false;
    }

    if (min !== undefined && num < min) {
      return false;
    }

    if (max !== undefined && num > max) {
      return false;
    }

    return true;
  };

  const validateNumber = (value, field, options = {}) => {
    const { min, max, allowNull = true } = options;

    // Allow null if specified
    if (value === null) {
      return allowNull ? { isValid: true } : {
        isValid: false,
        errorKey: `invalid${field.charAt(0).toUpperCase() + field.slice(1)}`
      };
    }

    const num = Number(value);
    if (isNaN(num)) {
      return {
        isValid: false,
        errorKey: `invalid${field.charAt(0).toUpperCase() + field.slice(1)}`
      };
    }

    if (min !== undefined && num < min) {
      return {
        isValid: false,
        errorKey: `${field}TooLow`
      };
    }

    if (max !== undefined && num > max) {
      return {
        isValid: false,
        errorKey: `${field}TooHigh`
      };
    }

    return { isValid: true };
  };

  const { vehicles, addVehicle, updateVehicle, deleteVehicle } = useVehicles();

  const filteredVehicles = useMemo(() => {
    const query = searchQuery.toLowerCase().trim();
    if (!query) return vehicles;

    return vehicles.filter(vehicle =>
      vehicle.licensePlate.toLowerCase().includes(query) ||
      vehicle.make.toLowerCase().includes(query) ||
      vehicle.model.toLowerCase().includes(query)
    );
  }, [vehicles, searchQuery]);

  const styles = useThemedStyles((colors) => ({
    container: {
      flex: 1,
      backgroundColor: colors.background,
    },
    searchContainer: {
      position: 'relative',
      padding: 16,
      paddingBottom: 8,
      backgroundColor: colors.background,
    },
    searchInput: {
      height: 40,
      backgroundColor: colors.card,
      borderRadius: 20,
      paddingHorizontal: 16,
      paddingLeft: 40,
      fontSize: 16,
      color: colors.text,
      borderWidth: 1,
      borderColor: colors.border,
    },
    searchIcon: {
      position: 'absolute',
      width: 40,
      left: 25,
      top: '50%',
      transform: [{
        translateY: 5
      }],
      zIndex: 1,
    },
    vehicleCard: {
      backgroundColor: colors.card,
      borderRadius: 12,
      padding: 16,
      marginBottom: 12,
      elevation: 2,
      shadowColor: colors.shadowColor,
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.1,
      shadowRadius: 4,
    },
    vehicleHeader: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 8,
    },
    vehicleTitle: {
      fontSize: 18,
      fontWeight: 'bold',
      color: colors.text,
    },
    vehicleInfo: {
      fontSize: 14,
      color: colors.textSecondary,
      marginBottom: 4,
    },
    statusBadge: {
      paddingHorizontal: 8,
      paddingVertical: 4,
      borderRadius: 12,
      marginBottom: 8,
    },
    statusText: {
      fontSize: 12,
      fontWeight: 'bold',
    },
    list: {
      flexGrow: 1,
      padding: 16,
      paddingTop: 8,
    },
    modalContainer: {
      flex: 1,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    modalContent: {
      backgroundColor: colors.card,
      margin: 16,
      borderRadius: 12,
      padding: 16,
      maxHeight: '90%',
    },
    modalScrollView: {
      flexGrow: 1,
    },
    modalTitle: {
      fontSize: 20,
      fontWeight: 'bold',
      marginBottom: 16,
      textAlign: 'center',
      color: colors.text,
    },
    sectionTitle: {
      fontSize: 16,
      fontWeight: 'bold',
      color: colors.text,
      marginTop: 16,
      marginBottom: 8,
    },
    input: {
      height: 50,
      borderWidth: 1,
      borderColor: colors.border,
      borderRadius: 8,
      paddingHorizontal: 16,
      marginBottom: 12,
      fontSize: 16,
      color: colors.text,
      backgroundColor: colors.background,
    },
    dateInput: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    modalButtons: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginTop: 16,
    },
    button: {
      flex: 1,
      height: 50,
      borderRadius: 8,
      justifyContent: 'center',
      alignItems: 'center',
      marginHorizontal: 8,
    },
    cancelButton: {
      backgroundColor: colors.error,
    },
    saveButton: {
      backgroundColor: colors.primary,
    },
    buttonText: {
      color: colors.card,
      fontSize: 16,
      fontWeight: 'bold',
    },
    placeholder: {
      color: colors.textSecondary,
    },
    pickerContainer: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      marginBottom: 16,
    },
    pickerOption: {
      flex: 1,
      padding: 12,
      borderWidth: 1,
      borderColor: colors.border,
      backgroundColor: colors.background,
      marginRight: 8,
      borderRadius: 8,
      alignItems: 'center',
    },
    pickerOptionSelected: {
      backgroundColor: colors.primary,
      borderColor: colors.primary,
    },
    pickerOptionText: {
      color: colors.text,
      fontSize: 14,
    },
    pickerOptionTextSelected: {
      color: colors.white,
    },
    chipContainer: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      marginBottom: 16,
    },
    chip: {
      padding: 8,
      borderWidth: 1,
      borderColor: colors.border,
      backgroundColor: colors.background,
      margin: 4,
      borderRadius: 16,
    },
    chipSelected: {
      backgroundColor: colors.primary,
      borderColor: colors.primary,
    },
    chipText: {
      color: colors.text,
      fontSize: 14,
    },
    chipTextSelected: {
      color: colors.white,
    },
    dateInputText: {
      color: colors.text,
      flex: 1,
    },
    fab: {
      position: 'absolute',
      right: 16,
      bottom: 16,
      backgroundColor: colors.primary,
      width: 56,
      height: 56,
      borderRadius: 28,
      justifyContent: 'center',
      alignItems: 'center',
      elevation: 4,
      shadowColor: colors.shadowColor,
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.25,
      shadowRadius: 4,
    },
    noResults: {
      textAlign: 'center',
      marginTop: 20,
      fontSize: 16,
      color: colors.textSecondary,
    }
  }));

  const getStatusStyle = (status) => {
    const baseStyle = styles.statusBadge;
    switch (status) {
      case 'active':
        return { ...baseStyle, backgroundColor: '#4CAF50' };
      case 'maintenance':
        return { ...baseStyle, backgroundColor: '#FFC107' };
      case 'outOfService':
        return { ...baseStyle, backgroundColor: '#F44336' };
      default:
        return baseStyle;
    }
  };

  const resetForm = () => {
    setFormData(initialFormState);
    setEditingVehicle(null);
  };

  const handleSubmit = () => {
    // Basic required fields validation
    if (!formData.licensePlate || !formData.make || !formData.model) {
      Alert.alert(t('common.error'), t('vehicles.errors.requiredFields'));
      return;
    }

    // Year validation
    const yearValidation = validateNumber(formData.year, 'year', {
      min: 1900,
      max: new Date().getFullYear() + 1,
      allowNull: false
    });

    if (!yearValidation.isValid) {
      Alert.alert(t('common.error'), t(`vehicles.errors.${yearValidation.errorKey}`));
      return;
    }

    // Odometer validation
    const odometerValidation = validateNumber(
      formData.odometer_reading,
      'odometer',
      { min: 0 }
    );

    if (!odometerValidation.isValid) {
      Alert.alert(t('common.error'), t(`vehicles.errors.${odometerValidation.errorKey}`));
      return;
    }

    const vehicleData = {
      ...formData,
      licenseTypes: JSON.stringify(formData.licenseTypes),
      features: JSON.stringify(formData.features)
    };

    if (editingVehicle) {
      updateVehicle(vehicleData);
      Alert.alert(t('common.success'), t('vehicles.success.updated'));
    } else {
      addVehicle(vehicleData);
      Alert.alert(t('common.success'), t('vehicles.success.added'));
    }

    setModalVisible(false);
    resetForm();
  };

  const handleDelete = (vehicle) => {
    Alert.alert(
      t('vehicles.delete.title'),
      t('vehicles.delete.confirm', { plate: vehicle.licensePlate }),
      [
        {
          text: t('vehicles.buttons.cancel'),
          style: 'cancel',
        },
        {
          text: t('vehicles.buttons.delete'),
          style: 'destructive',
          onPress: () => {
            deleteVehicle(vehicle.id);
            Alert.alert(t('common.success'), t('vehicles.success.deleted'));
          },
        },
      ]
    );
  };

  const handleDateChange = (event, selectedDate) => {
    setShowDatePicker(false);
    if (selectedDate && currentDateField) {
      const dateString = selectedDate.toISOString().split('T')[0];
      setFormData({
        ...formData,
        [currentDateField]: dateString,
      });
    }
  };

  // Update form data when editing
  const handleEdit = (vehicle) => {
    setEditingVehicle(vehicle);
    setFormData({
      ...vehicle,
      licenseTypes: typeof vehicle.licenseTypes === 'string'
        ? JSON.parse(vehicle.licenseTypes)
        : vehicle.licenseTypes,
      features: typeof vehicle.features === 'string'
        ? JSON.parse(vehicle.features)
        : vehicle.features,
      year: vehicle.year || null,
      odometer_reading: vehicle.odometer_reading || null
    });
    setModalVisible(true);
  };

  const renderVehicle = ({ item }) => (
    <TouchableOpacity
      style={styles.vehicleCard}
      onPress={() => handleEdit(item)}
    >
      <View style={styles.vehicleHeader}>
        <Text style={styles.vehicleTitle}>
          {item.make} {item.model} ({item.year})
        </Text>
        <TouchableOpacity onPress={() => handleDelete(item)}>
          <Feather name="trash-2" size={20} color="#FF3B30" />
        </TouchableOpacity>
      </View>

      <View style={getStatusStyle(item.status)}>
        <Text style={styles.statusText}>{t(`vehicles.status.${item.status}`)}</Text>
      </View>

      <Text style={styles.vehicleInfo}>{t('vehicles.fields.licensePlate')}: {item.licensePlate}</Text>
      <Text style={styles.vehicleInfo}>{t('vehicles.fields.transmission')}: {t(`vehicles.transmission.${item.transmission}`)}</Text>
      <Text style={styles.vehicleInfo}>
        {t('vehicles.fields.licenseTypes')}: {
          (typeof item.licenseTypes === 'string'
            ? JSON.parse(item.licenseTypes)
            : item.licenseTypes
          ).join(', ')
        }
      </Text>

      {/* Show warning if insurance or registration is expiring soon */}
      {new Date(item.insurance_expiry_date) <= new Date(Date.now() + 30 * 24 * 60 * 60 * 1000) && (
        <Text style={[styles.vehicleInfo, { color: '#FFC107' }]}>
          {t('vehicles.warnings.insuranceExpiring')}
        </Text>
      )}
    </TouchableOpacity>
  );

  return (
    <View style={styles.container}>
      <View style={styles.searchContainer}>
        <Feather
          name="search"
          size={20}
          color={styles.placeholder.color}
          style={styles.searchIcon}
        />
        <TextInput
          style={styles.searchInput}
          placeholder={t('vehicles.search')}
          placeholderTextColor={styles.placeholder.color}
          value={searchQuery}
          onChangeText={setSearchQuery}
          autoCapitalize="none"
        />
      </View>

      <FlatList
        data={filteredVehicles}
        renderItem={renderVehicle}
        keyExtractor={item => item.id}
        contentContainerStyle={styles.list}
        ListEmptyComponent={() => (
          <Text style={styles.noResults}>
            {searchQuery
              ? t('vehicles.noSearchResults')
              : t('vehicles.noVehicles')}
          </Text>
        )}
      />

      <TouchableOpacity
        style={styles.fab}
        onPress={() => {
          resetForm();
          setModalVisible(true);
        }}
      >
        <Feather name="plus" size={24} color="#fff" />
      </TouchableOpacity>

      <Modal
        visible={isModalVisible}
        animationType="slide"
        transparent={true}
        onRequestClose={() => {
          setModalVisible(false);
          resetForm();
        }}
      >
        <View style={styles.modalContainer}>
          <ScrollView style={styles.modalContent}>
            <Text style={styles.modalTitle}>
              {editingVehicle ? t('vehicles.editVehicle') : t('vehicles.addNew')}
            </Text>

            {/* Basic Information */}
            <Text style={styles.sectionTitle}>{t('vehicles.sections.basic')}</Text>
            <TextInput
              style={styles.input}
              placeholder={t('vehicles.fields.licensePlate')}
              placeholderTextColor={styles.placeholder.color}
              value={formData.licensePlate}
              onChangeText={(text) => setFormData({ ...formData, licensePlate: text })}
            />
            <TextInput
              style={styles.input}
              placeholder={t('vehicles.fields.make')}
              placeholderTextColor={styles.placeholder.color}
              value={formData.make}
              onChangeText={(text) => setFormData({ ...formData, make: text })}
            />
            <TextInput
              style={styles.input}
              placeholder={t('vehicles.fields.model')}
              placeholderTextColor={styles.placeholder.color}
              value={formData.model}
              onChangeText={(text) => setFormData({ ...formData, model: text })}
            />
            <TextInput
              style={styles.input}
              placeholder={t('vehicles.fields.year')}
              placeholderTextColor={styles.placeholder.color}
              value={formatNumberValue(formData.year)}
              onChangeText={(text) => handleNumberInput(text, 'year')}
              keyboardType="numeric"
              maxLength={4}
            />

            {/* Transmission Selection */}
            <Text style={styles.sectionTitle}>{t('vehicles.fields.transmission')}</Text>
            <View style={styles.pickerContainer}>
              <TouchableOpacity
                style={[
                  styles.pickerOption,
                  formData.transmission === 'automatic' && styles.pickerOptionSelected
                ]}
                onPress={() => setFormData({ ...formData, transmission: 'automatic' })}
              >
                <Text style={[
                  styles.pickerOptionText,
                  formData.transmission === 'automatic' && styles.pickerOptionTextSelected
                ]}>
                  {t('vehicles.transmission.automatic')}
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={[
                  styles.pickerOption,
                  formData.transmission === 'manual' && styles.pickerOptionSelected
                ]}
                onPress={() => setFormData({ ...formData, transmission: 'manual' })}
              >
                <Text style={[
                  styles.pickerOptionText,
                  formData.transmission === 'manual' && styles.pickerOptionTextSelected
                ]}>
                  {t('vehicles.transmission.manual')}
                </Text>
              </TouchableOpacity>
            </View>

            {/* Status Selection */}
            <Text style={styles.sectionTitle}>{t('vehicles.fields.status')}</Text>
            <View style={styles.pickerContainer}>
              {['active', 'maintenance', 'outOfService'].map((status) => (
                <TouchableOpacity
                  key={status}
                  style={[
                    styles.pickerOption,
                    formData.status === status && styles.pickerOptionSelected
                  ]}
                  onPress={() => setFormData({ ...formData, status })}
                >
                  <Text style={[
                    styles.pickerOptionText,
                    formData.status === status && styles.pickerOptionTextSelected
                  ]}>
                    {t(`vehicles.status.${status}`)}
                  </Text>
                </TouchableOpacity>
              ))}
            </View>

            {/* License Types */}
            <Text style={styles.sectionTitle}>{t('vehicles.fields.licenseTypes')}</Text>
            <View style={styles.chipContainer}>
              {['A', 'A1', 'B', 'C', 'D'].map((type) => (
                <TouchableOpacity
                  key={type}
                  style={[
                    styles.chip,
                    formData.licenseTypes.includes(type) && styles.chipSelected
                  ]}
                  onPress={() => {
                    const types = formData.licenseTypes.includes(type)
                      ? formData.licenseTypes.filter(t => t !== type)
                      : [...formData.licenseTypes, type];
                    setFormData({ ...formData, licenseTypes: types });
                  }}
                >
                  <Text style={[
                    styles.chipText,
                    formData.licenseTypes.includes(type) && styles.chipTextSelected
                  ]}>
                    {type}
                  </Text>
                </TouchableOpacity>
              ))}
            </View>

            {/* Insurance Information */}
            <Text style={styles.sectionTitle}>{t('vehicles.sections.insurance')}</Text>
            <TextInput
              style={styles.input}
              placeholder={t('vehicles.fields.insuranceProvider')}
              placeholderTextColor={styles.placeholder.color}
              value={formData.insurance_provider}
              onChangeText={(text) => setFormData({ ...formData, insurance_provider: text })}
            />
            <TextInput
              style={styles.input}
              placeholder={t('vehicles.fields.policyNumber')}
              placeholderTextColor={styles.placeholder.color}
              value={formData.insurance_policy_number}
              onChangeText={(text) => setFormData({ ...formData, insurance_policy_number: text })}
            />
            <TouchableOpacity
              style={[styles.input, styles.dateInput]}
              onPress={() => {
                setCurrentDateField('insurance_expiry_date');
                setShowDatePicker(true);
              }}
            >
              <Text style={styles.dateInputText}>
                {formData.insurance_expiry_date || t('vehicles.fields.insuranceExpiry')}
              </Text>
              <Feather name="calendar" size={20} color={styles.placeholder.color} />
            </TouchableOpacity>

            {/* Maintenance Information */}
            <Text style={styles.sectionTitle}>{t('vehicles.sections.maintenance')}</Text>
            <TextInput
              style={styles.input}
              placeholder={t('vehicles.fields.odometer')}
              placeholderTextColor={styles.placeholder.color}
              value={formatNumberValue(formData.odometer_reading)}
              onChangeText={(text) => handleNumberInput(text, 'odometer_reading')}
              keyboardType="numeric"
            />
            <TouchableOpacity
              style={[styles.input, styles.dateInput]}
              onPress={() => {
                setCurrentDateField('last_service_date');
                setShowDatePicker(true);
              }}
            >
              <Text style={styles.dateInputText}>
                {formData.last_service_date || t('vehicles.fields.lastService')}
              </Text>
              <Feather name="calendar" size={20} color={styles.placeholder.color} />
            </TouchableOpacity>
            <TouchableOpacity
              style={[styles.input, styles.dateInput]}
              onPress={() => {
                setCurrentDateField('next_service_due');
                setShowDatePicker(true);
              }}
            >
              <Text style={styles.dateInputText}>
                {formData.next_service_due || t('vehicles.fields.nextService')}
              </Text>
              <Feather name="calendar" size={20} color={styles.placeholder.color} />
            </TouchableOpacity>

            {/* Registration Expiry */}
            <TouchableOpacity
              style={[styles.input, styles.dateInput]}
              onPress={() => {
                setCurrentDateField('registration_expiry');
                setShowDatePicker(true);
              }}
            >
              <Text style={styles.dateInputText}>
                {formData.registration_expiry || t('vehicles.fields.registrationExpiry')}
              </Text>
              <Feather name="calendar" size={20} color={styles.placeholder.color} />
            </TouchableOpacity>

            {/* Features */}
            <Text style={styles.sectionTitle}>{t('vehicles.fields.features')}</Text>
            <View style={styles.chipContainer}>
              {[
                'dualControls',
                'parkingSensors',
                'camera',
                'abs',
                'tractionControl',
                'dashcam'
              ].map((feature) => (
                <TouchableOpacity
                  key={feature}
                  style={[
                    styles.chip,
                    formData.features.includes(feature) && styles.chipSelected
                  ]}
                  onPress={() => {
                    const features = formData.features.includes(feature)
                      ? formData.features.filter(f => f !== feature)
                      : [...formData.features, feature];
                    setFormData({ ...formData, features });
                  }}
                >
                  <Text style={[
                    styles.chipText,
                    formData.features.includes(feature) && styles.chipTextSelected
                  ]}>
                    {t(`vehicles.features.${feature}`)}
                  </Text>
                </TouchableOpacity>
              ))}
            </View>

            {/* Notes */}
            <Text style={styles.sectionTitle}>{t('vehicles.sections.notes')}</Text>
            <TextInput
              style={[styles.input, { height: 100, textAlignVertical: 'top' }]}
              placeholder={t('vehicles.fields.notes')}
              placeholderTextColor={styles.placeholder.color}
              value={formData.notes}
              onChangeText={(text) => setFormData({ ...formData, notes: text })}
              multiline
              numberOfLines={4}
            />

            {/* Action Buttons */}
            <View style={styles.modalButtons}>
              <TouchableOpacity
                style={[styles.button, styles.cancelButton]}
                onPress={() => {
                  setModalVisible(false);
                  resetForm();
                }}
              >
                <Text style={styles.buttonText}>{t('common.cancel')}</Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={[styles.button, styles.saveButton]}
                onPress={handleSubmit}
              >
                <Text style={styles.buttonText}>
                  {editingVehicle ? t('common.update') : t('common.save')}
                </Text>
              </TouchableOpacity>
            </View>
          </ScrollView>
        </View>
      </Modal>

      {showDatePicker && (
        <DateTimePicker
          value={new Date()}
          mode="date"
          display="default"
          onChange={handleDateChange}
        />
      )}

    </View>
  );
}