// components/AccountSelector.js
import React, { useState, useMemo } from 'react';
import { View, Text, TouchableOpacity, Modal, FlatList, TextInput } from 'react-native';
import { Feather } from '@expo/vector-icons';
import { useTranslation } from 'react-i18next';
import { useThemedStyles } from '../../theme/hooks';
import { ACCOUNT_TYPES } from '../../constants';

export const AccountSelector = ({
    label,
    value,
    accounts,
    onChange,
    accountType,
    disabled = false,
    placeholder,
    required = false,
    validateAccount = null
}) => {
    const { t } = useTranslation();
    const [showPicker, setShowPicker] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [error, setError] = useState(null);

    const filteredAccounts = useMemo(() => {
        let filtered = accounts || [];

        if (searchQuery) {
            const query = searchQuery.toLowerCase();
            filtered = filtered.filter(account => {
                // Get the display name based on account type
                const displayName = getDisplayName(account);
                const accountNumber = account.accountNumber || account.accounting_id?.toString();

                return (
                    displayName.toLowerCase().includes(query) ||
                    (accountNumber && accountNumber.toLowerCase().includes(query))
                );
            });
        }

        return filtered;
    }, [accounts, searchQuery]);

    const getDisplayName = (account) => {
        // Handle entity (student/supplier)
        if (account.firstName || account.lastName) {
            return `${account.firstName} ${account.lastName}`.trim();
        }
        if (account.name) {
            return account.name;
        }
        // Handle regular account
        return account.accountName || '';
    };

    const handleAccountSelect = (account) => {
        let validationError = null;
        if (validateAccount) {
            validationError = validateAccount(account);
        }

        if (validationError) {
            setError(validationError);
            return;
        }

        const formattedAccount = {
            ...account,
            // Ensure we always have accounting_id
            accounting_id: account.accounting_id || account.accountNumber
        };

        setError(null);
        onChange(account);
        setShowPicker(false);
        setSearchQuery('');
    };

    const getDisplayValue = (value) => {
        if (!value) return placeholder || '';
        return getDisplayName(value);
    };

    const styles = useThemedStyles((colors) => ({
        fieldContainer: {
            marginBottom: 16,
        },
        labelContainer: {
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: 8,
        },
        label: {
            fontSize: 14,
            color: colors.textSecondary,
        },
        requiredIndicator: {
            color: colors.error,
            marginLeft: 4,
        },
        pickerButton: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderWidth: 1,
            borderColor: error ? colors.error : colors.border,
            borderRadius: 8,
            padding: 12,
            backgroundColor: colors.background,
        },
        pickerButtonDisabled: {
            opacity: 0.6,
        },
        pickerLabel: {
            fontSize: 16,
            color: colors.text,
        },
        pickerLabelDisabled: {
            color: colors.textSecondary,
        },
        modalOverlay: {
            flex: 1,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            justifyContent: 'flex-end',
        },
        modalContent: {
            backgroundColor: colors.card,
            borderTopLeftRadius: 16,
            borderTopRightRadius: 16,
            maxHeight: '80%',
        },
        modalHeader: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: 16,
            borderBottomWidth: 1,
            borderBottomColor: colors.border,
        },
        modalTitle: {
            fontSize: 20,
            fontWeight: 'bold',
            color: colors.text,
        },
        searchInput: {
            borderWidth: 1,
            borderColor: colors.border,
            borderRadius: 8,
            padding: 12,
            margin: 16,
            fontSize: 16,
            color: colors.text,
        },
        accountItem: {
            padding: 16,
            borderBottomWidth: 1,
            borderBottomColor: colors.border,
        },
        accountName: {
            fontSize: 16,
            color: colors.text,
            marginBottom: 4,
        },
        accountNumber: {
            fontSize: 14,
            color: colors.textSecondary,
        },
        closeButton: {
            padding: 8,
        },
        closeIcon: {
            color: colors.textSecondary,
        },
        error: {
            color: colors.error,
            fontSize: 12,
            marginTop: 4,
            marginLeft: 4,
        }
    }));

    return (
        <View style={styles.fieldContainer}>
            <View style={styles.labelContainer}>
                <Text style={styles.label}>{label}</Text>
                {required && <Text style={styles.requiredIndicator}>*</Text>}
            </View>

            <TouchableOpacity
                style={[styles.pickerButton, disabled && styles.pickerButtonDisabled]}
                onPress={() => !disabled && setShowPicker(true)}
                disabled={disabled}
            >
                <Text style={[styles.pickerLabel, disabled && styles.pickerLabelDisabled]}>
                    {getDisplayValue(value)}
                </Text>
                {!disabled && <Feather name="chevron-down" size={20} style={styles.closeIcon} />}
            </TouchableOpacity>

            {error && <Text style={styles.error}>{error}</Text>}

            <Modal
                visible={showPicker}
                transparent
                animationType="fade"
                onRequestClose={() => {
                    setShowPicker(false);
                    setSearchQuery('');
                }}
            >
                <View style={styles.modalOverlay}>
                    <View style={styles.modalContent}>
                        <View style={styles.modalHeader}>
                            <Text style={styles.modalTitle}>{label}</Text>
                            <TouchableOpacity
                                style={styles.closeButton}
                                onPress={() => {
                                    setShowPicker(false);
                                    setSearchQuery('');
                                }}
                            >
                                <Feather name="x" size={24} style={styles.closeIcon} />
                            </TouchableOpacity>
                        </View>

                        <TextInput
                            style={styles.searchInput}
                            placeholder={t('common.search')}
                            value={searchQuery}
                            onChangeText={setSearchQuery}
                        />

                        <FlatList
                            data={filteredAccounts}
                            keyExtractor={item => item.accountNumber || item.accounting_id?.toString() || item.id?.toString()}
                            renderItem={({ item }) => (
                                <TouchableOpacity
                                    style={styles.accountItem}
                                    onPress={() => handleAccountSelect(item)}
                                >
                                    <Text style={styles.accountName}>{getDisplayName(item)}</Text>
                                    <Text style={styles.accountNumber}>
                                        {item.accountNumber || item.accounting_id?.toString() || ''}
                                    </Text>
                                </TouchableOpacity>
                            )}
                        />
                    </View>
                </View>
            </Modal>
        </View>
    );
};